import React, { Fragment } from "react";
import { Datagrid, List, TextField, TopToolbar, useRedirect, BulkDeleteButton, EditButton, DeleteButton, ReferenceField,
  Filter, SearchInput, useListContext, usePermissions} from "react-admin";
import { makeStyles, Box, Typography, ThemeProvider, TablePagination} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import * as locales from '@material-ui/core/locale';
import Fab from '@mui/material/Fab';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  BulkDeletePostButton: lightTheme.BulkDeletePostButton,
  CompanyTable: lightTheme.CompanyTable,
  PostViewCard: lightTheme.PostViewCard,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  RightSerchBar: lightTheme.CompanyStyle.List.RightSerchBar,
  root: lightTheme.CompanyStyle.List.root,
});

const BulkDeleteCompanyButton = ({ basePath }) => {
  return(
    <Fragment>
      <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Seleccionados</div>} aria-label="Delete" arrow placement="left">
        <BulkDeleteButton label="" basePath={basePath} id="GoogleStyleBulkButton"/>
      </MyTooltip>
    </Fragment>
  )
};

const CompanyFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn variant="outlined" className={classes.root}/>
    </Filter>
  )
};

const CompanyEditActions = () => {
  const redirect = useRedirect();
  return(
      <TopToolbar>
          <Box style={{position: 'fixed', bottom: '25px', right: '30px', zIndex: '999'}}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Sociedad</div>} arrow placement="left">
                  <Fab color="primary" aria-label="add" onClick={() => { redirect(`/company/create`); }}>
                      <AddRoundedIcon />
                  </Fab>
              </MyTooltip>
          </Box>
      </TopToolbar>
  )
};

const CompanyPagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();

  const handleChangeRowsPerPage = (event) => { 
      setPerPage(parseInt(event.target.value, 10));
      setPage(1);
  };
  const handleChangePage = (event, newPage) => {
      setPage(newPage + 1);
   };
  
  return (
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
          <TablePagination
              component="div"
              rowsPerPageOptions={[8, 10, 12, 15, 20]}
              count={total || 0}
              page={page - 1}
              onChangePage ={handleChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </ThemeProvider>
  );
}

const CompanyList = props => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  return (
    <Box className={classes.RightSerchBar}>
      <Box display="flex" style={{ width: '100%', position: 'relative', top: '8px'}} justifyContent="flex-start">
        <Typography className={classes.PostTitle}>Sociedades</Typography>
        <Typography className={classes.PostSubTitle}>&nbsp;Gestiona, Edita y Consulta</Typography>
      </Box>
      <List {...props} actions={<CompanyEditActions />} bulkActionButtons={<BulkDeleteCompanyButton {...props} />} filters={<CompanyFilter/>} 
        pagination={<CompanyPagination/>} perPage={8} style={{marginBottom: '5em'}}>
        <Datagrid rowClick="show" className={classes.CompanyTable}>
          <TextField label="Nombre" source="name" />
          <ReferenceField label="Provincia" source="state" reference="state">
            <TextField source="name" />
          </ReferenceField>
          <TextField label="Email" source="contact[0].email" />
          <TextField label="Teléfono" source="contact[0].phone" />
          {permissions === 'Admin' &&
              <MyTooltip title={<div style={{fontSize: '12px'}}>Editar</div>} aria-label="edit" arrow placement="bottom">
                  <EditButton basePath="/company" label="" record={props} id="GoogleStyleButton"/>
              </MyTooltip>
          }
          {permissions === 'Admin' &&
              <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar</div>} aria-label="delete" arrow placement="bottom">
                  <DeleteButton basePath="/company" label="" record={props} id="GoogleStyleDeleteButton"/>
              </MyTooltip>
          }
        </Datagrid>
      </List>
    </Box>
  )
};

export default CompanyList;