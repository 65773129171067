import React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, useGetList, useRedirect, MenuItemLink, usePermissions, Link } from 'react-admin';
import { makeStyles, Box, Button, SwipeableDrawer, Typography, Modal, Backdrop, Fade, Divider } from '@material-ui/core';
import Badge from '@mui/material/Badge';
import Logo from './Logo';
import MyTooltip from "../components/MuiTooltip";
import CloseButton from '../components/CloseButton';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import Avatar from '@material-ui/core/Avatar';
import firebase from 'firebase';
import lightTheme from '../layout/themes';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import BasketList from '../basket/BasketList';
import BasketListInfo from '../basket/BasketListInfo';
import OrdersCreate from '../orders/OrdersCreate';
import clsx from 'clsx';

const useStyles = makeStyles({
    MuiModal: lightTheme.CommonStyles.Modal.MuiModal,
    MuiPaper: lightTheme.CommonStyles.Modal.MuiPaper,
    AboutModal: lightTheme.AppBarStyles.AboutModal,
    AboutPaper: lightTheme.AppBarStyles.AboutPaper,
    FlexCenter: lightTheme.AppBarStyles.FlexCenter,
    FlexCenterBox: lightTheme.AppBarStyles.FlexCenterBox,
    FlexStartTitle: lightTheme.AppBarStyles.FlexStartTitle,
    FlexEndDescription: lightTheme.AppBarStyles.FlexEndDescription,
    FixedAppBar: lightTheme.AppBarStyles.FixedAppBar,
    title: lightTheme.AppBarStyles.title,
    spacer: lightTheme.AppBarStyles.spacer,
    avatar: lightTheme.AppBarStyles.avatar,
    list: lightTheme.AppBarStyles.list,
    fullList: lightTheme.AppBarStyles.fullList,
    basketButton: lightTheme.AppBarStyles.basketButton,
    TramitarButton: lightTheme.AppBarStyles.TramitarButton,
    MenuItem: lightTheme.AppBarStyles.MenuItem,
    ProfileMenuItem: lightTheme.AppBarStyles.ProfileMenuItem,
    LogoBox:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '15px',
        '& h6':{
            color: '#fff',
        }
    },
    basketDrawer:{
        '& .MuiDrawer-paper': {
            overflow: 'hidden !important',
        }
    }
});


const MyCustomIcon = () => {
    const classes = useStyles();
    return (
        <Avatar
            className={classes.avatar}
            src="https://firebasestorage.googleapis.com/v0/b/trendico-intranet.appspot.com/o/Pictures%2FAvatar.png?alt=media&token=c48688c8-d6fb-4f3c-8217-1f5c81f853a6"
        />
    )
};

const UserProfile = forwardRef((props, ref) => {
    const classes = useStyles();
    const user = firebase.auth().currentUser;
    return (
        <Box>
            <Box className={classes.ProfileMenuItem}>
                <div id="infoBox">Esta cuenta la gestiona trendico.es. <spam>Más información</spam></div>
                <img src="static/images/ProfileImage2.png" alt="ProfilePic"></img>
                <div id="UserInfo">
                    <span id="UserName">{user.displayName}</span><br/>
                    <span id="UserEmail">{user.email}</span>
                </div>
                <MenuItemLink
                    id="ProfileButton"
                    ref={ref}
                    to="/profile"
                    primaryText="Acceder a mi perfil de Trendico"
                    onClick={props.onClick}
                    sidebarIsOpen
                />
            </Box>
            <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
        </Box>
    );
});

const CustomUserMenu = (props) => {
    return(
        <UserMenu {...props} icon={<MyCustomIcon />}>
            <UserProfile />
        </UserMenu>
    )
};

let fakeProps = {basePath: "/basket", resource: "basket",}
let BasketProps = {basePath: "/basket", resource: "basket",}

const CustomAppBar = props => {
    const classes = useStyles();
    const user = firebase.auth().currentUser;
    const { permissions } = usePermissions();

    const BasketQTY = ({ id }) => {
        const { ids } = useGetList('basket', { page: 1, perPage: 200 }, { field: 'name', order: 'ASC'}, { user_id: id } );
        if (ids) return (ids.length);
    };
    
    const BasketTotal = ({ id }) => {
        const { data, ids } = useGetList('basket', { page: 1, perPage: 200 }, { field: 'name', order: 'ASC'}, { user_id: id } );
        const Precios = ids.map(id => (data[id].variant_price * data[id].cantidad));
        let sum = 0;
        for (let i = 0; i < Precios.length; i++) {
            sum += Precios[i];
        }
        return (sum.toFixed(2).replace('.', ','));
    };

    const anchor = 'right';
    const [state, setState] = React.useState({right: false,});

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }
        setState({ ...state, [anchor]: open });
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {setOpen(true)};
    const handleClose = () => {setOpen(false)};
    const redirect = useRedirect();
    const handleClick = () => {
        redirect('/faq');
    }
    const handleClick2 = () => {
        redirect('/admin');
    }

    var ToolbarElement = document.getElementsByClassName("MuiToolbar-root")[0];
    var SideMenuElement = document.getElementById('FixedSideNavBar');
    if(ToolbarElement && SideMenuElement){
        var MenuButton = ToolbarElement.firstChild;
        MenuButton.onclick = function(){SideMenuElement.classList.toggle('hidden-menu')}
    }

    let OrderProps = {basePath: "/orders", resource: "orders", anchor: anchor}
    const list = ({anchor}) => (
        <Box>
            <div className={clsx(classes.list, {[classes.fullList]: anchor === 'top' || anchor === 'bottom'})}
                role="presentation"
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <Box style={{display: 'flex', justifyContent: 'center', paddingTop:'20px'}}>
                <Typography>
                    Subtotal: <span style={{color: 'rgb(182, 54, 21)'}}><BasketTotal id={user.uid}/> €</span>
                </Typography>
                </Box>
                <Box className={classes.TramitarButton}>
                    <Button onClick={handleOpen}>Tramitar Pedido</Button>
                </Box>
                <BasketList {...fakeProps} filter={{ user_id: user.uid }}/>
            </div>

            <div>
                <Modal className={classes.MuiModal} open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
                    <Fade in={open}>
                        <div className={classes.MuiPaper} style={{width: '800px'}}>
                        <Box id="TitleBox">
                            <Box id="Title">
                                <h2>Terminar Pedido</h2>
                            </Box>
                            <Box id="CloseButton">
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                                <CloseButton style={{paddingLeft: '17px',  paddingRight: '5px'}} onClick={handleClose}></CloseButton>
                                </MyTooltip>
                            </Box>
                        </Box>
                        <BasketListInfo {...BasketProps} filter={{ user_id: user.uid }}/>
                        <Box style={{display: 'flex', justifyContent: 'flex-start', paddingTop:'20px', marginLeft: '5px'}}>
                            <Typography>
                                Subtotal: <span style={{color: 'rgb(182, 54, 21)'}}><BasketTotal id={user.uid}/> €</span>
                            </Typography>
                        </Box>
                        <OrdersCreate {...OrderProps} toggleModal={setOpen} toggleSidebar={setState}/>
                        </div>
                    </Fade>
                </Modal>
            </div>

        </Box>
    );

    return (
        <Box className={classes.FixedAppBar}>
            <AppBar {...props} userMenu={<CustomUserMenu />} elevation={1}>
                <Link to={'/dashboard'}>
                    <Box className={classes.LogoBox}>
                        <Logo /> 
                        {process.env.REACT_APP_TEST_MODE === 'true' &&
                            <Typography variant="h6">Test Mode</Typography>
                        }
                    </Box>
                </Link>
                <span className={classes.spacer} />   
                <Box display="flex" justifyContent="flex-end" className={classes.basketButton}>
                    {(permissions === 'Admin' || permissions === 'Central') &&
                        <MyTooltip title={<div style={{fontSize: '12px'}}>Panel de Administrador</div>}>
                            <Button startIcon={<SecurityRoundedIcon style={{fontSize: '1.4rem'}}/>} id="GoogleStyleBasket" onClick={handleClick2}></Button>
                        </MyTooltip>
                    }
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Preguntas Frecuentes</div>}>
                        <Button startIcon={<HelpOutlineRoundedIcon style={{fontSize: '1.5rem'}}/>} id="GoogleStyleBasket" onClick={handleClick}></Button>
                    </MyTooltip>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Mi Cesta</div>}>
                        <Badge badgeContent={<BasketQTY id={user.uid}/>}>
                            <Button startIcon={<ShoppingCartIcon/>} id="GoogleStyleBasket" onClick={toggleDrawer(anchor, true)}></Button>
                        </Badge>
                    </MyTooltip>
                    <div>
                        {['right'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <SwipeableDrawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            onOpen={toggleDrawer(anchor, true)}
                            className={classes.basketDrawer}
                            >
                            {list(anchor)}
                            </SwipeableDrawer>
                        </React.Fragment>
                        ))}
                    </div>
                </Box>
            </AppBar>
        </Box>
    );
};

export default CustomAppBar;
