import React from "react";
import { Create, SimpleForm, TextInput, Toolbar, SaveButton, required, AutocompleteInput, ReferenceInput } from "react-admin";
import { Box, makeStyles, CircularProgress} from '@material-ui/core';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import lightTheme from '../layout/themes';
import MyTooltip from '../components/MuiTooltip';
import '../index.css';

const useStyles = makeStyles({
    BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
    CardUsersEdit: lightTheme.CardUsersEdit,
    EditToolBar: lightTheme.EditToolBar,
});

const StateCreate = props => {
    const classes = useStyles();
    const redirect = () => `/state`;

    const StateEditToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        return (
          <Box>
            <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Provincia</div>} aria-label="Save" arrow placement="right">
                <SaveButton disabled={props.pristine} redirect={`/state`} id="GoogleStyleSaveButton" label="Crear" icon={<AddRoundedIcon/>}>
                  {loading && (<CircularProgress/>)}
                </SaveButton>
              </MyTooltip>
            </Toolbar>
          </Box>
        )
    };

    return (
      <Create {...props}>
        <SimpleForm redirect={redirect} toolbar={<StateEditToolbar/>}>
          <Box style={{ width: '100%', display: 'flex' }}>
            <TextInput label="Nombre" source="name" variant="outlined" style={{width: '60%', marginRight: '10px'}}
              multiline="true" fullWidth className={classes.BlackInput} validate={required()}/> 
              <ReferenceInput source="country" reference="country" filterToQuery={searchText => ({ name: searchText })} 
                label="País" className={classes.BlackInput} variant="outlined" style={{width: '40%'}} validate={required()}>
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
          </Box>
        </SimpleForm>
      </Create>
    )
};

export default StateCreate;