import React from 'react';

const glyphs = {

  // PDF //
  acrobat: (
    <path 
      fill="#db4437" 
      d="M12.5,10.47h1.35v4.06H12.5V10.47 M5.74,11.82h1.35v-1.35H5.74V11.82 M23.32,4.38v16.23c0,1.49-1.22,2.71-2.71,2.71H4.38
      c-1.49,0-2.71-1.22-2.71-2.71V4.38c0-1.49,1.22-2.71,2.71-2.71h16.23C22.11,1.68,23.32,2.89,23.32,4.38 M9.12,10.47
      c0-1.12-0.91-2.03-2.03-2.03H3.71v8.12h2.03v-2.71h1.35c1.12,0,2.03-0.91,2.03-2.03V10.47 M15.88,10.47c0-1.12-0.91-2.03-2.03-2.03
      h-3.38v8.12h3.38c1.12,0,2.03-0.91,2.03-2.03V10.47 M21.29,8.44h-4.06v8.12h2.03v-2.71h2.03v-2.03h-2.03v-1.35h2.03V8.44z"
    />
  ),

  // WORD //
  document: (
    <path
      fill="#4b87e4"
      d="M16.72,18.52H14.91l-2.41-9-2.41,9H8.28l-2.89-12H7.44l1.86,9,2.36-9h1.68l2.38,9,1.84-9h2.05m1.32-4.82H4.07A2.4,2.4,0,0,0,1.66,4.07V20.93a2.41,
      2.41,0,0,0,2.41,2.41H20.93a2.41,2.41,0,0,0,2.41-2.41V4.07A2.4,2.4,0,0,0,20.93,1.66Z"
    />
  ),

  // IMAGE //
  image: (
    <path
      fill="#d93025" 
      d="M8.28,14.31l3,3.61,4.21-5.42,5.42,7.23H4.07m19.27,1.2V4.07a2.4,2.4,0,0,0-2.41-2.41H4.07A2.41,2.41,0,0,0,1.66,4.07V20.93a2.41,
      2.41,0,0,0,2.41,2.41H20.93A2.41,2.41,0,0,0,23.34,20.93Z"
    />
  ),

  // POWER POINT //
  presentation: (
    <path 
      fill="#fd7541" 
      d="M9.85,14.19h3a4.53,4.53,0,0,0,3.37-1,3.48,3.48,0,0,0,1.09-2.83,3.6,3.6,0,0,0-1.09-2.83,4.26,4.26,0,0,0-3.37-1.06H7.68v12H9.85V14.19M20.93,
      1.66a2.41,2.41,0,0,1,2.41,2.41V20.93a2.41,2.41,0,0,1-2.41,2.41H4.07a2.41,2.41,0,0,1-2.41-2.41V4.07A2.4,2.4,0,0,1,4.07,
      1.66H20.93M9.85,12.5V8.16h2.77a2.44,2.44,0,0,1,1.81.73,2,2,0,0,1,.6,1.49,2,2,0,0,1-.6,1.52,2.19,2.19,0,0,1-1.67.6Z"
    />
  ),

  // EXCEL //
  spreadsheet: (
    <path 
      fill="#0f9d58" 
      d="M17.27,18.52H15L12.5,14,10,18.52H7.73l3.63-6-3.63-6H10l2.5,4.57L15,6.48h2.27l-3.63,6M20.45,1.66H4.55A2.33,2.33,0,0,0,2.28,
      4.07V20.93a2.35,2.35,0,0,0,2.27,2.41h15.9a2.35,2.35,0,0,0,2.27-2.41V4.07A2.34,2.34,0,0,0,20.45,1.66Z"
    />
  ),
}; 

export default glyphs;
