import React from "react";
import { 
    Show,SimpleShowLayout, TextField, usePermissions, useGetOne, TopToolbar, 
    DeleteButton, EditButton, CloneButton, LinearProgress
} from "react-admin";
import { Box, makeStyles, Button } from '@material-ui/core';
import { FileIcon, defaultStyles } from '../components/FileIcon';
import { mdiDownloadCircleOutline  } from '@mdi/js';
import Icon from '@mdi/react'
import ToolbarBackButton from '../components/ToolbarBackButton';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from "../layout/themes";
import CloneIcon from '@material-ui/icons/FilterNoneRounded';

const DownloadIcon = () => {return(<Icon path={mdiDownloadCircleOutline } size={0.9}/>)};

const useStyles = makeStyles({
    HoverTitle: lightTheme.FilesGridHoverTitle,
    FilesGridHiddenTitle: lightTheme.FilesGridHiddenTitle,
    PostTitle: lightTheme.PostTitle,
    PostSubTitle: lightTheme.PostSubTitle,
    EmptyBubble: lightTheme.EmptyBubble,
    EmptyFilesMediaCard: lightTheme.EmptyFilesMediaCard,
    BodyBox:{
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
    },
    OfferButtons:{
      marginLeft: '-5px',
      padding: '10px',
      '& .MuiButtonBase-root':{
          color: '#196cdb !important',
          textTransform: 'capitalize !important',
      },
      '& .MuiButtonBase-root:hover':{
          backgroundColor: '#196cdb2e !important',
      }
    },
    NewsCard:{
        padding: '5px',
    },
    LinkText:{
        textTransform: 'capitalize !important',
        color: '#196cdb !important',
    },
    DetailsBox:{
        display: 'flex',
        marginTop: '10px',
        '& #LogoBox':{
            '& #Icon':{
                '& svg':{
                    width: '200px !important',
                    height: '200px !important',
                    marginLeft: '-15px',
                }
            },
            '& Button':{

            }
        },
        '& #Details':{
            width: '100%',
            padding: '15px',
            '& #Line':{ 
                display: 'flex',
                width: '100%',
            },
            '& #Header':{
                color: '#7d8085',
                paddingBottom: '15px',
                paddingRight: '20px',
                display: 'flex',
                justifyContent: 'flex-start',
                width: '20%'
            },
            '& #Text':{
                paddingBottom: '15px',
                display: 'flex',
                justifyContent: 'flex-start',
                width: '80%',
                '& svg':{
                    marginRight: '10px',
                    marginTop: '-3px',
                },
                '& p':{
                    marginTop: '0px !important',
                },
                '& a':{
                    color: '#196cdb !important',
                    textDecoration: 'none'
                }
            }
        }
    }
});

const CategotyName = ({ id }) => {
    const { data, loading, error } = useGetOne('filesCategories', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return ( data.name )};
};

const UserName = ({ id }) => {
    const { data, loading, error } = useGetOne('users', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return ( data.displayName )};
};

const ProviderName = ({ dataId }) => {
    const classes = useStyles();
    const { data, loading, error } = useGetOne('provider', dataId);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return (<span className={classes.LinkText}>{data.name}</span>)};
};

const CampaignName = ({ dataId }) => {
    const classes = useStyles();
    const { data, loading, error } = useGetOne('campaigns', dataId);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return (<span className={classes.LinkText}>{data.name}</span>)};
};

const FileView = (props) => {
    const { permissions } = usePermissions();
    const classes = useStyles();
    const DateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const { data } = useGetOne('files', props.id);

    const OffersViewActions = ({ basePath, resource }) => {
        const { data } = useGetOne('files', props.id);
        return(
          <TopToolbar style={{paddingBottom: '2px', marginTop: '8px'}} id="GoogleStyleToolBar">
            {data &&
                <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Volver Atrás</div>} arrow>
                        <ToolbarBackButton style={{paddingLeft: '17px', paddingRight: '5px', marginRight: '10px'}} id="GoogleStyleBackButton"></ToolbarBackButton>
                    </MyTooltip>
                    {permissions === "Admin"  &&
                        <Box display="flex" justifyContent="flex-start">
                          <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Archivo</div>} arrow>
                              <DeleteButton label="" basePath={basePath} record={data} resource={resource} id="GoogleStyleDeleteButton"/>
                          </MyTooltip>           
                          <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Archivo</div>} arrow>
                              <EditButton label="" type="link" to={`/files/${props.id}/edit`} id="GoogleStyleButton"/>
                          </MyTooltip>
                          <MyTooltip title={<div style={{fontSize: '12px'}}>Clonar Archivo</div>} arrow>
                              <CloneButton label="" icon={<CloneIcon /> } basePath={basePath} resource={resource} id="GoogleStyleButton"
                                record={{
                                    name: data.name, campaign: data.campaign, category: data.category, shop: data.shop, categoryName: data.categoryName, 
                                    condition_id: data.condition_id, notice_id: data.notice_id, offer_id: data.offer_id, provider: data.provider, 
                                    type: data.type, user: data.user, description: data.description, fileUrl: data.fileUrl
                                }}
                              />
                          </MyTooltip>
                        </Box>
                    }
                </Box>
            }
          </TopToolbar>
        )
    };

    return (
        <Show {...props} actions={<OffersViewActions />}>
            <SimpleShowLayout >
                {data &&
                    <Box className={classes.NewsCard}>
                        <div id="Title"><TextField style={{fontSize: '2.0rem'}} source="name" multiline="true" fullWidth /></div>
                        <Box className={classes.DetailsBox}>
                            <Box id="LogoBox">
                                <Box id="Icon">
                                    {data.type === 'PDF' &&
                                        <FileIcon extension="pdf" {...defaultStyles.pdf} />
                                    }
                                    {data.type === 'Excel' &&
                                        <FileIcon extension="xlsx" {...defaultStyles.xlsx} />
                                    }
                                    {data.type === 'PowerPoint' &&
                                        <FileIcon extension="pptx" {...defaultStyles.pptx} />
                                    }
                                    {data.type === 'Word' &&
                                        <FileIcon extension="docx" {...defaultStyles.docx} />
                                    }
                                    {data.type === 'Imagen' &&
                                        <FileIcon extension="png" {...defaultStyles.png} />
                                    }
                                </Box>
                                <Box id="Button">
                                    <div className={classes.OfferButtons}>
                                        <Button startIcon={<DownloadIcon/>} onClick={() => window.open(`${data.fileUrl.src}`, `_blank`)}>
                                            Descargar Arhcivo
                                        </Button>
                                    </div>
                                </Box>
                            </Box>
                            <Box id="Details">
                                <Box id="Line"><span id="Header">Ubicación</span><span id="Text"><FolderRoundedIcon/><CategotyName id={data.category}/></span><br/></Box>
                                {data.category === 'eLRVRilYd5sdIR7w8Zl3' &&
                                    <Box id="Line">
                                        <span id="Header">Detalles</span>
                                        <span id="Text">
                                            Campaña&nbsp;<CampaignName dataId={data.campaign}/>&nbsp;del Proveedor&nbsp;<ProviderName dataId={data.provider}/>
                                        </span><br/>
                                    </Box>
                                }
                                <Box id="Line"><span id="Header">Tipo</span><span id="Text">{data.type}</span><br/></Box>
                                <Box id="Line"><span id="Header">Autor</span><span id="Text"><UserName id={data.user}/></span><br/></Box>
                                <Box id="Line"><span id="Header">Fecha Modificación</span>
                                    <span id="Text">
                                        {new Date(data.lastupdate).toLocaleDateString("es-ES", DateOptions)}
                                    </span><br/>
                                </Box>
                                <Box id="Line"><span id="Header">Nombre Archivo</span><span id="Text">{data.fileUrl.title}</span><br/></Box>
                                <Box id="Line">
                                    <span id="Header">Comentarios</span>
                                    <span id="Text"><div dangerouslySetInnerHTML={{ __html: data.description }}/></span>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                }
            </SimpleShowLayout>
        </Show>
    );
}; export default FileView;