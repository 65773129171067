import React from "react";
import { List, useGetOne, LinearProgress, usePermissions, useRedirect, ReferenceInput, AutocompleteInput,
    useListContext, Filter
} from "react-admin"
import { Box, Typography, makeStyles, ThemeProvider, TablePagination, Fab } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import * as locales from '@material-ui/core/locale';
import Skeleton from '@mui/material/Skeleton';
import lightTheme from '../layout/themes';
import MyTooltip from '../components/MuiTooltip';
import sanitizeHtml from 'sanitize-html';
import AddCommentRoundedIcon from '@mui/icons-material/AddCommentRounded';
import moment from 'moment';
import 'moment/locale/es';


const useStyles = makeStyles({
    image: lightTheme.NewsStyles.NewsList.image,
    TopToolbarCustom: lightTheme.NewsStyles.NewsList.TopToolbarCustom,
    boxTitle: lightTheme.NewsStyles.NewsList.boxTitle,
    TopTitle: lightTheme.CommonStyles.ListItems.TopTitle,
    TopSubTitle: lightTheme.CommonStyles.ListItems.TopSubTitle,
    TooltipTitle: lightTheme.CommonStyles.ListItems.TooltipTitle,
    btnNotify: lightTheme.NewsStyles.NewsList.btnNotify,
    ListBackground: lightTheme.NewsStyles.NewsList.ListBackground_RightSearchBar,
    NewsCard: lightTheme.NewsStyles.NewsList.NewsCard,
    ShopsBar: lightTheme.NewsStyles.NewsList.ShopsBar,
    skeletonNewsList:lightTheme.NewsStyles.NewsList.skeletonNewsList,
    BlackInputMargin: lightTheme.CommonStyles.CreateAndEdit.BlackInputMargin,
    NewsActionBar:{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    }
});

const CategotyName = ({ id }) => {
    const { data, loading, error } = useGetOne('newsCategories', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return (data.name); }
};

const SubCategotyName = ({ id }) => {
    const { data, loading, error } = useGetOne('newsSubCategories', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return (data.name); }
};

const PublicationsBody = ({ body , index}) => {
    if (body && index) { 
        const CleanHTML = sanitizeHtml(body, {
            disallowedTags: [ 'img' ]
        })
        return (<div dangerouslySetInnerHTML={{ __html: CleanHTML }} id={index}/>); 
    }
};

const ShopName = ({ id }) => {
    const { data, loading } = useGetOne('shops', id);
    if (loading) { return <LinearProgress /> }
    if (data) { 
        return (
            <div>
                {id === '618bd896efea2b2049ada486' &&
                    <img alt="logo" src="/static/images/Shops/TrendicoFaviconRounded.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada489' &&
                    <img alt="logo" src="/static/images/Shops/SotkattLogo.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada488' &&
                    <img alt="logo" src="/static/images/Shops/AtleetLogo.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada487' &&
                    <img alt="logo" src="/static/images/Shops/FoMLogo.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada490' &&
                    <img alt="logo" src="/static/images/Shops/TrendicoFaviconRounded.png" id="LogoImg"/>
                }
                <span>{data.name}</span>
            </div>
        );
    }
};

const DaysPassed = ({ date }) => {
    const FormatDate = moment(date).format();
    const LongDate = moment(date).format('DD MMM YYYY');
    const ShortDate = moment(`${FormatDate}`).locale('es').fromNow();
    if (moment().subtract(60, 'days').valueOf() < moment(date).valueOf()) {
        return ( 
            <span style={{textTransform: 'capitalize'}}>{ShortDate}</span>
        )
    };
    if (moment().subtract(60, 'days').valueOf() > moment(date).valueOf()) {
        return ( 
            <span style={{textTransform: 'capitalize'}}>{LongDate}</span>
        )
    };
};


const PublicationsList = (props) => {
    const classes = useStyles();

    const PublicationsGrid = () => { 
        const { data, ids, loading } = useListContext();
        const classes = useStyles();
        const redirect = useRedirect();

        const SkeletonData = {1: {key: 1, value: 1},2: {key: 2, value: 2},3: {key: 3, value: 3},4: {key: 4, value: 4},5: {key: 5, value: 5}};
        const SkeletonIds = [1,2,3,4,5];

        return (
            <div>
                {loading === false ?
                    <Box>
                        {ids.map(id => (
                            <Box key={id}>
                                {data[id] &&
                                    <div className={classes.NewsCard} onClick={() => redirect(`/publications/${data[id].id}/show`)}>
                                        <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                            <Box style={{display: 'flex', justifyContent: 'flex-start', width: '100%', flexDirection: 'column'}}>
                                                <Box className={classes.ShopsBar}>
                                                    {data[id].shops && data[id].shops.map(shop => (
                                                        <Typography className="ShopBadge" id={shop === '618bd896efea2b2049ada487' ? 'FoM' : 
                                                            (shop === '618bd896efea2b2049ada486' ? 'Trendico' : 
                                                            (shop === '618bd896efea2b2049ada488' ? 'Atleet' : 
                                                            (shop === '618bd896efea2b2049ada489' ? 'Sötkatt' : 
                                                            (shop === '618bd896efea2b2049ada490' ? 'TrendicoGroup' : 'ShopBadge'))))}
                                                        >
                                                            <ShopName id={shop} />
                                                        </Typography>
                                                    ))}
                                                </Box>
                                                <Typography variant="h6" id="Title" onClick={() => redirect(`/publications/${data[id].id}/show`)}>
                                                    {data[id].title}
                                                </Typography>
                                                {data[id].category &&
                                                    <Typography variant="button" style={{color: '#196cdb', marginBottom: '15px'}}>
                                                        <CategotyName id={data[id].category} />&nbsp;|&nbsp;<SubCategotyName id={data[id].subCategory} />
                                                    </Typography>
                                                }
                                                <Typography variant="body2" id="Body">
                                                    {data[id].subTitle &&
                                                        <PublicationsBody body={data[id].subTitle} index="subTitle"/>
                                                    }
                                                    <PublicationsBody body={data[id].review ? data[id].review: data[id].body} index="review"/>
                                                    <span id="seeMore">Leer más »</span>
                                                </Typography>
                                                <Typography id="Date">
                                                    <DaysPassed date={data[id].createdate}/>
                                                </Typography>
                                            </Box>
                                            <div style={{display: 'flex', justifyContent: 'center', margin: '16px 0 16px 50px'}}>
                                                <div style={{overflow: 'hidden', position: 'relative', borderRadius: '8px', backgroundColor: '#fff', height: '250px', width: '250px'}}>
                                                    <g-img style={{display: 'flex', height: '100%', justifyContent: 'center'}}>
                                                        <img className={classes.image} alt="complex" src={data[id].logo.image}/>
                                                    </g-img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Box>
                        ))}
                    </Box>
                :   
                    <div>
                       {SkeletonIds.map(id => (
                            <Box className={classes.skeletonNewsList} id={SkeletonData[id].key}>
                                <Box id="text">
                                    <div id="chips">
                                        <Skeleton variant="circular" width={110} height={20}/>
                                    </div>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Box>
                                <Box id="picture">
                                    <Skeleton />
                                </Box>
                            </Box>
                        ))}
                    </div>
                }
            </div>
        )
    }

    const PublicationsPagination = () => {
        const { page, perPage, total, setPage, setPerPage } = useListContext();
      
        const handleChangeRowsPerPage = (event) => { 
            setPerPage(parseInt(event.target.value, 10));
            setPage(1);
        };
        const handleChangePage = (event, newPage) => {
            setPage(newPage + 1);
         };
        
        return (
            <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 12, 15, 20]}
                    count={total || 0}
                    page={page - 1}
                    onChangePage ={handleChangePage}
                    rowsPerPage={perPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </ThemeProvider>
        );
    }

    const PublicationsFilter = ({...props }) => {
        const classes = useStyles();
        const redirect = useRedirect();
        const { permissions } = usePermissions();
        
        return (
            <Box className={classes.NewsActionBar}>
                <Filter {...props}>
                    <ReferenceInput source="shops" label="Enseña" reference="shops" filterToQuery={searchText => ({ name: searchText })}
                        variant="outlined" className={classes.BlackInputMargin} perPage={500} alwaysOn>
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                </Filter>
                {(permissions === 'Admin' || permissions === 'Publicador' || permissions === 'Central') &&
                    <Box style={{position: 'fixed', bottom: '25px', right: '30px', zIndex: '999'}}>
                        <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Publicación</div>} arrow placement="left">
                            <Fab color="primary" aria-label="add" onClick={() => { redirect(`/publications/create`); }}>
                                <AddCommentRoundedIcon />
                            </Fab>
                        </MyTooltip>
                    </Box>
                }
            </Box>
        )
    }

    return (
        <Box className={classes.ListBackground}>
            <Box className={classes.boxTitle}>
                <Typography className={classes.TopTitle}>Publicaciones</Typography>
                <Typography className={classes.TopSubTitle}>&nbsp;Crea y Edita Publicaciones</Typography>
            </Box>
            <List {...props} actions={false} style={{marginTop: '-65px', marginBottom: '5em'}} perPage={10} pagination={<PublicationsPagination/>}
                sort={{ field: 'createdate', order: 'DESC' }} filters={<PublicationsFilter />}
            >
                <PublicationsGrid record={props} style={{marginTop: '40px'}}/>
            </List>
        </Box>
    )
}; export default PublicationsList;