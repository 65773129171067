import NewsletterEdit from './NewsletterEdit';
import NewsletterList from './NewsletterList';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

const NewsletterModule = {
    list: NewsletterList,
    edit: NewsletterEdit,
    icon: MailOutlineRoundedIcon
}

export default NewsletterModule;