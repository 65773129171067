import React, { Fragment } from "react";
import { Datagrid, List, TextField, useTranslate, TopToolbar, useRedirect, BulkDeleteButton, EditButton, DeleteButton, 
  Filter, SearchInput, usePermissions, DateField, useListContext} from "react-admin";
import { makeStyles, Box, Typography, ThemeProvider, TablePagination} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import Fab from '@mui/material/Fab';
import * as locales from '@material-ui/core/locale';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';

const useStyles = makeStyles((theme) => ({
  BulkDeletePostButton: lightTheme.BulkDeletePostButton,
  CampaignsTable: lightTheme.OffersAndPromotionsOffersTable,
  PostViewCard: lightTheme.PostViewCard,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  RightSerchBar: {
    '& .MuiToolbar-root': {
      display: 'flex',
      '& .MuiToolbar-gutters':{
        paddingTop: '21px',
        width: 'auto',
        '& a':{
          marginLeft: '0px !important',
          '& span':{
            width: '110px !important',
          }
        }
      },
      '& form': {
        width: '100%',
        justifyContent: 'right',
        
      },
    },
    '& .list-page':{
      marginTop: '-45px'
    },
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#F44336',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#F44336A6 !important',
        color: '#fff !important',
    },
    marginBottom: '20px',
  },
  root: {
    marginRight: '-16px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
        borderColor: '#3d3d3d',
        },
        '&:hover fieldset': {
        borderColor: '#3d3d3d',
        },
        '&.Mui-focused fieldset': {
        borderColor: '#3d3d3d',
        },
    },
    '& .RaBulkActionsToolbar-toolbar': {
        '& h6': {
            color: '#3d3d3d',
        },
    },
  },
}));

const BulkDeleteCampaignsButton = ({ basePath }) => {
  return(
    <Fragment>
      <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Seleccionados</div>} aria-label="Delete" arrow placement="left">
        <BulkDeleteButton label="" basePath={basePath} id="GoogleStyleBulkButton"/>
      </MyTooltip>
    </Fragment>
  )
};

const CampaignsFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn variant="outlined" className={classes.root}/>
    </Filter>
  )
};

const CamapignsCreateActions = () => {
  const redirect = useRedirect();
  return(
    <TopToolbar>
        <Box style={{position: 'fixed', bottom: '25px', right: '30px', zIndex: '999'}}>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Campaña</div>} arrow placement="left">
                <Fab color="primary" aria-label="add" onClick={() => { redirect(`/campaigns/create`); }}>
                    <AddRoundedIcon />
                </Fab>
            </MyTooltip>
        </Box>
    </TopToolbar>
  )
};

const CampaignsPagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();

  const handleChangeRowsPerPage = (event) => { 
      setPerPage(parseInt(event.target.value, 10));
      setPage(1);
  };
  const handleChangePage = (event, newPage) => {
      setPage(newPage + 1);
   };
  
  return (
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
          <TablePagination
              component="div"
              rowsPerPageOptions={[8, 10, 12, 15, 20]}
              count={total || 0}
              page={page - 1}
              onChangePage ={handleChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </ThemeProvider>
  );
}

const CampaignsList = props => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const classes = useStyles();
  return (
    <Box className={classes.RightSerchBar}>
      <Box display="flex" style={{ width: '100%', position: 'relative', top: '8px'}} justifyContent="flex-start">
        <Typography className={classes.PostTitle}>Campañas</Typography>
        <Typography className={classes.PostSubTitle}>&nbsp;Gestiona, Edita y Consulta</Typography>
      </Box>
      <List {...props} actions={<CamapignsCreateActions />} bulkActionButtons={<BulkDeleteCampaignsButton {...props} />} style={{marginBottom: '5em'}}
        filters={<CampaignsFilter/>} sort={{ field: 'startDate', order: 'DESC' }} pagination={<CampaignsPagination/>} perPage={8}>
        <Datagrid rowClick="edit" className={classes.CampaignsTable}>
          <TextField label={translate('resources.campaigns.field.name')} source="name" />
          <DateField label={translate('resources.campaigns.field.startDate')} source="startDate" locales="fr-FR" />
          <DateField label={translate('resources.campaigns.field.endDate')} source="endDate" locales="fr-FR"/>
          {permissions === 'Admin' &&
            <MyTooltip title={<div style={{fontSize: '12px'}}>Editar</div>} aria-label="edit" arrow placement="bottom">
                <EditButton basePath="/pointOfSale" label="" record={props} id="GoogleStyleButton"/>
            </MyTooltip>
          }
          {permissions === 'Admin' &&
            <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar</div>} aria-label="delete" arrow placement="bottom">
                <DeleteButton basePath="/pointOfSale" label="" record={props} id="GoogleStyleDeleteButton"/>
            </MyTooltip>
          }
        </Datagrid>
      </List>
    </Box>
  )
}; export default CampaignsList;