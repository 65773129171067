import {  useRedirect, useTranslate  } from "react-admin";
import { Box, Typography, makeStyles, Button } from '@material-ui/core';
import lightTheme from '../themes';

import HomeIcon from '@mui/icons-material/Home';

const useStyles = makeStyles({
    EmptyBubble: lightTheme.EmptyBubble,
    EmptyInvoiceMediaCard: lightTheme.EmptyInvoiceMediaCard,
    InvoiceButton:{
        marginTop: '20px',
        '& .MuiButtonBase-root':{
          border: '1px solid #196cdb',
          color: '#196cdb',
          borderRadius: '4px !important',
          textTransform: 'capitalize',
          padding: '4px 8px !important',
        },
        '& .MuiButtonBase-root:hover':{
          backgroundColor: '#196cdb21',
          boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important'
        },
      },
});


export const ErrorPage = ({error, errorInfo, ...rest}) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const translate = useTranslate();
    return (
        <Box style={{width: '80%', marginLeft: '140px'}}>
            <Box style={{width: '100%', display: 'flex', marginTop: '70px'}}>
                <Box style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
                    <div className="error" data-text="Error" style={{marginLeft: '-10px', marginBottom: '25px'}}>
                        Error
                    </div><br/>
                    <Typography variant="h5">
                    Ooops ! Algo ha ido Mal
                    </Typography>
                    <Typography variant="h6" style={{marginTop: '20px'}}>
                        Se produjo un error de cliente y no se pudo completar su solicitud.
                    </Typography>
                    <h2>{translate(error.toString())}</h2>
                </Box>
                <Box style={{width: '50%', display: 'flex', alignItems: 'end', justifyContent: 'flex-end'}}>
                    <Box>
                        <img src="/static/images/Gifs/AngryV2.gif" alt="error_img" style={{width: '440px'}}/>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Typography variant="body1">
                    {process.env.NODE_ENV !== 'production' && (
                        <details>
                            <pre>{errorInfo.componentStack}</pre>
                        </details>
                    )}
                </Typography>
                <div className={classes.InvoiceButton}>
                    <Button onClick={() => { redirect(`/dashboard`); }} startIcon={<HomeIcon/>}>
                        Volver al Inicio
                    </Button>
                </div>
            </Box>
        </Box>
    );
};

export default ErrorPage;