const esTranslation = {
  LUNES: "Lunes",
  MARTES: "Martes",
  MIERCOLES: "Miércoles",
  JUEVES: "Jueves",
  VIERNES: "Viernes",
  SABADO: "Sábado",
  DOMINGO: "Domingo",
  TODOS_LOS_DIAS: "Todos los días",
  ACTIVO: 'Activo',
  INACTIVO: 'Inactivo',
  ra: {
    action: {
      add: "Añadir",
      add_filter: "",
      back: "Ir atrás",
      bulk_actions:
        "1 Elemento Seleccionado |||| %{smart_count} Elementos Seleccionados",
      cancel: "Cancelar",
      clear_input_value: "Limpiar valor",
      clone: "Clonar",
      close: "Cerrar",
      close_menu: "Cerrar menú",
      confirm: "Confirmar",
      create: "Crear",
      delete: "Eliminar",
      edit: "Editar",
      expand: "Expandir",
      export: "Exportar",
      list: "Listar",
      open_menu: "Abrir menú",
      refresh: "Refrescar",
      remove: "Borrar",
      remove_filter: "Borrar filtro",
      save: "Guardar",
      search: "Buscar",
      show: "Mostrar",
      sort: "Ordenar",
      undo: "Deshacer",
      unselect: "Deseleccionar"
    },
    auth: {
      auth_check_error: "Por favor inicie sesión para continuar",
      logout: "Cerrar Sesión",
      password: "Contraseña",
      sign_in: "Acceder",
      sign_in_error: "La autenticación falló, por favor, vuelva a intentarlo",
      user_menu: "Perfil",
      username: "Usuario",
      recover_password: "¿Has olvidado tu contraseña?",
      reset_password: "Recuperar contraseña",
      reset_password_label: "Un mail con las instrucciones será enviado a la siguiente Email:"
    },
    boolean: {
      true: "Sí",
      false: "No",
      null: " ",
    },
    input: {
      file: {
        upload_several:
          "Arrastre algunos archivos para subir o haga clic para seleccionarlos.",
        upload_single:
          "Arrastre un archivo para subir o haga clic para seleccionarlo."
      },
      image: {
        upload_several:
          "Arrastre algunas imagénes para subir o haga clic para seleccionarlas.",
        upload_single:
          "Arrastre aquí una imagen o haga clic aquí para adjuntar."
      },
      references: {
        all_missing: "No se pueden encontrar datos de referencias.",
        many_missing:
          "Al menos una de las referencias asociadas parece no estar disponible.",
        single_missing: "La referencia asociada no parece estar disponible."
      },
      password: {
        toggle_visible: "Ocultar contraseña",
        toggle_hidden: "Mostrar contraseña"
      }
    },
    message: {
      about: "Acerca de",
      are_you_sure: "¿Está seguro?",
      bulk_delete_content:
        "¿Seguro que quiere eliminar este %{name}? |||| ¿Seguro que quiere eliminar estos %{smart_count} items?",
      bulk_delete_title:
        "Eliminar %{name} |||| Eliminar %{smart_count} %{name} items",
      delete_content: "¿Seguro que quiere eliminar este item?",
      delete_title: "Eliminar %{name} #%{id}",
      details: "Detalles",
      error:
        "Se produjo un error en el cliente y su solicitud no se pudo completar",
      invalid_form:
        "El formulario no es válido. Por favor verifique si hay errores",
      loading: "La página se está cargando, espere un momento por favor",
      no: "No",
      not_found:
        "O bien escribió una URL incorrecta o siguió un enlace incorrecto.",
      yes: "Sí",
      unsaved_changes:
        "Algunos de sus cambios no se guardaron. ¿Está seguro que quiere ignorarlos?",
      recover_password_email_sent: "¡ Se envió un correo electrónico para restablecer la contraseña !"
    },
    navigation: {
      next: "",
      no_more_results:
        "El número de página %{page} está fuera de los límites. Pruebe la página anterior.",
      no_results: "No se han encontrado resultados",
      page_out_from_begin: "No puede ir antes de la página 1",
      page_out_from_end: "No puede ir después de la última página",
      page_out_of_boundaries: "Número de página %{page} fuera de los límites",
      page_range_info: "%{offsetBegin} - %{offsetEnd} de %{total}",
      page_rows_per_page: "Filas por página:",
      prev: "",
      skip_nav: 'Saltar al contenido',
    },
    sort: {
      sort_by: "Ordenar por %{field} %{order}",
      ASC: "ascendente",
      DESC: "descendente",
    },
    notification: {
      bad_item: "Elemento incorrecto",
      canceled: "Acción cancelada",
      created: "Elemento creado",
      data_provider_error:
        "Error del proveedor de datos. Consulte la consola para más detalles.",
      deleted: "Elemento borrado |||| %{smart_count} elementos borrados.",
      http_error: "Error de comunicación con el servidor",
      item_doesnt_exist: "El elemento no existe",
      logged_out: "Su sesión ha finalizado, vuelva a conectarse.",
      updated:
        "Elemento actualizado |||| %{smart_count} elementos actualizados",
      i18n_error:
        "No se pudieron cargar las traducciones para el idioma especificado"
    },
    page: {
      create: "Crear %{name}",
      dashboard: "Tablero",
      edit: "%{name} #%{id}",
      empty: "Sin %{name} todavía.",
      error: "Algo salió mal",
      invite: "¿Quiere agregar una?",
      list: "Lista de %{name}",
      loading: "Cargando",
      not_found: "No encontrado",
      show: "%{name} #%{id}"
    },
    validation: {
      email: "Debe ser un correo electrónico válido",
      maxLength: "Debe contener %{max} caracteres o menos",
      maxValue: "Debe ser %{max} o menos",
      minLength: "Debe contener %{min} caracteres al menos",
      minValue: "Debe ser al menos %{min}",
      number: "Debe ser un número",
      oneOf: "Debe ser uno de: %{options}",
      regex: "Debe coincidir con un formato específico (regexp): %{pattern}",
      required: "Requerido"
    }
  },
  pos: {
    search: 'Search',
    configuration: 'Configuración',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      new_customers: 'New Customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
          'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to react-admin demo',
        subtitle:
          "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        aor_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },
    menu: {
      sales: 'Sales',
      catalog: 'Catalog',
      customers: 'Customers',
    },
  },
  resources: {
    // BASE DE EJEMPLO !!!
    // categories: {
    //     name: 'Categoría |||| Categorías',
    //     fields: {
    //         products: 'Products',
    //         name: 'Nombre',
    //         colour: 'Color',
    //         image: 'Imagen',
    //     },
    //     input: {
    //         image: 'Arrastre su imagen aquí o haga click para seleccionarla'
    //     },
    //     originalImage: 'Imagen original',
    //     messages:{
    //         required:'Requerido',
    //         invalidColor: 'Color inválido. Debe ingresar un valor hexadecimal',
    //         imageNotAllowed: 'El formato de imagen debe ser .jpg o .png',
    //         imageWeightExceeded: 'El tamaño de la imagen no debe exceder a 50 Kilobytes',
    //         imageWeightExceededReward: 'El tamaño de la imagen no debe exceder a 204 Kilobytes',
    //     }
    // },
    menu: { 
      materialCorp: 'Material Corporativo',
      calendar: 'Calenadrio Tréndico',
      provider: 'Proveedores',
      config: 'Configuración',
      administration: 'Administración'
    },
    dashboard: {
      TitleName: 'Escritorio'
    },
    post: {
      name: 'Tablón |||| Tablón'
    },
    state: {
      name: 'Provincia |||| Provincias',
      TitleName: 'Provincias'
    },
    country: {
      name: 'País |||| Paises',
      TitleName: 'Paises'
    },
    address: {
      name: 'Dirección |||| Direcciones',
      TitleName: 'Direcciones'
    },
    provider: {
      name: 'Proveedor |||| Proveedores',
      TitleName: 'Proveedores'
    },
    shops: {
      name: 'Enseña |||| Enseñas',
      TitleName: 'Enseñas'
    },
    material: {
      name: 'Material |||| Consumibles',
      TitleName: 'Consumibles',
      filters: {
        availability: 'Disponibilidad',
        available: 'Disponible',
        notAvailable: 'No disponible',
        ecommerce: 'Ecommerce',
        category: 'Categoría'
      }
    },
    variants:{
      TitleName: 'Variantes',
    },
    events: {
      name: 'Evento |||| Eventos'
    },
    calendar: {
      name: 'Calendario',
      TitleName: 'Calendario',
    },
    conditions: {
      name: 'Condición |||| Condiciones',
      TitleName: 'Condiciones',
      SortMenu: {
        provider: 'Proveedor',
        campaign: 'Campaña',
        shop: 'Enseña',
      }
    },
    division: {
      name: 'División |||| Divisiones',
      TitleName: 'Division'
    },
    comments: {
      name: 'Comentario |||| Comentarios'
    },
    users: {
      name: 'Usuario |||| Usuarios',
      create: 'Nuevo Usuario',
      TitleName: 'Usuarios',
      field: {
        displayName: 'Nombre Completo',
        name: 'Usuario',
        email: 'Email',
        phone: 'Teléfono',
        password: 'Contraseña',
        role: 'Rol de Usuario',
        shop: 'Tienda',
        address: 'Dirección',
        location: 'Localidad',
        state: 'Provincia',
        shopName: 'Nombre de la Tienda',
        company: 'Sociedad',
        country: 'País',
      }
    },
    pointOfSale: {
      name: 'Punto de venta |||| Puntos de venta',
      TitleName: 'Puntos de Venta',
      field: {
        name: 'Nombre',
        location: 'Localidad',
        province: 'Provincia',
        address: 'Dirección',
        phone: 'Teléfono',
        gln: 'GLN',
        shop: 'Tienda',
        company: 'Sociedad',
        email: 'Email',
      }
    },
    company: {
      name: 'Sociedad |||| Sociedades',
      TitleName: 'Sociedades'
    },
    orders: {
      name: 'Pedido |||| Pedidos',
      TitleName: 'Pedidos Consumibles'
    },
    categorys: {
      name: 'Categoría |||| Categorías',
      TitleName: 'Categorías'
    },
    campaigns: {
      name: 'Campaña |||| Campañas',
      TitleName: 'Campañas',
      field: {
        name: 'Nombre',
        startDate: 'Fecha Inicio',
        endDate: 'Fecha Fin',
      }
    },
    faq:{
      TitleName: 'Preguntas Frecuentes',
    },
    files: {
      name: 'Descarga |||| Descargas',
      TitleName: 'Archivos'
    },
    filesCategories: {
      name: 'Descarga |||| Descargas',
      TitleName: 'Archivos'
    },
    NewsCategories:{
      TitleName: 'Categorías de Publicaciones',
    },
    offersAndPromotions: {
      name: 'Ofertas & Promociones',
      TitleName: 'Ofertas & Promociones',
      fields: {
        date: 'Fecha',
        title: 'Titulo',
        body: 'Descripción',
        image: 'Imagen Oferta',
        file: 'Archivos relacionados',
        important: 'Destacar Publicacion'
      },
      button: {
        dowloadFile: 'Descargar archivos',
        dowload: 'Descargar',
        requestPromotion: 'Solicitar promoción'
      }
    },
    profile:{
      name: 'Mi Perfil',
      TitleName: 'Mi Perfil',
      myInformation: 'Mi informacion',
      displayName: 'Nombre completo',
      email: 'Email',
      phone: 'Teléfono',
      password: 'Contraseña',
      role: 'Rol de Usuario',
      shop: 'Tienda',
      address: 'Dirección',
      location: 'Localidad',
      province: 'Provincia',
      shopName: 'Nombre de la Tienda'
    },
    userProfile: {
      name: 'Mi Perfil',
      TitleName: 'Mi Perfil',
      myInformation: 'Mi informacion',
      displayName: 'Nombre completo',
      email: 'Email',
      phone: 'Teléfono',
      password: 'Contraseña',
      role: 'Rol de Usuario',
      shop: 'Tienda',
      address: 'Dirección',
      location: 'Localidad',
      province: 'Provincia',
      shopName: 'Nombre de la Tienda'
    },
    comunicados:{
      TitleName: 'Comunicados',
    },
    Newsletter:{
      TitleName: 'Newsletter',
    },
    informacion:{
      TitleName: 'Puntos de Venta',
    },
    news: {
      TitleName: 'Noticias',
      name: 'Noticia |||| Noticias',
      title: 'Titulo de la noticia',
      body: 'Descripcion de noticia',
      image: 'Imagen',
      imgPlaceHolder: 'Arrastre una Imagen para subir o haga clic para seleccionarla.',
      subTitle: 'Descubre nuestras últimas Noticias!',
      createNewNotice: 'Crear Nueva'
    },
    publications:{
      TitleName: 'Publicaciones',
    },
    whatsNew:{
      TitleName: 'Actualidad',
    },
    comercial:{
      TitleName: 'Comercial',
    },
    informatica:{
      TitleName: 'Informática',
    },
    marketing:{
      TitleName: 'Marketing',
    },
    admin:{
      TitleName: 'Administración',
    }
  },
  Actualidad: {
    name: 'Actualidad',
  },
  Marketing: {
    name: 'Marketing',
  },
  Comercial: {
    name: 'Comercial',
  },

};

export default esTranslation;