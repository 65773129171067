import React from "react";
import { Create, SimpleForm, useTranslate, TextInput, Toolbar, SaveButton, required, SelectInput } from "react-admin";
import { makeStyles, CircularProgress } from '@material-ui/core';
import lightTheme from '../layout/themes';
import MyTooltip from '../components/MuiTooltip';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import '../index.css';

const useStyles = makeStyles({
  BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
  CardUsersEdit: lightTheme.CardUsersEdit,
  EditToolBar: lightTheme.EditToolBar,
});

const NewsCategoriesCreate = props => {
  const classes = useStyles();
  const translate = useTranslate();
  const redirect = () => `/newsCategories`;
  const TypeChoices=[ 
    { id: 'Categoría', name: 'Categoría' }, 
    { id: 'Subcategoría', name: 'Subcategoría' },
  ] 

  const NewsCategoriesEditToolbar = ({loading, ...props}) => {
    const classes = useStyles();
    return (
      <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Categoría</div>} aria-label="Save" arrow placement="right">
          <SaveButton disabled={props.pristine} redirect={`/newsCategories`} id="GoogleStyleSaveButton" label="Crear" icon={<AddRoundedIcon/>}>
            {loading && (<CircularProgress/>)}
          </SaveButton>
        </MyTooltip>
      </Toolbar>
    )
  };

  return (
    <Create {...props}>
      <SimpleForm redirect={redirect} toolbar={<NewsCategoriesEditToolbar/>}>
        <TextInput label={translate('resources.pointOfSale.field.name')} source="name" variant="outlined" 
          multiline="true" fullWidth className={classes.BlackInput} validate={required()}/>
        <SelectInput required label="Tipo" variant="outlined" source="type" defaultValue="Subcategoría" 
          multiline="true" fullWidth className={classes.BlackInput} choices={TypeChoices} style={{textTransform: 'capitalize', display: 'none'}}/>
      </SimpleForm>
    </Create>
  )
}; export default NewsCategoriesCreate;