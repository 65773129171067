import {  useRedirect } from "react-admin";
import { Box, Typography, makeStyles, Button } from '@material-ui/core';
import lightTheme from '../themes';

import HomeIcon from '@mui/icons-material/Home';

const useStyles = makeStyles({
    EmptyBubble: lightTheme.EmptyBubble,
    EmptyInvoiceMediaCard: lightTheme.EmptyInvoiceMediaCard,
    InvoiceButton:{
        marginTop: '20px',
        '& .MuiButtonBase-root':{
          border: '1px solid #196cdb',
          color: '#196cdb',
          borderRadius: '4px !important',
          textTransform: 'capitalize',
          padding: '4px 8px !important',
        },
        '& .MuiButtonBase-root:hover':{
          backgroundColor: '#196cdb21',
          boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important'
        },
      },
});


export const ForbiddenPage = () => {
    const classes = useStyles();
    const redirect = useRedirect();

    return (
        <Box style={{width: '80%', marginLeft: '140px'}}>
            <Box style={{width: '100%', display: 'flex', marginTop: '70px'}}>
                <Box style={{width: '50%', display: 'flex', flexDirection: 'column'}}>
                    <div className="error" data-text="403" style={{marginLeft: '-10px', marginBottom: '25px'}}>
                        403
                    </div><br/>
                    <Typography variant="h5" style={{marginTop: '20px'}}>
                        ¿Que ha pasado?
                    </Typography><br/>
                    <Typography variant="h6" style={{marginTop: '20px'}}>
                        Parece que has entrado en una zona restringida<br/>
                        por favor vuelve al Inicio y deja de trastear.
                    </Typography>
                    <div className={classes.InvoiceButton}>
                        <Button onClick={() => { redirect(`/dashboard`); }} startIcon={<HomeIcon/>}>
                            Volver al Inicio
                        </Button>
                     </div>
                </Box>
                <Box style={{width: '50%', display: 'flex', alignItems: 'end', justifyContent: 'flex-end'}}>
                    <Box>
                        <img src="/static/images/Gifs/403V2.gif" alt="error_img" style={{width: '440px'}}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ForbiddenPage;