import React from "react";
import { Create, SimpleForm, TextInput, required, SaveButton, Toolbar, FormDataConsumer } from "react-admin";
import { makeStyles, Box, Typography, CircularProgress } from "@material-ui/core";
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from "../layout/themes";
import TinyMCETextEditor from '../components/TextEditors/TinyMCETextEditor'
import clsx from 'clsx';


const useStyles = makeStyles({
    RichTextInputBlack: lightTheme.CommonStyles.CreateAndEdit.RichTextInputBlack,
    BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
    ToolBar: lightTheme.CommonStyles.CreateAndEdit.ToolBar,
    TooltipTitle: lightTheme.CommonStyles.ListItems.TooltipTitle,
    W100: lightTheme.CommonStyles.ListItems.W100,
    FlexTitleBox: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox,
    FlexTitle: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox.FlexTitle,
    FlexCloseButtonBox: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox.FlexCloseButtonBox,
    FAQInputCard: lightTheme.FAQStyles.CreateAndEdit.FAQInputCard,
});

const FAQCreate = props => {
    const classes = useStyles();
    const redirect = () => `/faq`;

    let today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;

    const FAQCreateToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        return (
            <Box>
                <Toolbar {...props} className={clsx(classes.ToolBar, classes.FlexStart100)}>
                    <MyTooltip title={<div className={classes.TooltipTitle}>Agregar Nueva Pregunta</div>} arrow placement="right">
                        <SaveButton disabled={props.pristine} id="GoogleStyleSaveButton" label="Agregar">
                            {loading && (<CircularProgress/>)}
                        </SaveButton>
                    </MyTooltip>
                </Toolbar>
            </Box>
        )
    };

    return (
        <Create {...props} actions={false}>
            <SimpleForm redirect={redirect} toolbar={<FAQCreateToolbar/>}>
                <Box className={classes.W100}>
                    <Box className={classes.FlexTitleBox} p={1}>
                        <Box className={classes.FlexTitle} p={1} >
                            <Typography variant="h5">Crear Pregunta Frecuente</Typography>
                        </Box>
                        <Box className={classes.FlexCloseButtonBox} p={1} >
                            <MyTooltip title={<div className={classes.TooltipTitle}>Cerrar</div>} arrow>
                                <BackButton/>
                            </MyTooltip>
                        </Box>
                    </Box>
                    <Box className={classes.FAQInputCard}>
                        <TextInput label="Título" source="title" variant="outlined" multiline="true" fullWidth className={classes.BlackInput} validate={required()}/>
                    </Box>

                    <TinyMCETextEditor />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            formData.body = formData.textValue
                        }}
                    </FormDataConsumer>

                </Box>
            </SimpleForm>
        </Create>
    )
}; export default FAQCreate;