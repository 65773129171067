import FilesIcon from '@material-ui/icons/AttachFile';
import FilesCreate from './FilesCreate';
import FilesList from './FilesList';
import FilesEdit from './FilesEdit';
import FilesView from './FilesView';

const FilesModule = {
    icon: FilesIcon,
    create: FilesCreate,
    list: FilesList,
    edit: FilesEdit,
    show: FilesView,
}; export default FilesModule;