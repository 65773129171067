import * as React from 'react';
import { FC, Fragment, ReactElement } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowBack from '@material-ui/icons/ChevronRightRounded';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    icon: { 
        minWidth: theme.spacing(5),
        fill: '#757575'
    },
    sidebarIsOpen: {
        '& a': {
            paddingLeft: theme.spacing(4),
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
    },
    sidebarIsClosed: {
        '& a': {
            paddingLeft: theme.spacing(2),
            transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
        },
    },
    MenuItem: {
        borderTopRightRadius: '25px',
        borderBottomRightRadius: '25px',
        marginTop: '5px',
        marginBottom: '5px',
        marginRight: '5px',
        fontSize: '14px',
        paddingRight: '0px !important',
        paddingTop: '8px',
        paddingBottom: '8px',
        color: '#424242',
        width: '240px',
        '& svg':{
            color: '#424242 !important',
        },
        '& span':{
            color: '#424242 !important',
        },
        '&:hover':{
            fontSize: '14px',
            backgroundColor: 'rgba(25, 108, 219, 0.14) !important',
            borderTopRightRadius: '25px',
            borderBottomRightRadius: '25px',
            transition: 'all .2s ease-in-out',
            '& span':{
                color: '#196cdb !important',
            },
            '& svg':{
                color: '#196cdb !important',
            }
        },    
    },
}));

interface Props {
    dense: boolean;
    handleToggle: () => void;
    icon: ReactElement;
    isOpen: boolean;
    name: string;
    sidebarIsOpen: boolean;
    children: React.ReactNode;
}

const SubMenu: FC<Props> = ({
    dense,
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    children,
}) => {
    const classes = useStyles();

    const header = (
        <MenuItem dense={dense} button onClick={handleToggle} className={classes.MenuItem} >
            <ListItemIcon className={classes.icon}>
                {isOpen ? icon : icon}
            </ListItemIcon>
            <Box style={{display: 'flex', width: '100%'}}>
                <Typography variant="inherit" color="textSecondary" style={{width: '75%'}}>
                    {name}
                </Typography>
                <ListItemIcon className={classes.icon} style={{alignContent: 'center', display: 'flex', justifyContent: 'center'}}>
                    {isOpen ? <ExpandMore /> : <ArrowBack />}
                </ListItemIcon>
            </Box>
        </MenuItem>
    );

    return (
        <Fragment>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    className={
                        sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                >
                    {children}
                </List>
            </Collapse>
        </Fragment>
    );
};

export default SubMenu;