import React from "react";
import { List, useGetList, Link, } from "react-admin";
import { makeStyles, Grid, Card, CardContent, Typography, Box } from '@material-ui/core';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  FolderGrid:{
    marginTop: '20px',
  },
  Folder:{
    '& .MuiCardContent-root':{
      padding: '24px !important'
    },
    '& #FolderTitle':{
      color: '#424242',
      fontSize: '15px',
      '& svg':{
        marginBottom: '-5px',
        marginRight: '15px',
      }
    }
  }
});

const FoldersGrid = () => {
  const classes = useStyles();
  const { data, ids } = useGetList('filesCategories', { page: 1, perPage: 10 }, { field: 'name', order: 'DESC'})
  return(
    <Grid container spacing={2} className={classes.FolderGrid}>
      {ids.map(id => (
        <Grid key={id} xs={3} sm={3} md={4} lg={4} xl={4} item >
          <Card className={classes.Folder}>
            <Link to={`/filesCategories/${data[id].id}/show`}>
              <CardContent>
                <Typography id="FolderTitle">
                  <FolderRoundedIcon />{data[id].name}
                </Typography>
              </CardContent>
            </Link>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}


const FilesCatList = props => {
  const classes = useStyles();
  return (
    <Box>
      <Box display="flex" style={{ width: '100%', position: 'relative', top: '8px'}} justifyContent="flex-start">
        <Typography className={classes.PostTitle}>Archivos Descargables</Typography>
        <Typography className={classes.PostSubTitle}>&nbsp;Catálogos, Condiciones...</Typography>
      </Box>
      <List {...props} actions={false} pagination={false} component="div">
        <FoldersGrid />
      </List>
    </Box>
  )
}; export default FilesCatList;