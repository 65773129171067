import {useState} from 'react';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';

export function useLocalStorage(key, initialValue) {
    const [storedValue, setStoredValue] = useState(() => {
      if (typeof window === "undefined") {
        return initialValue;
      }
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        console.log(error);
        return initialValue;
      }
    });
    const setValue = (value) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        console.log(error);
      }
    };
    return [storedValue, setValue];
}

export const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
        paddingBottom: '0px !important',
        '& .MuiFormControlLabel-root':{
            marginLeft: '-11px',
            paddingRight: '16px',
            marginRight: '4px !important',
            marginBottom: '4px',
            borderRadius: '4px',
        },
        '& .MuiFormControlLabel-root:hover':{
            backgroundColor: '#196cdb1c !important',
        }
      }
    },
    '& #FilterButtonBox':{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '90%',
        margin: '0 auto',
        marginTop: '10px',
        marginBottom: '10px',
        '& #FilterButton':{
            backgroundColor: 'transparent !important',
            color: '#196cdb !important',
            margin: '0px !important',
            width: '100%',
        },
        '& #FilterButton:hover':{
            backgroundColor: '#196cdb1c !important',
            color: '#196cdb !important',
        }
    },
}));

var eventos = {
    calendarId: "c_2e4v56c36dqtfr06pef4nufo1k@group.calendar.google.com",
    color: '#196cdb',
    id: "eventos"
};
var central = {
    calendarId: "c_jne0j9rapgdhk68tmd7q7i0p0g@group.calendar.google.com",
    color: '#cacaca',
    id: "central"
};
var vacaciones = {
    calendarId: "es.spain#holiday@group.v.calendar.google.com",
    color: '#0b8043',
    id: "vacaciones"
};
var fom = {
    calendarId: "c_7fcldii10epqgghjonio8ir2tc@group.calendar.google.com",
    color: '#da291c',
    id: "fom"
};
var atleet = {
    calendarId: "c_d924o5grkbshruer40j4chm770@group.calendar.google.com",
    color: '#00bcb4',
    id: "atleet"
};
var sotkatt = {
    calendarId: "c_tmlncbnl47243njl02m7eknmno@group.calendar.google.com",
    color: '#015cb8',
    id: "sotkatt"
};
var trendico = {
    calendarId: "c_vqosue3sevpt9qsql947m6f87o@group.calendar.google.com",
    color: '#3d3d3d',
    id: "trendico"
};

var calendarArrayGuardada = [
    {
        calendarId: "c_2e4v56c36dqtfr06pef4nufo1k@group.calendar.google.com",
        color: '#196cdb',
        id: "eventos"
    }
]
calendarArrayGuardada = JSON.parse(localStorage.getItem("LocalCalendarArray")) ? JSON.parse(localStorage.getItem("LocalCalendarArray")) : calendarArrayGuardada;

export const handleChange = (event) => {
    var IndexCentral = calendarArrayGuardada.findIndex(x => x.id === "central");
    if (IndexCentral < 0){
        calendarArrayGuardada.push(central);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    } else {
        calendarArrayGuardada.splice(IndexCentral, 1);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    }
};

export const handleChange2 = (event) => {
    var IndexEventos = calendarArrayGuardada.findIndex(x => x.id === "eventos");
    if (IndexEventos < 0){
        calendarArrayGuardada.push(eventos);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    } else {
        calendarArrayGuardada.splice(IndexEventos, 1);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    }
};

export const handleChange3 = (event) => {
    var IndexFom = calendarArrayGuardada.findIndex(x => x.id === "fom");
    if (IndexFom < 0){
        calendarArrayGuardada.push(fom);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    } else {
        calendarArrayGuardada.splice(IndexFom, 1);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    }
};

export const handleChange4 = (event) => {
    var IndexAtleet = calendarArrayGuardada.findIndex(x => x.id === "atleet");
    if (IndexAtleet < 0){
        calendarArrayGuardada.push(atleet);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    } else {
        calendarArrayGuardada.splice(IndexAtleet, 1);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    }
};

export const handleChange5 = (event) => {
    var IndexTrendico = calendarArrayGuardada.findIndex(x => x.id === "trendico");
    if (IndexTrendico < 0){
        calendarArrayGuardada.push(trendico);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    } else {
        calendarArrayGuardada.splice(IndexTrendico, 1);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    }
};

export const handleChange6 = (event) => {
    var IndexSotkatt = calendarArrayGuardada.findIndex(x => x.id === "sotkatt");
    if (IndexSotkatt < 0){
        calendarArrayGuardada.push(sotkatt);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    } else {
        calendarArrayGuardada.splice(IndexSotkatt, 1);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    }
};

export const handleChange7 = (event) => {
    var IndexVacaciones = calendarArrayGuardada.findIndex(x => x.id === "vacaciones");
    if (IndexVacaciones < 0){
        calendarArrayGuardada.push(vacaciones);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    } else {
        calendarArrayGuardada.splice(IndexVacaciones, 1);
        localStorage.setItem('LocalCalendarArray', JSON.stringify(calendarArrayGuardada));
    }
};