import React, {useState} from 'react';
import { usePermissions } from 'react-admin';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import lightTheme from '../layout/themes';
import MyTooltip from '../components/MuiTooltip';
import Calendar from '@ericz1803/react-google-calendar';
import Fab from '@mui/material/Fab';
import { mdiCalendarPlus } from '@mdi/js'; 
import Icon from '@mdi/react'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { 
    useLocalStorage, StyledMenu, handleChange, handleChange2, handleChange3, handleChange4, handleChange5, handleChange6, handleChange7
} from '../utils/CalendarUtils'

const EventIcon = () => {  return( <Icon path={mdiCalendarPlus} size={0.8}/> ) };
const API_KEY = "AIzaSyDgOLk_RZv4Epi_h-dEcHD-jVfMVe0a5fE";
const language = 'ES';

const useStyles = makeStyles({
    Events: lightTheme.CalendarStyles.Events,
    CalendarFilterButton: lightTheme.CalendarStyles.CalendarFilterButton,       
});


const CalendarPanel = () => {
    const {permissions} = usePermissions();
    const classes = useStyles();
    const [hasRender, setHasRender] = useState(false);
    var position = 0;
    var calendarArrayGuardada = [
        {
            calendarId: "c_2e4v56c36dqtfr06pef4nufo1k@group.calendar.google.com",
            color: '#196cdb',
            id: "eventos"
        }
    ]
    calendarArrayGuardada = JSON.parse(localStorage.getItem("LocalCalendarArray")) ? JSON.parse(localStorage.getItem("LocalCalendarArray")) : calendarArrayGuardada;

    const AsideCalendarFilter = () => {
        const classes = useStyles();
        setHasRender(true);
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };

        const [checked, setChecked] = useLocalStorage("checked", false);
        const [checked2, setChecked2] = useLocalStorage("checked2", true);
        const [checked3, setChecked3] = useLocalStorage("checked3", false);
        const [checked4, setChecked4] = useLocalStorage("checked4", false);
        const [checked5, setChecked5] = useLocalStorage("checked5", false);
        const [checked6, setChecked6] = useLocalStorage("checked6", false);
        const [checked7, setChecked7] = useLocalStorage("checked7", false);

        function refreshPage(){
            window.location.reload()
        }

        return(
            <Box className={classes.CalendarFilterButton}>
                <Button
                    id="button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <FilterAltRoundedIcon id="icon"/>
                    <Typography variant='body2' id="text">Filtrar</Typography>
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <FormGroup style={{marginLeft: '15px'}}>
                        {permissions === 'Admin' &&
                            <FormControlLabel 
                                control={
                                <Checkbox id="central" value="central" checked={checked} onChange={(event) => {handleChange(); setChecked(event.target.checked)}}/>
                                } 
                                label="Calendario Central" 
                            />
                        }
                        <FormControlLabel 
                            control={
                             <Checkbox id="eventos" value="eventos" checked={checked2} onChange={(event) => {handleChange2(); setChecked2(event.target.checked)}}/>
                            } 
                            label="Eventos Trendico Group" 
                        />
                        <FormControlLabel 
                            control={
                             <Checkbox id="fom" value="fom" checked={checked3} onChange={(event) => {handleChange3(); setChecked3(event.target.checked)}}/>
                            } 
                            label="Calendario FoM" 
                        />
                        <FormControlLabel 
                            control={
                             <Checkbox id="atleet" value="atleet" checked={checked4} onChange={(event) => {handleChange4(); setChecked4(event.target.checked)}}/>
                            } 
                            label="Calendario Atleet" 
                        />
                        <FormControlLabel 
                            control={
                             <Checkbox id="trendico" value="trendico" checked={checked5} onChange={(event) => {handleChange5(); setChecked5(event.target.checked)}}/>
                            } 
                            label="Calendario Trendico" 
                        />
                        <FormControlLabel 
                            control={
                             <Checkbox id="sotkatt" value="sotkatt" checked={checked6} onChange={(event) => {handleChange6(); setChecked6(event.target.checked)}}/>
                            } 
                            label="Calendario Sötkatt" 
                        />
                        <FormControlLabel 
                            control={
                             <Checkbox id="vacaciones" value="vacaciones" checked={checked7} onChange={(event) => {handleChange7(); setChecked7(event.target.checked)}}/>
                            } 
                            label="Festividades en España" 
                        />
                    </FormGroup>
                    <Divider />
                    <div id="FilterButtonBox">
                        <Button
                            onClick={() => refreshPage()} id="FilterButton"
                        >
                            Confirmar Filtrar
                        </Button>
                    </div>
                </StyledMenu>
            </Box>
        )
    }

    if(hasRender === true){

        const TodayButton = document.createElement('div');
        TodayButton.setAttribute('class', 'today-button');
        TodayButton.setAttribute('id', 'today-button');
        TodayButton.innerHTML = 'Hoy';

        const calendarHeader = document.querySelector('[class="calendar-header"]');
        calendarHeader.children[1].setAttribute("id", "title-box");

        if(calendarHeader.lastChild.innerHTML !== 'Hoy'){
            calendarHeader.appendChild(TodayButton);
        }

        const calendarTitleBox = document.querySelector('[id="title-box"]');

        const span = document.createElement('span');
        span.setAttribute('id', 'span1');
        span.innerHTML = 'Powered By'; 

        const span2 = document.createElement('span');
        span2.setAttribute('id', 'span2');
        span2.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="186 38 76 76"><path fill="#fff" d="M244 56h-40v40h40V56z"/><path fill="#EA4335" d="M244 114l18-18h-18v18z"/><path fill="#FBBC04" d="M262 56h-18v40h18V56z"/><path fill="#34A853" d="M244 96h-40v18h40V96z"/><path fill="#188038" d="M186 96v12c0 3.315 2.685 6 6 6h12V96h-18z"/><path fill="#1967D2" d="M262 56V44c0-3.315-2.685-6-6-6h-12v18h18z"/><path fill="#4285F4" d="M244 38h-52c-3.315 0 -6 2.685-6 6v52h18V56h40V38z"/><path fill="#4285F4" d="M212.205 87.03c-1.495-1.01-2.53-2.485-3.095-4.435l3.47-1.43c.315 1.2.865 2.13 1.65 2.79.78.66 1.73.985 2.84.985 1.135 0 2.11-.345 2.925-1.035s1.225-1.57 1.225-2.635c0-1.09-.43-1.98-1.29-2.67-.86-.69-1.94-1.035-3.23-1.035h-2.005V74.13h1.8c1.11 0 2.045-.3 2.805-.9.76-.6 1.14-1.42 1.14-2.465 0 -.93-.34-1.67-1.02-2.225-.68-.555-1.54-.835-2.585-.835-1.02 0 -1.83.27-2.43.815a4.784 4.784 0 0 0 -1.31 2.005l-3.435-1.43c.455-1.29 1.29-2.43 2.515-3.415 1.225-.985 2.79-1.48 4.69-1.48 1.405 0 2.67.27 3.79.815 1.12.545 2 1.3 2.635 2.26.635.965.95 2.045.95 3.245 0 1.225-.295 2.26-.885 3.11-.59.85-1.315 1.5-2.175 1.955v.205a6.605 6.605 0 0 1 2.79 2.175c.725.975 1.09 2.14 1.09 3.5 0 1.36-.345 2.575-1.035 3.64s-1.645 1.905-2.855 2.515c-1.215.61-2.58.92-4.095.92-1.755.005-3.375-.5-4.87-1.51zM233.52 69.81l-3.81 2.755-1.905-2.89 6.835-4.93h2.62V88h-3.74V69.81z"/></svg>'; 

        const span3 = document.createElement('span');
        span3.setAttribute('id', 'span2');
        span3.innerHTML = 'Calendar'; 

        const PoweredBy = document.createElement('div');
        PoweredBy.setAttribute('id', 'powered-by');

        PoweredBy.appendChild(span);
        PoweredBy.appendChild(span2);
        PoweredBy.appendChild(span3);

        if(calendarTitleBox.lastChild.id !== 'powered-by'){
            calendarTitleBox.appendChild(PoweredBy);
        }

        const calendarTitle = document.querySelector('[class="calendar-title"]');
        calendarTitle.addEventListener('DOMSubtreeModified', myFunction);

        function myFunction(e) {
            calendarTitle.setAttribute("id", calendarTitle.textContent)
            if (calendarTitle.firstChild.data.includes("Nombre")){
                calendarTitle.textContent = calendarTitle.firstChild.data.replace("Nombre", "Noviembre");
            }
        }

        const calendarButtons = document.getElementsByClassName("calendar-navigate");
        calendarButtons[0].setAttribute("id", "ButtonLeft");
        calendarButtons[0].addEventListener('click', function(){ 
            position = position - 1
        });
        calendarButtons[0].innerHTML = '<svg id="leftIcon" xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 28 28" width="28px" fill="#000000"><path d="M0 0h28v28H0V0z" fill="none"/><path d="M14.71 6.71c-.39-.39-1.02-.39-1.41 0L8.71 11.3c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L10.83 12l3.88-3.88c.39-.39.38-1.03 0-1.41z"/></svg>';
        calendarButtons[1].setAttribute("id", "ButtonRight");
        calendarButtons[1].addEventListener('click', function(){ 
            position = position + 1
        });
        calendarButtons[1].innerHTML = '<svg id="rightIcon" xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 0 28 28" width="28px" fill="#000000"><path d="M0 0h28v28H0V0z" fill="none"/><path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z"/></svg>';
        
        const calendarTodayButton = document.querySelector('[class="today-button"]');
        calendarTodayButton.addEventListener('click', function(){ 
            if(position > 0){
                for (var i = 0; i < position; i++){
                    calendarButtons[0].click()
                } 
            }
            else {
                for (var j = 0; j < Math.abs(position); j++){
                    calendarButtons[1].click()
                } 
            }
            
        });
    }

    return(
        <Box>
            <Box className={classes.Events}>
                <Box id="ButtonBox">
                    {permissions === 'Admin' &&
                        <Box style={{position: 'fixed', bottom: '40px', right: '30px', zIndex: '999'}}>
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Evento Público</div>} arrow placement="left">
                                <Fab color="primary" aria-label="add" onClick={() => {window.open("https://calendar.google.com/calendar/u/0/r/eventedit")}}>
                                    <EventIcon />
                                </Fab>
                            </MyTooltip>
                        </Box>
                    }
                </Box>
                <Box id="CalendarBox">
                    <AsideCalendarFilter />
                    <Calendar apiKey={API_KEY} calendars={calendarArrayGuardada} language={language} showFooter={false} />
                </Box>
            </Box> 
        </Box>
    )
};

export default CalendarPanel;