import React, {useState} from "react";
import { Datagrid, List, TextField, ImageField, ReferenceField, FunctionField, useGetList } from "react-admin";
import { makeStyles, Box, Typography, CardMedia } from '@material-ui/core';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  BulkDeletePostButton: lightTheme.BulkDeletePostButton,
  InputBlackBorder: lightTheme.InputBlackBorder,
  InLineViewPost: lightTheme.InLineViewPost,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  FilterButtonGoogleStyle: lightTheme.FilterButtonGoogleStyle,
  BasketListInfoTable: lightTheme.BasketListInfoTable,
  EmptyBasketMediaCard: lightTheme.EmptyBasketMediaCard,
  VariantBadge:{
    fontSize: '0.80rem',
    marginTop: '-5px',
    '& span':{
      zIndex: '50',
      marginTop: '12px',
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '6px',
      paddingBottom: '4px',
      backgroundColor: '#bf1e2d',
      color: '#fff !important',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '12px'
    },
    '& p':{
      fontSize: '15px !important',
      textTransform: 'capitalize',
    }
  },
  PriceColor:{
    '& p':{
      color: 'rgb(182, 54, 21) !important',
    }
  },
  InfoBox:{
    '& #see-more':{
      color: '#196cdb !important',
      cursor: 'pointer',
    }
  }
});

const EmptyBasket = () => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" style={{width: '100%'}}>
      <Box textAlign="center" className={classes.EmptyBubble}>
        <CardMedia className={classes.EmptyBasketMediaCard}></CardMedia>
        <Typography variant="h6" style={{marginTop: '20px'}}>
          Tu Cesta está Vacía
        </Typography>
      </Box>
    </Box>
  );
};

function containsAnyLetter(str) {
  return /[a-zA-Z]/.test(str);
}

const BasketListInfo = ({record, ...props}) => {
  const {data, ids} = useGetList('basket', { page: 1, perPage: 50 }, { field: 'createdate', order: 'DESC' }, props.filter);
  const [ perPage, setPerPage ] = useState(4)
  const classes = useStyles();
  if(data){
    function handelChangePerPage(){
      setPerPage(ids.length)
    }
    return (
      <Box className={classes.BasketListInfoTable}>
        <List {...props} sort={{ field: 'createdate', order: 'DESC'}} actions={false} pagination={false} bulkActionButtons={false} style={{marginLeft: '-15px'}}
        empty={<EmptyBasket/>} perPage={perPage}>
          <Datagrid>
            <Box style={{display: 'flex', width: '100%', borderTop: '1px solid #cacaca', paddingTop: '5px'}}>
              <Box style={{margiLeft: '-15px', width: '20%'}}>
                <ReferenceField label="" source="material_id" reference="material" link={false} style={{color: 'rgb(130, 130, 130)'}} id="ProductImage">
                  <ImageField disabled label="" source="logo.image" variant="outlined"/>
                </ReferenceField>
              </Box>
              <Box style={{marginTop: '20px', marginRight: '-30px'}}>
                <ReferenceField label="" source="material_id" reference="material" link={false} style={{color: 'rgb(130, 130, 130)'}}>
                  <TextField disabled label="" source="title" variant="outlined"/>
                </ReferenceField><br/>
                <Box style={{width: '100%', display: 'flex'}}>
                  <Box style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                    <FunctionField 
                      render={ record => 
                        <Box>
                          <Box style={{display: 'flex', width: '130%', marginTop: '5px'}}>
                            <Box className={classes.PriceColor} style={{display: 'flex', width: '50%', justifyContent: 'flex-start'}}>
                              <Typography variant="body2" >
                                {(record.variant_price).toFixed(2).replace('.', ',')} €
                              </Typography>
                            </Box>
                            <Box style={{display: 'flex', width: '50%', justifyContent: 'flex-start'}}>
                              <Typography variant="body2" style={{fontSize: '0.80rem'}}>x&nbsp;
                                <TextField disabled label="" source="cantidad" variant="outlined"/>&nbsp; UD/S
                              </Typography>
                            </Box>
                          </Box>
                          {record.variant_id && containsAnyLetter(record.variant_name) === true &&
                            <Typography variant="body2" style={{display: 'flex', width: '100%', fontSize: '13px', marginTop: '5px'}}>
                              <ReferenceField label="" source="variant_id" reference="variants" link={false}>
                                <TextField disabled label="" source="name" variant="outlined"/>
                              </ReferenceField>
                            </Typography>
                          }
                          {record.variant_id && containsAnyLetter(record.variant_name) === false &&
                            <Typography variant="body2" className={classes.VariantBadge}>
                              <Box style={{display: 'flex', width: '100%'}}>
                                <p>Talla: &nbsp;</p>
                                <ReferenceField label="" source="variant_id" reference="variants" link={false}>
                                  <TextField disabled label="" source="name" variant="outlined"/>
                                </ReferenceField>
                              </Box>
                            </Typography>
                          }
                        </Box>
                      } 
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Datagrid>
        </List>
        {ids.length > 4 &&
          <Box className={classes.InfoBox}>
            <p>Mostrando 4 productos de {ids.length}. <span id="see-more" onClick={handelChangePerPage}>Ver Todos</span></p>
          </Box>
        }
      </Box>
    )
  } else {
    return null
  }
}; export default BasketListInfo;