import React from "react";
import { 
    Edit, SimpleForm, useTranslate, TextInput, Toolbar, SaveButton, required, FormDataConsumer, 
    ReferenceInput, AutocompleteInput, ArrayInput, SimpleFormIterator, DeleteButton
} from "react-admin";
import { Typography, Box, makeStyles, CircularProgress, Divider, Button } from '@material-ui/core';
import { mdiAccountBox } from '@mdi/js';
import lightTheme from '../layout/themes';
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import PlaceRoundedIcon from '@material-ui/icons/PlaceRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Icon from '@mdi/react'
import '../index.css';

const ContactIcon = () => {  return( <Icon path={mdiAccountBox} size={'24px'} style={{marginBottom: '-4px'}}/> ) };

const useStyles = makeStyles({
    InputBlackBorder: lightTheme.InputBlackBorder,
    CardUsersEdit: lightTheme.CardUsersEdit,
    EditToolBar: lightTheme.EditToolBar,
    ContactArray: lightTheme.CompanyStyle.CreateAndEdit.ContactArray
});

const DefaultAddButton = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Contacto</div>} aria-label="Delete" arrow placement="right">
            <Button size="small" {...props} id="GoogleStyleDownload">
                <AddRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
};

const DefaultRemoveButton = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Contacto</div>} aria-label="Delete" arrow placement="bottom">
            <Button size="small" {...props} id="GoogleStyleMuiDeleteButton">
                <DeleteRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
};

const CompanyEdit = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const redirect = () => `/company`;

    const CompanyToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        const basePath = `/company`;
        return (
          <Box>
            <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="Save" arrow>
                <SaveButton disabled={props.pristine} redirect={`/company`} id="GoogleStyleSaveButton">
                  {loading && (<CircularProgress/>)}
                </SaveButton>
              </MyTooltip>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Sociedad</div>} aria-label="Delete" arrow placement="right">
                <DeleteButton basePath={basePath} label="" id="GoogleStyleDeleteButton"/>
              </MyTooltip>
            </Toolbar>
          </Box>
        )
    };

    return (
        <Edit {...props} actions={false}>
            <SimpleForm redirect={redirect} toolbar={<CompanyToolbar/>}>
                <Box style={{ width:'100%', color: '#3d3d3d' }}>
                    <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                        <Box p={1} width="100%">
                            <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Sociedad</Typography>
                        </Box>
                        <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                                <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                            </MyTooltip>
                        </Box>
                    </Box>
                    <Box>
                        <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, marginLeft: '10px'}}>
                            <InfoRoundedIcon style={{fontSize: '1.4rem', marginBottom: '-4px', paddingRight: '5px'}}/>&nbsp;Información General
                        </Typography>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label="Nombre" source="name" variant="outlined"
                                multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()}/>
                            <TextInput label="IVA" source="iva" variant="outlined" style={{ width: '40%' }}
                                multiline="true" fullWidth className={classes.InputBlackBorder}/>
                            <TextInput label="GLN" source="gln" variant="outlined" style={{ width: '40%' }}
                                multiline="true" fullWidth className={classes.InputBlackBorder}/>
                        </Box>
                        <Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px'}} />
                        <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, marginLeft: '10px'}}>
                            <PlaceRoundedIcon style={{fontSize: '1.4rem', marginBottom: '-4px', paddingRight: '5px'}}/>&nbsp;Dirección
                        </Typography>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label={translate(`resources.users.field.address`)} variant="outlined" source="address" 
                                multiline="true" fullWidth className={classes.InputBlackBorder} />
                        </Box>
                        <Box>
                            <Box style={{ width: '100%',display: 'flex' }}>
                                <ReferenceInput source="country" reference="country" filterToQuery={searchText => ({ name: searchText })} 
                                    label={translate(`resources.users.field.country`)} className={classes.InputBlackBorder} variant="outlined" style={{ width: '30%' }}>
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (
                                            <Box style={{marginRight: '20px', width: '30%', marginLeft: '20px' }}>
                                                <ReferenceInput source="state" reference="state" filterToQuery={searchText => ({ name: searchText })} perPage={55}
                                                    label={translate(`resources.users.field.state`)} className={classes.InputBlackBorder} variant="outlined"
                                                    filter={{country: formData.country}}>
                                                    <AutocompleteInput optionText="name"/>
                                                </ReferenceInput>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                                <TextInput label={translate(`resources.users.field.location`)} variant="outlined" source="location" 
                                    multiline="true" fullWidth className={classes.InputBlackBorder} style={{ width: '30%' }}/>
                            </Box>
                        </Box>
                        <Box className={classes.ContactArray}>
                            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, marginLeft: '10px'}}>
                                <ContactIcon style={{fontSize: '1.2rem', marginBottom: '-4px', paddingRight: '5px'}}/>&nbsp;Contacto de la Sociedad
                            </Typography>
                            <ArrayInput source="contact" label="" style={{width: '100%', marginLeft: '10px', marginTop: '-10px'}}>
                                <SimpleFormIterator addButton={<DefaultAddButton />} removeButton={<DefaultRemoveButton />}>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData, formData, ...rest }) => {
                                            return (
                                                <Box>
                                                    <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                                        <TextInput label="Nombre del Contacto" source={getSource('contactName')} variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
                                                    </Box>
                                                    <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                                        <TextInput label="Email" source={getSource('email')} variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
                                                        <TextInput label="Teléfono" source={getSource('phone')} variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    )
};

export default CompanyEdit;