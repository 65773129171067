import NewsSubCategoriesIcon from '@material-ui/icons/TurnedIn';
import NewsSubCategoriesList from './NewsSubCategoriesList';
import NewsSubCategoriesCreate from './NewsSubCategoriesCreate';
import NewsSubCategoriesEdit from './NewsSubCategoriesEdit';

const NewsSubCategoriesModule = {
    list: NewsSubCategoriesList,
    icon: NewsSubCategoriesIcon,
    create: NewsSubCategoriesCreate,
    edit: NewsSubCategoriesEdit,
}; export default NewsSubCategoriesModule;