import React, { useEffect, useCallback } from 'react';
import { makeStyles, Box, Button, Typography } from '@material-ui/core';
import { useLogout } from 'react-admin';
import HomeIcon from '@mui/icons-material/Home';

const useStyles = makeStyles({
    MainBlockPage:{
      width: '100%',
      height: '100vh',
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    InvoiceButton:{
      marginTop: '20px',
      '& .MuiButtonBase-root':{
        border: '1px solid #196cdb',
        color: '#196cdb',
        textTransform: 'capitalize',
        borderRadius: '4px !important',
        padding: '4px 8px !important',
      },
      '& .MuiButtonBase-root:hover':{
        backgroundColor: '#196cdb21',
        boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important'
      },
      '& a':{
        color: '#196cdb',
        textDecoration: 'none',
      }
    },
});

const UserBlocked = () => {
    const logout = useLogout();
    const classes = useStyles();

    const timeChecker = useCallback(() => {
        setTimeout(() => {
            logout();
            window.location.reload()
        }, 7000);
    }, [logout]);

    useEffect(() => {
        timeChecker();
    }, [timeChecker]);

    return(
      <Box style={{width: '100%', height: '100vh'}}>
        <Box style={{width: '100%', display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <Box style={{width: '40%', display: 'flex', flexDirection: 'column'}}>
                <div className="error" data-text="403" style={{marginLeft: '-10px', marginBottom: '25px'}}>
                    403
                </div><br/>
                <Typography variant="h4" style={{marginTop: '20px'}}>
                    Este usuario ha sido bloqueado temporalmente 
                </Typography><br/>
                <Typography variant="h6" style={{marginTop: '20px'}}>
                    Contacta con Central para obtener<br/>
                    más información sobre el estado de tu cuenta
                </Typography>
                <div className={classes.InvoiceButton}>
                    <Button startIcon={<HomeIcon/>} onClick={() => logout()}>
                      Volver al Login
                    </Button>
                </div>
            </Box>
            <Box style={{width: '25%', display: 'flex', alignItems: 'end', justifyContent: 'flex-end'}}>
                <Box>
                    <img src="/static/images/Gifs/ErrorV2.gif" alt="error_img" style={{width: '440px'}}/>
                </Box>
            </Box>
        </Box>
      </Box>
    )
}; export default UserBlocked;