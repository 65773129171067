import React from "react";
import { 
  useGetOne, useRefresh, useDelete, usePermissions, SaveButton, useGetList, LinearProgress, 
  TextInput, SimpleForm, Edit, Toolbar 
} from 'react-admin';
import { CardMedia, Box, makeStyles, Typography, Button, Modal, Fade, Backdrop } from '@material-ui/core';
import lightTheme from '../layout/themes';
import MyTooltip from "../components/MuiTooltip";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import firebase from 'firebase';
import CloseButton from '../components/CloseButton';
import clsx from 'clsx';
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles({
  EmptyCommentsMediaCard: lightTheme.CommentsStyles.CommentsList.EmptyCommentsMediaCard,
  EmptyCommentsBox: lightTheme.CommentsStyles.CommentsList.EmptyCommentsBox,
  CommentsListBackground: lightTheme.CommentsStyles.CommentsList.CommentsListBackground,
  CommentAutorBox: lightTheme.CommentsStyles.CommentsList.CommentAutorBox,
  CommentBodyBox: lightTheme.CommentsStyles.CommentsList.CommentBodyBox,
  CommentsTable: lightTheme.CommentsStyles.CommentsList.CommentsTable,
  ButtonBox: lightTheme.CommentsStyles.CommentsList.ButtonBox,
  BodyComment: lightTheme.CommentsStyles.CommentsList.BodyComment,
  BubbleMessage: lightTheme.CommentsStyles.CommentsList.BubbleMessage,
  TooltipTitle: lightTheme.CommonStyles.ListItems.TooltipTitle,
  W100: lightTheme.CommonStyles.ListItems.W100,
  MuiModal: lightTheme.CommonStyles.Modal.MuiModal,
  MuiPaper: lightTheme.CommonStyles.Modal.MuiPaper,
  BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
  ToolBar: lightTheme.CommonStyles.CreateAndEdit.ToolBar,
});

const UserName = ({ id }) => {
  const { data, loading, error } = useGetOne('users', id);
  if (loading) { return <LinearProgress /> }
  if (error) { return ( <span style={{color: '#196cdb'}}>Unknown</span> ) }
  if (data) { 
    return ( <span style={{color: '#196cdb'}}>{data.displayName}</span> )
  };
};


const CommentsList = ({...props}) => {

  const CommentCard = () => {
    const classes = useStyles();
    const permissions = usePermissions();
    const refresh = useRefresh();
    const [deleteOne] = useDelete();

    const user = firebase.auth().currentUser;

    const { data, ids } = useGetList('comments', { page: 1, perPage: 200 }, { field: 'createdate', order: 'DESC'}, { post_id: props.post_id } );
    const DateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {setOpen(true)};
    const handleClose = () => {setOpen(false)};

    const [commentID, setCommentID] = React.useState('');
    let CommentsProps = {basePath: "/comments", resource: "comments", post_id: props.post_id, id: commentID}
    const redirect = () => `/post/${props.post_id}/show`;

    const CommentEditToolbar = ({event, loading, ...props}) => {
      const classes = useStyles();
      return (
        <Box>
          <Toolbar {...props} className={clsx(classes.ToolBar, classes.FlexStart100)}>
            <MyTooltip title={<div className={classes.TooltipTitle}>Guardar y Enviar</div>} arrow placement="right">
              <SaveButton disabled={props.pristine} id="GoogleStyleSaveButton" onClick={event}>
                {loading && (<CircularProgress/>)}
              </SaveButton>
            </MyTooltip>
          </Toolbar>
        </Box>
      )
    };
    
    const handleDelete = (id) => {
      deleteOne('comments', id);
      setTimeout(refresh(), 3000);
    }

    return ids.length > 0 ? (
      <Box>
        {ids.map(id => (
          <Box>
            {data &&
              <Box>
                <Typography variant="body2" id="Body">
                  <UserName id={data[id].user_id}/> comentó el {new Date(data[id].createdate).toLocaleDateString("es-ES", DateOptions)}
                </Typography>
                <div className="bubble bubble-bottom-left">
                  <Box className={classes.BodyComment}>
                    <Typography variant="body2" id="Body">
                      <div dangerouslySetInnerHTML={{ __html: data[id].body }}/>
                    </Typography>
                  </Box>
                  <Box className={classes.ButtonBox} id="ButtonBox">
                    {permissions.permissions === 'Admin' &&
                      <MyTooltip title={<div className={classes.TooltipTitle}>Editar Comentario</div>} arrow>
                        <Button id="GoogleStyle-MuiButton" onClick={() => { handleOpen(); setCommentID(data[id].id);}} startIcon={<EditRoundedIcon/>}></Button>
                      </MyTooltip>
                    }
                    {permissions.permissions === 'Admin' &&
                      <MyTooltip title={<div className={classes.TooltipTitle}>Eliminar Comentario</div>} arrow>
                        <Button id="GoogleStyleMuiDeleteButton" onClick={()=> handleDelete(data[id].id)} startIcon={<DeleteRoundedIcon/>}></Button>
                      </MyTooltip>
                    }
                    {user.email === data[id].createdby && permissions.permissions !== "Admin"  &&
                      <MyTooltip title={<div className={classes.TooltipTitle}>Editar Comentario</div>} arrow>
                        <Button id="GoogleStyle-MuiButton" onClick={() => { handleOpen(); setCommentID(data[id].id);}} startIcon={<EditRoundedIcon/>}></Button>
                      </MyTooltip>
                    }
                    {user.email === data[id].createdby && permissions.permissions !== "Admin"  &&
                      <MyTooltip title={<div className={classes.TooltipTitle}>Eliminar Comentario</div>} arrow>
                        <Button id="GoogleStyleMuiDeleteButton" onClick={()=> handleDelete(data[id].id)} startIcon={<DeleteRoundedIcon/>}></Button>
                      </MyTooltip>
                    }
                  </Box>
                </div>

                <div>
                  <Modal className={classes.MuiModal} open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
                    <Fade in={open}>
                      <div className={classes.MuiPaper} style={{width: '700px'}}>
                        <Box id="TitleBox">
                            <Box id="Title">
                              <h2>Editar Comentario</h2>
                            </Box>
                            <Box id="CloseButton">
                              <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                                <CloseButton style={{paddingLeft: '17px',  paddingRight: '5px'}} onClick={handleClose}></CloseButton>
                              </MyTooltip>
                            </Box>
                        </Box>
                        <Box>
                          <Edit {...CommentsProps} actions={null}>
                            <SimpleForm toolbar={<CommentEditToolbar event={handleClose}/>} redirect={redirect}>
                              <TextInput label="Comentario" source="body" variant="outlined" multiline="true" fullWidth rows={5}
                              className={classes.BlackInput}/>
                            </SimpleForm>
                          </Edit>
                        </Box>
                      </div>
                    </Fade>
                  </Modal>
                </div>   

              </Box>
            }
          </Box>
        ))}

        

      </Box>
    ) : 
    <Box className={classes.EmptyCommentsBox}>
      <Box>
        <CardMedia className={classes.EmptyCommentsMediaCard}></CardMedia>
        <Typography variant="h6">
          Aún no hay Comentarios<br/>
          Sé el Primero en dejar tu Comentario
        </Typography>
      </Box>
    </Box>
  }

  return (
    <CommentCard record={props} style={{marginTop: '40px'}}/>
  )
}; export default CommentsList;