import React from 'react'
import { withRouter } from 'react-router'
import { Button } from '@material-ui/core'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'


const ToolbarBackButton = ({ history: { goBack }, children, ...props }) => {
  return (
    <Button {...props} onClick={goBack} startIcon={<ArrowBackRoundedIcon />} >
      {children}
    </Button>
  )
}; export default withRouter(ToolbarBackButton)