import React from "react";
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton, required, DeleteButton } from "react-admin";
import { Typography, Box, makeStyles, CircularProgress } from '@material-ui/core';
import lightTheme from '../layout/themes';
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import '../index.css';

const useStyles = makeStyles({
    InputBlackBorder: lightTheme.InputBlackBorder,
    CardUsersEdit: lightTheme.CardUsersEdit,
    EditToolBar: lightTheme.EditToolBar,
});

const CountryEdit = props => {
    const classes = useStyles();
    const redirect = () => `/country`;

    const CountryEditToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        const basePath = `/country`;
        return (
            <Box>
                <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="Save" arrow>
                        <SaveButton disabled={props.pristine} redirect={`/country`} id="GoogleStyleSaveButton">
                        {loading && (<CircularProgress/>)}
                        </SaveButton>
                    </MyTooltip>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Pais</div>} aria-label="Delete" arrow placement="right">
                        <DeleteButton basePath={basePath} label="" id="GoogleStyleDeleteButton"/>
                    </MyTooltip>
                </Toolbar>
            </Box>
        )
    };

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect} toolbar={<CountryEditToolbar/>}>
                <Box style={{ width:'100%', color: '#3d3d3d' }}>
                    <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                        <Box p={1} width="100%">
                            <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Pais</Typography>
                        </Box>
                        <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                                <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                            </MyTooltip>
                        </Box>
                    </Box>
                    <Box>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label="Nombre" source="name" variant="outlined" 
                                multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()}/>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    )
};

export default CountryEdit;