import React from "react";
import { 
  Create, SimpleForm, SelectInput, ArrayInput, SimpleFormIterator, FormDataConsumer, useDeleteMany, useUpdate, useDataProvider,
  ReferenceInput, useGetList, Toolbar, SaveButton, NumberInput, TextInput, required, useRefresh, useRedirect
} from "react-admin";
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import DoneAllRoundedIcon from '@material-ui/icons/DoneAllRounded';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';
import firebase from 'firebase';
import '../index.css';
import env from "react-dotenv";
import { Typography } from "@mui/material";
import moment from 'moment';
import 'moment/locale/es';

const useStyles = makeStyles({
  BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
  EditToolBar: lightTheme.EditToolBar,
  infoText:{
    width: '100%',
    '& .MuiTypography-root':{
      fontSize: '0.9rem !important',
    }
  }
});

const OrdersCreate = ({toggleModal, toggleSidebar, record, ...props }) => {
  const user = firebase.auth().currentUser;
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [deleteMany] = useDeleteMany();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { data, ids } = useGetList('basket', { page: 1, perPage: 200 }, { field: 'name', order: 'ASC'}, { user_id: user.uid });
  const Productos = ids.map(id => (data[id].material_id));
  const ProductosNames = ids.map(id => (data[id].material_name));
  const Variantes = ids.map(id => (data[id].variant_id));
  const VariantesNames = ids.map(id => (data[id].variant_name));
  const V_Stock = ids.map(id => (data[id].variant_stock));
  const M_Stock = ids.map(id => (data[id].material_stock));
  const Precios = ids.map(id => (data[id].variant_price));
  const Cantidades = ids.map(id => (data[id].cantidad));
  const PrecioTotal = ids.map(id => (data[id].variant_price * data[id].cantidad));
  const ProductList = [];

  let today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = dd + '/' + mm + '/' + yyyy;

  for (let i = 0; i < Productos.length; i++) {
    if ( Variantes[i] === undefined ) {
      ProductList.push({
        material_id: Productos[i], material_name: ProductosNames[i], variant_name: VariantesNames[i], variant_id: 'empty_variant', variant_price: Precios[i], total_variant_price: PrecioTotal[i], 
        cantidad: Cantidades[i], m_stock: M_Stock[i], v_stock: V_Stock[i]
      });
    } else {
      ProductList.push({
        material_id: Productos[i], material_name: ProductosNames[i], variant_name: VariantesNames[i], variant_id: Variantes[i], variant_price: Precios[i], total_variant_price: PrecioTotal[i], 
        cantidad: Cantidades[i], m_stock: M_Stock[i], v_stock: V_Stock[i]
      });
    }
  } 

  let sum = 0;
  for (let i = 0; i < PrecioTotal.length; i++) {
      sum += PrecioTotal[i];
  }

  var randomToken = require('random-token').create('ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789');
  var albaranNumber = require('random-token').create('0123456789');
  var Today = moment().format('DD/MM/YYYY');

  const OnSuccess = ({ data }) => {
    let MaterialQty = 0;
    let VariantQty = 0;
    const objectModule = {
      Token: data.tocken,
      State: 'NotifyOrder',
      OrderURL: `https://${env.REACT_APP_DOMAIN}/#/orders/${data.id}/show`,
      OrderTrackingNumber: '', 
      OrderTrackingLink: '',
      Comment: '',
      Total: data.total_price,
      Products: data.products,
      OrderDate: Today, 
      UserId: data.user_id,
    }
    dataProvider.sendOrderStateEmail('orders', objectModule);
    if(Productos.length > 0){
      for (var i = 0; i < Productos.length; i++) {
        MaterialQty = M_Stock[i] - Cantidades[i];
        VariantQty = V_Stock[i] - Cantidades[i];
        if (MaterialQty === 0) {
          update('material', Productos[i], { _id: Productos[i], stock: MaterialQty, stock_filter: false })
        } else {
          update('material', Productos[i], { _id: Productos[i], stock: MaterialQty })
        }      
        update('variants', Variantes[i], { _id: Variantes[i], stock: VariantQty })
        
      };
      if(ids && ids.length > 0){
        deleteMany('basket', ids);
      };
      refresh();
    }
    redirect(`/orders/${data.id}/show`);
    toggleModal(false);
    toggleSidebar({right: false,});
  }

  const OrdersCreateToolbar = ({loading, ...props}) => {
    const classes = useStyles();
    return (
      <Box>
        <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
          <MyTooltip title={<div style={{fontSize: '12px'}}>Terminar y Realizar Pedido</div>} aria-label="Save" placement="right" arrow>
            <SaveButton id="GoogleStyleSaveButton" label="Terminar" icon={<DoneAllRoundedIcon/>}>
              {loading && ( <CircularProgress/> )}
            </SaveButton>
          </MyTooltip>
        </Toolbar>
      </Box>
    )
  };

  return(
    <Create {...props} onSuccess={OnSuccess}>
      <SimpleForm toolbar={<OrdersCreateToolbar/>} redirect="show">
        {data &&
          <Box style={{width: '100%'}}>
            <Box className={classes.infoText}>
              <Typography variant="body1">
               ** Si no puedes ver ninguna dirección, por favor, crea una en tu perfil de Usuario, desde la pestaña "Mis Direcciones" **
              </Typography>
              <ReferenceInput label="Dirección de Envio" source="user_address" reference="address" filter={{ user: user.uid }} variant="outlined"
                multiline="true" fullWidth className={classes.BlackInput} validate={required()}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Box>
            <Box style={{display: 'none'}}>
                <ReferenceInput source="user_id" reference="users" defaultValue={user.uid}>
                    <SelectInput optionText="id" />
                </ReferenceInput>
                <TextInput source="tocken" validate={required()} defaultValue={randomToken(10)} />
                <TextInput source="albaran" validate={required()} defaultValue={albaranNumber(4)} />
                <ArrayInput source="products" style={{marginLeft: '15px'}} defaultValue={ProductList} validate={required()}>
                    <SimpleFormIterator>
                        <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                              return (
                                  <Box style={{ width: '100%' }} justifyContent="flex-start">
                                      <ReferenceInput source={getSource('material_id')} reference="material" record={scopedFormData} >
                                        <TextInput optionText="id" fullWidth validate={required()}/>
                                      </ReferenceInput>
                                      <TextInput source={getSource('material_name')} fullWidth validate={required()}/>
                                      <TextInput source={getSource('material_img')} fullWidth/>
                                      <ReferenceInput source={getSource('variant_id')} reference="variants" record={scopedFormData} >
                                        <TextInput optionText="id" fullWidth />
                                      </ReferenceInput>
                                      <TextInput source={getSource('variant_name')} fullWidth validate={required()}/>
                                      <NumberInput source={getSource('variant_price')} />
                                      <NumberInput source={getSource('total_variant_price')} />
                                      <NumberInput source={getSource('cantidad')} />
                                  </Box>
                              );
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput source="date" defaultValue={today}/>
                <NumberInput source="total_items" defaultValue={ProductList.length}/>
                <NumberInput source="total_price" defaultValue={sum}/>
                
                <SelectInput source="state" choices={[
                    { id: '1', name: 'Sin Confirmar' },
                    { id: '2', name: 'Aceptado' },
                    { id: '3', name: 'Enviado' },
                    { id: '4', name: 'Entregado' },
                    { id: '5', name: 'Cancelado' },
                    { id: '6', name: 'Devuelto'},
                ]} optionText="name" optionValue="name" defaultValue="Sin Confirmar"/>
            </Box>
          </Box>
        }
      </SimpleForm>
    </Create>
  )
}; export default OrdersCreate;