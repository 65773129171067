import React from "react";
import { Create, SimpleForm, required, Toolbar, SaveButton, DateInput, ReferenceInput, SelectInput, TextInput } from "react-admin";
import { Box, makeStyles, CircularProgress } from '@material-ui/core';
import lightTheme from '../layout/themes';
import MyTooltip from "../components/MuiTooltip";
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import firebase from 'firebase';
import clsx from 'clsx';
import '../index.css';

const useStyles = makeStyles({
  CommentsEditCard: lightTheme.CommentsStyles.CreateAndEdit.CommentsEditCard,
  BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
  ToolBar: lightTheme.CommonStyles.CreateAndEdit.ToolBar,
  RichTextInputBlack: lightTheme.CommonStyles.CreateAndEdit.RichTextInputBlack,
  TooltipTitle: lightTheme.CommonStyles.ListItems.TooltipTitle,
  W100: lightTheme.CommonStyles.ListItems.W100,
  FlexTitleBox: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox,
  FlexTitle: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox.FlexTitle,
  FlexCloseButtonBox: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox.FlexCloseButtonBox,
});

export const CommentCreate = ({ event, permissions, ...props }) => {
  const classes = useStyles();
  const user = firebase.auth().currentUser;
  const post_id = props.post_id;
  const today = new Date();
  const redirect = () => `/post/${post_id}/show`;

  const CommentCreateToolbar = ({loading, ...props}) => {
    const classes = useStyles();
    return (
      <Toolbar {...props} className={clsx(classes.ToolBar, classes.FlexStart100)}>
        <MyTooltip title={<div className={classes.TooltipTitle}>Enviar Comentario</div>} arrow placement="right">
          <SaveButton disabled={props.pristine} id="GoogleStyleSaveButton" label="Enviar" icon={<SendRoundedIcon/>} redirect={redirect} onClick={event}>
            {loading && (<CircularProgress/>)}
          </SaveButton>
        </MyTooltip>
      </Toolbar>
    )
  };

  return (
    <Create {...props} actions={null}>
      <SimpleForm toolbar={<CommentCreateToolbar/>} defaultValue={{ created_at: today, post_id: post_id }} redirect={redirect}>
        
        <TextInput label="Comentario" source="body" variant="outlined" multiline="true" fullWidth rows={5}
        className={classes.BlackInput}/>

        <Box style={{display: 'none'}}>
          <ReferenceInput label="Autor" source="user_id" reference="users" defaultValue={user.uid} validate={required()}>
            <SelectInput optionText="displayName" />
          </ReferenceInput>
          <ReferenceInput source="post_id" reference="post" allowEmpty validate={required()}>
            <SelectInput optionText="id" />
          </ReferenceInput>
          <DateInput source="created_at" />
        </Box>

      </SimpleForm>
    </Create>
  )
}; export default CommentCreate;