import React from "react";
import { 
  Create, SimpleForm, TextInput, ReferenceInput, SelectInput, Toolbar, SaveButton, 
  required, NumberInput, useGetOne, useRefresh, useUpdate
} from "react-admin";
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import { mdiHanger } from '@mdi/js'; 
import Icon from '@mdi/react'
import MyTooltip from "../components/MuiTooltip";
import lightTheme from '../layout/themes';
import '../index.css';

const AddVariant = () => {  return( <Icon path={mdiHanger} size={0.8} style={{marginRight: '10px'}}/> ) };

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  EditToolBar: lightTheme.EditToolBar,
});

const VariantsCreateToolbar = ({loading, ...props}) => {
  const classes = useStyles();
  return (
    <Box>
      <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Crear nueva variante</div>} arrow placement="right">
          <SaveButton id="GoogleStyleSaveButton" label="Crear" icon={<AddVariant/>}>
            {loading && ( <CircularProgress/> )}
          </SaveButton>
        </MyTooltip>
      </Toolbar>
    </Box>
  )
};



const VariantsCreate = ({ permissions, ...props }) => {
  const classes = useStyles();
  const [update] = useUpdate();
  const refresh = useRefresh();
  const redirect = () => `/material/${props.material_id}/show`;
  const { data } = useGetOne('material', props.material_id);
  const m_Stock = data.stock;

  const updateStock = ({data}) => {
    const NewStock = m_Stock + data.stock;
    update('material', props.material_id, {_id: props.material_id, stock: NewStock, stock_filter: true});
    refresh();
  };

  return(
    <Create {...props} onSuccess={updateStock}>
      <SimpleForm redirect={redirect} toolbar={<VariantsCreateToolbar/>}>
        <Box style={{ width: '100%'}}>
          <Box display="flex" justifyContent="flex-start" style={{ width: '100%'}}>
            <TextInput label="Nombre" source="name" variant="outlined" fullWidth className={classes.InputBlackBorder} style={{ width: '100%'}} validate={required()}/>
          </Box>
          <Box display="flex" justifyContent="flex-start" style={{ width: '100%'}}>
            <TextInput label="Código EAN" source="ean" variant="outlined" fullWidth className={classes.InputBlackBorder} validate={required()}/>
          </Box>
          <Box display="flex" justifyContent="flex-start" style={{ width: '100%'}}>
            <NumberInput label="Stock" source="stock" variant="outlined" className={classes.InputBlackBorder} style={{display: 'flex', width: '50%'}} 
              step={1} min={0} validate={required()}
            />
            <NumberInput id="Price" label="Precio" source="price" variant="outlined" className={classes.InputBlackBorder} style={{display: 'flex', width: '50%'}} 
              min={0} defaultValue={0} validate={required()}
            />
          </Box>
          <Box style={{display: 'none'}}>
            <ReferenceInput source="material_id" reference="material" allowEmpty defaultValue={props.material_id}>
              <SelectInput optionText="id" />
            </ReferenceInput>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}; export default VariantsCreate;