import React from "react";
import { Create, SimpleForm, TextInput, Toolbar, SaveButton, required } from "react-admin";
import { Box, makeStyles, CircularProgress } from '@material-ui/core';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import lightTheme from '../layout/themes';
import MyTooltip from '../components/MuiTooltip';
import '../index.css';

const useStyles = makeStyles({
    BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
    CardUsersEdit: lightTheme.CardUsersEdit,
    EditToolBar: lightTheme.EditToolBar,
});

const CountryCreate = props => {
    const classes = useStyles();
    const redirect = () => `/country`;

    const CountryEditToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        return (
          <Box>
            <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Crear País</div>} aria-label="Save" arrow placement="right">
                <SaveButton disabled={props.pristine} redirect={`/country`} id="GoogleStyleSaveButton" label="Crear" icon={<AddRoundedIcon/>}>
                  {loading && (<CircularProgress/>)}
                </SaveButton>
              </MyTooltip>
            </Toolbar>
          </Box>
        )
    };

    return (
      <Create {...props}>
        <SimpleForm redirect={redirect} toolbar={<CountryEditToolbar/>}>
          <TextInput label="Nombre" source="name" variant="outlined" 
            multiline="true" fullWidth className={classes.BlackInput} validate={required()}/>
        </SimpleForm>
      </Create>
    )
};

export default CountryCreate;