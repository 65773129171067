import React, {useState} from "react";
import { 
  Create, SimpleForm, TextInput, ReferenceInput, SelectInput, FileInput, FileField, required, 
  Toolbar, SaveButton, FormDataConsumer
} from "react-admin";
import { makeStyles, Typography, Box, Divider, CircularProgress } from '@material-ui/core';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import BackButton from '../components/BackButton';
import MyTooltip from "../components/MuiTooltip";
import TinyMCETextEditor from '../components/TextEditors/TinyMCETextEditor'
import lightTheme from '../layout/themes';
import firebase from 'firebase';
import '../index.css';

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  FileInputBox: lightTheme.FileInputBox,
  EditToolBar: lightTheme.EditToolBar,
});

const FilesCreateToolbar = ({loading, ...props}) => {
  const classes = useStyles();
  return (
    <Box>
      <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Adjuntar Archivo</div>} aria-label="Save" arrow placement="right">
          <SaveButton disabled={props.pristine} id="GoogleStyleSaveButton" label="Adjuntar" icon={<AttachFileRoundedIcon/>}>
            {loading && ( <CircularProgress/> )}
          </SaveButton>
        </MyTooltip>
      </Toolbar>
    </Box>
  )
};

const FilesCreate = ({permissions, ...props}) => {
  const [mDescription, setMDescription] = useState("");
  const classes = useStyles();
  const category = (props.location.search);
  const categoryName = (props.location.search);
  const provider = (props.location.search);
  const shop = (props.location.search);
  const providerName = (props.location.search);
  const shopName = (props.location.search);
  const campaign = (props.location.search);
  const condition_id = (props.location.search);
  const notice_id = (props.location.search);
  const user = firebase.auth().currentUser;

  return(
    <Create {...props} actions={false}>
      <SimpleForm toolbar={<FilesCreateToolbar/>} 
        initialvalues={{ category: category, categoryName: categoryName, provider: provider, campaign: campaign, shop: shop,
          condition_id: condition_id, notice_id: notice_id, shopName: shopName, providerName: providerName
        }}>
        <Box style={{ width:'100%' }}>
          <Box style={{ width:'100%', color: '#3d3d3d' }}>
            <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
              <Box p={1} width="100%">
                <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Adjuntar Archivo</Typography>
              </Box>
              <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                <MyTooltip title={<div style={{fontSize: '13px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                  <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                </MyTooltip>
              </Box>
            </Box>
          </Box>
          <Box >
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                <InfoRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>Información General
            </Typography>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Título" source="name" multiline="true" fullWidth variant="outlined" className={classes.InputBlackBorder} validate={required()}/> 
              <TextInput label="Categoría" source="category" variant="outlined" style={{ display: 'none' }} className={classes.InputBlackBorder} validate={required()}/>
            </Box>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.category &&
                <Box style={{ display: 'none'}}>
                  <TextInput label="Categoría" source="categoryName"/>
                </Box>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.category === 'eLRVRilYd5sdIR7w8Zl3' &&
                <Box>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <ReferenceInput label="Proveedor" source="provider" reference="provider" className={classes.InputBlackBorder} allowEmpty
                    perPage={200} sort={{ field: 'name', order: 'ASC' }} filter={{shop: formData.shop}}>
                      <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }} {...rest}/>
                    </ReferenceInput>
                    <ReferenceInput label="Enseña" source="shop" reference="shops" className={classes.InputBlackBorder} allowEmpty>
                      <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }} {...rest}/>
                    </ReferenceInput>
                  </Box>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <ReferenceInput label="Campaña" source="campaign" reference="campaigns" className={classes.InputBlackBorder} allowEmpty>
                      <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }} {...rest}/>
                    </ReferenceInput>
                  </Box>
                  <Box style={{display: 'none'}}>
                    <TextInput label="condition ID" source="condition_id"/>
                    <TextInput label="shopName" source="shopName" />
                    <TextInput label="providerName" source="providerName" />
                  </Box>
                </Box>
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.category === 'VtNc5D0KMKEtk4cX1LZq' &&
                <Box style={{display: 'none'}}>
                  <Box style={{ display: 'none'}}>
                    <TextInput label="notice ID" source="notice_id"/>
                  </Box>
                </Box>
              }
            </FormDataConsumer>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                <SubjectRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>
                Descripción del Archivo
            </Typography>

            <TextInput source="descriptionCopy" multiline="true" style={{width: '100%', display: 'none'}}/>
            <TinyMCETextEditor data={mDescription} source="TextValue1"/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                  setMDescription(formData.descriptionCopy);
                  formData.description = formData.TextValue1;
                }}
            </FormDataConsumer>

            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                <AttachFileRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>Archivo
            </Typography>
            <Box style={{paddingLeft: '10px', paddingRight: '10px', marginTop: '-15px'}} className={classes.FileInputBox} >
              <FileInput source="fileUrl" label="" validate={required()}>
                <FileField source="src" title="title"/>
              </FileInput>
            </Box>
            <Box style={{display: 'none'}}>
              <ReferenceInput label="" source="user" reference="users" initialValue={user.uid} >
                <SelectInput optionText="displayName" optionValue="id"/>
              </ReferenceInput>
            </Box>
            <FormDataConsumer>
              {({ formData, ...rest }) => formData.fileUrl &&
                <Box style={{display: 'none'}}>
                  {(formData.fileUrl.title.toLowerCase().includes(".pptx") || formData.fileUrl.title.toLowerCase().includes(".ppt")) &&
                    <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="PowerPoint" {...rest}/> 
                  }
                  {(formData.fileUrl.title.toLowerCase().includes(".xlsx") || formData.fileUrl.title.toLowerCase().includes(".xls")) &&
                    <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="Excel" {...rest}/> 
                  }
                  {formData.fileUrl.title.toLowerCase().includes(".pdf") &&
                    <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="PDF" {...rest}/> 
                  }
                  {(formData.fileUrl.title.toLowerCase().includes(".docx") || formData.fileUrl.title.toLowerCase().includes(".doc") || 
                    formData.fileUrl.title.toLowerCase().includes(".txt")) &&
                    <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="Word" {...rest}/> 
                  }
                  {(formData.fileUrl.title.toLowerCase().includes(".svg") || formData.fileUrl.title.toLowerCase().includes(".png") || 
                    formData.fileUrl.title.toLowerCase().includes(".jpg") || formData.fileUrl.title.toLowerCase().includes(".jpeg")) &&
                    <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="Imagen" {...rest}/> 
                  }
                </Box>
              }
            </FormDataConsumer>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}; export default FilesCreate;