import React from "react";
import { 
  Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, Toolbar, SaveButton, required, NumberInput, DeleteButton, useGetOne,
  useUpdate, useRefresh
} from "react-admin";
import { makeStyles, Box, CircularProgress, Typography } from '@material-ui/core';
import MyTooltip from '../components/MuiTooltip';
import BackButton from '../components/BackButton';
import lightTheme from '../layout/themes';
import '../index.css';

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  EditToolBar: lightTheme.EditToolBar,
});

const VariantsEditToolbar = ({loading, basePath, ...props}) => {
  const classes = useStyles();
  return (
    <Box>
      <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Variante</div>} arrow placement="top" >
          <SaveButton id="GoogleStyleSaveButton">
            {loading && (<CircularProgress/>)}
          </SaveButton>
        </MyTooltip>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Variante</div>} arrow placement="right"> 
          <DeleteButton basePath={basePath} label="" id="GoogleStyleDeleteButton"/>
        </MyTooltip>
      </Toolbar>
    </Box>
  )
};

const VariantsEdit = ({ permissions, ...props }) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const [update] = useUpdate();
  const {data} = useGetOne('variants', props.id);
  var materialID = "";

  if(data) {
    materialID = data.material_id;
  } else  {
    materialID = "";
  }

  const materialData = useGetOne('material', materialID)

  if(materialData.data){
    const m_Stock = materialData.data.stock;
    
    const updateStock = () => {
      var StockForm = document.getElementById('Stock');
      var formStock = "";
      if(StockForm){
        formStock = parseInt(StockForm.value)
        const NewStock = formStock + m_Stock;
        update('material', materialID, {_id: materialID, stock: NewStock, stock_filter: true});
        refresh();
      }
    };

    const redirect = () => `/material/${materialID}/edit`;
    return(
      <Edit {...props} undoable={false} onSuccess={updateStock}>
        <SimpleForm redirect={redirect} toolbar={<VariantsEditToolbar/>}>
          <Box style={{ width:'100%', color: '#3d3d3d' }}>
            <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
              <Box p={1} width="100%">
                <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Variante</Typography>
              </Box>
              <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack">
                  <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                </MyTooltip>
              </Box>
            </Box>
            <Box style={{ width: '100%'}}>
              <Box display="flex" justifyContent="flex-start" style={{ width: '100%'}}>
                <TextInput label="Nombre" source="name" variant="outlined" fullWidth className={classes.InputBlackBorder} style={{ width: '50%'}} validate={required()}/>
                <TextInput label="Código EAN" source="ean" variant="outlined" fullWidth className={classes.InputBlackBorder} style={{ width: '50%'}} validate={required()}/>               
              </Box>
              <Box display="flex" justifyContent="flex-start" style={{ width: '100%'}}>
                <NumberInput id="Stock" label="Stock" source="stock" variant="outlined" className={classes.InputBlackBorder} style={{display: 'flex', width: '50%'}} 
                  step={1} min={0} defaultValue={0} validate={required()}
                />
                <NumberInput id="Price" label="Precio" source="price" variant="outlined" className={classes.InputBlackBorder} style={{display: 'flex', width: '50%'}} 
                  min={0} defaultValue={0} validate={required()}
                />
              </Box>
              <Box style={{display: 'none'}}>
                <ReferenceInput source="material_id" reference="material" allowEmpty defaultValue={props.material_id}>
                  <SelectInput optionText="id" />
                </ReferenceInput>
              </Box>
            </Box>
          </Box>
        </SimpleForm>
      </Edit>
    )
  } else {
    return null
  }
}; export default VariantsEdit;