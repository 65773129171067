import React from "react";
import { 
  Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, useGetOne, DateInput, ArrayInput, SimpleFormIterator,
  Toolbar, SaveButton, DeleteButton, FormDataConsumer, required, SelectArrayInput, ReferenceArrayInput 
} from "react-admin";
import { makeStyles ,Typography, Box, Divider, CircularProgress, Button } from '@material-ui/core';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import BackButton from '../components/BackButton';
import MyTooltip from "../components/MuiTooltip";
import lightTheme from '../layout/themes';
import TinyMCETextEditor from '../components/TextEditors/TinyMCETextEditor'
import '../index.css';

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  ContactArray: lightTheme.PointOfSaleStyle.CreateAndEdit.ContactArray,
  EditToolBar: lightTheme.EditToolBar,
});

const Provider = (dataId) => {
  const { data, error } = useGetOne('provider', dataId);
  if (error) { return <p>ERROR</p>; }
  if (data) { return data.name };
};

const ConditionsEdit = ({ permissions, ...props }) => {
  const classes = useStyles();
  const { data } = useGetOne('conditions', props.id);
  const StateChoices=[ 
    { id: 'borrador', name: 'borrador' }, 
    { id: 'publicado', name: 'publicado' }
  ] 
  
  const ConditionsEditToolbar = ({loading, ...props}) => {
    const classes = useStyles();
    return (
      <Box>
        {data &&
          <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Condición</div>} aria-label="Save" >
              <SaveButton disabled={props.pristine} redirect={`/provider/${data.provider.id}/show`} id="GoogleStyleSaveButton">
                {loading && ( <CircularProgress/> )}
              </SaveButton>
            </MyTooltip>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Condición</div>} aria-label="Delete">
              <DeleteButton redirect={`/provider/${data.provider.id}/show`} label="" id="GoogleStyleDeleteButton"/>
            </MyTooltip>
          </Toolbar>
        }
      </Box>
    )
  };

  const DefaultAddButton = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Dead Line</div>} aria-label="Delete" arrow placement="right">
            <Button size="small" {...props} id="GoogleStyleDownload">
                <AddRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
  };
  
  const DefaultRemoveButton = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Dead Line</div>} aria-label="Delete" arrow placement="bottom">
            <Button size="small" {...props} id="GoogleStyleMuiDeleteButton">
                <DeleteRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
  };
  if (data) {

    const conditionProps = {basePath: "/conditions", resource: "conditions", id: data.id, shop_id: data.shop}

    return(
      <Box>
        {data && data.provider && 
          <Edit {...conditionProps} actions={false}>
            <SimpleForm toolbar={<ConditionsEditToolbar/>}>
              <Box style={{ width: '100%' }}>
                <Box style={{ width:'100%', color: '#3d3d3d' }}>
                  <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                    <Box p={1} width="100%">
                      <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Condición</Typography>
                    </Box>
                    <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                      <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack">
                        <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                      </MyTooltip>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                    <InfoRoundedIcon/>&nbsp;&nbsp;Información General
                  </Typography>
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      formData.shops = formData.shop
                      formData.provider.name = Provider(formData.provider.id)
                      return (
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                          <ReferenceInput label="Proveedor" source="provider.id" reference="provider" className={classes.InputBlackBorder} validate={required()}
                          perPage={200} sort={{ field: 'name', order: 'ASC' }} filter={{shop: formData.shop}}>
                            <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }}/>
                          </ReferenceInput>
                          <ReferenceInput label="Enseña" source="shop" reference="shops" className={classes.InputBlackBorder} validate={required()}>
                            <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }}/>
                          </ReferenceInput>
                          <Box style={{display: 'none'}}>
                            <ReferenceInput label="Enseña" source="shops" reference="shops" className={classes.InputBlackBorder} validate={required()}>
                              <SelectInput optionText="id" optionValue="id" variant="outlined" style={{ width: '100%' }}/>
                            </ReferenceInput>
                          </Box>
                        </Box>
                      )
                    }}
                  </FormDataConsumer>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <ReferenceInput label="Campaña" source="campaign" reference="campaigns"  className={classes.InputBlackBorder} validate={required()}>
                      <SelectInput optionText="name" optionValue="id"  variant="outlined" style={{ width: '100%' }}/>
                    </ReferenceInput>
                    <SelectInput label="Estado" variant="outlined" source="state" validate={required()} style={{textTransform: 'capitalize'}}
                      multiline="true" fullWidth className={classes.InputBlackBorder} choices={StateChoices}/>
                  </Box>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <ReferenceArrayInput label="Categoría" source="category" reference="categorys" variant="outlined" className={classes.InputBlackBorder} 
                      perPage={200} sort={{ field: 'name', order: 'ASC' }} validate={required()}>
                      <SelectArrayInput source="name" style={{ width: '100%'}}/>
                    </ReferenceArrayInput>
                  </Box>
                  <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
                  <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                    <LocalOfferIcon/>&nbsp;&nbsp;Descuentos
                  </Typography>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <TextInput label="Descuento General" source="dec_general" multiline="true" fullWidth variant="outlined" className={classes.InputBlackBorder} />
                  </Box>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <TextInput label="Descuento Programación" source="dec_programacion" multiline="true" fullWidth  variant="outlined" className={classes.InputBlackBorder} />
                  </Box>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <TextInput label="Descuento Reposición" source="dec_reposicion" multiline="true" fullWidth variant="outlined" className={classes.InputBlackBorder} />
                  </Box>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <TextInput label="Descuento Concentración" source="dec_concentracion" multiline="true" fullWidth variant="outlined" className={classes.InputBlackBorder} />
                  </Box>
                  <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
                  <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                    <AddRoundedIcon/>&nbsp;&nbsp;Extras
                  </Typography>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <TextInput label="Facturación" source="facturacion" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
                    <TextInput label="Envío" source="envio" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
                  </Box>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <TextInput label="Pago" source="pago" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
                  </Box>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <TextInput label="Portes" source="portes" multiline="true" fullWidth size="big" variant="outlined" className={classes.InputBlackBorder} />
                  </Box>
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <TextInput label="Pedido Mínimo" source="pedido_min" multiline="true" fullWidth size="big" variant="outlined" className={classes.InputBlackBorder} />
                  </Box>
                  <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
                  <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 0, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                    <CalendarTodayRoundedIcon/>&nbsp;&nbsp;Fechas
                  </Typography>
                  <Box className={classes.ContactArray}>
                    <ArrayInput source="dates" label="" style={{width: '100%', marginLeft: '10px', marginTop: '-10px'}}>
                      <SimpleFormIterator addButton={<DefaultAddButton />} removeButton={<DefaultRemoveButton />}>
                          <FormDataConsumer>
                            {({ getSource, scopedFormData}) => {
                              return (
                                <Box>
                                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                    <DateInput label="Dead Line" source={getSource('deadLine')} variant="outlined" className={classes.InputBlackBorder} />
                                    <DateInput label="Fecha Servicio" source={getSource('serviceDate')} variant="outlined" className={classes.InputBlackBorder} />
                                  </Box>
                                </Box>
                              );
                            }}
                          </FormDataConsumer>
                      </SimpleFormIterator>
                    </ArrayInput>
                  </Box>
                  <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
                  <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                    <SubjectRoundedIcon/>&nbsp;&nbsp;Observaciones
                  </Typography>

                  <TinyMCETextEditor data={data.observaciones}/>
                  <FormDataConsumer>
                      {({ formData, ...rest }) => {
                          formData.observaciones = formData.textValue
                      }}
                  </FormDataConsumer>

                </Box>
              </Box>
            </SimpleForm>
          </Edit>
        }
      </Box>
    )
  } else {return null}
}; export default ConditionsEdit;