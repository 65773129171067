import jsPDF from 'jspdf'

export function generatePDF(){
    var PDFVars = document.querySelectorAll("#breadcrumbTitle");
    var PDFLogoImg = document.getElementById("providerLogoImgField");
    var ConditionsVarsGen = document.querySelectorAll('[for="general"]');
    var ConditionsVarsProg = document.querySelectorAll('[for="programacion"]');
    var ConditionsVarsRep = document.querySelectorAll('[for="reposicion"]');
    var ConditionsVarsCon = document.querySelectorAll('[for="concentracion"]');
    var ConditionsVarsObs = document.querySelectorAll('[for="observaciones"]')[0].firstChild.children;
    var ConditionsFacturacion = document.querySelectorAll('[for="facturacion"]');
    var ConditionsPago = document.querySelectorAll('[for="pago"]');
    var ConditionsEnvio = document.querySelectorAll('[for="envio"]');
    var ConditionsDeadLines = document.querySelectorAll('[id="dead-lines-box"]');
    var ConditionsFiles = document.querySelectorAll('[id="FolderContent"]');

    var doc = new jsPDF('p', 'pt');
    var textHeight = 40;
    var img = new Image();
    img.src = PDFLogoImg.firstChild.attributes.src.value;

    doc.addFont('helvetica', 'normal');
    //doc.rect(10, 10, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 20, 'S');
    doc.addImage(img, "PNG", 460, 0, 100, 100);
    doc.setFontSize(18);
    doc.text(20, textHeight, `Condiciones de ${PDFVars[2].innerText} - ${PDFVars[1].innerText}`);
    textHeight = textHeight + PDFVars[2].offsetHeight + 10
    doc.text(20, textHeight, `Para la campaña ${PDFVars[3].innerText}`);
    textHeight = textHeight + PDFVars[3].offsetHeight + 10
    doc.line(20, textHeight, 570, textHeight);
    textHeight = textHeight + 30

    if(ConditionsVarsGen.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'Descuento General');
      textHeight = textHeight + 20
      doc.setFontSize(12);
      var splitTextGen = doc.splitTextToSize(ConditionsVarsGen[0].firstChild.firstChild.textContent, 570);
      doc.text(20, textHeight, splitTextGen);
      textHeight = textHeight + ConditionsVarsGen[0].firstChild.firstChild.offsetHeight + 10
    }
    if(ConditionsVarsProg.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'Descuento Programación');
      textHeight = textHeight + 20
      doc.setFontSize(12);
      var splitTextProg = doc.splitTextToSize(ConditionsVarsProg[0].firstChild.firstChild.textContent, 570);
      doc.text(20, textHeight, splitTextProg);
      textHeight = textHeight + ConditionsVarsProg[0].firstChild.firstChild.offsetHeight + 10
    }
    if(ConditionsVarsRep.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'Descuento Reposición');
      textHeight = textHeight + 20
      doc.setFontSize(12);
      var splitTextRep = doc.splitTextToSize(ConditionsVarsRep[0].firstChild.firstChild.textContent, 570);
      doc.text(20, textHeight, splitTextRep);
      textHeight = textHeight + ConditionsVarsRep[0].firstChild.firstChild.offsetHeight + 10
    }
    if(ConditionsVarsCon.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'Descuento Concentración');
      textHeight = textHeight + 20
      doc.setFontSize(12);
      var splitTextCon = doc.splitTextToSize(ConditionsVarsCon[0].firstChild.firstChild.textContent, 570);
      doc.text(20, textHeight, splitTextCon);
      textHeight = textHeight + ConditionsVarsCon[0].firstChild.firstChild.offsetHeight + 20
    }
    if(ConditionsVarsObs.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'Observaciones');
      var splitTextCon2 = 'observaciones';
      var textToShow = '';
      for(var i = 0; i<ConditionsVarsObs.length; i++){
        doc.setFontSize(12);
        if(ConditionsVarsObs[i].localName === 'ul'){
          textToShow = ConditionsVarsObs[i].innerHTML;
          textToShow = textToShow.replace(/<\/li>/ig, '');
          textToShow = textToShow.replace(/<li>/ig, '  -  ');
        }
        else{
          textToShow = ConditionsVarsObs[i].textContent;
        }
        splitTextCon2 = doc.splitTextToSize(textToShow, 560);
        if(i === 0){
          textHeight = textHeight + 20
        } else {
          textHeight = textHeight + ConditionsVarsObs[i > 0 ? i - 1 : i].offsetHeight + 10
        }
        doc.text(20, textHeight, splitTextCon2);
      }
    }
    textHeight = textHeight + 40
    doc.line(20, textHeight, 570, textHeight);
    textHeight = textHeight + 40
    if(ConditionsFacturacion.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'Facturación');
      textHeight = textHeight + 20
      doc.setFontSize(12);
      var splitTextCon3 = doc.splitTextToSize(ConditionsFacturacion[0].textContent, 570);
      doc.text(20, textHeight, splitTextCon3.toString());
      textHeight = textHeight + 40
    }
    if(ConditionsPago.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'Pago');
      textHeight = textHeight + 20
      doc.setFontSize(12);
      var splitTextCon4 = doc.splitTextToSize(ConditionsPago[0].textContent, 570);
      doc.text(20, textHeight, splitTextCon4.toString());
      textHeight = textHeight + 40
    }
    if(ConditionsEnvio.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'Envío');
      textHeight = textHeight + 20
      doc.setFontSize(12);
      var splitTextCon5 = doc.splitTextToSize(ConditionsEnvio[0].textContent, 570);
      doc.text(20, textHeight, splitTextCon5.toString());
      textHeight = textHeight + 40
    }
    textHeight = textHeight + 40
    doc.line(20, textHeight, 570, textHeight);
    textHeight = textHeight + 40
    var pageHeight = doc.internal.pageSize.height;
    if (textHeight >= pageHeight)
    {
      doc.addPage();
      textHeight = 40 
    }
    if(ConditionsDeadLines.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'DeadLine   --   Fecha de Servicio');
      textHeight = textHeight + 30
      for (var x = 0; x < ConditionsDeadLines.length; x++){
        doc.setFontSize(12);
        var deadLineText = `${ConditionsDeadLines[x].firstChild.textContent}   --   ${ConditionsDeadLines[x].lastChild.textContent}`
        doc.text(20, textHeight, deadLineText);
        textHeight = textHeight + ConditionsDeadLines[x].firstChild.offsetHeight + 10
      }
    }
    textHeight = textHeight + 1;
    doc.line(20, textHeight, 570, textHeight);
    textHeight = textHeight + 40
    if(ConditionsFiles.length > 0){
      doc.setFontSize(14);
      doc.text(20, textHeight, 'Archivos Adjuntos');
      textHeight = textHeight + 30
      for (var j = 0; j < ConditionsFiles.length; j++){
        doc.setFontSize(12);
        doc.setTextColor(25, 108, 219);
        var FilesText = ConditionsFiles[j].textContent;
        doc.textWithLink(FilesText, 20, textHeight, { url: ConditionsFiles[j].title });
        textHeight = textHeight + ConditionsFiles[j].offsetHeight
      }
    }

    //doc.setProperties({ title: `${PDFVars[2].innerText} - ${PDFVars[1].innerText}: Campaña ${PDFVars[3].innerText}`});
    doc.setProperties({ title: `bbb`});
    //window.open(doc.output('bloburl'))
    doc.save(`${PDFVars[2].innerText} - ${PDFVars[1].innerText}: Campaña ${PDFVars[3].innerText}.pdf`);
}