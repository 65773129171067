import React from "react";
import { Link } from 'react-router-dom';
import { List, useListContext, Filter, SearchInput, useTranslate, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Grid, Card, CardMedia, CardContent, Typography, Divider, Box, makeStyles, Chip } from '@material-ui/core';
import ToolbarBackButton from '../components/ToolbarBackButton';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import MyTooltip from "../components/MuiTooltip";
import inflection from 'inflection';
import lightTheme from '../layout/themes';

const useStyles = makeStyles(theme => ({
  BlackInputMargin: lightTheme.CommonStyles.CreateAndEdit.BlackInputMargin,
  InputBlackBorder: lightTheme.InputBlackBorder,
  GridMedia: lightTheme.ProviderGridMedia,
  EmptyProvidersMediaCard: lightTheme.EmptyProvidersMediaCard,
  EmptyBubble: lightTheme.EmptyBubble,
  title: lightTheme.ProviderGridTitle,
  actionSpacer: lightTheme.ProviderGridActionSpacer,
  card: lightTheme.ProviderGridCard,
  ProviderFilterButtonGoogleStyle: lightTheme.ProviderFilterButtonGoogleStyle,
  chip: { marginBottom: theme.spacing(1), },
}));

const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return <Chip className={classes.chip} label={translate(label)}/>;
};

const EmptyProviders = () => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" style={{width: '100%', marginTop: '30px'}}>
      <Box textAlign="center" className={classes.EmptyBubble}>
        <CardMedia className={classes.EmptyProvidersMediaCard}></CardMedia>
        <Typography variant="h6" style={{marginTop: '20px'}}>
          No hay Proveedores Todavía para esta Tienda
        </Typography>
      </Box>
    </Box>
  );
};


const ProviderFilter = ({ resource, filters, displayedFilters, filterValues, showFilter, shop, ...props }) => {
  const classes = useStyles();
  return (
    <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      { props.context !== 'button' && props.data && props.ids && props.ids[0] &&
        <Box style={{ width: 'auto', display: 'flex', justifyContent: 'flex-start' }}>
          <Box>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Volver Atrás</div>} arrow>
              <ToolbarBackButton style={{paddingLeft: '16px', paddingRight: '5px', marginRight: '10px', marginTop: '15px'}} id="GoogleStyleBackButton"></ToolbarBackButton>
            </MyTooltip>
          </Box>
          <Box style={{ width: 'auto', display: 'flex', justifyContent: 'flex-start', marginTop: '15px' }}>
            <Box>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Lista de Tiendas</div>} arrow>
                <Link to={`/shops`} style={{textDecoration: 'none'}}>
                  <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '10px', zIndex: '5', fontSize: '14px', cursor: 'pointer'}}>Proveedores</Typography>
                </Link>
              </MyTooltip>
            </Box>
            <NavigateNextRoundedIcon style={{marginLeft: '10px', marginRight: '10px', marginTop: '8px', marginBottom: '6px'}}/>
              <Box>
                {props.data[props.ids[0]].shop === '618bd896efea2b2049ada486' &&
                  <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '8px', zIndex: '5'}}>Tréndico</Typography>
                }
                {props.data[props.ids[0]].shop === '618bd896efea2b2049ada489' &&
                  <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '8px', zIndex: '5'}}>Sötkatt</Typography>
                }
                {props.data[props.ids[0]].shop === '618bd896efea2b2049ada488' &&
                  <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '8px', zIndex: '5'}}>Atleet</Typography>
                }
                {props.data[props.ids[0]].shop === '618bd896efea2b2049ada487' &&
                  <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '8px', zIndex: '5'}}>Foot on Mars</Typography>
                }
              </Box>
          </Box>
        </Box>
      }
      <Box style={{marginLeft: '10px'}}>
        <Filter {...props} className={classes.ProviderFilterButtonGoogleStyle}>
          <SearchInput source="name" alwaysOn variant="outlined" className={classes.InputBlackBorder}/>
          <ReferenceInput source="category" label="Categoría" reference="categorys" filterToQuery={searchText => ({ name: searchText })}
            variant="outlined" className={classes.BlackInputMargin} perPage={500}>
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
          <QuickFilter source="jornadas_compras" label="Jornadas de Compra" defaultValue={true}/>
        </Filter>
      </Box>
    </Box>
  )
};

const ProviderGrid = ({ permissions, ...props })  => {
  const classes = useStyles(props);
  const { data, ids } = useListContext();
  return ids ? (
    <Grid container spacing={2}>
      {data && ids.map(id => (
        <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item >
            <Card className={classes.card} style={{boxShadow: '1px 1px 10px #bbb6b6 !important'}}>
              <Link to={`/provider/${data[id].id}/show`}>
                <CardMedia className={classes.GridMedia}>
                  <img alt={data[id].name} src={data[id].logo.src}/>
                </CardMedia>
                <Divider style={{marginLeft: '5px', marginRight: '5px', zIndex: '10', position: 'sticky'}}/>
                <CardContent className={classes.title}>
                  <Typography variant="body1" align="center" style={{ fontSize: '13px', textTransform: 'uppercase', textDecoratiom: 'none'}}>
                    {inflection.humanize(data[id].name)}
                  </Typography>
                </CardContent>
              </Link>
            </Card>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

const ProviderList = (props) => {
  return (
    <Box>
      <List {...props} perPage={200} filters={<ProviderFilter />} style={{marginBottom: '5em'}} pagination={false} component="div" sort={{ field: 'name', order: 'ASC' }} empty={<EmptyProviders/>}>
        <ProviderGrid />
      </List>
    </Box>
  )
}; export default ProviderList;
