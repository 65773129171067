import {
    Edit, SimpleForm, TextInput, SelectInput, ArrayInput, SimpleFormIterator, useTranslate, BooleanInput,
    ReferenceInput, SaveButton, DeleteButton, Toolbar, FormDataConsumer, AutocompleteInput, useGetOne
} from "react-admin";
import { makeStyles, Typography, Box, CircularProgress, Divider, Button} from '@material-ui/core/';
import lightTheme from '../layout/themes';
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import PlaceRoundedIcon from '@material-ui/icons/PlaceRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import DomainAddRoundedIcon from '@mui/icons-material/DomainAddRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';


const useStyles = makeStyles({
    InputBlackBorder: lightTheme.InputBlackBorder,
    CardUsersEdit: lightTheme.CardUsersEdit,
    EditToolBar: lightTheme.EditToolBar,
    BoolInput:{
        paddingLeft: '10px',
        paddingTop: '10px'
    },
    ShopsList:{
        '& .MuiFormControl-root':{
            '& li':{
                paddingTop: '15px',
                paddingBottom: '15px',
                marginRight: '25px',
                '& p':{
                    display: 'none',
                }
            }
        }
    }
});

const UsersEditToolbar = ({loading, ...props}) => {
    const classes = useStyles();
    const basePath = `/users`;
    return (
      <Box>
        <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
          <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="Save" arrow>
            <SaveButton disabled={props.pristine} redirect={`/users`} id="GoogleStyleSaveButton">
              {loading && (<CircularProgress/>)}
            </SaveButton>
          </MyTooltip>
          <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Usuario</div>} aria-label="Delete" arrow placement="right">
            <DeleteButton basePath={basePath} label="" id="GoogleStyleDeleteButton"/>
          </MyTooltip>
        </Toolbar>
      </Box>
    )
};

const DefaultAddButton = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Tienda</div>} aria-label="Delete" arrow placement="right">
            <Button size="small" {...props} id="GoogleStyleDownload">
                <AddBusinessRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
};

const DefaultAddButtonSociety = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Tienda</div>} aria-label="Delete" arrow placement="right">
            <Button size="small" {...props} id="GoogleStyleDownload">
                <DomainAddRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
};

const DefaultRemoveButton = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Tienda</div>} aria-label="Delete" arrow placement="bottom">
            <Button size="small" {...props} id="GoogleStyleMuiDeleteButton">
                <DeleteRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
};

export const UserEdit = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const RoleChoices=[ 
        { id: 'Admin', name: 'Admin' }, 
        { id: 'User', name: 'User' }, 
        { id: 'Central', name: 'Central' },
        { id: 'Publicador', name: 'Publicador' }, 
        { id: 'Transporte', name: 'Transporte' }, 
    ];

    const { data} = useGetOne('users', props.id);
    if(data){
        let shops = data.shop
        for (let i = 0; i < shops.length; i++) {
            if(shops[i].shop && !shops[i].id){
                shops[i].id = shops[i].shop
            }
            if(shops[i].id && !shops[i].shop){
                shops[i].shop = shops[i].id
            }
        }
        return (
            <Edit {...props}>
                <SimpleForm toolbar={<UsersEditToolbar/>}>
                    <Box style={{ width:'100%', color: '#3d3d3d' }}>
                        <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                            <Box p={1} width="100%">
                                <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Usuario</Typography>
                            </Box>
                            <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                                    <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                                </MyTooltip>
                            </Box>
                        </Box>
                        <Box className={classes.ShopsList}>
                            <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, display: 'flex'}}>
                                <InfoRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Información General
                            </Typography>
                            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                <TextInput label={translate(`resources.users.field.displayName`)} variant="outlined" source="displayName" 
                                    multiline="true" fullWidth className={classes.InputBlackBorder} />
                                <TextInput label="Código" variant="outlined" source="code" 
                                    multiline="true" fullWidth className={classes.InputBlackBorder} />
                                <SelectInput label={translate(`resources.users.field.role`)} variant="outlined" source="role" 
                                    multiline="true" fullWidth className={classes.InputBlackBorder} choices={RoleChoices}/>
                            </Box>
                            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                <TextInput label={translate(`resources.users.field.email`)} variant="outlined" type="email" source="email" 
                                    multiline="true" fullWidth className={classes.InputBlackBorder} />
                                <TextInput label={translate(`resources.users.field.phone`)} variant="outlined" source="phone" 
                                    multiline="true" fullWidth className={classes.InputBlackBorder} />
                            </Box>
                            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                <BooleanInput label="Bloquear Usuario" style={{ width: '35%'}}
                                    source="blocked" defaultValue={false} className={classes.BoolInput} />
                            </Box>
                            <Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px'}} />
                            <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, display: 'flex'}}>
                                <PlaceRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Dirección de Envio
                            </Typography>
                            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                <TextInput label={translate(`resources.users.field.address`)} variant="outlined" source="address" 
                                    multiline="true" fullWidth className={classes.InputBlackBorder} />
                            </Box>
                            <Box>
                                <Box style={{ width: '100%',display: 'flex' }}>
                                    <ReferenceInput source="country" reference="country" filterToQuery={searchText => ({ name: searchText })} 
                                        label={translate(`resources.users.field.country`)} className={classes.InputBlackBorder} variant="outlined" style={{ width: '30%' }}>
                                        <AutocompleteInput optionText="name" />
                                    </ReferenceInput>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            return (
                                                <Box style={{marginRight: '20px', width: '30%', marginLeft: '20px' }}>
                                                    <ReferenceInput source="state" reference="state" filterToQuery={searchText => ({ name: searchText })} perPage={55}
                                                        label={translate(`resources.users.field.state`)} className={classes.InputBlackBorder} variant="outlined"
                                                        filter={{country: formData.country}}>
                                                        <AutocompleteInput optionText="name"/>
                                                    </ReferenceInput>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                    <TextInput label={translate(`resources.users.field.location`)} variant="outlined" source="location" 
                                        multiline="true" fullWidth className={classes.InputBlackBorder} style={{ width: '30%' }}/>
                                </Box>
                            </Box>
                            <Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px'}} />
                            <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, display: 'flex'}}>
                                <DomainRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Detalles de la Sociedad
                            </Typography>
                            <Box style={{marginRight: '20px'}}>
                                <ReferenceInput source="company" reference="company" filterToQuery={searchText => ({ name: searchText })} label="Sociedad Principal"
                                    className={classes.InputBlackBorder} variant="outlined">
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                            </Box>
                            <ArrayInput source="companies" label="Sociedades Asociadas" style={{width: '100%', marginLeft: '10px', marginTop: '-10px'}}>
                                <SimpleFormIterator addButton={<DefaultAddButtonSociety />} removeButton={<DefaultRemoveButton />}>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData, formData, ...rest }) => {
                                            return (
                                                <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                                    <Box display="flex" style={{ width: '98%' }} justifyContent="flex-start">
                                                        <ReferenceInput label="Sociedades Asociadas" variant="outlined" source={getSource('id')} 
                                                            reference="company" record={scopedFormData} className={classes.InputBlackBorder} filterToQuery={searchText => ({ name: searchText })}>
                                                            <AutocompleteInput optionText="name"/>
                                                        </ReferenceInput>
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                            <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, marginLeft: '10px', display: 'flex'}}>
                                <StoreRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Tiendas en Propiedad
                            </Typography>
                            <ArrayInput source="shop" label="" style={{width: '100%', marginLeft: '10px', marginTop: '-10px'}}>
                                <SimpleFormIterator addButton={<DefaultAddButton />} removeButton={<DefaultRemoveButton />}>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData, formData, ...rest }) => {
                                            return (
                                                <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                                    <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                                        {data.shop && data.shop[0].shop && (data.shop[0].id === null || data.shop[0].id === undefined) &&
                                                            <ReferenceInput label={translate(`resources.users.field.shop`)} variant="outlined" source={getSource('shop')} 
                                                                reference="shops" record={scopedFormData} className={classes.InputBlackBorder}>
                                                                <SelectInput optionText="name" multiline="true" fullWidth style={{ width: '30%' }}/>
                                                            </ReferenceInput>
                                                        }
                                                        {data.shop && data.shop[0].id && (data.shop[0].shop === null || data.shop[0].shop === undefined) &&
                                                            <ReferenceInput label={translate(`resources.users.field.shop`)} variant="outlined" source={getSource('id')} 
                                                                reference="shops" record={scopedFormData} className={classes.InputBlackBorder}>
                                                                <SelectInput optionText="name" multiline="true" fullWidth style={{ width: '30%' }}/>
                                                            </ReferenceInput>
                                                        }
                                                        {data.shop && data.shop[0].id && data.shop[0].shop &&
                                                            <ReferenceInput label={translate(`resources.users.field.shop`)} variant="outlined" source={getSource('id')} 
                                                                reference="shops" record={scopedFormData} className={classes.InputBlackBorder}>
                                                                <SelectInput optionText="name" multiline="true" fullWidth style={{ width: '30%' }}/>
                                                            </ReferenceInput>
                                                        }
                                                        {(formData.company && (formData.companies.length === 0 || formData.companies === undefined)) &&
                                                            <ReferenceInput label={translate(`resources.users.field.shopName`)} variant="outlined" source={getSource('shopName')} 
                                                                reference="pointOfSale" record={scopedFormData} className={classes.InputBlackBorder} 
                                                                filter={{company_id: formData.company}}
                                                                sort={{field: 'name', order: 'ASC'}}
                                                                perPage={500}
                                                                filterToQuery={searchText => ({ name: searchText })}>
                                                                <AutocompleteInput optionText="name" multiline="true" fullWidth style={{ width: '70%' }}/>
                                                            </ReferenceInput>
                                                        }
                                                        {(formData.company && formData.companies && formData.companies[0]) &&
                                                            <ReferenceInput label={translate(`resources.users.field.shopName`)} variant="outlined" source={getSource('shopName')} 
                                                                reference="pointOfSale" record={scopedFormData} className={classes.InputBlackBorder} 
                                                                filter={{company_id_gte: formData.company, company_id_lte: formData.companies[0].id}}
                                                                sort={{field: 'name', order: 'ASC'}}
                                                                perPage={500}
                                                                filterToQuery={searchText => ({ name: searchText })}>
                                                                <AutocompleteInput optionText="name" multiline="true" fullWidth style={{ width: '70%' }}/>
                                                            </ReferenceInput>
                                                        }
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                </SimpleForm>
            </Edit>
        )
    }else{
        return null
    }
};

export default UserEdit;