import ConditionsIcon from '@material-ui/icons/InsertDriveFile';
import ConditionsCreate from './ConditionsCreate';
import ConditionsView from './ConditionsView';
import ConditionsList from './ConditionsList';
import ConditionsEdit from './ConditionsEdit';

const ConditionModule = {
    icon: ConditionsIcon,
    show: ConditionsView,
    create: ConditionsCreate,
    list: ConditionsList,
    edit: ConditionsEdit,
}; export default ConditionModule;