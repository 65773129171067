import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    Box,
    Button,
    Card,
    CardActions,
    CircularProgress,
    TextField,
    Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Notification, useTranslate, useLogin, useNotify, FormDataConsumer } from 'react-admin';
import ForgotPasswordButton from './ForgotPassword'

const imageArray = [
    '../../static/images/Login/Fondo1.webp', 
    '../../static/images/Login/Fondo2.webp',
    '../../static/images/Login/Fondo3.webp',
    '../../static/images/Login/Fondo4.webp',
    '../../static/images/Login/Fondo5.webp',
    '../../static/images/Login/Fondo6.webp',
    '../../static/images/Login/Fondo7.webp',
    '../../static/images/Login/Fondo8.webp',
];
const RandomImage = imageArray[Math.floor(Math.random()*imageArray.length)];

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: `url(${RandomImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    card: {
        minWidth: 300,
        marginTop: '6em !important',
        padding: '30px',
        boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
    },
    avatar: {
        background: 'url("https://firebasestorage.googleapis.com/v0/b/trendico-intranet.appspot.com/o/Pictures%2FLogo_OK.svg?alt=media&token=077a8ec3-0454-499b-9425-0f994ced3832")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        color: '#3d3d3d',
        height: 80,
        width: 200,
        backgroundPosition: 'center',
        marginBottom: '10px',
    },
    loginButton:{
        textTransform: 'capitalize',
        backgroundColor: '#196cdb !important',
        transition: 'none !important',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    logo: {
        width: '50%'
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    progres: {
        color: '#fff !important',
        width: '20px !important',
        height: '20px !important',
    },
    root: {
        '& .MuiFormLabel-root.Mui-focused':{
            color: '#196cdb !important',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#757575',
            },
            '&:hover fieldset': {
                borderColor: '#000',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#196cdb',
            },
        },
    },
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

interface FormValues {
    username?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation<{ nextPathname: string } | null>();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error: Error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                    'warning',
                    {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                    ? error.message
                                    : undefined,
                    }
                );
            }
        );
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const showHide = () => {
        if ((document.getElementById('password') as HTMLInputElement).type === "text") {
            (document.getElementById('password') as HTMLInputElement).type = "password"
        } else {
            (document.getElementById('password') as HTMLInputElement).type = "text";
        }
    };


    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <Box display="flex" justifyContent="center">
                                <div className={classes.avatar}></div>
                            </Box>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="username"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('ra.auth.username')}
                                        disabled={loading}
                                        variant="outlined"
                                        className={classes.root}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('ra.auth.password')}
                                        type="password"
                                        disabled={loading}
                                        variant="outlined"
                                        id="password"
                                        className={classes.root}
                                    />
                                </div>
                                <FormDataConsumer>
                                    {({ formData }) => {
                                        return (
                                            <Box style={{marginTop: '10px'}}>
                                                {formData.password === undefined &&
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox name="showPassword" />
                                                        }
                                                        disabled
                                                        label="Mostrar contraseña"
                                                    />
                                                }
                                                {formData.password&&
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox name="showPassword" onClick={showHide}/>
                                                        }
                                                        label="Mostrar contraseña"
                                                    />
                                                }
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                            </div>
                            <Box style={{marginTop:'10px', width: '100%', display: 'flex', justifyContent: 'flex-start'}}>                    
                                <CardActions className={classes.actions}>
                                    <ForgotPasswordButton/>
                                </CardActions>
                                <CardActions className={classes.actions}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        fullWidth
                                        className={classes.loginButton}
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                                className={classes.progres}
                                                style={{marginRight: '10px'}}
                                            />
                                        )}
                                    {translate('ra.auth.sign_in')}
                                    </Button>
                                    {/* <Button href="#text-buttons" color="primary">
                                        {translate('ra.auth.recover_password')}
                                    </Button> */}
                                </CardActions>
                            </Box>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

const LoginWithTheme = (props: any) => (
    <Login {...props} />
);

export default LoginWithTheme;