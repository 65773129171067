import React from "react";
import { Datagrid, List, TextField } from "react-admin";

const DivisionList = ({ permissions, ...props }) => {
  return (
    <List {...props} style={{marginTop: '-55px'}}>
        <Datagrid>
            <TextField label="name" source="name" style={{textTransform: 'capitalize'}}/>
        </Datagrid>
    </List>
  )
}; export default DivisionList;