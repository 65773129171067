import React, { useState, useEffect } from 'react';
import { Admin, Resource, Logout } from 'react-admin';
import { makeStyles, Box, Divider } from '@material-ui/core';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import firebase from 'firebase';
import spanishMessages from './i18n/es'
import customRoutes from './routes';
import Layout from './layout/layout';
import UserBlocked from './layout/UserBlocked';
import SessionTimeout from './layout/SessionTimeout';
import { createHashHistory } from 'history';

import faq from './faq';
import admin from './admin';
import whatsNew from './whatsNew';
import comercial from './comercial';
import informatica from './informatica';
import marketing from './marketing';
import state from './state';
import country from './country';
import address from './address';
import provider from './provider';
import conditions from './conditions';
import user from './user';
import CustomLoginPage from './login/LoginPage';
import PointOfSale from './pointOfSale';
import company from './company';
import Campaigns from './campaigns';
import CalendarPanel from './calendar';
import shops from './shops';
import offersAndPromotions from './offersAndPromotions';
import orders from './orders';
import material from './material';
import files from './files';
import dashboard from './dashboard';
import division from './division';
import NewsSubCategories from './newsSubCategories';
import FilesCategories from './filesCategories';
import categorys from './categorys';
import comments from './comments';
import news from './news';
import informacion from './marketing/informacion';
import newsletter from './newsletter';
import comunicados from './comunicados';
import publications from './publications';
import variants from './variants';
import basket from './basket';
import ForbiddenPage from './layout/Error/ForbiddenPage';
import NotFoundPage from './layout/Error/NotFoundPage';
import About from './components/About';
import dataProvider from './firebaseProviders/dataProvider';
import authProvider from './firebaseProviders/authProvider';
import ReactGA from 'react-ga4';

const useStyles = makeStyles({
  CustomMenu:{
    color: '#000',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiListItemIcon-root':{
      display: 'none',
      width: '0px',
      minWidth: '0px !important',
    },
    '& #logout':{
      border: '1px solid #d7d7d7',  
      borderRadius: '4px',
      width: '100%',
      margin: '10px',
      marginLeft: '40px',
      marginRight: '40px',
      display: 'flex',
      justifyContent: 'center',
      padding: '10px',
      color: '#3c4043',
    },
    '& #logout:hover':{
      border: '1px solid #dc004e',  
      backgroundColor: '#dc004e1c !important',  
      color: '#dc004e !important',  
    }
  },
});

const i18nProvider = polyglotI18nProvider(locale => {
  // Always fallback on english
  return spanishMessages;
}, 'es');

const history = createHashHistory();

const MyLogoutButton = (props) => {
  const classes = useStyles();
  return(
    <Box>
      <Box className={classes.CustomMenu}>
        <Logout {...props} id="logout"/>
      </Box>
      <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
      <About/>
    </Box>
  )
}

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuth, setIsAuth] = useState(false)
  useEffect(() => {
    ReactGA.initialize('G-EMDK1CJ71M');
    ReactGA.send({ hitType: "pageview", page: window.location.hash });
    let restoreFetch;
    const fetchCurrentUser = async () => {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          async function getUserData(){
            const token = await user.getIdTokenResult();
            const userClaims = token.claims;
            return userClaims ? Promise.resolve(userClaims) : Promise.reject();
          };
          getUserData().then(value => {
            if (value.blocked === true) {
              setCurrentUser('userBlocked');
            } else {
              setCurrentUser(user);
              setIsAuth(true)
            }
          });
        } else {
          setCurrentUser('doLogin');
        }
      });
    };
    fetchCurrentUser();
    return restoreFetch;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  if (!currentUser) {
    return (null);
  };

  if (currentUser === 'userBlocked') {
    return(
      <Admin 
        dataProvider={dataProvider}
        authProvider={authProvider}
        ready={UserBlocked}
      >
      </Admin>
    )
  };

  return (
    <Admin 
      loginPage={CustomLoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      customRoutes={customRoutes}
      layout={Layout}
      history={history}
      catchAll={NotFoundPage}
      logoutButton={MyLogoutButton}
    >
      {permissions => [
        <Resource name="dashboard" 
          list={dashboard.list} 
        />,
        <Resource name="comments"
          list={permissions === 'Admin' ? comments.list : ForbiddenPage} 
          edit={comments.edit} 
          create={comments.create}
        />,
        <Resource name="publications" 
          list={publications.list}
          show={publications.show}
          edit={(permissions === 'Admin' || permissions === 'Publicador') ? publications.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Publicador') ? publications.create : ForbiddenPage}
        />,
        <Resource name="informacion" 
          list={informacion.list}
        />,
        <Resource name="news" 
          list={news.list}
        />,
        <Resource name="comunicados" 
          list={comunicados.list}
        />,
        <Resource name="offersAndPromotions" 
          list={offersAndPromotions.list}
        />,
        <Resource name="shops" 
          list={shops.list}
          show={shops.show}
        />,
        <Resource name="provider" 
          list={provider.list} 
          show={provider.show}
          edit={(permissions === 'Admin' || permissions === 'Publicador') ? provider.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Publicador') ? provider.create : ForbiddenPage}/>,
        <Resource name="calendar" 
          list={CalendarPanel.list} 
        />,
        <Resource name="material" 
          list={material.list} 
          show={material.show}
          edit={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Transporte') ? material.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Transporte') ? material.create : ForbiddenPage} 
        />,
        <Resource name="files" 
          list={files.list} 
          show={files.show}
          edit={(permissions === 'Admin' || permissions === 'Publicador') ? files.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Publicador') ? files.create : ForbiddenPage} 
        />,
        <Resource name="users" 
          list={(permissions === 'Admin' || permissions === 'Central') ? user.list : ForbiddenPage} 
          edit={permissions === 'Admin' ? user.edit : ForbiddenPage} 
          create={permissions === 'Admin' ? user.create : ForbiddenPage}
        />,
        <Resource name="userclaims" />,
        <Resource name="pointOfSale" 
          list={(permissions === 'Admin' || permissions === 'Central') ? PointOfSale.list : ForbiddenPage} 
          edit={(permissions === 'Admin' || permissions === 'Central') ? PointOfSale.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Central') ? PointOfSale.create : ForbiddenPage}
          show={PointOfSale.show} 
        />,
        <Resource name="company" 
          list={(permissions === 'Admin' || permissions === 'Central') ? company.list : ForbiddenPage} 
          edit={(permissions === 'Admin' || permissions === 'Central') ? company.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Central') ? company.create : ForbiddenPage}
          show={company.show} 
        />,
        <Resource name="orders" 
          show={orders.show}
          list={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Transporte') ? orders.list : ForbiddenPage} 
          edit={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Transporte') ? orders.edit : ForbiddenPage} 
          create={orders.create}
        />,
        <Resource name="campaigns" 
          list={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Publicador') ? Campaigns.list : ForbiddenPage} 
          edit={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Publicador') ? Campaigns.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Publicador') ? Campaigns.create : ForbiddenPage}
        />,
        <Resource name="conditions" 
          show={conditions.show}
          list={conditions.list} 
          edit={(permissions === 'Admin' || permissions === 'Publicador') ? conditions.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Publicador') ? conditions.create : ForbiddenPage}
        />,
        <Resource name="division"
          list={(permissions === 'Admin' || permissions === 'Central') ? division.list : ForbiddenPage}
        />,
        <Resource name="newsCategories"
          list={ForbiddenPage}
          edit={ForbiddenPage} 
          create={ForbiddenPage}
        />,
        <Resource name="newsSubCategories"
          list={(permissions === 'Admin' || permissions === 'Publicador') ? NewsSubCategories.list : ForbiddenPage}
          edit={(permissions === 'Admin' || permissions === 'Publicador') ? NewsSubCategories.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Publicador') ? NewsSubCategories.create : ForbiddenPage}
        />,
        <Resource name="filesCategories"
          list={FilesCategories.list}
          show={FilesCategories.show}
        />,
        <Resource name="categorys" 
          list={(permissions === 'Admin' || permissions === 'Publicador') ? categorys.list : ForbiddenPage} 
          edit={(permissions === 'Admin' || permissions === 'Publicador') ? categorys.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Publicador') ? categorys.create : ForbiddenPage}
        />,
        <Resource name="basket" 
          list={basket.list} 
          create={basket.create}
        />,
        <Resource name="variants"
          list={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Transporte') ? variants.list : ForbiddenPage} 
          edit={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Transporte') ? variants.edit : ForbiddenPage} 
          create={(permissions === 'Admin' || permissions === 'Central' || permissions === 'Transporte') ? variants.create : ForbiddenPage}
        />,
        <Resource name="faq" 
          list={faq.list} 
          edit={permissions === 'Admin' ? faq.edit : ForbiddenPage} 
          create={permissions === 'Admin' ? faq.create : ForbiddenPage}
        />,
        <Resource name="state" 
          list={(permissions === 'Admin' || permissions === 'Central') ? state.list : ForbiddenPage} 
          edit={permissions === 'Admin' ? state.edit : ForbiddenPage} 
          create={permissions === 'Admin' ? state.create : ForbiddenPage}
        />,
        <Resource name="country" 
          list={(permissions === 'Admin' || permissions === 'Central') ? country.list : ForbiddenPage} 
          edit={permissions === 'Admin' ? country.edit : ForbiddenPage} 
          create={permissions === 'Admin' ? country.create : ForbiddenPage}
        />,
        <Resource name="address"
          list={address.list} 
          edit={address.edit} 
          create={address.create}
        />,
        <Resource name="admin" 
          list={(permissions === 'Admin' || permissions === 'Central') ? admin.list : ForbiddenPage}
        />,
        <Resource name="whatsNew" 
          list={whatsNew.list}
        />,
        <Resource name="comercial" 
          list={comercial.list}
        />,
        <Resource name="informatica" 
          list={informatica.list}
        />,
        <Resource name="marketing" 
          list={marketing.list}
        />,
        <Resource name="newsletter" 
          list={(permissions === 'Admin' || permissions === 'Central') ? newsletter.list : ForbiddenPage} 
          edit={(permissions === 'Admin' || permissions === 'Central') ? newsletter.edit : ForbiddenPage} 
          show={(permissions === 'Admin' || permissions === 'Central') ? newsletter.show : ForbiddenPage}
        />,
        <SessionTimeout authentified={isAuth}/>
      ]}
    </Admin>
  );
}; export default App;