import React, {useState} from "react";
import { Create, SimpleForm, SelectInput, ReferenceInput, useGetOne, Toolbar, SaveButton, NumberInput,
  required, FormDataConsumer, useRedirect, useNotify
} from "react-admin";
import { makeStyles, Box, CircularProgress, Typography } from '@material-ui/core';
import AddShoppingCartRoundedIcon from '@material-ui/icons/AddShoppingCartRounded';
import lightTheme from '../layout/themes';
import firebase from 'firebase';
import '../index.css';

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  EditToolBar: lightTheme.EditToolBar,
  InputLabel: {
    fontSize: '18px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
  }
});

const BasketCreateToolbar = ({loading, qty, ...props}) => {
  const classes = useStyles();
  return (
    <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start', marginTop: '-20px'}} className={classes.EditToolBar}>
      <SaveButton id="GoogleStyleSaveButton" label="Añadir a la Cesta" icon={<AddShoppingCartRoundedIcon/>} disabled={qty === 0}>
        {loading && ( <CircularProgress/> )}
      </SaveButton>
    </Toolbar>
  )
};

const BasketCreate = ({record, ...props }) => {
  const classes = useStyles();
  const user = firebase.auth().currentUser;
  const material_id = props.material_id;
  const [cantidad, setCantidad] = useState(0);

  function VariantData(id){
    const { data } = useGetOne('variants', id);
    if(data){return data.name};
  }

  function VariantPrice(id){
    const { data } = useGetOne('variants', id);
    if(data){
      return(data.price)
    } 
  }

  function VariantStock(id){
    const { data } = useGetOne('variants', id);
    if(data){
      return(data.stock)
    } 
  }

  function MaterialStock(){
    const { data } = useGetOne('material', material_id);
    if(data){return data.stock};
  }

  function MaterialData(){
    const { data } = useGetOne('material', material_id);
    if(data){return data};
  }

  const redirect = useRedirect();
  const notify = useNotify();
  const SuccessMessage = () => {
    return(
      <Box>
        <Typography style={{color: '#fff', display: 'flex', width: '100%', fontSize: '14px'}}>
          <AddShoppingCartRoundedIcon/>
            <Box style={{marginLeft: '15px', paddingTop: '3px'}}><span>Añadido a la Cesta!</span></Box>
          </Typography>
      </Box>
      
    )
  }

  const onSuccess = () => {
    notify(<SuccessMessage/>, 'success');
    redirect('/material');
  };

  return(
    <Box>
      <Create onSuccess={onSuccess} {...props}>
        <SimpleForm toolbar={<BasketCreateToolbar qty={cantidad}/>} redirect="/material" name="basket_form">
          {MaterialData() &&
            <Box style={{width: '130%', marginLeft: '-15px', marginTop: '-15px'}}>
              <Box display="flex" style={{width: '100%'}}>
                <Box style={{width: '130%', display: 'flex'}}>
                  <Box style={{width: '135%', display: 'flex', marginLeft: '-10px'}}>
                    <NumberInput label="Cantidad" source="cantidad" variant="outlined" className={classes.InputBlackBorder} style={{display: 'flex', width: '30%'}} 
                      step={1} max={MaterialStock()} min={1} validate={required()}
                    />
                    <ReferenceInput label="Variante" source="variant_id" reference="variants" style={{display: 'flex', width: '45%'}} id="variant_id"
                    className={classes.InputBlackBorder} variant="outlined" filter={{material_id: props.material_id, stock_gte: 0}} sort={{ field: 'ean', order: 'ASC'}}>
                      <SelectInput optionText="name" optionValue="id" validate={required()}/>
                    </ReferenceInput>
                  </Box>
                </Box>
              </Box>
              <Box style={{display: 'none'}}>
                <ReferenceInput source="user_id" reference="users" defaultValue={user.uid}>
                  <SelectInput optionText="id" />
                </ReferenceInput>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    let SelectedVariant = document.querySelector('[aria-describedby="variant_id-helper-text"]');
                    let VariantPriceDiv = document.getElementById('v_price');
                    if(SelectedVariant && VariantPriceDiv){ 
                      if(SelectedVariant.value === ""){
                        VariantPriceDiv.textContent = "empty"
                      } else {
                        VariantPriceDiv.textContent = SelectedVariant.value
                      }
                    }
                    if(formData.cantidad && formData.cantidad > 0){
                      setCantidad(formData.cantidad);
                    }
                    return (
                    <Box>
                      <ReferenceInput source="variant_name" key={formData.variant_id} defaultValue={VariantData(formData.variant_id)} {...rest}
                        filter={{material_id: props.material_id}} sort={{ field: 'ean', order: 'ASC'}}>
                        <SelectInput optionText="name" optionValue="name" id={formData.variant_id}/>
                      </ReferenceInput>
                      <ReferenceInput source="material_name" key={props.material_id} defaultValue={MaterialData(props.material_id).title} {...rest}>
                        <SelectInput optionText="name" optionValue="name" id={props.material_id}/>
                      </ReferenceInput>
                      <NumberInput source="variant_price" variant="outlined" 
                        defaultValue={VariantPrice(formData.variant_id)}
                      />
                      <NumberInput source="variant_stock" variant="outlined" 
                        defaultValue={VariantStock(formData.variant_id)}
                      />
                    </Box>
                    )
                  }}
                </FormDataConsumer>
                <p id="v_price">empty</p>
                <ReferenceInput source="material_id" defaultValue={props.material_id}>
                  <SelectInput optionText="id" />
                </ReferenceInput>
                <ReferenceInput source="material_stock" defaultValue={MaterialData().stock}>
                  <SelectInput optionText="stock" optionValue="stock"/>
                </ReferenceInput>
                <ReferenceInput source="material_price" defaultValue={MaterialData().price}>
                  <SelectInput optionText="price" optionValue="price"/>
                </ReferenceInput>
                <ReferenceInput source="portes" defaultValue={MaterialData().portes}>
                  <SelectInput optionText="portes" optionValue="portes"/>
                </ReferenceInput>
              </Box>
            </Box>
          }
        </SimpleForm>
      </Create>
    </Box>
  )
}; export default BasketCreate;