import React, { Fragment } from "react";
import { Datagrid, List, TextField, TopToolbar, BulkDeleteButton, DeleteButton,
  Filter, SearchInput, useListContext, usePermissions} from "react-admin";
import { makeStyles, Box, Typography, ThemeProvider, TablePagination, Modal, Fade, Backdrop, Button } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import * as locales from '@material-ui/core/locale';
import Fab from '@mui/material/Fab';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MyTooltip from '../components/MuiTooltip';
import CloseButton from '../components/CloseButton';
import lightTheme from '../layout/themes';
import NewsCategoriesCreate from './NewsSubCategoriesCreate';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const useStyles = makeStyles((theme) => ({
  BulkDeletePostButton: lightTheme.BulkDeletePostButton,
  PointsTable: lightTheme.OffersAndPromotionsOffersTable,
  PostViewCard: lightTheme.PostViewCard,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  MuiModal: lightTheme.CommonStyles.Modal.MuiModal,
  MuiPaper: lightTheme.CommonStyles.Modal.MuiPaper,
  CreateButton: {
    marginRight: '12px',
    '& .MuiButtonBase-root':{
      fontSize: '0.8125rem',
      color: '#fff !important',
      backgroundColor: '#3d3d3d !important',
      marginRight: '10px',
      paddingRight: '8px !important',
      paddingLeft: '8px !important',
      textTransform: 'none !important',
      borderRadius: '5px',
      width: '110%',
      paddingTop: '4px !important',
      paddingBottom: '4px !important'
    }
  },
  RightSerchBar: {
    '& .MuiToolbar-root': {
      display: 'flex',
      '& .MuiToolbar-gutters':{
        paddingTop: '21px',
        width: 'auto',
        '& a':{
          marginLeft: '0px !important',
          '& span':{
            width: '110px !important',
          }
        }
      },
      '& form': {
        width: '100%',
        justifyContent: 'right',
      },
    },
    '& .list-page':{
      marginTop: '-45px'
    },
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#F44336',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#F44336A6 !important',
        color: '#fff !important',
    },
    marginBottom: '20px',
  },
  root: {
    marginRight: '-16px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
        borderColor: '#3d3d3d',
        },
        '&:hover fieldset': {
        borderColor: '#3d3d3d',
        },
        '&.Mui-focused fieldset': {
        borderColor: '#3d3d3d',
        },
    },
    '& .RaBulkActionsToolbar-toolbar': {
        '& h6': {
            color: '#3d3d3d',
        },
    },
  },
}));

const BulkDeleteNewsCategoriesButton = ({ basePath }) => {
  return(
    <Fragment>
      <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Seleccionados</div>} aria-label="Delete" arrow placement="left">
        <BulkDeleteButton label="" basePath={basePath} id="GoogleStyleBulkButton"/>
      </MyTooltip>
    </Fragment>
  )
};

const NewsCategoriesFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn variant="outlined" className={classes.root}/>
    </Filter>
  )
};

const NewsCategoriesPagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();

  const handleChangeRowsPerPage = (event) => { 
      setPerPage(parseInt(event.target.value, 10));
      setPage(1);
  };
  const handleChangePage = (event, newPage) => {
      setPage(newPage + 1);
   };
  
  return (
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
          <TablePagination
              component="div"
              rowsPerPageOptions={[8, 10, 12, 15, 20]}
              count={total || 0}
              page={page - 1}
              onChangePage ={handleChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </ThemeProvider>
  );
}

const NewsCategoriesList = props => {
  const { permissions } = usePermissions();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {setOpen(true)};
  const handleClose = () => {setOpen(false)};

  let NewsCategoriesProps = {basePath: "/newsCategories", resource: "newsCategories"}

  const NewsCategoriesEditActions = () => {
    return(
      <TopToolbar>
        <Box style={{position: 'fixed', bottom: '25px', right: '30px', zIndex: '999'}}>
          <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Categoría</div>} arrow placement="left">
            <Fab color="primary" aria-label="add" onClick={handleOpen}>
              <AddRoundedIcon />
            </Fab>
          </MyTooltip>
        </Box>
      </TopToolbar>
    )
  };

  return (
    <Box className={classes.RightSerchBar}>
      <Box display="flex" style={{ width: '100%', position: 'relative', top: '8px'}} justifyContent="flex-start">
        <Typography className={classes.PostTitle}>Categorías de Publicaciones</Typography>
        <Typography className={classes.PostSubTitle}>&nbsp;Gestiona, Edita y Consulta</Typography>
      </Box>
      <List {...props} actions={<NewsCategoriesEditActions />} bulkActionButtons={<BulkDeleteNewsCategoriesButton {...props} />} filters={<NewsCategoriesFilter/>}
        sort={{ field: 'name', order: 'ASC' }} pagination={<NewsCategoriesPagination/>} perPage={8} style={{marginBottom: '5em'}}>
        <Datagrid rowClick="edit" className={classes.PointsTable}>
          <TextField label="Nombre" source="name" />
          {permissions === 'Admin' &&
            <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Categoría</div>} arrow>
              <Button id="GoogleStyleDownload">
                <EditRoundedIcon/>
              </Button>
            </MyTooltip>
          }
          {permissions === 'Admin' &&
            <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Categoría</div>} aria-label="delete" arrow placement="bottom">
              <DeleteButton basePath="/newsCategories" label="" record={props} id="GoogleStyleDeleteButton"/>
            </MyTooltip>
          }
        </Datagrid>
      </List>

      <div>
        <Modal className={classes.MuiModal} open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
          <Fade in={open}>
            <div className={classes.MuiPaper} style={{width: '400px'}}>
              <Box id="TitleBox">
                  <Box id="Title">
                    <h2>Crear una categoría de noticia</h2>
                  </Box>
                  <Box id="CloseButton">
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                      <CloseButton style={{paddingLeft: '17px',  paddingRight: '5px'}} onClick={handleClose}></CloseButton>
                    </MyTooltip>
                  </Box>
              </Box>
              <NewsCategoriesCreate {...NewsCategoriesProps} />
            </div>
          </Fade>
        </Modal>
      </div>
      
    </Box>
  )
};

export default NewsCategoriesList;