import React from "react";
import { Card, Box, Typography } from '@material-ui/core';
import { UserDashboardCards } from "./UserDashboardCards";

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {   
        const classes = this.props.classes;
        return (
            <Box>
                <Box>
                    <Card className={classes.TrendicoBox}>
                        <img src="static/images/Dashboard/Trendico Group.webp" alt="TrendicoLogo"></img>
                        <Typography variant="h6">¡ Tu central... A un solo click !</Typography>
                    </Card>
                </Box>
                <Box className={classes.DepartemntsBox}>
                    <UserDashboardCards
                        access={"/whatsNew"}
                        title={"Actualidad"}
                        SubTitle={"Novedades & Noticias de la central"}
                        img={"static/images/Dashboard/Dashboard_Actualidad.webp"}
                        imgAlt={"Actualidad"}
                        link1={"/news"}
                        Button1={"Noticias"}
                        link2={"/calendar"}
                        Button2={"Calendario Trendico"}
                    />
                    <UserDashboardCards
                        access={"/comercial"}
                        title={"Comercial"}
                        SubTitle={"Novedades del area de comercial"}
                        img={"static/images/Dashboard/Dashboard_Comercial.webp"}
                        imgAlt={"Comercial"}
                        link1={"/shops"}
                        Button1={"Nuestros Proveedores"}
                        link2={"/conditions"}
                        Button2={"Condiciones Comerciales"}
                        link3={"/offersAndPromotions"}
                        Button3={"Ofertas & Promociones"}
                    />
                    <UserDashboardCards
                        access={"/marketing"}
                        title={"Marketing"}
                        SubTitle={"Novedades del area de marketing"}
                        img={"static/images/Dashboard/Dashboard_Marketing.webp"}
                        imgAlt={"Marketing"}
                        link1={"/comunicados"}
                        Button1={"Comunicados"}
                        link2={"/material"}
                        Button2={"Tienda de Consumibles"}
                    />
                    <UserDashboardCards
                        access={"/informatica"}
                        title={"Informática"}
                        SubTitle={"Novedades del area de informática"}
                        img={"static/images/Dashboard/Dashboard_Informática.webp"}
                        imgAlt={"Informática"}
                        link1={"/informatica"}
                        Button1={"Clac Clac"}
                        link2={"/informatica"}
                        Button2={"Zalando"}
                        link3={"/informatica"}
                        Button3={"Web Ecommerce"}
                        link4={"/informatica"}
                        Button4={"Amazon Marketplace"}
                    />
                </Box>
                <Box className={classes.WebsTitleBox}>
                    <Typography variant="h5">Descubre nuestras webs</Typography>
                </Box>
                <Box className={classes.BannersBox}>
                    <div id="BlockBox">
                        <Card id="Card" style={{backgroundImage: 'url(/static/images/Shops/HomeBanner-FoM.png)'}}>
                            <a href='https://footonmars.com' target='blank'><span>Foot on Mars</span></a>
                            <img src="static/images/Shops/FoMBannerLogo.png" alt="TrendicoLogo"></img>
                        </Card>
                    </div>
                    <div id="BlockBox">
                        <Card id="Card" style={{backgroundImage: 'url(/static/images/Shops/HomeBanner-Atleet.png)'}}>
                            <a href='https://atleet.store/' target='blank'><span>Atleet</span></a>
                            <img src="static/images/Shops/AtleetBannerLogo.png" alt="TrendicoLogo"></img>
                        </Card>
                    </div>
                    <div id="BlockBox">
                        <Card id="Card" style={{backgroundImage: 'url(/static/images/Shops/HomeBanner-Sotkatt.png)'}}>
                            <a href='https://sotkatt.com/' target='blank'><span>Sötkatt</span></a>
                            <img src="static/images/Shops/SotkattBannerLogo.png" alt="TrendicoLogo"></img>
                        </Card>
                    </div>
                </Box>
            </Box>
        );
    }
}

export const UserDashboard = () => {
    return (
        <Dashboard />
    )
}