import React from "react";
import { Link } from 'react-router-dom';
import { 
  List,  useListContext, ImageField, FilterLiveSearch, TopToolbar,
  Pagination, usePermissions, useRedirect
} from 'react-admin';
import { makeStyles, Grid, CardMedia, Typography, Box, Card, CardContent } from '@material-ui/core';
import { CategoryFilter } from '../../material/InformacionFilters';
import MyTooltip from "../../components/MuiTooltip";
import Fab from '@mui/material/Fab';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import lightTheme from '../../layout/themes';

const useStyles = makeStyles({
  BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
  root: lightTheme.MaterialGridroot,
  media: lightTheme.MaterialGridmedia,
  title: lightTheme.MaterialGridtitle,
  actionSpacer: lightTheme.MaterialGridactionSpacer,
  card: lightTheme.MaterialGridcard,
  InputBlackBorder: lightTheme.InputBlackBorder,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  EditToolBar: lightTheme.EditToolBar,
  Pagination: lightTheme.MaterialGridPaginationBox,
  MaterialStylePagination: lightTheme.MaterialGridMaterialStylePagination,
  modal: lightTheme.MaterialGridModal,
  paper: lightTheme.MaterialGridPaper,
  TramitarButton: lightTheme.MaterialGridTramitarButton,
  list: {width: 300},
  fullList: {width: 'auto'},
});

export function MaterialListActions() {
  const {permissions} = usePermissions();
  const redirect = useRedirect();
  
  return (
    <Box>
      <TopToolbar style={{paddingTop: '22px', paddingBottom: '10px'}}>
        {(permissions === "Admin" || permissions === "Transporte") &&
          <Box style={{position: 'fixed', bottom: '20px', right: '30px', zIndex: '999'}}>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Consumible</div>} arrow placement="left">
              <Fab color="primary" aria-label="add" onClick={() => { redirect(`/material/create`); }}>
                <AddRoundedIcon />
              </Fab>
            </MyTooltip>
          </Box>
        }
      </TopToolbar>
    </Box>
  );
}

const FilterSidebar = () => {
  const classes = useStyles();
  return (
    <Card id="FilterAside">
      <CardContent>
        <FilterLiveSearch source="reference" className={classes.BlackInput} variant="outlined"/>
        <CategoryFilter/>
      </CardContent>
    </Card>
  )
};

const MaterialGrid =  props  => {
  const classes = useStyles(props);
  const { data, ids } = useListContext();
  return ids ? (
    <Grid container spacing={1} className={classes.root}>
      {ids.map(id => (
        <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={3} item >
          <Card className={classes.card} style={{height: '100%'}}>
            {data && data[id] && 
              <Box>
                <Link to={`/material/${data[id].id}/show`}>
                  <Box style={{padding: '10px', paddingTop: '20px'}}>
                    { data[id].logo &&
                      <CardMedia className={classes.media} image={data[id].logo.image} style={{backgroundPosition: 'center', backgroundSize: 'contain', borderRadius: '6px'}}>
                        <ImageField source="image" title="imageName"/>
                      </CardMedia>
                    }
                  </Box>
                </Link>
                <CardContent className={classes.title}>
                  <Link to={`/material/${data[id].id}/show`}>
                    <Typography variant="body1" align="center" style={{textTransform: 'uppercase', textDecoratiom: 'none', height: '30px', fontSize: '13px'}} id="title">
                      {data[id].title}
                    </Typography>
                  </Link>
                </CardContent>
              </Box>
            }
          </Card>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

const MaterialPagination = (props) => {
  const classes = useStyles();
  return(
    <Pagination rowsPerPageOptions={[8, 12, 16, 20, 24]} {...props} className={classes.Pagination}/>
  )
};

const MaterialList = (props) => {  
  const classes = useStyles(props);
  const NewsProps = {
        basePath: "/material",
        resource: "material",
    }
  return(
    <Box style={{marginTop: '5px'}}>
      <Box display="flex" style={{ width: '100%', position: 'relative', top: '5px'}} justifyContent="flex-start">
        <Typography className={classes.PostTitle}>Material Puntos de Venta</Typography>
        <Typography className={classes.PostSubTitle}>&nbsp;Todo el material para preparar tu punto de venta</Typography>
      </Box>
      <List {...NewsProps} perPage={12} sort={{ field: 'title', order: 'ASC' }} className={classes.MaterialStylePagination} component="div" 
        actions={<MaterialListActions/>} aside={<FilterSidebar />} pagination={<MaterialPagination/>} filter={{type: 'puntodeventa'}}>
        <MaterialGrid record={props}/>
      </List>
    </Box>
  )
}; export default MaterialList;