import React from 'react';
import { useGetList, useRedirect, useGetOne, LinearProgress } from 'react-admin';
import { Box, Card, Typography, makeStyles } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import sanitizeHtml from 'sanitize-html';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import lightTheme from '../layout/themes';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles({
    AdminCard: lightTheme.DashboardStyles.AdminCard,
    AdminUsersBox: lightTheme.DashboardStyles.AdminUsersBox,
    AdminFlexBox: lightTheme.DashboardStyles.AdminFlexBox,
    image: lightTheme.NewsStyles.NewsList.image,
    WelcomeBox : lightTheme.WhatsNewStyles.WelcomeBox,
    WelcomeBox2 : lightTheme.WhatsNewStyles.WelcomeBox2,
    AdminButtons: lightTheme.WhatsNewStyles.AdminButtons,
    LinksTitles: lightTheme.WhatsNewStyles.LinksTitles,
    SmallAdminBox: lightTheme.WhatsNewStyles.SmallAdminBox,
    StepperBox : lightTheme.WhatsNewStyles.StepperBox,
    NewsCard: lightTheme.WhatsNewStyles.NewsCard,
    BannersBox: lightTheme.WhatsNewStyles.BannersBox,
});

const CategotyName = ({ id }) => {
    const { data, loading, error } = useGetOne('newsSubCategories', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return (data.name); }
};

const Stepper = () => {
    const theme = useTheme();
    const classes = useStyles();
    const redirect = useRedirect();
    const { data, ids } = useGetList('publications', { page: 1, perPage: 3 }, { field: 'createdate', order: 'DESC'}, {category: '6270d8d87a125adbf27a200a'});
    
    const news = [];
    for(var i = 0; i < ids.length; i++) {
        news.push({
            id: data[ids[i]].id, 
            label: data[ids[i]].title, 
            imgPath: data[ids[i]].logo.image,
            category: data[ids[i]].category,
            subCategory: data[ids[i]].subCategory,
            body: data[ids[i]].body, 
            subTitle: data[ids[i]].subTitle, 
            review: data[ids[i]].review
        })
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = news.length;

    const handleStepChange = (id) => {
        setActiveStep(id);
    };

    const NewsBody = ({ body , index}) => {
        if (body && index) { 
            const CleanHTML = sanitizeHtml(body, {
                disallowedTags: [ 'img', 'table', 'strong' ],
                transformTags: {
                    'h1': 'p',
                    'h2': 'p',
                    'h3': 'p',
                    'h4': 'p',
                    'h5': 'p',
                    'strong': 'span',
                    'table': 'p'
                },
            })
            return (<div dangerouslySetInnerHTML={{ __html: CleanHTML }} id={index}/>); 
        }
    };

    if (data[ids[0]] !== undefined) {
        return(
            <Box className={classes.StepperBox}>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    interval='8000'
                >
                    {news.map((step, index) => (
                        <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <div className={classes.NewsCard}>
                                    <Box style={{display: 'flex', justifyContent: 'flex-start', width: '100%', flexDirection: 'column'}}>
                                        <Typography variant="h5" id="Title">{step.label}</Typography>
                                        <Typography variant="button" style={{color: '#196cdb', marginBottom: '5px'}} id="Category">
                                            <CategotyName id={step.subCategory} />
                                        </Typography>
                                        <Typography variant="body2" id="Body">
                                            {step.subTitle &&
                                                <NewsBody body={step.subTitle} index="subTitle"/>
                                            }
                                            <NewsBody body={step.review ? step.review: step.body} index="review"/>
                                        </Typography>
                                        <span id="seeMore" onClick={() => { redirect(`/publications/${step.id}/show`); }}>Leer más »</span>
                                    </Box>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <div style={{overflow: 'hidden', position: 'relative', borderRadius: '8px', backgroundColor: '#fff', height: '235px', width: '235px'}}>
                                            <g-img style={{display: 'flex', height: '100%', justifyContent: 'center'}}>
                                                <img className={classes.image} alt="complex" src={step.imgPath}/>
                                            </g-img>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper steps={maxSteps} activeStep={activeStep} id="stepper"/>
            </Box>
        )
    } else {return (<div>Error</div>)}
};

const Admin = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box>
            <Box className={classes.WelcomeBox}>
                <Typography variant="h5">Accede a todas las novedades del departamento de Marketing</Typography>
            </Box>
            <Box className={classes.AdminFlexBox}>
                <Box className={classes.AdminUsersBox}>
                    <Card className={classes.AdminCard}>
                        <Stepper />
                    </Card>
                </Box>
            </Box>
            <Box className={classes.BannersBox} onClick={() => { redirect('/calendar'); }}>
                <div id="BlockBox">
                    <Card id="Card" style={{backgroundImage: 'url(/static/images/Calendar/Consumibles.png)'}}>
                        <div id="text">
                            <span id="title">Tienda de Consumibles</span>
                            <span id="subTitle">Accede y repón todo lo que <br/> necesites para tu punto de venta </span>
                        </div>
                    </Card>
                </div>
            </Box>
        </Box>
    )
};

export default Admin;
