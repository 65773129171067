import React from 'react';
import { Box, Card, Typography, makeStyles } from '@material-ui/core';
import lightTheme from '../layout/themes';


const useStyles = makeStyles({
    AdminCard: lightTheme.DashboardStyles.AdminCard,
    AdminUsersBox: lightTheme.DashboardStyles.AdminUsersBox,
    AdminFlexBox: lightTheme.DashboardStyles.AdminFlexBox,
    image: lightTheme.NewsStyles.NewsList.image,
    WelcomeBox : lightTheme.WhatsNewStyles.WelcomeBox,
    WelcomeBox2 : lightTheme.WhatsNewStyles.WelcomeBox2,
    AdminButtons: lightTheme.WhatsNewStyles.AdminButtons,
    LinksTitles: lightTheme.WhatsNewStyles.LinksTitles,
    SmallAdminBox: lightTheme.WhatsNewStyles.SmallAdminBox,
    StepperBox : lightTheme.WhatsNewStyles.StepperBox,
    BannersBox:{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        rowGap: '10px',
        '& #BlockBox':{
            height: '380px',
            width: '49%',
            overflow: 'hidden',
            position: 'relative',
            borderRadius: '10px',
            boxShadow: '0px 8px 10px #d9d2d2 !important',
            border: '1px solid #eeeeee',
            ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                height: '280px',
            },
        },
        '& #BlockBox:first-child':{
            marginRight: '10px'
        },
        '& #BlockBox:nth-child(2)':{
            marginRight: '0px'
        },
        '& #BlockBox:nth-child(3)':{
            marginRight: '10px'
        },
        '& #BlockBox:nth-child(4)':{
            marginRight: '0px'
        },
        '& #Card':{
            color: '#fff',
            display: 'inline-block',
            transition: 'all .5s',
            height: '380px',
            overflow: 'hidden',
            textAlign: 'center',
            width: '100%',
            ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                height: '280px',
            },
            '& img': {
                verticalAlign: 'top',
                maxWidth: '100%',
                backfaceVisibility: 'hidden',
                display: 'flex',
                alignItems: 'center',
                transform: 'scale(1.1)',
                width: '100%',
                height: '100%',
            },
            '& .text': {
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                alignItems: 'center',
                zIndex: '2',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                lineHeight: '1.1em',
                opacity: '0',
                transitionDelay: '0.1s',
                fontSize: '18px',
            }
        },
        '& #Card *': {
            boxSizing: 'border-box',
            transition: 'all 0.45s ease',
        },
        '& #Card:before, #Card:after': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            content: '""',
            transition: 'all 0.3s ease',
            zIndex: '1',
            opacity: '0',
        },
        '& #Card:hover':{
            backgroundColor: '#000',
        },
        '& #Card:hover:before, #Card:hover:after': {
            transform: 'scale(1)',
            opacity: '1',
        },
        '& #Card:hover > img':{
            opacity: '0.7',
            transform: 'scale(1.2)',
        },
        '& #Card:hover .text': {
            opacity: '1',
        }
    },
});

const Admin = () => {
    const classes = useStyles();
    return(
        <Box>
            <Box className={classes.WelcomeBox}>
                <Typography variant="h5">Accede a todas las novedades del departamento de IT</Typography>
            </Box>
            <Box className={classes.BannersBox}>
                <div id="BlockBox">
                    <Card id="Card">
                        <img src="static/images/Informatica/clacclac.png" alt="Clac Clac"/>
                        <div className="text">
                            <span>Clac Clac</span>
                        </div>
                    </Card>
                </div>
                <div id="BlockBox">
                    <Card id="Card">
                        <img src="static/images/Informatica/zalando.png" alt="Zalando"/>
                        <div className="text">
                            <span>Zalando</span>
                        </div>
                    </Card>
                </div>
                <div id="BlockBox">
                    <Card id="Card">
                        <img src="static/images/Informatica/ecommerce.png" alt="Web Ecommerce"/>
                        <div className="text">
                            <span>Web Ecommerce</span>
                        </div>
                    </Card>
                </div>
                <div id="BlockBox">
                    <Card id="Card">
                        <img src="static/images/Informatica/amazon.png" alt="Amazon MArketplace"/>
                        <div className="text">
                            <span>Amazon Marketplace</span>
                        </div>
                    </Card>
                </div>
            </Box>
        </Box>
    )
};

export default Admin;
