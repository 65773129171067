import React from 'react';
import { useGetOne } from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';
import lightTheme from '../layout/themes';
import FilesTable from '../files/FilesTable';

const useStyles = makeStyles({
  MainProviderBox: lightTheme.ShopsStyles.ShopList.MainProviderBox,
  LogoBox: lightTheme.ShopsStyles.ShopList.LogoBox,
});


const FilesCatView = (props ) => {
    const FileCatId = props.id;
    const classes = useStyles();
    const { data } = useGetOne('filesCategories', FileCatId);

    let fakeProps = {basePath: "/files", resource: "files", category: FileCatId }

    return (
      <Box className={classes.MainProviderBox}>
        {data &&
          <Box style={{marginTop: '50px'}}>
            <FilesTable {...fakeProps} pagination={false} filter={{ category: FileCatId }}/>
          </Box>
        }
      </Box>
    )
}; export default FilesCatView;