import React, { Fragment } from "react";
import { 
  Datagrid, List, TextField, BulkDeleteButton, EditButton, DeleteButton, ReferenceField, useGetOne, TopToolbar,
  Filter, useListContext, usePermissions, AutocompleteInput, ReferenceInput, SortButton, SelectInput
} from "react-admin";
import { makeStyles, Box, Typography, Divider, ThemeProvider, TablePagination, CardMedia} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import MuiTextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Skeleton from '@mui/material/Skeleton';
import * as locales from '@material-ui/core/locale';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';
import inflection from 'inflection';
import firebase from 'firebase';

const useStyles = makeStyles({
  ConditionsTable: lightTheme.ConditionsStyles.ConditionsTable,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  RightSerchBar: lightTheme.ConditionsStyles.RightSerchBar,
  root: lightTheme.ConditionsStyles.root,
  EmptyConditionsMediaCard: lightTheme.EmptyConditionsMediaCard,
  FilterLabel: lightTheme.ConditionsStyles.ConditionsList.FilterLabel,
  StateChipYellow: lightTheme.ConditionsStyles.ConditionsList.StateChipYellow,
  StateChipGreen: lightTheme.ConditionsStyles.ConditionsList.StateChipGreen,
  ConditionsLoading: lightTheme.ConditionsStyles.ConditionsList.ConditionsLoading,
  tableRow: lightTheme.ConditionsStyles.ConditionsList.tableRow,
  ConditionsFilterButtonGoogleStyle: lightTheme.ConditionsFilterButtonGoogleStyle,
  ConditionsToolbar:{
    '& #sort_button': {
      height: '100%',
      '& button': {
        height: '100%',
        backgroundColor: '#fff !important',
        color: '#3d3d3d !important',
        border: "1px solid #cacaca !important",
        '& .MuiButton-label':{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'nowrap',
          gap: '0px',
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: "500",
          lineHeight: "1.75",
          letterSpacing: "0.02857em",
          textTransform: "capitalize",
          fontSize: "0.95rem !important",
          '& span':{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }
        }
        
      }
    }
  }
});

const BulkDeleteConditionsButton = ({ basePath }) => {
  return(
    <Fragment>
      <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Seleccionados</div>} aria-label="Delete" arrow placement="left">
        <BulkDeleteButton label="" basePath={basePath} id="GoogleStyleBulkButton"/>
      </MyTooltip>
    </Fragment>
  )
};

const ConditionsFilter = ({resource, filters, displayedFilters, filterValues, showFilter, userShops, ...props}) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  var formChilds = document.querySelectorAll("#conditions-filter > [data-source]")
  var formElem = document.getElementById('conditions-filter')
  var FilterIds = document.querySelectorAll('#conditions-filter')[1]
  var MenuItems = document.querySelectorAll('.MuiListItem-button')
 
  
  if(formElem){
    if(formChilds.length > 0) {
      formElem.classList.add('filter-toolbar-displayed');
    } else {
      formElem.classList.remove('filter-toolbar-displayed');
    }
  }
  if(FilterIds){
    var FilterButton = FilterIds.children[0].children[0]
    var ButtonIcon = FilterButton.children[0]
    var ButtonLabel = FilterButton.children[1]
    ButtonLabel.innerHTML = 'Filtros'
    ButtonIcon.children[0].outerHTML = '<path d="M4.25 5.61C6.57 8.59 10 13 10 13v5c0 1.1.9 2 2 2s2-.9 2-2v-5s3.43-4.41 5.75-7.39c.51-.66.04-1.61-.8-1.61H5.04c-.83 0-1.3.95-.79 1.61z"></path>'
  }
  if(MenuItems){
    for(var i = 0; i < MenuItems.length; i++){
      if(MenuItems[i].tagName === "LI" && MenuItems[i].attributes.length === 5){
        if(MenuItems[i].attributes[4].value === "campaign"){
          if(MenuItems[i].textContent === "Campaign ascendente"){
            MenuItems[i].textContent = "Campaña ascendente"
          } else {
            MenuItems[i].textContent = "Campaña descendente"
          }
        }
        if(MenuItems[i].attributes[4].value === "shop"){
          if(MenuItems[i].textContent === "Shop ascendente"){
            MenuItems[i].textContent = "Enseña ascendente"
          } else {
            MenuItems[i].textContent = "Enseña descendente"
          }
        }
        if(MenuItems[i].attributes[4].value === "provider.name"){
          if(MenuItems[i].textContent === "Provider.name ascendente"){
            MenuItems[i].textContent = "Proveedor ascendente"
          } else {
            MenuItems[i].textContent = "Proveedor descendente"
          }
        }
      }
    }
    
  }

  function searchFunction() {
    var input, filter, li, a, txtValue;
    input = document.getElementById("searchprovider");
    filter = input.value.toUpperCase()
    li = document.getElementsByClassName("MuiTableBody-root")[0].getElementsByClassName("MuiTableRow-root");
    for (var i = 0; i < li.length; i++) {
        a = li[i].children[3].children[0].children[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
    }
  }

  return (
    <Box className={classes.ConditionsFilterButtonGoogleStyle}>
      <Filter {...props} id="conditions-filter">
        <ReferenceInput source="campaign" label="Campaña" reference="campaigns" filterToQuery={searchText => ({ name: searchText })}
          variant="outlined" className={classes.root} perPage={500} resettable sort={{ field: 'endDate', order: 'DESC'}}>
          <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput source="shop" label="Enseña" reference="shops"
          variant="outlined" className={classes.root} perPage={500} resettable>
          <SelectInput optionText="name"/>
        </ReferenceInput>
        <MuiTextField
          source="provider"
          label="Proveedor"
          size="small"
          id='searchprovider'
          variant='outlined'
          perPage={500}
          onKeyUp={searchFunction}
        />
        {permissions !== 'User' &&
          <SelectInput source="state" choices={[
                { id: 'borrador', name: 'borrador' },
                { id: 'publicado', name: 'publicado' },
            ]} variant="outlined" className={classes.root} label="Estado" optionText="name" optionValue="name" resettable/>
        }
      </Filter>
    </Box>
  )
};

const EmptyConditions = () => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" style={{width: '100%'}}>
      <Box textAlign="center" className={classes.EmptyBubble}>
        <CardMedia className={classes.EmptyConditionsMediaCard}></CardMedia>
        <Typography variant="h6" style={{marginTop: '20px'}}>
          No Hay Condiciones para este Proveedor
        </Typography>
      </Box>
    </Box>
  );
};

const ConditionsPagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();

  const handleChangeRowsPerPage = (event) => { 
      setPerPage(parseInt(event.target.value, 10));
      setPage(1);
  };
  const handleChangePage = (event, newPage) => {
      setPage(newPage + 1);
   };
  
  return (
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
        {total &&
          <TablePagination
              component="div"
              rowsPerPageOptions={[8, 10, 12, 15, 20, total]}
              count={total || 0}
              page={page - 1}
              onChangePage ={handleChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
      </ThemeProvider>
  );
}

const StateChip = ({ record }) => {
  const classes = useStyles();
  return (
      <div className={classes.root} style={{margin: '0px'}}>
          {record &&
              <Typography variant="body1" align="left" style={{ fontSize: '13px', textTransform: 'uppercase', textDecoratiom: 'none', textAlign: 'center'}}>
                  <Box className={record.state.includes('borrador') ? classes.StateChipYellow : classes.StateChipGreen }>
                    <Typography id="title">{inflection.humanize(record.state)}</Typography>
                  </Box>
              </Typography>
          }
      </div>
  );
}

const ConditionsListActions = ({ resource, filters, displayedFilters, filterValues, showFilter, ...props }) => {
  const classes = useStyles();
  return (
    <Box style={{display: 'flex', width: '5%', justifyContent: 'flex-end'}}>
      <TopToolbar {...props} className={classes.ConditionsToolbar}>
        {filters && React.cloneElement(filters, { showFilter, displayedFilters, context: 'button'}) }
        <div id="sort_button">
          <SortButton fields={['campaign', 'provider.name', 'shop']} label="Ordenar"/>
        </div>
      </TopToolbar>
    </Box>
  )
};

const ConditionsList = props => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const user = firebase.auth().currentUser;
  const { data } = useGetOne('users', user.uid);
  const SkeletonData = {1: {key: 1, value: 1},2: {key: 2, value: 2},3: {key: 3, value: 3},4: {key: 4, value: 4},5: {key: 5, value: 5},6: {key: 6, value: 6}};
  const SkeletonIds = [1,2,3,4,5,6];

  if (data) { 
    const userShops = ((data.shop[0].id === null || data.shop[0].id === undefined) ? data.shop.map(({shop}) => shop ) : data.shop.map(({id}) => id ));
    return (
      <Box className={classes.RightSerchBar}>
        <Box display="flex" style={{ width: '50%', position: 'relative', top: '20px', zIndex: '90'}} justifyContent="flex-start">
          <Typography className={classes.PostTitle}>Condiciones de Proveedores</Typography>
          <Typography className={classes.PostSubTitle}>&nbsp;Por campañas</Typography>
        </Box>
        {userShops &&
        <List {...props} empty={<EmptyConditions />} bulkActionButtons={permissions !== 'User' ? <BulkDeleteConditionsButton {...props}/> : false} filters={<ConditionsFilter userShops={userShops}/>} 
          pagination={<ConditionsPagination />} perPage={8} actions={<ConditionsListActions/>} 
          filter={permissions !== 'Admin' ? 
            {state: 'publicado', shop: { $in: userShops }} :
            {}
          }
        >
          <Datagrid rowClick="show" className={classes.ConditionsTable}>
            <ReferenceField label="Campaña" source="campaign" reference="campaigns" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Enseña" source="shop" reference="shops" link="show">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Proveedor" source="provider.id" reference="provider" link="show" sortBy="provider.name">
              <TextField source="name" />
            </ReferenceField>
            {permissions === 'Admin' &&
              <StateChip id="Order_Estate" source="state" label="Estado"/>
            }
            {permissions === 'Admin' &&
              <MyTooltip title={<div style={{fontSize: '12px'}}>Editar</div>} aria-label="edit" arrow placement="bottom">
                  <EditButton basePath="/conditions" label="" record={props} id="GoogleStyleButton"/>
              </MyTooltip>
            }
            {permissions === 'Admin' &&
              <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar</div>} aria-label="delete" arrow placement="bottom">
                  <DeleteButton basePath="/conditions" label="" record={props} id="GoogleStyleDeleteButton"/>
              </MyTooltip>
            }
          </Datagrid>
        </List>
        }
      </Box>
    )
  } else {
    return (
      <Box className={classes.RightSerchBar}>
        <Box display="flex" style={{ width: '100%', position: 'relative', top: '20px'}} justifyContent="flex-start">
          <Typography className={classes.PostTitle}>Condiciones de Proveedores</Typography>
          <Typography className={classes.PostSubTitle}>&nbsp;Por campañas</Typography>
        </Box>
        <Box className={classes.ConditionsLoading}>
          <Box id="table">
              <Box id="header">
                <Checkbox />
                <span>Campaña</span>
                <span>Tienda</span>
                <span>Proveedor</span>
              </Box>
              <Divider />
              {SkeletonIds.map(id => (
                <Box className={classes.tableRow} id={SkeletonData[id].key}>
                  <Checkbox />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    )
  }
}; export default ConditionsList;