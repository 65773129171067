import React, { Fragment } from 'react';
import { Datagrid, List, TextField, useTranslate, DateField, EditButton, ReferenceField, NumberField, ReferenceInput, SelectInput,
    usePermissions, BulkDeleteButton, DeleteButton, Filter, SearchInput, TopToolbar, useListContext } from "react-admin";
import { makeStyles, Typography, Box, ThemeProvider, TablePagination } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import * as locales from '@material-ui/core/locale';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';
import inflection from 'inflection';

const useStyles = makeStyles({
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  InLineViewOffer: lightTheme.InLineViewOffer,
  title: lightTheme.OffersAndPromotionsTitle,
  body: lightTheme.OffersAndPromotionsBody,
  offerImage: lightTheme.OffersAndPromotionsOfferImage,
  btnContainer: lightTheme.OffersAndPromotionsBtnContainer,
  btnArchivos: lightTheme.OffersAndPromotionsBtnArchivos,
  btnSolicitar: lightTheme.OffersAndPromotionsBtnSolicitar,
  OrdersTable: lightTheme.OrdersTable,
  root: {flexGrow: 1},
  BlackInputMargin: lightTheme.CommonStyles.CreateAndEdit.BlackInputMargin,
  RightSerchBar: lightTheme.OrdersRightSerchBar,
  Searchroot: lightTheme.FilesrootUser,
  RightSerchMargin: lightTheme.OrdersRightSerchMargin,
  OrderFilterButtonGoogleStyle: lightTheme.OrderFilterButtonGoogleStyle,
  EstateChipBlue:{
    color: '#196cdb !important',
    backgroundColor: '#196cdb24 !important',
    padding: '5px 8px 5px 8px',
    borderRadius: '25px',
    width: '70%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '0px !important',
    border: '1px solid #196cdb',
    '& #title':{
      fontSize: '14px',
    },
  },
  EstateChipGreen:{
    color: 'rgba(40, 121, 18, 0.87) !important',
    backgroundColor: 'rgba(63, 225, 17, 0.23) !important',
    padding: '5px 8px 5px 8px',
    borderRadius: '25px',
    width: '70%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '0px !important',
    border: '1px solid rgba(40, 121, 18, 0.87)',
    '& #title':{
      fontSize: '14px',
    },
  },
  EstateChipRed:{
    color: 'rgba(121, 18, 18, 0.87) !important',
    backgroundColor: 'rgba(225, 17, 17, 0.23) !important',
    padding: '5px 8px 5px 8px',
    borderRadius: '25px',
    width: '70%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '0px !important',
    border: '1px solid rgba(121, 18, 18, 0.87)',
    '& #title':{
      fontSize: '14px',
    },
  },
  EstateChipYellow:{
    color: '#eaa20f !important',
    backgroundColor: '#fff0bc',
    padding: '5px 8px 5px 8px',
    borderRadius: '25px',
    width: '70%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '0px !important',
    border: '1px solid #eaa20f',
    '& #title':{
      fontSize: '14px',
    },
  },
  EstateChip:{
    padding: '5px 8px 5px 8px',
    borderRadius: '25px',
    width: '70%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '0px !important',
    animation: 'StatePendingBlink 1s infinite',
    '& #title':{
      fontSize: '14px',
    },
  },
});


const BulkDeleteOrdersButton = ({ basePath }) => {
    return(
      <Fragment>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Seleccionados</div>} aria-label="Delete" arrow placement="left">
          <BulkDeleteButton label="" basePath={basePath} id="GoogleStyleBulkButton"/>
        </MyTooltip>
      </Fragment>
    )
  };


const OrdersFilter = ({...props}) => {
    const classes = useStyles();
    return (
      <Filter {...props} className={classes.OrderFilterButtonGoogleStyle}>
        <SearchInput source="tocken" alwaysOn variant="outlined" className={classes.Searchroot} resettable/>
        <ReferenceInput label="Usuario" source="user_id" reference="users" variant="outlined" className={classes.BlackInputMargin} perPage={999}>
            <SelectInput  optionText="displayName" variant="outlined" resettable/>
        </ReferenceInput>
        <SelectInput source="state" choices={[
            { id: '1', name: 'Sin Confirmar' },
            { id: '2', name: 'Aceptado' },
            { id: '3', name: 'Enviado' },
            { id: '4', name: 'Entregado' },
            { id: '5', name: 'Cancelado' },
            { id: '6', name: 'Devuelto'},
        ]} variant="outlined" className={classes.BlackInputMargin} label="Estado" optionText="name" optionValue="name" alwaysOn resettable/>
      </Filter>
    )
};

const OrdersActions = ({ filters, displayedFilters, showFilter }) => {
    const classes = useStyles();
    return (
      <TopToolbar className={classes.TopToolbarCustom}>
        <MyTooltip title={<div className={classes.TooltipTitle}>Añadir Filtro</div>} id="hideFilterTooltip" arrow placement="right">
          {filters && React.cloneElement(filters, { showFilter, displayedFilters, context: 'button'})}
        </MyTooltip>
      </TopToolbar>
    )
};

const EstateChip = ({ record }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {record &&
                <Typography variant="body1" align="left" style={{ fontSize: '13px', textTransform: 'uppercase', textDecoratiom: 'none', textAlign: 'center'}}
                  id={record.state} title="state-chip"
                >
                    <Box  className={record.state.includes('Aceptado') ? classes.EstateChipBlue : 
                      (record.state.includes('Entregado') ? classes.EstateChipGreen : 
                      (record.state.includes('Enviado') ? classes.EstateChipGreen: 
                      (record.state.includes('Cancelado') ? classes.EstateChipRed: classes.EstateChip )))}>
                      <Typography id="title">{inflection.humanize(record.state)}</Typography>
                    </Box>
                </Typography>
            }
        </div>
    );
}

const CompanyPagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();

  const handleChangeRowsPerPage = (event) => { 
      setPerPage(parseInt(event.target.value, 10));
      setPage(1);
  };
  const handleChangePage = (event, newPage) => {
      setPage(newPage + 1);
   };
  
  return (
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
          <TablePagination
              component="div"
              rowsPerPageOptions={[8, 10, 12, 15, 20]}
              count={total || 0}
              page={page - 1}
              onChangePage ={handleChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </ThemeProvider>
  );
}

const OrdersList = ({...props}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { permissions } = usePermissions();

  return (
    <Box style={{marginTop: '5px'}} className={classes.RightSerchBar}>
      <Box display="flex" style={{ width: '100%', position: 'relative', top: '10px'}} justifyContent="flex-start">
        <Typography className={classes.PostTitle}>Pedidos</Typography>
        <Typography className={classes.PostSubTitle}>&nbsp;Gestión de los Pedidos de los Consumibles</Typography>
      </Box>
      <List {...props} bulkActionButtons={(permissions === 'Admin' || permissions === 'Transporte') ? <BulkDeleteOrdersButton /> : false} filters={<OrdersFilter/>}
        actions={<OrdersActions/>} sort={{field: 'createdate', order: 'DESC'}} className={classes.RightSerchMargin} pagination={<CompanyPagination/>} perPage={8}>
        <Datagrid rowClick="show" className={classes.OrdersTable}>
          <TextField label="id" source="id" title="id"/>
          <DateField label={translate('resources.offersAndPromotions.fields.date')} source="createdate" locales="fr-FR"/>
          <TextField label="Token" source="tocken" variant="outlined"/>
          <ReferenceField label="Usuario" source="user_id" reference="users" link={false}>
              <TextField label="Usuario" source="displayName" variant="outlined"/>
          </ReferenceField>
          <TextField label="Nº Productos" source="total_items" variant="outlined"/>
          <NumberField options={{ style: 'currency', currency: 'EUR' }} label="Precio Total" source="total_price"/>
          <EstateChip id="Order_Estate" source="state" label="Estado"/>
          {permissions === 'Admin' &&
            <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Pedido</div>} arrow>
              <EditButton basePath="/orders" label="" record={props} id="GoogleStyleButton"/>
            </MyTooltip>
          }
          {permissions === 'Admin' &&
            <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Pedido</div>} arrow>
              <DeleteButton basePath="/orders" label="" record={props} id="GoogleStyleDeleteButton"/>
            </MyTooltip>
          }
        </Datagrid>
      </List>
    </Box>
  )
};

export default OrdersList;