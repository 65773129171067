import React from "react";
import { Edit, SimpleForm, useTranslate, TextInput, Toolbar, DeleteButton, SaveButton, required, DateInput } from "react-admin";
import { Typography, Box, makeStyles, CircularProgress, Divider } from '@material-ui/core';
import lightTheme from '../layout/themes';
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import '../index.css';

const useStyles = makeStyles({
    InputBlackBorder: lightTheme.InputBlackBorder,
    CardUsersEdit: lightTheme.CardUsersEdit,
    EditToolBar: lightTheme.EditToolBar,
});

const CampaignsEdit = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const redirect = () => `/campaigns`;

    const PointsEditToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        const basePath = `/campaigns`;
        return (
          <Box>
            <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="Save" arrow>
                <SaveButton disabled={props.pristine} redirect={`/campaigns`} id="GoogleStyleSaveButton">
                  {loading && (<CircularProgress/>)}
                </SaveButton>
              </MyTooltip>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Campaña</div>} aria-label="Delete" arrow placement="right">
                <DeleteButton basePath={basePath} label="" id="GoogleStyleDeleteButton"/>
              </MyTooltip>
            </Toolbar>
          </Box>
        )
    };

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect} toolbar={<PointsEditToolbar/>}>
                <Box style={{ width:'100%', color: '#3d3d3d' }}>
                    <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                        <Box p={1} width="100%">
                            <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Campaña</Typography>
                        </Box>
                        <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                                <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                            </MyTooltip>
                        </Box>
                    </Box>
                    <Box>
                        <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15}}>
                            <InfoRoundedIcon style={{fontSize: '1.5rem'}}/>Información General
                        </Typography>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label={translate('resources.pointOfSale.field.name')} source="name" variant="outlined" 
                                multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()}/>
                        </Box>
                        <Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px'}} />
                        <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15}}>
                            <DateRangeRoundedIcon style={{fontSize: '1.5rem'}}/>Duración de la Campaña
                        </Typography>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <DateInput label={translate('resources.campaigns.field.startDate')} source="startDate" variant="outlined" 
                                fullWidth className={classes.InputBlackBorder} validate={required()}/>
                            <DateInput label={translate('resources.campaigns.field.endDate')} source="endDate" variant="outlined"
                                fullWidth className={classes.InputBlackBorder} validate={required()}/>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    )
}; export default CampaignsEdit;