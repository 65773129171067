import React from 'react';
import { Link } from 'react-router-dom';
import { List, usePermissions, useGetList, useGetOne} from 'react-admin';
import { makeStyles, Grid, Card, CardMedia, CardContent, Typography, Divider, Box, Snackbar, Slide, Fade } from '@material-ui/core';
import MuiAlert from '@mui/material/Alert';
import inflection from 'inflection';
import firebase from 'firebase';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  GridRoot: lightTheme.ShopsStyles.ShopList.GridRoot,
  GridMedia: lightTheme.ShopsStyles.ShopList.GridMedia,
  GridMediaDeny: lightTheme.ShopsStyles.ShopList.GridMediaDeny,
  GridTitle: lightTheme.ShopsStyles.ShopList.GridTitle,
  GridCard: lightTheme.ShopsStyles.ShopList.GridCard,
  CentraProviders:{
    boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
    backgroundColor: '#fff',
    width: '100%',
    marginTop: '20px',
    borderRadius: '10px',
    '& a':{
      textDecoration: 'none',
      color: '#3d3d3d',
    },
    '& #GridTitle':{
      textDecoration: 'none',
      color: '#3d3d3d',
    }
  },
  AlertMsg:{
    '& .MuiAlert-root':{
      marginBottom: '85px',
      boxShadow: '0px 3px 5px -1px rgba(211, 47, 47,0.2),0px 6px 10px 0px rgba(211, 47, 47,0.14),0px 1px 18px 0px rgba(211, 47, 47,0.12) !important'
    }
  }
});

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const ShopGrid = ({userShops, ...props})  => {
  const classes = useStyles(props);
  const permissions = usePermissions().permissions;
  const { data, ids } = useGetList('shops',{page: 1, perPage: 4 },{ field: 'order', order: 'ASC' });

  let uniqueChars = [...new Set(userShops)];
  let choices = [];
  let arrayShopId = '';
  if(uniqueChars){
    for (var i = 0; i < uniqueChars.length; i++) {
      arrayShopId = uniqueChars[i];
      if(arrayShopId !== '') {
        choices.push(uniqueChars[i])
      }
    }
  }

  const [state, setState] = React.useState({
    open: false,
    Transition: Fade,
  });

  const handleClick = (Transition) => () => {
    setState({
      open: true,
      Transition,
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  if (ids) {
    return (
      <Box>
        {choices && 
          <Grid container spacing={6} className={classes.GridRoot} >
            {ids.map(id => (
              <Grid key={id} lg={3} item >
                <Card className={classes.GridCard}>
                  {(choices.some(n => n === data[id].id) === true || permissions === 'Central' || permissions === 'Publicador' || permissions === 'Admin') ?
                    <Link to={`/shops/${data[id].id}/show`}>
                      <CardMedia className={classes.GridMedia}>
                        <img alt={data[id].name} src={data[id].logo.src}/>
                      </CardMedia>
                      <Divider/>
                      <CardContent className={classes.GridTitle}>
                        <Typography variant="body1">
                            {inflection.humanize(data[id].name)}
                        </Typography>
                      </CardContent>
                    </Link>
                    :
                    <div>
                      <Box onClick={handleClick(SlideTransition)}>
                        <Box>
                        <CardMedia className={classes.GridMedia}>
                          <img alt={data[id].name} src={data[id].logo.src}/>
                        </CardMedia>
                        </Box>
                        <Divider/>
                        <CardContent className={classes.GridTitle}>
                          <Typography variant="body1">
                              {inflection.humanize(data[id].name)}
                          </Typography>
                        </CardContent>
                      </Box>
                      <Snackbar
                        open={state.open}
                        onClose={handleClose}
                        TransitionComponent={state.Transition}
                        key={state.Transition.name}
                      >
                        <Box className={classes.AlertMsg}>
                          <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
                            No tienes Permisos para ver estos Proveedores
                          </MuiAlert>
                          <Snackbar
                            open={state.open}
                            onClose={handleClose}
                            TransitionComponent={state.Transition}
                            message="Contacte con Central para más Información"
                            key={state.Transition.name}
                          />
                        </Box>
                      </Snackbar>
                    </div>
                  }
                </Card>
              </Grid>
            ))}
          </Grid>
        }
        {(permissions === 'Central' || permissions === 'Publicador' || permissions === 'Admin') &&
          <Box className={classes.CentraProviders}>
            <Link to={`/shops/618bd896efea2b2049ada490/show`}>
              <CardMedia className={classes.GridMedia} style={{height: '250px'}}>
                <img alt="Trendico Group" src="https://firebasestorage.googleapis.com/v0/b/trendico-intranet.appspot.com/o/shops%2F618bd896efea2b2049ada490%2FTrendicoGroup.svg?alt=media&token=ca70a420-a8b9-449f-bda4-fbdb9d68d32f"/>
              </CardMedia>
              <Divider/>
              <CardContent className={classes.GridTitle} id="GridTitle">
                <Typography variant="body1">
                    Trendico Group - Central
                </Typography>
              </CardContent>
            </Link>
          </Box>
        }
      </Box>
    )
  } else {
    return null
  }
};

const ShopList = props => {
  const user = firebase.auth().currentUser;
  const { data } = useGetOne('users', user.uid);
  if (data) { 
    const userShops = data.role === 'Admin' ? 
      [ "618bd896efea2b2049ada486", "618bd896efea2b2049ada488", "618bd896efea2b2049ada489", "618bd896efea2b2049ada487" ] : 
      ((data.shop[0].id === null || data.shop[0].id === undefined) ? data.shop.map(({shop}) => shop ) : data.shop.map(({id}) => id ));
    return (
      <List {...props} perPage={200} pagination={false} component="div" actions={false} style={{marginTop: 20}}>
        <ShopGrid userShops={userShops}/>
      </List>
    )
  } else {
    return (null)
  }
}; 

export default ShopList;
