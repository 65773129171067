import React from "react";
import { List, useRedirect, useListContext } from "react-admin";
import { makeStyles, Grid, Card, CardContent, Typography, Box, Button, ThemeProvider, TablePagination } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import * as locales from '@material-ui/core/locale';
import { FileIcon, defaultStyles } from '../components/FileIcon';
import { mdiFileDownload } from '@mdi/js';
import Icon from '@mdi/react';
import env from "react-dotenv";
import MyTooltip from "../components/MuiTooltip";
import lightTheme from '../layout/themes';

const DownloadIcon = () => {return(<Icon path={mdiFileDownload} size={0.9}/>)};

const useStyles = makeStyles({
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  FolderGrid:{
    marginTop: '20px',
    '& #GoogleStyleOffer':{
      display: 'none',
      '& svg':{
        paddingLeft: '13px'
      }
    },
    '& #ContentCard:hover':{
      borderColor: 'transparent',
      boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.3) ,0px 2px 6px 5px rgba(26, 26, 26, 0.15) !important',
      '& #GoogleStyleOffer':{
        display: 'flex',
      }
    }
  },
  Folder:{
    '& .MuiCardContent-root':{
      padding: '15px !important'
    },
    '& #FolderContent':{
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      cursor: 'pointer',
      height: '40px',
    },
    '& #FolderTitle':{
      color: '#424242',
      fontSize: '15px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '90%',
      '& svg':{
        marginBottom: '-5px',
        marginRight: '15px',
      }
    }
  }
});

const FoldersGrid = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();

  let filters = {};

  if (props.category === 'eLRVRilYd5sdIR7w8Zl3') {
    filters = {
      category: props.category, 
      campaign: props.campaign, 
      provider: props.provider, 
      condition_id: props.condition_id
    }
  }

  if (props.category === 'VtNc5D0KMKEtk4cX1LZq') {
    filters = {
      category: props.category,
      notice_id: props.notice_id
    }
  }

  if (props.category === 'JovTtJjyBDezCYAtXpMH') {
    filters = {
      category: props.category,
      offer_id: props.offer_id
    }
  }

  const { data, ids, setFilters } = useListContext();
  setFilters(filters);
  
  return(
    <Grid container spacing={2} className={classes.FolderGrid}>
      {ids.length > 0 ? (
        <>
        {ids.map(id => (
          <Grid key={id} xs={3} sm={3} md={4} lg={4} xl={4} item >
            <Card className={classes.Folder} id="ContentCard">
              <CardContent>
                  {data[id].type === 'PDF' &&
                    <Box id="FolderContent" title={`https://${env.REACT_APP_DOMAIN}/#/files/${data[id].id}/show`}>
                      <Typography id="FolderTitle" onClick={() => redirect(`/files/${data[id].id}/show`)}>
                        <FileIcon extension="pdf" {...defaultStyles.pdf} />
                        {data[id].name} 
                      </Typography>
                      <MyTooltip title={<div style={{fontSize: '12px'}}>Descargar Archivo</div>} arrow >
                        <Button onClick={() => window.open(`${data[id].fileUrl.src}`, `_blank`)} startIcon={ <DownloadIcon />} id="GoogleStyleOffer"></Button>
                      </MyTooltip>
                    </Box>
                  }
                  {data[id].type === 'Excel' &&
                    <Box id="FolderContent" title={`https://${env.REACT_APP_DOMAIN}/#/files/${data[id].id}/show`}>
                      <Typography id="FolderTitle" onClick={() => redirect(`/files/${data[id].id}/show`)}>
                        <FileIcon extension="xlsx" {...defaultStyles.xlsx} />
                        {data[id].name} 
                      </Typography>
                      <MyTooltip title={<div style={{fontSize: '12px'}}>Descargar Archivo</div>} arrow>
                        <Button onClick={() => window.open(`${data[id].fileUrl.src}`, `_blank`)} startIcon={ <DownloadIcon />} id="GoogleStyleOffer"></Button>
                      </MyTooltip>
                    </Box>
                  }
                  {data[id].type === 'PowerPoint' &&
                  <Box id="FolderContent" title={`https://${env.REACT_APP_DOMAIN}/#/files/${data[id].id}/show`}>
                    <Typography id="FolderTitle" onClick={() => redirect(`/files/${data[id].id}/show`)}>
                      <FileIcon extension="pptx" {...defaultStyles.pptx} />
                      {data[id].name} 
                    </Typography>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Descargar Archivo</div>} arrow >
                      <Button onClick={() => window.open(`${data[id].fileUrl.src}`, `_blank`)} startIcon={ <DownloadIcon />} id="GoogleStyleOffer"></Button>
                    </MyTooltip>
                  </Box>
                  }
                  {data[id].type === 'Word' &&
                    <Box id="FolderContent" title={`https://${env.REACT_APP_DOMAIN}/#/files/${data[id].id}/show`}>
                      <Typography id="FolderTitle" onClick={() => redirect(`/files/${data[id].id}/show`)}>
                        <FileIcon extension="docx" {...defaultStyles.docx} />
                        {data[id].name} 
                      </Typography>
                      <MyTooltip title={<div style={{fontSize: '12px'}}>Descargar Archivo</div>} arrow >
                        <Button onClick={() => window.open(`${data[id].fileUrl.src}`, `_blank`)} startIcon={ <DownloadIcon />} id="GoogleStyleOffer"></Button>
                      </MyTooltip>
                    </Box>
                  }
                  {data[id].type === 'Imagen' &&
                    <Box id="FolderContent" title={`https://${env.REACT_APP_DOMAIN}/#/files/${data[id].id}/show`}>
                      <Typography id="FolderTitle" onClick={() => redirect(`/files/${data[id].id}/show`)}>
                        <FileIcon extension="png" {...defaultStyles.png} />
                        {data[id].name} 
                      </Typography>
                      <MyTooltip title={<div style={{fontSize: '12px'}}>Descargar Archivo</div>} arrow >
                        <Button onClick={() => window.open(`${data[id].fileUrl.src}`, `_blank`)} startIcon={ <DownloadIcon />} id="GoogleStyleOffer"></Button>
                      </MyTooltip>
                    </Box>
                  }
              </CardContent>
            </Card>
          </Grid>
        ))}
        </>
      ):(
        <Grid xs={3} sm={3} md={4} lg={4} xl={4} item >
          <Typography>No se hay archivos adjuntos</Typography>
        </Grid>
      )}
    </Grid>
  )
}


const Pagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();

  const handleChangeRowsPerPage = (event) => { 
      setPerPage(parseInt(event.target.value, 10));
      setPage(1);
  };
  const handleChangePage = (event, newPage) => {
      setPage(newPage + 1);
   };

  return (
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
        {total > 0 && (
          <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 15, 20]}
              count={total || 0}
              page={page - 1}
              onChangePage ={handleChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </ThemeProvider>
  );
}

export default function FilesGrid(props){
  return (
    <Box>
      <List {...props} actions={false} perPage={10} pagination={<Pagination />} component="div">
        <FoldersGrid {...props}/>
      </List>
    </Box>
  )
};