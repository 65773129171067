import MaterialIcon from '@material-ui/icons/LocalMallRounded';
import MaterialList from './MaterialList';
import MaterialView from './MaterialView';
import MaterialEdit from './MaterialEdit';
import MaterialCreate from './MaterialCreate';
const MaterialModule = {
    list: MaterialList,
    icon: MaterialIcon,
    show: MaterialView,
    create: MaterialCreate,
    edit: MaterialEdit,
}; export default MaterialModule;