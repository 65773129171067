import React from 'react';
import { useGetList } from "react-admin";
import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';
import BarChartRoundedIcon from '@material-ui/icons/BarChartRounded';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import { FilterList, FilterListItem } from 'react-admin';

export const PriceFilter = () => {
    return(
        <FilterList label="resources.material.filters.availability" icon={<BarChartRoundedIcon />} >
            <FilterListItem label="resources.material.filters.available" value={{ stock_filter: true}} />
            <FilterListItem label="resources.material.filters.notAvailable" value={{ stock_filter: false }} />
        </FilterList>
    )
}; 

export const CategoryFilter = () => {
    const { data, ids } = useGetList('shops', { page: 1, perPage: 10 }, {field: 'name', order: 'ASC'})
    return(
        <FilterList label="resources.material.filters.ecommerce" icon={<LocalOfferRoundedIcon />}>
            {ids.map(id => (
                <FilterListItem key={id} label={data[id].name} value={{ shop: data[id].id }}/>
            ))}
        </FilterList>
    )
}; 

export const TypeFilter = () => {
    const { data, ids } = useGetList('division', { page: 1, perPage: 20 }, {field: 'name', order: 'ASC'});
    return(
        <FilterList label="resources.material.filters.category" icon={<TurnedInIcon />}>
            {ids.map(id => (
                <FilterListItem key={id} label={data[id].name} value={{ division: data[id].id }}/>
            ))}
        </FilterList>
    )
}; 
