import React from 'react'
import { withRouter } from 'react-router'
import { makeStyles, Button, Box } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  BackButtonStyle: lightTheme.BackButtonStyle,
});

const BackButton = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.BackButtonStyle}>
      <Button {...props} startIcon={<CloseIcon />}  >
        {children}
      </Button>
    </Box>
  )
}; export default withRouter(BackButton)