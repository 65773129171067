import PointOfSaleIcon from '@material-ui/icons/PlaceRounded';
import PointOfSaleList from './PointOfSaleList';
import PointOfSaleCreate from './PointOfSaleCreate';
import PointOfSaleEdit from './PointOfSaleEdit';
import PointOfSaleView from './PointOfSaleView';

const PointOfSaleModule = {
    list: PointOfSaleList,
    edit: PointOfSaleEdit,
    create: PointOfSaleCreate,
    show: PointOfSaleView,
    icon: PointOfSaleIcon,
}

export default PointOfSaleModule;