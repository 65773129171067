import React, { useState } from "react";
import { SimpleForm, TextField, TextInput, useGetOne, Show, RichTextField, ReferenceField, ChipField, useDataProvider, useRefresh,
  TopToolbar, CreateButton, EditButton, DeleteButton, CloneButton, Link, ReferenceArrayField, SingleFieldList, useUpdate, ImageField 
} from "react-admin";
import { Typography, Divider, Box, makeStyles, Button } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ToolbarBackButton from '../components/ToolbarBackButton';
import MyTooltip from "../components/MuiTooltip";
import FilesGrid from '../files/FilesGrid';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import EventIcon from '@mui/icons-material/Event';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import FilterNoneRoundedIcon from '@material-ui/icons/FilterNoneRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import lightTheme from "../layout/themes";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { generatePDF } from '../utils/ConditionsUtils';
import firebase from 'firebase';
import moment from 'moment';
import env from "react-dotenv";
import '../index.css';
import 'moment/locale/es';

import { mdiFileOutline } from '@mdi/js'; 
import Icon from '@mdi/react'

const DraftIcon = () => {return(<Icon path={mdiFileOutline} size={0.9}  />)};

const useStyles = makeStyles({
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  YellowStateChip: lightTheme.ConditionsStyles.ConditionsViewStyles.YellowStateChip,
  GreenStateChip: lightTheme.ConditionsStyles.ConditionsViewStyles.GreenStateChip,
  HideFilesTitle: lightTheme.ConditionsStyles.ConditionsViewStyles.HideFilesTitle,
  ReferenceLinkText: lightTheme.ConditionsStyles.ConditionsViewStyles.HideFilesTitle,
  IconTitle: lightTheme.ConditionsStyles.ConditionsViewStyles.IconTitle,
  ProviderLogo: lightTheme.ConditionsStyles.ConditionsViewStyles.ProviderLogo,
  DatesBox: lightTheme.ConditionsStyles.ConditionsViewStyles.DatesBox,
  dateBox: lightTheme.ConditionsStyles.ConditionsViewStyles.dateBox,
  ConditionsTitle: lightTheme.ConditionsStyles.ConditionsViewStyles.ConditionsTitle,
  ConditionsToolbar: lightTheme.ConditionsStyles.ConditionsViewStyles.ConditionsToolbar,
  Breadcrumb: lightTheme.ConditionsStyles.ConditionsViewStyles.Breadcrumb,
  ConditionsBoxShow: lightTheme.ConditionsStyles.ConditionsViewStyles.ConditionsBoxShow,
  ConditionsAtachedFilesBox: lightTheme.ConditionsStyles.ConditionsViewStyles.ConditionsAtachedFilesBox
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionLeft(props) {
  return <Slide {...props} direction="right" />;
}

const SubReference = ({ translateChoice, children, ...props }) => (
  <ReferenceField {...props}>{children}</ReferenceField>
);

const ShopName = () => {
  return(
    <Box>
      <ReferenceField source="provider.id" reference="provider" linkType={false} style={{color: '#3d3d3d'}}>
        <SubReference source="shop" reference="shops" link="show" style={{color: '#3d3d3d'}}>
          <TextField source="name" style={{fontSize: '1.0rem'}}/>
        </SubReference>
      </ReferenceField>
    </Box>
  )
};

const ProviderName = () => {
  return(
    <Box>
      <ReferenceField label="" source="provider.id" reference="provider" style={{color: '#3d3d3d'}} linkType="show">
        <TextField source="name" style={{ width: '100%' }}/>
      </ReferenceField>
    </Box>
  )
};

const ProviderLogo = () => {
  const classes = useStyles();
  return(
    <Box className={classes.ProviderLogo}>
      <ReferenceField label="" source="provider.id" reference="provider" linkType="show">
        <ImageField source="logo.src" title="proveedor" id="providerLogoImgField"/>
      </ReferenceField>
    </Box>
  )
};

const CampaignTitle = () => {
  return(
    <Box style={{marginTop: '-20px'}}>
      <ReferenceField label="" source="campaign" reference="campaigns" style={{color: '#3d3d3d', fontSize: '20px'}} linkType={false}>
        <TextField source="name" style={{ width: '100%' }}/>
      </ReferenceField>
    </Box>
  )
};

const CampaignName = () => {
  return(
    <Box>
      <ReferenceField label="" source="campaign" reference="campaigns" style={{color: '#3d3d3d'}} linkType={false}>
        <TextField source="name" style={{ width: '100%' }}/>
      </ReferenceField>
    </Box>
  )
};

const Shop = (dataId) => {
  const { data, error } = useGetOne('shops', dataId);
  if (error) { return <p>ERROR</p>; }
  if (data) { return data.name };
};

export const ConditionsView = ({ permissions, ...props }) => {
  const user = firebase.auth().currentUser;
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { data } = useGetOne('conditions', props.id);
  const [update] = useUpdate();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {setTimeout(() => {setOpen(true); setOpen2(false)}, 4000)};
  const handleClose = () => {setOpen(false)};

  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => {setTimeout(() => {setOpen(false); setOpen2(true)}, 4000)};
  const handleClose2 = () => {setOpen2(false)};

  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => {setOpen3(true)};
  const handleClose3 = () => {setOpen3(false)};

  if(data){

    function testMail(){
      var emailVars = document.querySelectorAll("#breadcrumbTitle");
      var emailLogoImg = document.getElementById("providerLogoImgField");

      const objectModule = {
        shopId: data.shop, 
        shop: emailVars[1].innerText, 
        provider: emailVars[2].innerText, 
        campaign: emailVars[3].innerText, 
        mode: 'notifyChanges',
        image: emailLogoImg ? emailLogoImg.firstChild.attributes.src.value : '', 
        pageUrl: `https://${env.REACT_APP_DOMAIN}/#/conditions/${data.id}/show`,
        replayToEmail: user.email,
        replayToName: user.displayName, 
      }

      dataProvider.sendConditionsEmail('conditions', objectModule);
      handleOpen3();
    }

    const SetStatePublish = () => {
      update('conditions', data.id, {_id: data.id, state: "publicado", shop: data.shop, campaign: data.campaign, provider: data.provider});

      var emailVars = document.querySelectorAll("#breadcrumbTitle");
      var emailLogoImg = document.getElementById("providerLogoImgField");

      const objectModule = {
        shopId: data.shop, 
        shop: emailVars[1].innerText, 
        provider: emailVars[2].innerText, 
        campaign: emailVars[3].innerText, 
        mode: 'publishMail',
        image: emailLogoImg ? emailLogoImg.firstChild.attributes.src.value : '', 
        pageUrl: `https://${env.REACT_APP_DOMAIN}/#/conditions/${data.id}/show`,
        replayToEmail: user.email,
        replayToName: user.displayName, 
      }
      dataProvider.sendConditionsEmail('conditions', objectModule);

      refresh();
      handleOpen();
    };
  
    const SetSateDraft = () => {
      update('conditions', data.id, {_id: data.id, state: "borrador", shop: data.shop, campaign: data.campaign, provider: data.provider});
      refresh();
      handleOpen2();
    };

    let ConditionsFilesProps = {
      basePath: "/files", 
      resource: "files", 
      category: "eLRVRilYd5sdIR7w8Zl3",
      condition_id: props.id,
      provider: data.provider.id,
      campaign: data.campaign
    }

    const ConditionsViewTopToolbar = ({ basePath, resource }) => {
      return(
        <TopToolbar id="GoogleStyleToolBar" className={classes.ConditionsToolbar}>
          {data &&
            <Box className={classes.Breadcrumb}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Volver atrás</div>} arrow>
                <ToolbarBackButton id="GoogleStyleBackButton"></ToolbarBackButton>
              </MyTooltip>
              <Box id="ActionsBar">
                <Box id="Main_breadcrumb">
                  <Box>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Lista de tiendas</div>} arrow>
                      <Link to={`/shops`}>
                        <Typography variant="body1" id="breadcrumbTitle">Proveedores</Typography>
                      </Link>
                    </MyTooltip>
                  </Box>
                  <NavigateNextRoundedIcon id="navigationArrow"/>
                  <Box>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Lista de proveedores</div>} arrow>
                      <Typography variant="body1" id="breadcrumbTitle" className="shopName"><ShopName /></Typography>
                    </MyTooltip>
                  </Box>
                  <NavigateNextRoundedIcon id="navigationArrow"/>
                  <Box>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Ver proveedor</div>} arrow>
                      <Typography variant="body1" id="breadcrumbTitle"><ProviderName /></Typography>
                    </MyTooltip>
                  </Box>
                  <NavigateNextRoundedIcon id="navigationArrow"/>
                  <Box>
                    <Typography variant="body1" id="breadcrumbTitle"><CampaignName/></Typography>
                  </Box>
                </Box>
                {permissions === "Admin" &&
                  <Box id="mailActions">
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Notificar cambios</div>} aria-label="GoBack" arrow>
                      <Button id="GoogleStyleBackButton" 
                        startIcon={<PublishedWithChangesRoundedIcon/>} onClick={() => {testMail()}}>
                      </Button>
                    </MyTooltip>
                    {data.state === 'borrador' &&
                      <MyTooltip title={<div style={{fontSize: '12px'}}>Publicar condiciones</div>} aria-label="GoBack" arrow>
                        <Button id="GoogleStyleBackButton" 
                          startIcon={<IosShareRoundedIcon/>} onClick={() => {SetStatePublish()}}>
                        </Button>
                      </MyTooltip>
                    }
                    {data.state === 'publicado' &&
                      <MyTooltip title={<div style={{fontSize: '12px'}}>Converitr a borrador</div>} aria-label="GoBack" arrow>
                        <Button id="GoogleStyleBackButton" 
                          startIcon={<DraftIcon/>} onClick={() => {SetSateDraft()}}>
                        </Button>
                      </MyTooltip>
                    }
                    <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} TransitionComponent={TransitionLeft}>
                      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Condiciones publicadas con éxito !
                      </Alert>
                    </Snackbar>
                    <Snackbar open={open2} autoHideDuration={5000} onClose={handleClose2} TransitionComponent={TransitionLeft}>
                      <Alert onClose={handleClose2} severity="info" sx={{ width: '100%' }}>
                        Condiciones convertidas a borrador
                      </Alert>
                    </Snackbar>
                    <Snackbar open={open3} autoHideDuration={5000} onClose={handleClose3} TransitionComponent={TransitionLeft}>
                      <Alert onClose={handleClose3} severity="success" sx={{ width: '100%' }}  icon={<MarkEmailReadRoundedIcon fontSize="inherit" />}>
                        Email de prueba enviado
                      </Alert>
                    </Snackbar>
                  </Box>
                }
                <MyTooltip title={<div style={{fontSize: '12px'}}>Generar PDF</div>} aria-label="GoBack" arrow>
                    <Button id="GoogleStyleBackButton" startIcon={<PictureAsPdfIcon id="Pdf-Icon"/>} onClick={() => {generatePDF(); refresh()}}></Button>
                </MyTooltip>
                {permissions === "Admin" &&
                  <Box id="AdminButtons">
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Adjuntar archivos a estas condiciones</div>} arrow>
                      <CreateButton label="" icon={<AttachFileRoundedIcon />} id="GoogleStyleButton" 
                        to={`/files/create?source={
                          "category":"eLRVRilYd5sdIR7w8Zl3",
                          "categoryName":"Proveedores",
                          "provider":"${data.provider.id}",
                          "campaign":"${data.campaign}",
                          "condition_id":"${data.id}",
                          "shopName":"${Shop(data.shop)}",
                          "shop":"${data.shop}",
                          "providerName":"${data.provider.name}"
                        }`}
                      />
                    </MyTooltip>
                    <Divider id="divider" orientation="vertical" flexItem/>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Duplicar estas condiciones</div>} arrow>
                      <CloneButton label="" icon={<FilterNoneRoundedIcon /> } basePath={basePath} resource={resource} id="GoogleStyleButton"
                        record={{
                          campaign: data.campaign, provider: data.provider, shop: data.shop, dec_general: data.dec_general, dec_programacion: data.dec_programacion,
                          dec_reposicion: data.dec_reposicion, dec_concentracion: data.dec_concentracion, observacionesCopy: data.observaciones, 
                          envio: data.envio, facturacion: data.facturacion, pago: data.pago, category: data.category, state: data.state, 
                          dates: data.dates, pedido_min: data.pedido_min, portes: data.portes
                        }}
                      />
                    </MyTooltip>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Editar condiciones</div>} arrow>
                      <EditButton label="" type="link" to={`/conditions/${props.id}/edit`} id="GoogleStyleButton"/>
                    </MyTooltip>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar condiciones</div>} arrow>
                      <DeleteButton label="" redirect={`/provider/${data.provider}/show`} record={data} resource={resource} id="GoogleStyleDeleteButton"/>
                    </MyTooltip>
                    {data && data.state === "publicado" &&
                      <Box className={classes.GreenStateChip}>
                        <ChipField source="state" style={{textTransform: 'capitalize'}}/>
                      </Box>
                    }
                    {data && data.state === "borrador" &&
                      <Box className={classes.YellowStateChip}>
                        <ChipField source="state" style={{textTransform: 'capitalize'}}/>
                      </Box>
                    }
                  </Box>
                }
              </Box>
            </Box>
          }
        </TopToolbar>
      )
    };

    return (
      <Box className={classes.ConditionsBoxShow}>
        <Show {...props} actions={<ConditionsViewTopToolbar />}>
          <SimpleForm toolbar={null}>
            <Box id="reference-to-print">
              <Box id="MainTitle">
                {data && data.category &&
                  <Box className={classes.ConditionsTitle}>
                    <Typography variant="body1"><CampaignTitle/></Typography>
                    <Box id="categories">
                      <Typography variant="body1">Categorías: </Typography>
                      <ReferenceArrayField reference="categorys" source="category">
                        <SingleFieldList linkType={false}>
                          <ChipField source="name"/>
                        </SingleFieldList>
                      </ReferenceArrayField>
                    </Box>
                  </Box>
                }
                {data &&
                  <Box id="ProviderLogoBox">
                    <ProviderLogo />
                  </Box>
                }
              </Box>
              <Divider id="divider-1"/>
              {data && data.dec_general && 
                <Typography id="SectionTitle" variant="body1">Descuento general 
                  <TextInput disabled for="general" label="" source="dec_general" multiline="true" fullWidth variant="standard"/>
                </Typography>
              }{data && data.dec_programacion && 
                <Typography id="SectionTitle" variant="body1">Descuento programación
                  <TextInput disabled for="programacion" label="" source="dec_programacion" multiline="true" fullWidth variant="standard"/>
                </Typography>
              }{data && data.dec_reposicion && 
                <Typography id="SectionTitle" variant="body1">Descuento reposición
                  <TextInput disabled for="reposicion" label="" source="dec_reposicion" multiline="true" fullWidth variant="standard"/>
                </Typography>
              }{data && data.dec_concentracion && 
                <Typography id="SectionTitle" variant="body1">Descuento concentración
                  <TextInput disabled for="concentracion" label="" source="dec_concentracion" multiline="true" fullWidth variant="standard"/> 
                </Typography>
              }{data && data.portes && 
                <Typography id="SectionTitle" variant="body1">Portes
                  <TextInput disabled for="portes" label="" source="portes" multiline="true" fullWidth variant="standard"/> 
                </Typography>
              }{data && data.pedido_min && 
                <Typography id="SectionTitle" variant="body1">Pedido mínimo
                  <TextInput disabled for="pedido_min" label="" source="pedido_min" multiline="true" fullWidth variant="standard"/> 
                </Typography>
              }{data && data.observaciones &&
                <Typography id="SectionTitle" variant="body1">Observaciones
                  <RichTextField label="Observaciones" source="observaciones" for="observaciones" variant="standard"/> 
                </Typography>
              }
              <Divider id="divider-2"/>
              <Box id="DetailBox">
                <Box id="Box1">
                  {data && data.facturacion && 
                    <Box>
                      <Typography className={classes.IconTitle} variant="body1">Facturación <ReceiptRoundedIcon/>
                        <TextInput disabled for="facturacion" label="" source="facturacion" multiline="true" fullWidth variant="standard"/> 
                      </Typography>
                    </Box>
                  }
                </Box>
                <Box id="Box2">
                  {data && data.envio && 
                    <Box> 
                      <Typography className={classes.IconTitle} variant="body1">Envío <LocalShippingRoundedIcon/>
                        <TextInput disabled for="envio" label="" source="envio" multiline="true" fullWidth variant="standard"/> 
                      </Typography>
                    </Box>
                  }
                </Box>
                <Box id="Box3">
                  {data && data.pago && 
                    <Box> 
                      <Typography className={classes.IconTitle} variant="body1">Pago <AttachMoneyRoundedIcon/>
                        <TextInput disabled for="pago" label="" source="pago" multiline="true" fullWidth variant="standard"/> 
                      </Typography>
                    </Box>
                  }
                </Box>
              </Box>
            </Box>
          </SimpleForm>
        </Show>
        {data.dates &&
          <Box className={classes.DatesBox}>
            <Box id="DatesTitle">
              <Typography id="title-1" variant="body1">
                <DateRangeIcon/>&nbsp;&nbsp;Dead lines
              </Typography>
              <Typography id="title-2" variant="body1">
                <EventIcon/>&nbsp;&nbsp;Fechas de servicio
              </Typography>
            </Box>
            {data.dates && data.dates.map(date => (
              <Box >
                <Divider id="divider"/>
                <Box className={classes.dateBox} id="dead-lines-box">
                  {date.deadLine ? (
                    <div id="deadLine">
                      <Typography variant="body1" for="dead-line"> {moment(date.deadLine).format("DD [de] MMMM YYYY")}</Typography>
                    </div>
                  ):(
                    <div id="deadLine">
                      <Typography variant="body1" for="dead-line">Sin especificar</Typography>
                    </div>
                  )
                  }
                  <div id="icon">
                    <EastRoundedIcon />
                  </div>
                  {date.serviceDate ? (
                        <div id="serviceDate">
                          <Typography variant="body1" for="service-date"> {moment(date.serviceDate).format("DD [de] MMMM YYYY")}</Typography>
                        </div>
                    ):(
                        <div id="serviceDate">
                          <Typography variant="body1" for="service-date">Consultar</Typography>
                        </div>
                    )
                  }
                </Box>
              </Box>
            ))}
          </Box>
        }
        <Box> 
          <Box className={classes.ConditionsAtachedFilesBox}>
            <Box id="Blocktitle">
                <Typography className={classes.PostTitle}>Archivos Adjuntos</Typography>
                <Typography className={classes.PostSubTitle}>&nbsp;Archivos relacionados con esta Condición</Typography>
            </Box>
            <Box>
              <FilesGrid {...ConditionsFilesProps} id="FilesGridBlock"/>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  } else {
    return null
  }
}; export default ConditionsView;