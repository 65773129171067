import { firebaseConfig } from '../firebase';
import {FirebaseDataProvider} from 'react-admin-firebase';
import firebase from 'firebase';
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = `${process.env.REACT_APP_API_URL}`;

const firebaseApp = !firebase.apps.length ? firebase.initializeApp(firebaseConfig): firebase.app();

let userPageTokens = JSON.parse(localStorage.getItem('userPageTokens'));
let userPagePerPage = JSON.parse(localStorage.getItem('userPagePerPage'));

const dataProvider = FirebaseDataProvider(firebaseConfig, {
  logging: true,
  // rootRef: 'rootrefcollection/QQG2McwjR2Bohi9OwQzP',
  app: firebaseApp,
  // watch: ['posts'];
  // dontwatch: ['comments'];
  persistence: 'local',
  // disableMeta: true
  dontAddIdFieldToDoc: true,
  lazyLoading: {
    enabled: false,
  },
  firestoreCostsLogger: {
    enabled: true,
  },
  useFileNamesInStorage: true,
});

const httpClient = async (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const user = firebase.auth().currentUser;
    const token = await user.getIdToken();
    options.headers.set('Authorization', `Bearer ${token}`);
 
    return fetchUtils.fetchJson(url, options);
}

const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    })
};

function isBase64(str) {
    if (str === '' || str.trim() === ''){ return false; }
    try {
        return btoa(atob(str)) === str;
    } catch (err) {
        return false;
    }
}

const dataProviderObject = {
    ...dataProvider,

    getList: (resource, params) => {
        if (resource !== 'users' && resource !== 'company' && resource !== 'division' && resource !== 'country' && resource !== 'state' &&
        resource !== 'shops' && resource !== 'provider' && resource !== 'pointOfSale' && resource !== 'campaigns' && resource !== 'categorys' &&
        resource !== 'conditions' && resource !== 'variants' && resource !== 'material' && resource !== 'news' && resource !== 'publications' &&
        resource !== 'orders' && resource !== 'newsCategories' && resource !== 'post' && resource !== 'basket' && resource !== 'newsSubCategories' &&
        resource !== 'offersAndPromotions' && resource !== 'comments' && resource !== 'faq' && resource !== 'address' && resource !== 'files' && resource !== 'newsletter') {
            return dataProvider.getList(resource, params);
        }

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        if (resource === 'users') {
            if (userPagePerPage === 0) {
                userPagePerPage = perPage;
                localStorage.setItem('userPagePerPage', perPage);
                localStorage.setItem('userPageTokens', JSON.stringify([]));
            } else if (userPagePerPage !== perPage) {
                userPagePerPage = 0;
                userPageTokens = [];
                localStorage.setItem('userPagePerPage', 0);
                localStorage.setItem('userPageTokens', JSON.stringify([]));
            }
            const query = {
                sort: JSON.stringify([field, order]),
                perPage: JSON.stringify(perPage),
                nextTokenPage: userPageTokens[page-1] || undefined,
                filter: JSON.stringify(params.filter),
            };
    
            const url = `${apiUrl}/${resource}/all/${stringify(query)}`;
    
            return httpClient(url).then(({json }) => {
                if (json.nextTokenPage){
                    userPageTokens[page] = json.nextTokenPage;
                    localStorage.setItem('userPageTokens',JSON.stringify(userPageTokens));
                }
                return { data: json.users, total: json.total }
            });
        } else {
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(params.filter),
                limit: JSON.stringify(perPage),
                page: JSON.stringify(page),
            };
            const url = `${apiUrl}/${resource}/all/${stringify(query)}`;

            return httpClient(url).then(({ headers, json }) => ({
                data: json.data,
                total: json.total,
            }));
        }

        
    },

    getOne: (resource, params) => {
        if (resource !== 'users' && resource !== 'company' && resource !== 'division' && resource !== 'country' && resource !== 'state' &&
        resource !== 'shops' && resource !== 'provider' && resource !== 'pointOfSale' && resource !== 'campaigns' && resource !== 'categorys' &&
        resource !== 'conditions' && resource !== 'variants' && resource !== 'material' && resource !== 'news' && resource !== 'publications' &&
        resource !== 'orders' && resource !== 'newsCategories' && resource !== 'post' && resource !== 'basket' && resource !== 'newsSubCategories' &&
        resource !== 'offersAndPromotions' && resource !== 'comments' && resource !== 'faq' && resource !== 'address' && resource !== 'files' && resource !== 'newsletter') {
            return dataProvider.getOne(resource, params);
        }

        return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json.data,
        }));
    },

    create: (resource, params) => {
        
        if (resource !== 'users' && resource !== 'company' && resource !== 'division' && resource !== 'country' && resource !== 'state' &&
            resource !== 'shops' && resource !== 'provider' && resource !== 'pointOfSale' && resource !== 'campaigns' && resource !== 'categorys' &&
            resource !== 'conditions' && resource !== 'variants' && resource !== 'material' && resource !== 'news' && resource !== 'publications' &&
            resource !== 'orders' && resource !== 'newsCategories' && resource !== 'post' && resource !== 'basket' && resource !== 'newsSubCategories' &&
            resource !== 'offersAndPromotions' && resource !== 'comments' && resource !== 'faq' && resource !== 'address' && resource !== 'files' && resource !== 'newsletter') {
            const record = dataProvider.create(resource, params);
            return record;
        }

        if (resource === 'provider') {
            if (isBase64(params.data.logo.src.replace(/^data:image\/\w+;base64,/, "")) === true || params.data.logo.src.includes('https://firebasestorage.googleapis.com/') === true){
                return  httpClient(`${apiUrl}/${resource}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.data.id },
                }));
            } else {
                let fileName = params.data.logo.rawFile.name;
                return convertFileToBase64(params.data.logo)
                .then((base64Pictures) => {
                    params.data.logo = {};
                    params.data.logo.src = base64Pictures;
                    params.data.logo.logo = fileName;
                    return  httpClient(`${apiUrl}/${resource}`, {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: json.data.id },
                    }));
                });
            }
        }
        if (resource === 'material') {
            if (isBase64(params.data.logo.image.replace(/^data:image\/\w+;base64,/, "")) === true || params.data.logo.image.includes('https://firebasestorage.googleapis.com/') === true){
                return  httpClient(`${apiUrl}/${resource}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: { ...params.data, id: json.data.id },
                }));
            } else {
                let fileName = params.data.logo.rawFile.name;
                return convertFileToBase64(params.data.logo)
                .then((base64Pictures) => {
                    params.data.logo = {};
                    params.data.logo.image = base64Pictures;
                    params.data.logo.imageName = fileName;
                    firebase
                        .storage()
                        .ref(`material_imgs/${params.data.id}.jpg`)
                        .putString(base64Pictures.split(',')[1], "base64", {contentType: 'image/jpeg'}).then((snapshot) => {
                            console.log('Uploaded a data_url string!');
                        });
                    return  httpClient(`${apiUrl}/${resource}`, {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: json.data.id },
                    }));
                });
            }
        }
        if (!params.data.logo && !params.data.fileUrl){
            return  httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: { ...params.data, id: json.data.id },
            }));
        } else {
            if (params.data.logo){
                if (isBase64(params.data.logo.image.replace(/^data:image\/\w+;base64,/, "")) === true || params.data.logo.image.includes('https://firebasestorage.googleapis.com/') === true){
                    return  httpClient(`${apiUrl}/${resource}`, {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: json.data.id },
                    }));
                } else {
                    let fileName = params.data.logo.rawFile.name;
                    return convertFileToBase64(params.data.logo)
                    .then((base64Pictures) => {
                        params.data.logo = {};
                        params.data.logo.image = base64Pictures;
                        params.data.logo.imageName = fileName;
                        return  httpClient(`${apiUrl}/${resource}`, {
                            method: 'POST',
                            body: JSON.stringify(params.data),
                        }).then(({ json }) => ({
                            data: { ...params.data, id: json.data.id },
                        }));
                    });
                }
            } else if (params.data.fileUrl){
                let fileName = params.data.fileUrl.rawFile.name;
                return convertFileToBase64(params.data.fileUrl)
                .then((base64Pictures) => {
                    params.data.fileUrl = {};
                    params.data.fileUrl.src = base64Pictures;
                    params.data.fileUrl.title = fileName;
                    return  httpClient(`${apiUrl}/${resource}`, {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: { ...params.data, id: json.data.id },
                    }));
                });
            }
        }

    },

    update: (resource, params) => {
        if (resource !== 'users' && resource !== 'company' && resource !== 'division' && resource !== 'country' && resource !== 'state' &&
        resource !== 'shops' && resource !== 'provider' && resource !== 'pointOfSale' && resource !== 'campaigns' && resource !== 'categorys' &&
        resource !== 'conditions' && resource !== 'variants' && resource !== 'material' && resource !== 'news' && resource !== 'publications' &&
        resource !== 'orders' && resource !== 'newsCategories' && resource !== 'post' && resource !== 'basket' && resource !== 'newsSubCategories' &&
        resource !== 'offersAndPromotions' && resource !== 'comments' && resource !== 'faq' && resource !== 'address' && resource !== 'files' && resource !== 'newsletter') {
            return dataProvider.update(resource, params);
        }

        if (!params.data.logo && !params.data.fileUrl){
            return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({
                data: json.data
            }));
        }

        if (resource === 'material') {
            if (isBase64(params.data.logo.image.replace(/^data:image\/\w+;base64,/, "")) === true || params.data.logo.image.includes('https://firebasestorage.googleapis.com/') === true){
                return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: json.data
                }));
            } else {
                let fileName = params.data.logo.rawFile.name;
                return convertFileToBase64(params.data.logo)
                .then((base64Pictures) => {
                    params.data.logo = {};
                    params.data.logo.image = base64Pictures;
                    params.data.logo.imageName = fileName;
                    firebase
                        .storage()
                        .ref(`material_imgs/${params.data.id}.jpg`)
                        .putString(base64Pictures.split(',')[1], "base64", {contentType: 'image/jpeg'}).then((snapshot) => {
                            console.log('Uploaded a data_url string!');
                        });
                    return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: json.data
                    }));
                });
            }
        }

        if (resource === 'provider') {
            if (isBase64(params.data.logo.src.replace(/^data:image\/\w+;base64,/, "")) === true || params.data.logo.src.includes('https://firebasestorage.googleapis.com/') === true){
                return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: json.data
                }));
            } else {
                let fileName = params.data.logo.rawFile.name;
                return convertFileToBase64(params.data.logo)
                .then((base64Pictures) => {
                    params.data.logo = {};
                    params.data.logo.src = base64Pictures;
                    params.data.logo.logo = fileName;
                    return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: json.data
                    }));
                });
            }
        }

        if (params.data.logo) {
            if (isBase64(params.data.logo.image.replace(/^data:image\/\w+;base64,/, "")) === true){
                return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: json.data
                }));
            } else {
                let fileName = params.data.logo.rawFile.name;
                return convertFileToBase64(params.data.logo)
                .then((base64Pictures) => {
                    params.data.logo = {};
                    params.data.logo.image = base64Pictures;
                    params.data.logo.imageName = fileName;
                    return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: json.data
                    }));
                });
            }
        }

        if (params.data.fileUrl) {
            if (params.data.fileUrl.src.includes("https://drive.google.com") === true){
                return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                }).then(({ json }) => ({
                    data: json.data
                }));
            } else {
                let fileName = params.data.fileUrl.rawFile.name;
                return convertFileToBase64(params.data.fileUrl)
                .then((base64Pictures) => {
                    params.data.fileUrl = {};
                    params.data.fileUrl.src = base64Pictures;
                    params.data.fileUrl.title = fileName;
                    return  httpClient(`${apiUrl}/${resource}/${params.id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    }).then(({ json }) => ({
                        data: json.data
                    }));
                });
            }
        }
    },

    delete: (resource, params) => {
        if (resource !== 'users' && resource !== 'company' && resource !== 'division' && resource !== 'country' && resource !== 'state' &&
        resource !== 'shops' && resource !== 'provider' && resource !== 'pointOfSale' && resource !== 'campaigns' && resource !== 'categorys' &&
        resource !== 'conditions' && resource !== 'variants' && resource !== 'material' && resource !== 'news' && resource !== 'publications' &&
        resource !== 'orders' && resource !== 'newsCategories' && resource !== 'post' && resource !== 'basket' && resource !== 'newsSubCategories' &&
        resource !== 'offersAndPromotions' && resource !== 'comments' && resource !== 'faq' && resource !== 'address' && resource !== 'files' && resource !== 'newsletter') {
            return dataProvider.delete(resource, params);
        }

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json.data }));
    },

    deleteMany: (resource, params) => {
        if (resource !== 'users' && resource !== 'company' && resource !== 'division' && resource !== 'country' && resource !== 'state' &&
        resource !== 'shops' && resource !== 'provider' && resource !== 'pointOfSale' && resource !== 'campaigns' && resource !== 'categorys' &&
        resource !== 'conditions' && resource !== 'variants' && resource !== 'material' && resource !== 'news' && resource !== 'publications' &&
        resource !== 'orders' && resource !== 'newsCategories' && resource !== 'post' && resource !== 'basket' && resource !== 'newsSubCategories' &&
        resource !== 'offersAndPromotions' && resource !== 'comments' && resource !== 'faq' && resource !== 'address' && resource !== 'files' && resource !== 'newsletter') {
            return dataProvider.deleteMany(resource, params);
        }
        const query = {
            param: JSON.stringify({ids: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}/deleteMany/${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json.data }));
    },

    getMany: (resource, params) => {
        if (resource !== 'users' && resource !== 'company' && resource !== 'division' && resource !== 'country' && resource !== 'state' &&
        resource !== 'shops' && resource !== 'provider' && resource !== 'pointOfSale' && resource !== 'campaigns' && resource !== 'categorys' &&
        resource !== 'conditions' && resource !== 'variants' && resource !== 'material' && resource !== 'news' && resource !== 'publications' &&
        resource !== 'orders' && resource !== 'newsCategories' && resource !== 'post' && resource === 'basket' && resource !== 'newsSubCategories' &&
        resource !== 'offersAndPromotions' && resource !== 'comments' && resource !== 'faq' && resource !== 'address' && resource !== 'files' && resource !== 'newsletter') {
            return dataProvider.getMany(resource, params);
        }
        const query = {
            param: JSON.stringify({ids: params.ids}),
        };
        const url = `${apiUrl}/${resource}/getMany/${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.data }));
    },

    getManyReference: (resource, params) => {
        if (resource !== 'users') {
            return dataProvider.getManyReference(resource, params);
        }

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },
    
    sendOffersAndPromotionsEmail: (resource, data) => {
        return  httpClient(`${apiUrl}/newsletter/${resource}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({ json }) => ({
            data: { ...data, message: json.message },
        }));
    },

    sendOrderStateEmail: (resource, data) => {
        return  httpClient(`${apiUrl}/newsletter/${resource}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({ json }) => ({
            data: { ...data, message: json.message },
        }));
    },

    //Para suscribir o borrar una suscripcion
    addNewsletterSubscription: (resource, data) => {
        return  httpClient(`${apiUrl}/newsletter/${resource}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({ json }) => ({
            data: { ...data, message: json.message, subscribed: json.subscribed },
        }));
       
    },

    //Para Pintar o no la campanita - por cada modulo
    isSuscribedToNewsletter: (resource, data) => {
        // resource isSubscribed
        const url = `${apiUrl}/newsletter/${resource}/${data.moduleId}`;

        return httpClient(url).then(({ json }) => ({
            data: { ...data, message: json.message, subscribed: json.subscribed },
        }));
    },
 
    //DATA INFO DEL BODY
    //Cuando se publica nuevo contenido
    sendNewsletterEmail: (resource, data) => {
        // resource  newsletter
        return  httpClient(`${apiUrl}/newsletter/${resource}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({ json }) => ({
            data: { ...data, message: json.message },
        }));
    },

    sendConditionsEmail: (resource, data) => {
        // resource  conditions
        return  httpClient(`${apiUrl}/newsletter/${resource}`, {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(({ json }) => ({
            data: { ...data, message: json.message },
        }));
    },


};
export default dataProviderObject;
