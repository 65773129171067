import React, {useState} from "react";
import { 
    Show,SimpleShowLayout, TextField, TextInput, ReferenceField, usePermissions, useGetOne, NumberField, ChipField, useDataProvider, FormDataConsumer,
    TopToolbar, DeleteButton, EditButton, DateField, Link, useUpdate, useRefresh, SimpleForm, Edit, LinearProgress 
} from "react-admin";
import { Box, makeStyles, Typography, Divider, Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ToolbarBackButton from '../components/ToolbarBackButton';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from "../layout/themes";
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { generatePDF } from '../utils/OrdersUtils';
import env from "react-dotenv";

const useStyles = makeStyles({
    mediaExcel: lightTheme.ProviderGridMediaFilesExcel,
    mediaPDF: lightTheme.ProviderGridMediaFilesPDF,
    mediaPNG: lightTheme.ProviderGridMediaFilesPNG,
    titleExcel: lightTheme.ProviderGridTitleFilesExcel,
    titlePDF: lightTheme.ProviderGridTitleFilesPDF,
    titlePNG: lightTheme.ProviderGridTitleFilesPNG,
    ButtonsBoxExcel: lightTheme.FilesGridButtonsBoxExcel,
    ButtonsBoxPDF: lightTheme.FilesGridButtonsBoxPDF,
    ButtonsBoxPNG: lightTheme.FilesGridButtonsBoxPNG,
    HoverTitle: lightTheme.FilesGridHoverTitle,
    FilesGridHiddenTitle: lightTheme.FilesGridHiddenTitle,
    PostTitle: lightTheme.PostTitle,
    PostSubTitle: lightTheme.PostSubTitle,
    OrdersMaterialTable: lightTheme.OrdersMaterialTable_Mui,
    NewsTopToolbar: lightTheme.NewsStyles.NewsShow.NewsTopToolbar,
    BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
    Breadcrumb: lightTheme.ConditionsStyles.ConditionsViewStyles.Breadcrumb,
    OrderTrackModal:{
        '& #ModalActions':{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '0px !important',
            '& .MuiButtonBase-root':{
                textTransform: 'capitalize',
                color: '#196cdb',
            },
            '& .MuiButtonBase-root:hover':{
                backgroundColor: '#196cdb1c',
            }
        }
    },
    OrderTrackForm:{
        '& .MuiPaper-root':{
            backgroundColor: 'transparent !important',
            boxShadow: 'unset !important',
        },
        '& .MuiCardContent-root':{
            padding: '0px !important'
        }
    },
    StateColorGreen: {
        '& .MuiChip-root ':{
            color: 'rgba(40, 121, 18, 0.87) !important',
            backgroundColor: 'rgba(63, 225, 17, 0.23) !important',
            fontSize: '1rem',
            border: '1px solid rgba(40, 121, 18, 0.87) !important',
            padding: '5px 10px 5px 10px !important',
        }
    },
    StateColorRed: {
        '& .MuiChip-root ':{
            color: 'rgba(121, 18, 18, 0.87) !important',
            backgroundColor: 'rgba(225, 17, 17, 0.23) !important',
            fontSize: '1rem',
            border: '1px solid rgba(121, 18, 18, 0.87) !important',
            padding: '5px 10px 5px 10px !important',
        }
    },
    StateColorYellow: {
        '& .MuiChip-root ':{
            color: '#eaa20f !important',
            backgroundColor: '#fff0bc !important',
            fontSize: '1rem',
            border: '1px solid #eaa20f !important',
            padding: '5px 10px 5px 10px !important',
        }
    },
    StateColorBlue: {
        '& .MuiChip-root ':{
            color: '#196cdb !important',
            backgroundColor: '#196cdb24 !important',
            fontSize: '1rem',
            border: '1px solid #196cdb !important',
            padding: '5px 10px 5px 10px !important',
        }
    },
    StateColorGrey: {
        '& .MuiChip-root ':{
            fontSize: '1rem',
            padding: '5px 10px 5px 10px !important',
            animation: 'StatePendingBlink 1s infinite',
        }
    },
    CancelButton:{
        justifyContent: 'flex-start', 
        marginRight: '10px',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& #cancelar':{
            textTransform: 'capitalize',
            border: '1px solid #dc004e',
            color: '#dc004e',
            marginLeft: '10px'
        },
        '& #cancelar:hover':{
            backgroundColor: '#dc004e1c'
        },
        '& #aceptar':{
            textTransform: 'capitalize',
            border: '1px solid rgba(40, 121, 18, 0.87)',
            color: 'rgba(40, 121, 18, 0.87)'
        },
        '& #aceptar:hover':{
            backgroundColor: 'rgba(40, 121, 18, 0.23)'
        }
    },
    BlueButton:{
        justifyContent: 'flex-start', 
        marginRight: '10px',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .MuiButton-root':{
            textTransform: 'unset !important',
            color: '#196cdb',
        },
        '& .MuiButton-root:hover':{
            backgroundColor: '#196cdb1c'
        }
    },
    TrackBlueButton:{
        marginTop: '30px',
        justifyContent: 'flex-start', 
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .MuiButton-root':{
            textTransform: 'unset !important',
            color: '#196cdb',
            backgroundColor: '#196cdb1c',
            padding: '15px 25px !important',
            borderRadius: '10px !important',
            border: '1px solid #196cdb',
        },
        '& .MuiButton-root:hover':{
            backgroundColor: '#196cdb1c',
            cursor: 'pointer',
            boxShadow: '1px 1px 5px #196cdb1c'
        },
        '& a':{
            textDecoration: 'none !important',
            color: '#196cdb !important',
        }
    },
    ProductsTableTitle:{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center'
    },
    ProductLink:{
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "inline-block",
        textOverflow: "ellipsis",
    }
});

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
        ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const MaterialImg = ({ id }) => {
    const { data, loading, error } = useGetOne('material', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (<img alt={data.title} src={data.logo.image}/>);
};

const MaterialName = ({ id }) => {
    const classes = useStyles();
    const { data, loading, error } = useGetOne('material', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (<Link to={`/material/${id}/show`} className={classes.ProductLink}>{data.title}</Link>);
};

const MaterialReference = ({ id }) => {
    const { data, loading, error } = useGetOne('material', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (data.reference);
};

const VariantUPC = ({ id }) => {
    const { data, loading, error } = useGetOne('variants', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return (data.ean)};
};

const VariantName = ({ id }) => {
    const { data, loading, error } = useGetOne('variants', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (data.name);
};

const UserCode = ({ User_id }) => {
    const { data, loading, error } = useGetOne('users', User_id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (<>&nbsp;-&nbsp;<span id="UserCode">{data.code}</span></>);
};

const OffersAndPromotionsShow = (props) => {
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const [update] = useUpdate();
    const refresh = useRefresh();
    const classes = useStyles();
    const { data } = useGetOne('orders', props.id);
    const [hasEdit, setHasEdit] = useState(false);
    const [updateTrack, setUpdateTrack] = useState(false);
    const [makeComment, setMakeComment] = useState(false);
    const [trackNumber, setTrackNumber] = useState('');
    const [trackLink, setTrackLink] = useState('');
    const [comment, setComment] = useState('');

    var fakeOrderTrackLink = '';
    var fakeOrderTrackNumber = '';

    const SubReference = ({ translateChoice, children, ...props }) => (
        <ReferenceField {...props}>{children}</ReferenceField>
    );

    const handleClose = () => {
        setUpdateTrack(false);
    };
    const handleOpen = () => {
        setUpdateTrack(true);
    };

    const handleClose2 = () => {
        setMakeComment(false);
    };
    const handleOpen2 = () => {
        setMakeComment(true);
    };
    
    if (data) {

        var objIndex = 0;
        var NewTotal = 0;
        const filteredArr = [];

        const columns = [
            { field: 'imagen', headerName: 'Imagen', editable: false, sortable: false,
                renderCell: (params) => {
                    return (<MaterialImg id={params.row.material_id}/>);
                }
            },
            { field: 'material_id', headerName: 'Nombre', editable: false, sortable: false,
                renderCell: (params) => {
                    return (<MaterialName id={params.value}/>);
                }
            },
            { field: 'referencia', headerName: 'Referencia', editable: false, sortable: false,
                renderCell: (params) => {
                    return (<MaterialReference id={params.row.material_id}/>);
                }
            },
            { field: 'upc', headerName: 'UPC', editable: false, sortable: false,
                renderCell: (params) => {
                    return (<VariantUPC id={params.row.variant_id}/>);
                }
            },
            { field: 'variant_id', headerName: 'Variante', editable: false, sortable: false,
                renderCell: (params) => {
                    return (<VariantName id={params.value}/>);
                }
            },
            { field: 'variant_price', headerName: 'Precio/Ud', editable: false, sortable: false,
                renderCell: (params) => {
                    return (<div>{params.value.toFixed(2)}&nbsp;€</div>);
                }
            },
            { field: 'cantidad', headerName: 'Cantidad', editable: (permissions === "Admin" || permissions === "Transporte") ? true : false, sortable: false
            },
            { field: 'total_variant_price', headerName: 'Precio Total', editable: false, sortable: false,
                renderCell: (params) => {
                    return (<div>{params.value.toFixed(2)}&nbsp;€</div>);
                }
            },
            { field: 'acciones', headerName: 'Acciones', editable: false, sortable: false,
                renderCell: (params) => {
                    params.row.total_variant_price = params.row.variant_price * params.row.cantidad
                    if(filteredArr.findIndex((item) => item.variant_id === params.row.variant_id) === -1){
                        filteredArr.push(params.row);
                    }
                    objIndex = filteredArr.findIndex((obj => obj.variant_id === params.row.variant_id));
                    filteredArr[objIndex].cantidad = params.row.cantidad;
                    filteredArr[objIndex].total_variant_price = params.row.total_variant_price;
                    function UpdateProducts(){
                        for(var i = 0; i < filteredArr.length; i++){
                            NewTotal += filteredArr[i].total_variant_price;
                        }
                        update('orders', data.id, {_id: data.id, products: filteredArr, total_price: NewTotal});
                        setHasEdit(false);
                        refresh();
                    }

                    return(
                        <>
                            {hasEdit === true &&
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="save" arrow>
                                    <IconButton aria-label="save-changes" component="span" onClick={() => UpdateProducts()}>
                                        <SaveRoundedIcon />
                                    </IconButton>
                                </MyTooltip>
                            }
                        </>
                    )
                }
            },
        ];

        function SetEstado(newState, orderTrackNumber, orderTrackLink, comment){
            const objectModule = {
                Token: data.tocken,
                State: newState,
                OrderURL: `https://${env.REACT_APP_DOMAIN}/#/orders/${data.id}/show`,
                OrderTrackingNumber: orderTrackNumber, 
                OrderTrackingLink: orderTrackLink,
                Comment: comment,
                UserId: data.user_id,
            }
            dataProvider.sendOrderStateEmail('orders', objectModule);

            if(newState === 'Aceptado'){
                update('orders', data.id, {_id: data.id, state: newState, comment: comment});
                refresh();
            }
            if(newState === 'Enviado'){
                update('orders', data.id, {_id: data.id, state: newState, tracking_number: orderTrackNumber, tracking_link: orderTrackLink});
                refresh();
            } else{
                update('orders', data.id, {_id: data.id, state: newState});
                refresh();
            }
            refresh();
        };

        const OrderEditProps = {
            basePath: "/orders",
            resource: "orders",
            id: data.id
        }

        function handleCellEdit(){
            setHasEdit(true)
        }

        const OffersViewActions = ({ basePath, resource }) => {
            return(
                <TopToolbar className={classes.NewsTopToolbar} id="GoogleStyleToolBar">
                    {data &&
                        <Box className={classes.Breadcrumb}>
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Volver Atrás</div>} arrow>
                                <ToolbarBackButton style={{paddingLeft: '17px', paddingRight: '5px', marginRight: '10px'}} id="GoogleStyleBackButton"></ToolbarBackButton>
                            </MyTooltip>
                            <Box id="ActionsBar">
                                <Box id="Main_breadcrumb">
                                    {(permissions === "Admin" || permissions === "Transporte") &&
                                        <Box>
                                            <MyTooltip title={<div style={{fontSize: '12px'}}>Volver a la lista de pedidos</div>} arrow>
                                                <Link to={`/orders`}>
                                                    <Typography variant="body1" id="breadcrumbTitle">Pedidos Consumibles</Typography>
                                                </Link>
                                            </MyTooltip>
                                        </Box>
                                    }
                                    {(permissions === "User" || permissions === "Central" || permissions === "Publicador") &&
                                        <Box>
                                            <MyTooltip title={<div style={{fontSize: '12px'}}>Volver al Perfil de Usuario</div>} arrow>
                                                <Link to={`/profile`}>
                                                    <Typography variant="body1" id="breadcrumbTitle">Mis Pedidos</Typography>
                                                </Link>
                                            </MyTooltip>
                                        </Box>
                                    }
                                    <NavigateNextRoundedIcon id="navigationArrow"/>
                                    <Box>
                                        <Typography variant="body1" id="breadcrumbTitle">{data.tocken}</Typography>
                                    </Box>
                                    <NavigateNextRoundedIcon id="navigationArrow"/>
                                    <Box>
                                        <Typography variant="body1" id="breadcrumbTitle">{data.state}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {(permissions === "Admin" || permissions === "Transporte")  &&
                                <Box style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row-reverse'}}>
                                    <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Pedido</div>} arrow>
                                        <DeleteButton label="" basePath={basePath} record={data} resource={resource} id="GoogleStyleDeleteButton"/>
                                    </MyTooltip>           
                                    <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Pedido</div>} arrow>
                                        <EditButton label="" type="link" to={`/orders/${props.id}/edit`} id="GoogleStyleButton"/>
                                    </MyTooltip>
                                    <Divider orientation="vertical" flexItem style={{marginLeft: '10px', marginRight: '10px', marginTop: '6px', marginBottom: '6px'}}/>
                                    <MyTooltip title={<div style={{fontSize: '12px'}}>Generar Albarán</div>} aria-label="GoBack" arrow>
                                        <Button id="GoogleStyleBackButton" startIcon={<PrintRoundedIcon id="Pdf-Icon"/>} onClick={() => {generatePDF(); refresh()}}></Button>
                                    </MyTooltip>
                                </Box>
                            }
                        </Box>
                    }
                </TopToolbar>
            )
        };

        return (
            <div>
                {data &&
                <Box>
                    <Show {...props} actions={<OffersViewActions />}>
                        <SimpleShowLayout style={{padding: '10px'}} >
                            <Box>
                                <Box>
                                    <Box p={1} style={{ width: '100%', justifyContent:'flex-start', display: 'flex'}}>
                                        <Box style={{justifyContent: 'flex-start', width: '50%', marginRight: '10px'}}>
                                            <Typography variant="h5" style={{marginBottom: '1.5vw', textAlign: 'left'}}>Pedido&nbsp;
                                                "<TextField source="tocken" style={{fontSize: '1.5rem', color: '#3d3d3d'}} id="Pedido_Tocken"/>"
                                            </Typography>
                                        </Box>
                                        <Box style={{justifyContent: 'flex-end', width: '50%', marginRight: '10px'}}>
                                            <Typography variant="h5" style={{marginBottom: '1.5vw', textAlign: 'right'}}>
                                                {data.state === 'Aceptado' &&
                                                    <Box className={classes.StateColorBlue}>
                                                        <ChipField source="state"/>
                                                    </Box>
                                                }
                                                {data.state === 'Cancelado' &&
                                                    <Box className={classes.StateColorRed}>
                                                        <ChipField source="state"/>
                                                    </Box>
                                                }
                                                {data.state === 'Enviado' &&
                                                    <Box className={classes.StateColorGreen}>
                                                        <ChipField source="state"/>
                                                    </Box>
                                                }
                                                {data.state === 'Entregado' &&
                                                    <Box className={classes.StateColorGreen}>
                                                        <ChipField source="state"/>
                                                    </Box>
                                                }
                                                {data.state === 'Sin Confirmar' &&
                                                    <Box className={classes.StateColorGrey}>
                                                        <ChipField source="state"/>
                                                    </Box>
                                                }
                                                {data.state === 'Devuelto' &&
                                                    <Box className={classes.StateColorRed}>
                                                        <ChipField source="state"/>
                                                    </Box>
                                                }
                                            </Typography>
                                            {data.state === 'Sin Confirmar' && (permissions === "Admin" || permissions === "Transporte")  &&
                                                <Box className={classes.CancelButton}>
                                                    <Button onClick={() => SetEstado("Cancelado")} id="cancelar" startIcon={<HighlightOffRoundedIcon/>}>
                                                        Cancelar
                                                    </Button>
                                                    <Button onClick={handleOpen2} id="aceptar" startIcon={<CheckCircleOutlineRoundedIcon/>}>
                                                        Aceptar
                                                    </Button>
                                                    <Dialog open={makeComment} onClose={handleClose2} className={classes.OrderTrackModal}>
                                                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose2}>
                                                            Añadir un Comentario
                                                        </BootstrapDialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                Si no hay stock de algo o va a tardar más de lo normal en ser enviado, este es el momento de
                                                                decírselo al socio.
                                                            </DialogContentText>
                                                            <Edit {...OrderEditProps} actions={false} className={classes.OrderTrackForm}>
                                                                <SimpleForm toolbar={false}>
                                                                    <TextInput
                                                                        id="comment"
                                                                        label="Comentario"
                                                                        multiline="true" fullWidth
                                                                        variant="outlined"
                                                                        source="comment"
                                                                        className={classes.BlackInput}
                                                                    />
                                                                    <FormDataConsumer>
                                                                        {({ formData}) => {
                                                                            setComment(formData.comment);
                                                                        }}
                                                                    </FormDataConsumer>
                                                                </SimpleForm>
                                                            </Edit>
                                                            <DialogActions id="ModalActions">
                                                                <Button onClick={() => {SetEstado("Aceptado", fakeOrderTrackLink, fakeOrderTrackNumber, comment); handleClose2()}}>
                                                                    Aceptar
                                                                </Button>
                                                            </DialogActions>
                                                        </DialogContent>
                                                    </Dialog>
                                                </Box>
                                            }
                                            {data.state === 'Aceptado' && (permissions === "Admin" || permissions === "Transporte")  &&
                                                <Box className={classes.BlueButton}>
                                                    <Button onClick={handleOpen} id="enviar">
                                                        Marcar como enviado
                                                    </Button>
                                                    <MyTooltip title={<div style={{fontSize: '12px'}}>Reiniciar Proceso</div>} aria-label="GoBack" arrow>
                                                        <Button 
                                                            style={{paddingLeft: '17px', paddingRight: '5px', marginRight: '10px'}} id="GoogleStyleBackButton" 
                                                            startIcon={<RestartAltRoundedIcon/>} onClick={() => SetEstado("Sin Confirmar")}>
                                                        </Button>
                                                    </MyTooltip>
                                                    <Dialog open={updateTrack} onClose={handleClose} className={classes.OrderTrackModal}>
                                                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                                            Seguimiento del pedido
                                                        </BootstrapDialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                Introduce el Nº de seguimiento del Pedido y el enlace a la página del seguimiento
                                                                para que el socio pueda acceder al estado del pedido.
                                                            </DialogContentText>
                                                            <Edit {...OrderEditProps} actions={false} className={classes.OrderTrackForm}>
                                                                <SimpleForm toolbar={false}>
                                                                    <TextInput
                                                                        id="tracking_number"
                                                                        label="Nº de Seguimiento"
                                                                        multiline="true" fullWidth
                                                                        variant="outlined"
                                                                        source="tracking_number"
                                                                        className={classes.BlackInput}
                                                                    />
                                                                    <TextInput
                                                                        id="tracking_link"
                                                                        label="Link de Seguimiento"
                                                                        multiline="true" fullWidth
                                                                        variant="outlined"
                                                                        source="tracking_link"
                                                                        className={classes.BlackInput}
                                                                    />
                                                                    <FormDataConsumer>
                                                                        {({ formData}) => {
                                                                            setTrackNumber(formData.tracking_number);
                                                                            setTrackLink(formData.tracking_link);
                                                                        }}
                                                                    </FormDataConsumer>
                                                                </SimpleForm>
                                                            </Edit>
                                                            <DialogActions id="ModalActions">
                                                                <Button onClick={() => {SetEstado("Enviado", trackNumber, trackLink); handleClose()}}>
                                                                    Confirmar & enviar
                                                                </Button>
                                                            </DialogActions>
                                                        </DialogContent>
                                                    </Dialog>
                                                </Box>
                                            }
                                            {data.state === 'Enviado' &&
                                                <Box className={classes.BlueButton}>
                                                    <Button onClick={() => SetEstado("Entregado")} id="entregar">
                                                        Confirmar Recepción
                                                    </Button>
                                                    {(permissions === "Admin" || permissions === "Transporte")  &&
                                                        <MyTooltip title={<div style={{fontSize: '12px'}}>Reiniciar Proceso</div>} aria-label="GoBack" arrow>
                                                            <Button 
                                                                style={{paddingLeft: '17px', paddingRight: '5px', marginRight: '10px'}} id="GoogleStyleBackButton" 
                                                                startIcon={<RestartAltRoundedIcon/>} onClick={() => SetEstado("Sin Confirmar")}>
                                                            </Button>
                                                        </MyTooltip>
                                                    }
                                                </Box>
                                            }
                                            {data.state === 'Entregado' && (permissions === "Admin" || permissions === "Transporte")  &&
                                                <Box className={classes.BlueButton}>
                                                    <Button onClick={() => SetEstado("Devuelto")} id="devolbver">
                                                        Marcar como devolución
                                                    </Button>
                                                    <MyTooltip title={<div style={{fontSize: '12px'}}>Reiniciar Proceso</div>} aria-label="GoBack" arrow>
                                                        <Button 
                                                            style={{paddingLeft: '17px', paddingRight: '5px', marginRight: '10px'}} id="GoogleStyleBackButton" 
                                                            startIcon={<RestartAltRoundedIcon/>} onClick={() => SetEstado("Sin Confirmar")}>
                                                        </Button>
                                                    </MyTooltip>
                                                </Box>
                                            }
                                            {(data.state === 'Devuelto' || data.state === 'Cancelado') && (permissions === "Admin" || permissions === "Transporte")  &&
                                                <Box className={classes.BlueButton}>
                                                    <Button onClick={() => SetEstado("Sin Confirmar")} id="reset">
                                                        Reiniciar
                                                    </Button>
                                                </Box>
                                            }
                                        </Box>
                                        
                                    </Box>
                                    {(permissions === "Admin" || permissions === "Transporte")  &&
                                        <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin:'-25px 10px 10px 0px', display: 'flex'}}>
                                            <RoomRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Dirección de Envio
                                        </Typography>
                                    }
                                    {(permissions === "User" || permissions === "Central" || permissions === "Publicador")  &&
                                        <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', marginBottom: 15, display: 'flex'}}>
                                            <RoomRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Dirección de Envio
                                        </Typography>
                                    }
                                    <Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px'}} />
                                    <Box p={1} style={{ width: '100%', justifyContent:'flex-start', display: 'flex'}}>
                                        <Box style={{justifyContent: 'flex-start', width: '50%'}}>
                                            <ReferenceField label="" source="user_address" reference="address" link={false}>
                                                <TextField source="user_name" style={{fontSize: '1.1rem'}} id="AddressToName"/>
                                            </ReferenceField>&nbsp;
                                            <ReferenceField label="" source="user_address" reference="address" link={false}>
                                                <TextField source="user_lastname" style={{fontSize: '1.1rem'}} id="AddressToLastName"/>
                                            </ReferenceField>
                                            <UserCode User_id={data.user_id} /><br/><br/>
                                            <ReferenceField label="" source="user_address" reference="address" link={false}>
                                                <TextField source="user_address" style={{fontSize: '1.0rem'}} id="Address"/>
                                            </ReferenceField><br/>
                                            <Typography style={{fontSize: '1.0rem'}}>
                                                <ReferenceField source="user_address" reference="address" link={false}>
                                                    <SubReference source="state" reference="state" link={false}>
                                                        <TextField source="name" style={{fontSize: '1.0rem'}} id="AddressState"/>
                                                    </SubReference>
                                                </ReferenceField>,&nbsp; 
                                                <ReferenceField label="" source="user_address" reference="address" link={false}>
                                                    <TextField source="location" style={{fontSize: '1.0rem'}} id="AddressLocation"/>
                                                </ReferenceField>&nbsp; 
                                                <ReferenceField label="" source="user_address" reference="address" link={false}>
                                                    <TextField source="CP" style={{fontSize: '1.0rem'}} id="AddressCP"/>
                                                </ReferenceField><br/>
                                                <ReferenceField source="user_address" reference="address" link={false}>
                                                    <SubReference source="country" reference="country" link={false}>
                                                        <TextField source="name" style={{fontSize: '1.0rem'}} id="AddressCountry"/>
                                                    </SubReference>
                                                </ReferenceField>
                                            </Typography>
                                        </Box>
                                        <Box p={1} style={{justifyContent: 'flex-end', width: '50%', marginRight: '10px'}}>
                                            <Typography style={{textAlign: 'right', fontSize: '1.1rem'}}>Fecha:&nbsp;
                                                <DateField source="createdate" locales="fr-FR" style={{color: '#3d3d3d', fontSize: '1.1rem'}} id="Fecha_Pedido"/>
                                            </Typography>
                                            <TextField source="albaran" id="Albaran_Pedido" style={{display: 'none'}}/>
                                            {data.state === 'Enviado' && data.tracking_link &&
                                                <Box className={classes.TrackBlueButton}>
                                                    <Button>
                                                        <a href={data.tracking_link}>
                                                            Seguimiento del Pedido
                                                        </a>
                                                    </Button>
                                                </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                <Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px', marginTop: '25px'}} />
                                <Box className={classes.ProductsTableTitle}>
                                    <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, display: 'flex'}}>
                                        <ShoppingCartRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Productos
                                    </Typography>
                                </Box>
                                <div style={{width: 'auto', paddingLeft: '10px', paddingRight: '10px'}} id="Products_Table">
                                    <DataGrid
                                        className={classes.OrdersMaterialTable} 
                                        id="Products_Table"
                                        columns={columns}
                                        rows={data.products}
                                        getRowId={(row) => row.variant_id}
                                        pageSize={data.products.length}
                                        rowsPerPageOptions={[5]}
                                        disableColumnMenu
                                        autoHeight
                                        hideFooter={true}
                                        hideFooterPagination={true}
                                        disableSelectionOnClick
                                        disableColumnSelector
                                        onCellEditCommit={() => handleCellEdit()}
                                    />
                                </div>
                                <Box style={{paddingTop: '25px', paddingBottom: '20px', marginLeft: '15px'}}>
                                    <Typography style={{textAlign: 'left', fontSize: '1.0rem'}}>Subtotal:&nbsp; 
                                        <NumberField options={{ style: 'currency', currency: 'EUR' }} style={{fontSize: '1.0rem', color: '#b63615'}} source="total_price" /> &nbsp;+ Portes
                                    </Typography>
                                </Box>
                            </Box>
                        </SimpleShowLayout>
                    </Show>
                </Box>
                }
            </div>
        );
    } else {
        return null
    }
}; export default OffersAndPromotionsShow;