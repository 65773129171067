import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  makeStyles
} from "@material-ui/core";
import firebase from "firebase";
import { useTranslate, useNotify } from 'react-admin';

const useStyles = makeStyles({
  InputBlackBorder: {
    marginTop: '10px',
    marginBottom: '10px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#3d3d3d',
        },
        '&:hover fieldset': {
            borderColor: '#3d3d3d',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#3d3d3d',
        },
    },
  },
  ForgotButton:{
    paddingRight: '3em !important',
    '& button': {
      textTransform: 'capitalize',
      backgroundColor: 'transparent !important',
      color: '#196cdb !important',
      width: 'auto !important',
      margin: '0 !important',
    },
    '& button:hover': {
      backgroundColor: '#196cdb2e !important',
    }
  },
  CancelButton:{
    '& button': {
      textTransform: 'capitalize',
      backgroundColor: 'transparent !important',
      color: '#196cdb !important',
      width: 'auto !important',
      margin: '0 !important'
    },
    '& button:hover': {
      backgroundColor: '#196cdb2e !important',
    }
  },
  SendButton:{
    '& button': {
      textTransform: 'capitalize',
      backgroundColor: '#196cdb !important',
      transition: 'none !important'
    },
    '& button:hover': {
      backgroundColor: '#196cdbd6 !important',
    }
  }
});

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const translate = useTranslate();
  const notify = useNotify();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      setOpen(false);
      notify(translate('ra.message.recover_password_email_sent'), 'success');
    } catch (error) {
      notify(error.message, 'error');
    }
  };

  const handleOnChange = (event) => {
    const email = event.target.value;
    setEmail(email);
  };
  const classes = useStyles();

  return (
    <div>
      <div className={classes.ForgotButton}>
        <Button
            type="button"
            color="primary"
            fullWidth
            style={{ width: 268 }}
            onClick={handleClickOpen}
        >
            {translate('ra.auth.recover_password')}
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate('ra.auth.reset_password')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {translate('ra.auth.reset_password_label')}
          </DialogContentText>
          <TextField
            id="outlined-basic"
            label="Email"
            type="email"
            variant="outlined"
            style={{width: '100%'}}
            className={classes.InputBlackBorder}
            onChange={handleOnChange}
          />
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'flex-start', marginLeft: '10px', marginBottom: '10px'}}>
          <div className={classes.CancelButton}>
            <Button onClick={handleClose} >Cancelar</Button>
          </div>
          <div className={classes.SendButton}>
            <Button onClick={handleSubmit} color="primary" autoFocus>
              Enviar Email
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
