import React, {useState} from "react";
import { Create, SimpleForm, TextInput, ImageInput, ImageField, ReferenceInput, SelectInput, Toolbar, ReferenceArrayInput,
  SaveButton, required, NumberInput, BooleanInput, SelectArrayInput, FormDataConsumer } from "react-admin";
import { makeStyles, Typography, Box, Divider, CircularProgress } from '@material-ui/core';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import TinyMCETextEditor from '../components/TextEditors/TinyMCETextEditor';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';
import '../index.css';

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  EditToolBar: lightTheme.EditToolBar,
  SelectInputs:{
    '& .MuiOutlinedInput-root':{
      height: '57px',
    },
  },
});

const ProviderCreateToolbar = ({loading, ...props}) => {
  const classes = useStyles();
  return (
    <Box>
      <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Consumible</div>} aria-label="Save" arrow placement="right">
          <SaveButton id="GoogleStyleSaveButton" label="Crear" icon={<AddRoundedIcon/>}>
            {loading && ( <CircularProgress/> )}
          </SaveButton>
        </MyTooltip>
      </Toolbar>
    </Box>
  )
};

const MaterialCreate = ({ permissions, ...props }) => {
  const classes = useStyles();
  const [mDescription, setMDescription] = useState("");
  const [mShipping, setMShipping] = useState("");
  const descriptionCopy = (props.location.search);
  const shippingCopy = (props.location.search);

  const TypeChoices=[ 
    { id: 'puntodeventa', name: 'Punto de Venta' }, 
    { id: 'consumible', name: 'Consumible' }
  ] 

  return(
    <Create {...props}>
      <SimpleForm toolbar={<ProviderCreateToolbar/>} defaultValue={{ descriptionCopy: descriptionCopy, shippingCopy: shippingCopy }}>
        <Box style={{ width:'100%', color: '#3d3d3d' }}>
          <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
            <Box p={1} width="100%">
              <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Nuevo Consumible</Typography>
            </Box>
            <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
              </MyTooltip>
            </Box>
          </Box>
          <Box >
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <DescriptionRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>
              Detalles del Consumibles
            </Typography>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Título" source="title" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()} style={{ width: '60%' }}/>
              <TextInput label="Referencia" source="reference" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()} style={{ width: '40%' }}/>
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start" className={classes.SelectInputs}>
              <ReferenceInput label="Categoría" source="shop" reference="shops" variant="outlined" className={classes.InputBlackBorder} validate={required()}>
                <SelectInput source="name" style={{ width: '50%' }}/>
              </ReferenceInput>
              <ReferenceArrayInput label="División del Producto" source="division" reference="division" variant="outlined" className={classes.InputBlackBorder} validate={required()}>
                <SelectArrayInput source="name" style={{ width: '50%'}}/>
              </ReferenceArrayInput>
              <SelectInput required label="Tipo de Producto" variant="outlined" source="type" 
                multiline="true" fullWidth className={classes.InputBlackBorder} choices={TypeChoices}/>
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <SubjectRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>
              Descripción
            </Typography>
            <Box style={{display:'none'}}>
                <NumberInput step={0.1} label="Cantidad" source="stock" variant="outlined" fullWidth className={classes.InputBlackBorder} style={{ width: '48%' }} min={0}/>
                <BooleanInput label="Disponible en central" source="stock_filter" className={classes.InputBlackBorder} defaultValue={false} style={{width: '30%', paddingTop: '8px'}}/>
            </Box>
            
            <TextInput source="descriptionCopy" multiline="true" style={{width: '100%', display: 'none'}}/>
            <TinyMCETextEditor data={mDescription} source="TextValue1"/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                  setMDescription(formData.descriptionCopy);
                  formData.description = formData.TextValue1;
                }}
            </FormDataConsumer>

            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, marginTop: 25, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <SubjectRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>
              Información del Envio
            </Typography>

            <TextInput source="shippingCopy" multiline="true" style={{width: '100%', display: 'none'}}/>
            <TinyMCETextEditor data={mShipping} source="TextValue2"/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                  setMShipping(formData.shippingCopy);
                  formData.shipping = formData.TextValue2;
                  formData.type = 'consumible';
                }}
            </FormDataConsumer>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
            <Box style={{paddingLeft: '10px', paddingRight: '10px'}}>
              <ImageInput source="logo" label="Imagen del Consumible" accept="image/*" >
                <ImageField source="image" title="imageName" style={{border: '1px solid rgb(208, 203, 203)', borderRadius: '10px'}}/>
              </ImageInput>
            </Box>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}; export default MaterialCreate;