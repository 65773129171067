import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { MenuItemLink, usePermissions, useTranslate } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core";
import SubMenu from './SubMenu';
import lightTheme from "./themes";

import DashboardIcon from '@material-ui/icons/DashboardRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import ProviderIcon from '@material-ui/icons/DomainRounded';
import DesktopWindowsRoundedIcon from '@mui/icons-material/DesktopWindowsRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import PublicationsIcon from '@mui/icons-material/ForumRounded';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

import { /*mdiFolderOpenOutline*/ mdiTagMultipleOutline, mdiFileDocumentOutline, mdiNewspaper, mdiShoppingOutline } from '@mdi/js';
import Icon from '@mdi/react'

// const MaterialIcon = () => {return(<Icon path={mdiFolderOpenOutline } size={1}  />)};
const OffersIcon = () => {return(<Icon path={mdiTagMultipleOutline} size={1} />)};
const NewsIcon = () => {return(<Icon path={mdiNewspaper} size={1} />)};
const ConditionsIcon = () => {return(<Icon path={mdiFileDocumentOutline} size={1} />)};
const ShopIcon = () => {return(<Icon path={mdiShoppingOutline} size={1} />)};


const useStyles = makeStyles({
    active: lightTheme.menu.Active,
    CopyrightFooter: {
        fontSize: '12px',
        lineHeight: '16px',
        fontFamily: 'Roboto,Arial,sans-serif',
        fontWeight: '400',
        letterSpacing: '0.3px',
        paddingLeft: '10px',
        paddingBottom: '10px',
        paddingTop: '10px',
        paddingRight: '70px',
        backgroundColor: '#fbfbfb',
        zIndex: '1000',
        display: 'block',
        width: '160px',
        height: '10%',
        position: 'fixed',
        bottom: '0px',
        '& a':{
            textDecoration: 'none',
            color: '#196cdb',
        }
    },
    FixedSideNavBar: {
        zIndex: '1000',
        position: 'fixed',
        left: '175px',
        top: '60px',
        bottom: '0px',
        marginLeft: '-175px',
        overflowY: 'auto',
        scrollbarWidth: 'thin !important',
        scrollbarColor: '#acacac #fbfbfb',
        paddingRight: '5px',
        width: '250px',
        transition: 'width 195ms ease-in-out',
        '& .MuiButtonBase-root':{
            transition: 'width 195ms ease-in-out',
        },
        '& .MuiButtonBase-root:hover:active::after':{
            backgroundColor: 'rgba(25, 108, 219, 0.58)',
        },
        '& span':{
            display: 'flex',
            alignItems: 'center',
        }
    },
});

const Menu = ({ onMenuClick, logout, dense }) => {
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const classes = useStyles();
    const { permissions } = usePermissions();
    const translate = useTranslate();

    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });
    const handleToggle = (menu => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    });

    return (
        <div className={classes.FixedSideNavBar} id="FixedSideNavBar">
            {<MenuItemLink
                to={`/dashboard`}
                primaryText="Escritorio"
                leftIcon={<DashboardIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                className={classes.active}
                activeStyle={{
                    backgroundColor: 'rgba(25, 108, 219, 0.14)',
                    borderTopRightRadius: '25px',
                    borderBottomRightRadius: '25px',
                    borderTop: '1px solid #196cdb',
                    borderBottom: '1px solid #196cdb',
                    borderRight: '1px solid #196cdb',
                    color: '#196cdb',
                    marginTop: '5px',
                    marginBottom: '5px',
                    marginRight: '5px',
                    fontSize: '14px',
                    width: '240px',
                    transition: 'width 195ms ease-in-out',
                }}
            />}
            <SubMenu
                handleToggle={() => handleToggle('menuProveedores')}
                isOpen={state.menuProveedores}
                sidebarIsOpen={open}
                icon={<PublicRoundedIcon />}
                name={translate('Actualidad.name')}
                dense={dense}
            >
                {<MenuItemLink
                    to={`/news`}
                    primaryText="Noticias"
                    leftIcon={<NewsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />}
                {<MenuItemLink
                    to={`/calendar`}
                    primaryText="Calendario Trendico"
                    leftIcon={<CalendarTodayOutlinedIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />}
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuMaterial')}
                isOpen={state.menuMaterial}
                sidebarIsOpen={open}
                icon={<WorkRoundedIcon />}
                name={translate('Comercial.name')}
                dense={dense}
            >
                {<MenuItemLink
                    to={`/shops`}
                    primaryText="Proveedores"
                    leftIcon={<ProviderIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />}
                {<MenuItemLink
                    to={`/conditions`}
                    primaryText="Condiciones"
                    leftIcon={<ConditionsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />}
                {<MenuItemLink
                    to={`/offersAndPromotions`}
                    primaryText="Ofertas & Promociones"
                    leftIcon={<OffersIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />}
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('MenuEvents')}
                isOpen={state.MenuEvents}
                sidebarIsOpen={open}
                icon={<CampaignRoundedIcon />}
                name={translate('Marketing.name')}
                dense={dense}
            >
                {<MenuItemLink
                    to={`/comunicados`}
                    primaryText="Comunicados"
                    leftIcon={<NewReleasesOutlinedIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />}
                {<MenuItemLink
                    to={`/material`}
                    primaryText="Consumibles"
                    leftIcon={<ShopIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />}
                {<MenuItemLink
                    to={`/informacion`}
                    primaryText="Punto de Venta"
                    leftIcon={<StorefrontOutlinedIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />}
                {/*<MenuItemLink
                    to={`/filesCategories`}
                    primaryText="Material Coorporativo"
                    leftIcon={<MaterialIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />*/}
            </SubMenu>
            {<MenuItemLink
                to={`/informatica`}
                primaryText="Informática"
                leftIcon={<DesktopWindowsRoundedIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                className={classes.active}
                activeStyle={{
                    backgroundColor: 'rgba(25, 108, 219, 0.14)',
                    borderTopRightRadius: '25px',
                    borderBottomRightRadius: '25px',
                    borderTop: '1px solid #196cdb',
                    borderBottom: '1px solid #196cdb',
                    borderRight: '1px solid #196cdb',
                    color: '#196cdb',
                    marginTop: '5px',
                    marginBottom: '5px',
                    marginRight: '5px',
                    fontSize: '14px',
                    width: '240px',
                    transition: 'width 195ms ease-in-out',
                }}
            />}
            
            {(permissions === 'Central' || permissions === 'Publicador' || permissions === 'Admin') &&
                <MenuItemLink
                    to={`/publications`}
                    primaryText="Publicaciones"
                    leftIcon={<PublicationsIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />
            }
            {(permissions === 'Admin' || permissions === 'Central') &&
                <MenuItemLink
                    to={`/admin`}
                    primaryText="Panel de Administración"
                    leftIcon={<SecurityRoundedIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />
            }
            {permissions === 'Transporte' &&
                <MenuItemLink
                    to={`/orders`}
                    primaryText="Pedidos Consumibles"
                    leftIcon={<ContentPasteRoundedIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                    className={classes.active}
                    activeStyle={{
                        backgroundColor: 'rgba(25, 108, 219, 0.14)',
                        borderTopRightRadius: '25px',
                        borderBottomRightRadius: '25px',
                        borderTop: '1px solid #196cdb',
                        borderBottom: '1px solid #196cdb',
                        borderRight: '1px solid #196cdb',
                        color: '#196cdb',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '5px',
                        fontSize: '14px',
                        width: '240px',
                        transition: 'width 195ms ease-in-out',
                    }}
                />
            }
        </div>
    );
}

export default withRouter(Menu);