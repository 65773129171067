import FaQIcon from '@material-ui/icons/LocalOffer';
import FaQList from './FaQList';
import FaQEdit from './FaQEdit';
import FaQCreate from './FaQCreate';

const FaQModule = {
    list: FaQList,
    edit: FaQEdit,
    icon: FaQIcon,
    create: FaQCreate,
}; export default FaQModule;