import { firebaseConfig } from '../firebase';
import {
  FirebaseAuthProvider
} from 'react-admin-firebase';
import firebase from 'firebase';

const authProvider = FirebaseAuthProvider(firebaseConfig);

const authProviderObject =  {
    ...authProvider,
  
    getIdentity: async () => {

      const user = firebase.auth().currentUser;

      const fullName = user.displayName;

      return { fullName };
    },
  
    checkAuth: async () => {
      return firebase.auth().currentUser
        ? Promise.resolve()
        : Promise.reject();
    },

    getPermissions: async () => {
      const user = firebase.auth().currentUser; 
      const token = await user.getIdTokenResult();
      const role = token.claims.role;

      return role ? Promise.resolve(role) : Promise.reject();
    },

    getUserData: async () => {
      const user = firebase.auth().currentUser; 
      const token = await user.getIdTokenResult();
      const userClaims = token.claims;

      return userClaims ? Promise.resolve(userClaims) : Promise.reject();;
    },
  };
export default authProviderObject;