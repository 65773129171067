import React, {useState} from "react";
import { 
  Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required, Toolbar, SaveButton,
  FormDataConsumer, SelectArrayInput, ReferenceArrayInput, DateInput, ArrayInput, SimpleFormIterator
} from "react-admin";
import { makeStyles, Typography, Box, Divider, CircularProgress, Button } from '@material-ui/core';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import BackButton from '../components/BackButton';
import MyTooltip from "../components/MuiTooltip";
import lightTheme from '../layout/themes';
import TinyMCETextEditor from '../components/TextEditors/TinyMCETextEditor'
import '../index.css';


const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  EditToolBar: lightTheme.EditToolBar,
  ContactArray: lightTheme.PointOfSaleStyle.CreateAndEdit.ContactArray,
});

const ProviderCreateToolbar = ({loading, ...props}) => {
  const classes = useStyles();
  return (
    <Box>
      <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Condición</div>} aria-label="Save" >
          <SaveButton disabled={props.pristine} id="GoogleStyleSaveButton" label="Crear" icon={<AddRoundedIcon/>}>
            {loading && ( <CircularProgress/> )}
          </SaveButton>
        </MyTooltip>
      </Toolbar>
    </Box>
  )
};

const DefaultAddButton = props => {
  const classes = useStyles(props);
  return (
      <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Dead Line</div>} aria-label="Delete" arrow placement="right">
          <Button size="small" {...props} id="GoogleStyleDownload">
              <AddRoundedIcon className={classes.leftIcon} />
          </Button>
      </MyTooltip>
  );
};

const DefaultRemoveButton = props => {
  const classes = useStyles(props);
  return (
      <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Dead Line</div>} aria-label="Delete" arrow placement="bottom">
          <Button size="small" {...props} id="GoogleStyleMuiDeleteButton">
              <DeleteRoundedIcon className={classes.leftIcon} />
          </Button>
      </MyTooltip>
  );
};

const ConditionsCreate = ({permissions, ...props}) => {
  const classes = useStyles();
  const [mObservaciones, setMObservaciones] = useState("");
  const provider = (props.location.search);
  const shop = (props.location.search);
  const shops = (props.location.search);
  const observacionesCopy = (props.location.search);
  const StateChoices=[ 
    { id: 'borrador', name: 'Borrador' }, 
    { id: 'publicado', name: 'Publicado' }
  ] 
  return(
    <Create {...props}>
      <SimpleForm defaultValue={{ provider: provider, shop: shop, shops: shops, state: "borrador", observacionesCopy: observacionesCopy }} toolbar={<ProviderCreateToolbar/>}>
        <Box style={{ width: '100%' }}>
          <Box style={{ width:'100%', color: '#3d3d3d' }}>
            <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
              <Box p={1} width="100%">
                <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Nueva Condición</Typography>
              </Box>
              <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack">
                  <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                </MyTooltip>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <InfoRoundedIcon/>&nbsp;&nbsp;Información General
            </Typography>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                formData.shops = formData.shop
                return (
                  <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <ReferenceInput label="Proveedor" source="provider.id" reference="provider" className={classes.InputBlackBorder} validate={required()}
                    perPage={200} sort={{ field: 'name', order: 'ASC' }} filter={{shop: formData.shop}}>
                      <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }}/>
                    </ReferenceInput>
                    <ReferenceInput label="Enseña" source="shop" reference="shops" className={classes.InputBlackBorder} validate={required()}>
                      <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }}/>
                    </ReferenceInput>
                    <Box style={{display: 'none'}}>
                      <ReferenceInput label="Enseña" source="shops" reference="shops" className={classes.InputBlackBorder} validate={required()}>
                        <SelectInput optionText="id" optionValue="id" variant="outlined" style={{ width: '100%' }}/>
                      </ReferenceInput>
                    </Box>
                  </Box>
                ) 
              }}
            </FormDataConsumer>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <ReferenceInput label="Campaña" source="campaign" reference="campaigns"  className={classes.InputBlackBorder} validate={required()}>
                <SelectInput optionText="name" optionValue="id"  variant="outlined" style={{ width: '100%' }}/>
              </ReferenceInput>
              <SelectInput label="Estado" variant="outlined" source="state" validate={required()} style={{textTransform: 'capitalize'}}
                multiline="true" fullWidth className={classes.InputBlackBorder} choices={StateChoices}/>
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <ReferenceArrayInput label="Categoría" source="category" reference="categorys" variant="outlined" className={classes.InputBlackBorder} 
                perPage={200} sort={{ field: 'name', order: 'ASC' }} validate={required()}>
                <SelectArrayInput source="name" style={{ width: '100%'}}/>
              </ReferenceArrayInput>
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
            <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <LocalOfferIcon/>&nbsp;&nbsp;Descuentos
            </Typography>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Descuento General" source="dec_general" multiline="true" fullWidth variant="outlined" className={classes.InputBlackBorder} />
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Descuento Programación" source="dec_programacion" multiline="true" fullWidth  variant="outlined" className={classes.InputBlackBorder} />
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Descuento Reposición" source="dec_reposicion" multiline="true" fullWidth variant="outlined" className={classes.InputBlackBorder} />
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Descuento Concentración" source="dec_concentracion" multiline="true" fullWidth variant="outlined" className={classes.InputBlackBorder} />
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
            <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <AddRoundedIcon/>&nbsp;&nbsp;Extras
            </Typography>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Facturación" source="facturacion" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
              <TextInput label="Envío" source="envio" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Pago" source="pago" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/> 
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Portes" source="portes" multiline="true" fullWidth size="big" variant="outlined" className={classes.InputBlackBorder} />
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Pedido Mínimo" source="pedido_min" multiline="true" fullWidth size="big" variant="outlined" className={classes.InputBlackBorder} />
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
            <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 0, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <CalendarTodayRoundedIcon/>&nbsp;&nbsp;Fechas
            </Typography>
            <Box className={classes.ContactArray}>
              <ArrayInput source="dates" label="" style={{width: '100%', marginLeft: '10px', marginTop: '-10px'}}>
                <SimpleFormIterator addButton={<DefaultAddButton />} removeButton={<DefaultRemoveButton />}>
                    <FormDataConsumer>
                      {({ getSource, scopedFormData}) => {
                        return (
                          <Box>
                            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                              <DateInput label="Dead Line" source={getSource('deadLine')} variant="outlined" className={classes.InputBlackBorder} />
                              <DateInput label="Fecha Servicio" source={getSource('serviceDate')} variant="outlined" className={classes.InputBlackBorder} />
                            </Box>
                          </Box>
                        );
                      }}
                    </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
            <Typography  style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <SubjectRoundedIcon/>&nbsp;&nbsp;Observaciones
            </Typography>

            <TextInput source="observacionesCopy" multiline="true" style={{width: '100%', display: 'none'}}/>
            <TinyMCETextEditor data={mObservaciones} source="TextValue1"/>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                  setMObservaciones(formData.observacionesCopy);
                  formData.observaciones = formData.TextValue1;
                }}
            </FormDataConsumer>

          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}; export default ConditionsCreate;