import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Card, CardContent, Typography, Box, AppBar, Tabs, Tab, Divider, Grid, CardMedia } from '@material-ui/core';
import { useGetMany, useAuthProvider, LinearProgress, useGetOne } from 'react-admin';
import lightTheme from '../layout/themes';
import firebase from 'firebase';
import MyTooltip from '../components/MuiTooltip';
import OrdersTable from '../orders/OrdersTable';
import AddressList from '../address/AddressList';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';

const useStyles = makeStyles({
    MaterialUI_Tabs: lightTheme.ProfileStyles.MaterialUI_Tabs,
    Profile: lightTheme.ProfileStyles.Profile,
    Profile_InfoBlock: lightTheme.ProfileStyles.Profile_InfoBlock,
    Profile_ImageBlock: lightTheme.ProfileStyles.Profile_ImageBlock,
    Divider_Text: lightTheme.ProfileStyles.Divider_Text,
    UserInfoCard: lightTheme.ProfileStyles.UserInfoCard,
    UserInfo_Text: lightTheme.ProfileStyles.UserInfoCard.UserInfo_Text,
    UserInfo_Name: lightTheme.ProfileStyles.UserInfoCard.UserInfo_Name,
    UserInfo_Email: lightTheme.ProfileStyles.UserInfoCard.UserInfo_Email,
    UserInfoCard_Last: lightTheme.ProfileStyles.UserInfoCard.LastCard,
    MaterialUI_Icon: lightTheme.ProfileStyles.UserInfoCard.MaterialUI_Icon,
    ShopGrid_Card: lightTheme.ProfileStyles.ShopGrid_Card,
    Shop_Image: lightTheme.ProfileStyles.ShopGrid_Card.Shop_Image,
    LogoBox_FoM: lightTheme.ProfileStyles.ShopGrid_Card.LogoBox_FoM,
    LogoBox_Atleet: lightTheme.ProfileStyles.ShopGrid_Card.LogoBox_Atleet,
    LogoBox_Sotkatt: lightTheme.ProfileStyles.ShopGrid_Card.LogoBox_Sotkatt,
    LogoBox_Trendico: lightTheme.ProfileStyles.ShopGrid_Card.LogoBox_Trendico,
    Black_Text: lightTheme.ProfileStyles.ShopGrid_Card.Black_Text,
    UserCardBackground: lightTheme.ProfileStyles.UserCardBackground,
    CodeBox: lightTheme.ProfileStyles.CodeBox,
    UserCardImage: lightTheme.ProfileStyles.UserCardImage,
    EmptyInvoiceMediaCard: lightTheme.EmptyInvoiceMediaCard,
    EmptyBubble: lightTheme.EmptyBubble,
});

const ShopCompanyName = ({ id }) => {
    const { data, loading, error } = useGetOne('company', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (data.name);
};

const ShopsList = ({ shops }) => {
    const classes = useStyles();
    const { data } = useGetMany('pointOfSale', shops);
    if (data) {
        console.log(data)
        return (
            <Grid container spacing={2}>
                {data.map(pointOfSale => (
                    <Grid key={pointOfSale} xs={12} sm={4} md={6} lg={6} xl={6} item>
                        {pointOfSale && pointOfSale !== undefined &&
                            <Card className={classes.ShopGrid_Card} id="ListGrid">
                                <Box style={{width: '100%'}}>
                                    <Box style={{display: 'flex', width: '100%'}}>
                                        {pointOfSale.shop === '618bd896efea2b2049ada487' &&
                                            <div className={classes.LogoBox_FoM} id="ShopLogo">
                                                <Box className={classes.Shop_Image}>
                                                    <img src="../../static/images/Shops/FoMFavicon.png" alt="FoM" id="FoM"></img>
                                                </Box>
                                            </div>
                                        }
                                        {pointOfSale.shop === '618bd896efea2b2049ada488' &&
                                            <div className={classes.LogoBox_Atleet} id="ShopLogo">
                                                <Box className={classes.Shop_Image}>
                                                    <img src="../../static/images/Shops/AtleetFavicon.png" alt="Atleet" id="Atleet"></img>
                                                </Box>
                                            </div>
                                        }
                                        {pointOfSale.shop === '618bd896efea2b2049ada489' &&
                                            <div className={classes.LogoBox_Sotkatt} id="ShopLogo">
                                                <Box className={classes.Shop_Image}>
                                                    <img src="../../static/images/Shops/SotkattFavicon.png" alt="Sotkatt" id="Sotkatt"></img>
                                                </Box>
                                            </div>
                                        }
                                        {pointOfSale.shop === '618bd896efea2b2049ada486' &&
                                            <div className={classes.LogoBox_Trendico} id="ShopLogo">
                                                <Box className={classes.Shop_Image}>
                                                    <img src="../../static/images/Shops/TrendicoFavicon.png" alt="Trendico" id="Trendico"></img>
                                                </Box>
                                            </div>
                                        }
                                        <div className={classes.Black_Text}>
                                            <Typography variant="body1">
                                                <StoreRoundedIcon/> {pointOfSale.name}
                                            </Typography>
                                            <Typography variant="body2" id="CompanyName">
                                                <ShopCompanyName id={pointOfSale.company_id}/>
                                            </Typography>
                                            <Typography variant="body2">
                                                {pointOfSale.address}<br/>
                                                {pointOfSale.location}<br/>
                                            </Typography>
                                        </div>
                                    </Box>
                                </Box>
                            </Card>
                        }
                    </Grid>
                ))}
            </Grid>
        );
    }else{
        return(<LinearProgress/>)
    }
};

const UserShopsList = ({ userId }) => {
    const userData = useGetOne('users', userId);
    if(userData.data){
        var shops = userData.data.shop.map(shop => shop.shopName);
        if(userData.data.shop){
            return(
                <ShopsList shops={shops}/> 
            )
        }
        else {
            return(
                <LinearProgress/>
            )
        }
    }else{
        return(
            <LinearProgress/>
        )
    }
};

const StateName = ({ claims }) => {
    const result = claims.state
    const { data, loading, error } = useGetOne('state', result);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (data.name);
};

const CountryName = ({ claims }) => {
    const result = claims.country
    const { data, loading, error } = useGetOne('country', result);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (data.name);
};

const CompaniesNames = ({ companies }) => {
    const classes = useStyles();
    const { data } = useGetMany('company', companies);
    if(data && data.length > 0){
        return(
            <Box className={classes.UserInfo_Email}>
                {data.map(company => {
                    return(
                        <>
                            {company !== undefined &&
                                <>
                                    <span>{company.name ? company.name : ''}</span>
                                    <span className="divider">|</span>
                                </>
                            }
                        </>
                    )
                })}
            </Box>
        )
    }else{
        return(
            <LinearProgress/>
        )
    }
};

const CompaniesToShow = ({ userId }) => {
    const userData = useGetOne('users', userId);
    if(userData.data){
        var companies = userData.data.companies.map(company => company.id);
        companies.push(userData.data.company);
        if(userData.data.company && companies.length >= 1){
            return(
                <CompaniesNames companies={companies}/>
            )
        }
        else {
            return(
                <LinearProgress/>
            )
        }
    }else{
        return(
            <LinearProgress/>
        )
    }
};

let OrdersProps = {basePath: "/orders", resource: "orders",}
let AddresProps = {basePath: "/address", resource: "address",}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}



const UserProfile = (props) => {
    const classes = useStyles();
    const user = firebase.auth().currentUser;
    const auth = useAuthProvider();
    const [userClaims, setUserClaims] = useState();
    const [company, setCompany] = useState()
    const [dataCompany, setDataCompany] = useState(false)
    const [userData, setUserData] = useState(false)
    
    auth.getUserData().then(userData => {
        if (!userClaims) {
            setUserClaims(userData);
            setCompany(userData.company)
        }
    });
    const { data } = useGetOne('company', company)

    const [value, setValue] = React.useState(Number(props.location.search.slice(8) || 0));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    
    const CompanyDataUser = ({ name, location, iva, userId}) =>{
        if(userId && !userData){
            UserData(userId);
        }
        
        setDataCompany(data);
        if (!dataCompany) { return <LinearProgress /> }

        if (name) {
            return (<span>{dataCompany ? dataCompany.name : ''}</span>);
        } else if (iva) {
            return (<span>{dataCompany ? dataCompany.iva : ''}</span>);
        } else if (location) {
            return (<span>{dataCompany ? dataCompany.location : ''}</span>);
        }
    }

    const UserData = (userId) =>{
        const { data } = useGetOne('users', userId)
        setUserData(data);
    }

    return (
        <div className={classes.MaterialUI_Tabs}>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} centered indicatorColor="primary" textColor="primary" >
                    <Tab label="Mi Información" icon={<InfoRoundedIcon />} {...a11yProps(0)} />
                    <Tab label="Mis Pedidos" icon={<ShoppingCartRoundedIcon />} {...a11yProps(1)} />
                    <Tab label="Mis Direcciones" icon={<RoomRoundedIcon />} {...a11yProps(2)} />
                    <Tab label="Mis Facturas" icon={<ReceiptRoundedIcon />} {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Box style={{display: 'flex', width: '100%'}}>
                    <Box style={{display: 'flex', width: '39%'}}>
                        <Card className={classes.Profile}>
                            <CardContent className={classes.Profile_ImageBlock}>
                                {userClaims &&
                                    <Box className={classes.UserCardBackground}>
                                        <Box className={classes.CodeBox}>
                                            <p>Cod. Socio: <span>{userClaims.code}</span></p>
                                        </Box>
                                    </Box>
                                }
                                <Box className={classes.UserCardImage}>
                                    <img src="../../static/images/ProfileImage2.png" alt="User_Profile_Img"></img>
                                </Box>
                                {userClaims &&
                                    <Box style={{marginBottom: '-25px'}}>
                                        <Typography className={classes.UserInfo_Name}>
                                            {user.displayName}
                                        </Typography>
                                        <Box>
                                            { userClaims &&
                                                <CompaniesToShow userId={user.uid}/>
                                            }
                                        </Box>
                                    </Box>
                                }
                            </CardContent>
                        </Card>
                    </Box>
                    {userClaims &&
                        <Box style={{display: 'flex', width: '100%'}}>
                            <CardContent className={classes.Profile_InfoBlock}>
                                <Card className={classes.UserInfoCard} id="UserInfoCard">
                                    <div className={classes.MaterialUI_Icon}>
                                        <MyTooltip title={<div style={{fontSize: '12px'}}>Información Personal</div>} arrow>
                                            <AccountCircleIcon/>
                                        </MyTooltip>
                                    </div>
                                    <div className={classes.UserInfo_Text}>
                                        <Typography variant="body1">
                                            {user.displayName}<br/>
                                            {user.email}<br/>
                                            {userClaims.phone || ''}
                                        </Typography>
                                    </div>
                                </Card>
                                <Card className={classes.UserInfoCard} id="UserInfoCard">
                                    <div className={classes.MaterialUI_Icon}>
                                        <MyTooltip title={<div style={{fontSize: '12px'}}>Dirección de Envio</div>} arrow>
                                            <RoomRoundedIcon/>
                                        </MyTooltip>
                                    </div>
                                    <div className={classes.UserInfo_Text}>
                                        <Typography variant="body1">
                                            {userClaims.address || ''}<br/>
                                            <StateName claims={userClaims}/>, {userClaims.location || ''}<br/>
                                            <CountryName claims={userClaims}/>
                                        </Typography>
                                    </div>
                                </Card>
                                <Card className={classes.UserInfoCard_Last} id="UserInfoCard">
                                    <div className={classes.MaterialUI_Icon}>
                                        <MyTooltip title={<div style={{fontSize: '12px'}}>Sociedad</div>} arrow>
                                            <DomainRoundedIcon/>
                                        </MyTooltip>
                                    </div>
                                    <div className={classes.UserInfo_Text}>
                                        <Typography variant="body1">
                                        <CompanyDataUser name /><br/>
                                        <CompanyDataUser location /><br/>
                                            IVA: <CompanyDataUser iva />
                                        </Typography>
                                    </div>
                                </Card>
                            </CardContent>
                        </Box>
                    }
                </Box>
                <Box style={{marginTop: '40px', width: '100%'}}>
                    {user &&
                        <Box>
                            <Box style={{marginBottom: '50px', width: '100%'}}>
                                <Typography className={classes.Divider_Text}>
                                    Acceso a Tiendas
                                </Typography>
                                <Divider style={{marginTop: '-23px', marginLeft: '215px'}}/>
                            </Box>
                            <div style={{width: '100%'}}>
                                <UserShopsList userId={user.uid}/> 
                            </div>
                        </Box>
                    }
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <OrdersTable {...OrdersProps} filter={{ user_id: user.uid }} style={{marginTop: '15px'}}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AddressList {...AddresProps} filter={{ user: user.uid }} style={{marginTop: '15px'}}/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Box display="flex" justifyContent="center" style={{width: '100%'}}>
                    <Box textAlign="center" className={classes.EmptyBubble}>
                        <CardMedia className={classes.EmptyInvoiceMediaCard}></CardMedia>
                        <Typography variant="h6" style={{marginTop: '20px'}}>
                            Proximamente<br/>
                            Visualiza todas tus Facturas
                        </Typography>
                        <Typography variant="body" style={{marginTop: '20px'}}>
                            Estamos trabajando en ello...<br/>
                        </Typography>
                    </Box>
                </Box>
            </TabPanel>
            
        </div>
    )
}; export default UserProfile;