import React, {useState} from "react";
import { 
    Show,SimpleShowLayout, TextField, RichTextField, usePermissions, useGetOne, TopToolbar, Link, ChipField, useUpdate, useRefresh,
    DeleteButton, EditButton, CloneButton, LinearProgress, CreateButton, useGetList, useRedirect, useDataProvider
} from "react-admin";
import { Box, makeStyles, Divider, Typography, Button } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import ToolbarBackButton from '../components/ToolbarBackButton';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import FilesGrid from '../files/FilesGrid';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from "../layout/themes";
import CloneIcon from '@material-ui/icons/FilterNoneRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import moment from 'moment';
import firebase from 'firebase';
import env from "react-dotenv";
import 'moment/locale/es';
import { mdiFileOutline } from '@mdi/js'; 
import Icon from '@mdi/react'

const DraftIcon = () => {return(<Icon path={mdiFileOutline} size={0.9}  />)};

const useStyles = makeStyles({
    PostTitle: lightTheme.PostTitle,
    PostSubTitle: lightTheme.PostSubTitle,
    Breadcrumb: lightTheme.ConditionsStyles.ConditionsViewStyles.Breadcrumb,
    YellowStateChip: lightTheme.ConditionsStyles.ConditionsViewStyles.YellowStateChip,
    GreenStateChip: lightTheme.ConditionsStyles.ConditionsViewStyles.GreenStateChip,
    BodyBox: lightTheme.NewsStyles.NewsShow.BodyBox,
    NewsCard: lightTheme.NewsStyles.NewsShow.NewsCard,
    ImageOverlay: lightTheme.NewsStyles.NewsShow.ImageOverlay,
    ShopsBar: lightTheme.NewsStyles.NewsShow.ShopsBar,
    RelatedNews: lightTheme.NewsStyles.NewsShow.RelatedNews,
    ShowStyles: lightTheme.NewsStyles.NewsShow.ShowStyles,
    NewsTopToolbar: lightTheme.NewsStyles.NewsShow.NewsTopToolbar,
    StateAlert:{
        width: '100%',
        marginTop: '-5px',
        '& .MuiAlert-standardWarning':{
            border:'1px solid #ffaa2f',
        }
    },
    AttachedFilesBox: lightTheme.ConditionsStyles.ConditionsViewStyles.ConditionsAtachedFilesBox
});

const MyAlert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
  
function TransitionLeft(props) {
    return <Slide {...props} direction="right" />;
}

const SubCategoryName = ({ id }) => {
    const { data, loading, error } = useGetOne('newsSubCategories', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (data.name);
};

const CategoryNameShort = ({ id }) => {
    const { data, loading, error } = useGetOne('newsCategories', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data && data.name !== 'Marketing') { return (data.name.replace("& Promociones", "")) }
    else {return "Comunicados"};
};

const ShopName = ({ id }) => {
    const { data, loading } = useGetOne('shops', id);
    if (loading) { return <LinearProgress /> }
    if (data) { 
        return (
            <div>
                {id === '618bd896efea2b2049ada486' &&
                    <img alt="logo" src="/static/images/Shops/TrendicoFaviconRounded.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada489' &&
                    <img alt="logo" src="/static/images/Shops/SotkattLogo.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada488' &&
                    <img alt="logo" src="/static/images/Shops/AtleetLogo.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada487' &&
                    <img alt="logo" src="/static/images/Shops/FoMLogo.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada490' &&
                    <img alt="logo" src="/static/images/Shops/TrendicoFaviconRounded.png" id="LogoImg"/>
                }
                <span>{data.name}</span>
            </div>
        );
    }
};

const DaysPassed = ({ date }) => {
    if (date) {
        const FormatDate = moment(date).format();
        const LongDate = moment(date).format('DD MMM YYYY');
        const ShortDate = moment(`${FormatDate}`).locale('es').fromNow();
        if (moment().subtract(60, 'days').valueOf() < moment(date).valueOf()) {
            return (<span style={{textTransform: 'capitalize'}}>{ShortDate}</span>)
        } else {
            return (<span style={{textTransform: 'capitalize'}}>{LongDate}</span>)
        }
    } else {
        return (
            <LinearProgress />
        )
    }
};

const NewsBody = ({ body , index}) => {
    if (body && index) { 
        return (<div dangerouslySetInnerHTML={{ __html: body }} id={index}/>); 
    }
};

const RelatedNews = ({NewsData, NewsProps}) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const { data, loading } = useGetList('publications', {page: 1, perPage: 999 },{ field: 'createdate', order: 'DESC' }, {category: NewsProps.notice_category});
    if (data) {
        const arrayOfObj = Object.entries(data).map((e) => ( e[1] ));
        const results = arrayOfObj.filter(obj => {
            return (
                obj.shops.includes(NewsData.shops[0]) || obj.shops.includes(NewsData.shops[1]) || obj.shops.includes(NewsData.shops[2]) ||
                obj.shops.includes(NewsData.shops[3]) || obj.shops.includes(NewsData.shops[4])
            );
        });
        const arrayToObject1 = (arr, key) => {
            return arr.reduce((obj, item) => {
                obj[item[key]] = item
                return obj
            }, {})
        }
        const FData = arrayToObject1(results, '_id');
        const FIds = Object.keys(FData);
        const SkeletonData = {1: {key: 1, value: 1},2: {key: 2, value: 2},3: {key: 3, value: 3}};
        const SkeletonIds = [1,2,3];

        return(
            <Box className={classes.RelatedNews}>
                <Box id="Title_Box">
                  <Typography className={classes.PostTitle}><CategoryNameShort id={NewsProps.notice_category}/> Relacionadas</Typography>
                </Box>
                {loading === false ?
                    <Box id="Rnews_Box">
                        {FData && FIds.slice(0,5).map(id => {
                            return(
                                <div>
                                    {FData[id].id !== NewsData.id &&
                                        <div onClick={() => redirect(`/publications/${FData[id].id}/show`)}>
                                            <Box id={FData[id].shops.includes('618bd896efea2b2049ada487') ? 'Rnew-FoM' : 
                                                (FData[id].shops.includes('618bd896efea2b2049ada486') ? 'Rnew-Trendico' : 
                                                (FData[id].shops.includes('618bd896efea2b2049ada488') ? 'Rnew-Atleet' : 
                                                (FData[id].shops.includes('618bd896efea2b2049ada489') ? 'Rnew-Sötkatt' : 
                                                (FData[id].shops.includes('618bd896efea2b2049ada490') ? 'Rnew-TrendicoGroup' : 'Rnew'))))}>
                                                <div id="image_box" style={{backgroundImage: `url(${FData[id].logo.image})`}}/>
                                                <div id="title" onClick={() => redirect(`/publications/${FData[id].id}/show`)} title={FData[id].title}>{FData[id].title}</div>
                                            </Box>
                                            <Divider/>
                                        </div>
                                    }
                            </div>
                            )
                        })}
                    </Box>
                : 
                    <Box id="Skeleton_Box">
                        {SkeletonIds.map(id => (
                            <Box id="skeleton" key={SkeletonData[id].key}>
                                <Skeleton id="skeleton_picture"/>
                                <Box id="skeleton_text">
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Box>
                            </Box>
                        ))}
                    </Box>       
                }
            </Box>
        )
    }
}

const NewsShow = (props) => {
    const { permissions } = usePermissions();
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const { data } = useGetOne('publications', props.id);
    const [update] = useUpdate();
    const refresh = useRefresh();
    const user = firebase.auth().currentUser;

    const [open, setOpen] = useState(false);
    const handleOpen = () => {setOpen(true)};
    const handleClose = () => {setOpen(false)};

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => {setOpen2(true)};
    const handleClose2 = () => {setOpen2(false)};


    if (data) {

        let NoticeFilesProps = {
            basePath: "/files", 
            resource: "files", 
            category: "VtNc5D0KMKEtk4cX1LZq",
            notice_id: props.id,
            notice_category: data.category
        }

        function ForceSendEmail(){
            const objectModule = {
                moduleId: data.category === '6270d8d07a125adbf27a2006' ? "news" : 
                    (data.category === '6270d8d87a125adbf27a200a' ? "comunicados" : 
                        data.category === '6270d8e07a125adbf27a200e' ? "ofertas" : null),
                sendMode: "ForcePublication",
                title: data.title,
                pageUrl: `https://${env.REACT_APP_DOMAIN}/#/news/${data.id}/show`,
                message: data.review,
                image: data.logo.image,
                shops: data.shops,
                replayToEmail: user.email,
                replayToName: user.displayName,
            }
            dataProvider.sendNewsletterEmail('newsletter', objectModule);
            handleOpen();
        };

        function NotifyChangesEmail(){
            const objectModule = {
                moduleId: data.category === '6270d8d07a125adbf27a2006' ? "news" : 
                    (data.category === '6270d8d87a125adbf27a200a' ? "comunicados" : 
                        data.category === '6270d8e07a125adbf27a200e' ? "ofertas" : null),
                sendMode: "notifyChanges",
                title: data.title,
                pageUrl: `https://${env.REACT_APP_DOMAIN}/#/news/${data.id}/show`,
                message: data.review,
                image: data.logo.image,
                shops: data.shops,
                replayToEmail: user.email,
                replayToName: user.displayName,
            }
            dataProvider.sendNewsletterEmail('newsletter', objectModule);
            handleOpen();
        };

        function PublicateSendEmail(){
            const objectModule = {
                moduleId: data.category === '6270d8d07a125adbf27a2006' ? "news" : 
                    (data.category === '6270d8d87a125adbf27a200a' ? "comunicados" : 
                        data.category === '6270d8e07a125adbf27a200e' ? "ofertas" : null),
                sendMode: "CreateNew",
                title: data.title,
                pageUrl: `https://${env.REACT_APP_DOMAIN}/#/publications/${data.id}/show`,
                message: data.review,
                image: data.logo.image,
                shops: data.shops,
                replayToEmail: user.email,
                replayToName: user.displayName,
            }
            dataProvider.sendNewsletterEmail('newsletter', objectModule);
            handleOpen2();
            update('publications', data.id, {_id: data.id, state: 'publicado', title: data.title, logo: data.logo, body: data.body, category: data.category, shops: data.shops});
            refresh();
        };

        function SetDraftState(){
            update('publications', data.id, {_id: data.id, state: 'borrador', title: data.title, logo: data.logo, body: data.body, category: data.category, shops: data.shops});
            refresh();
        };

        const NewsViewActions = ({ basePath, resource }) => {
            const { data } = useGetOne('publications', props.id);
            return(
            <TopToolbar className={classes.NewsTopToolbar} id="GoogleStyleToolBar">
                {data &&
                    <Box className={classes.Breadcrumb}>
                        <MyTooltip title={<div style={{fontSize: '12px'}}>Volver Atrás</div>} arrow>
                            <ToolbarBackButton style={{paddingLeft: '17px', paddingRight: '5px', marginRight: '10px'}} id="GoogleStyleBackButton"></ToolbarBackButton>
                        </MyTooltip>
                        <Box id="ActionsBar">
                            <Box id="Main_breadcrumb">
                                <Box>
                                    <MyTooltip title={<div style={{fontSize: '12px'}}>Volver a la lista de {data.category === '6270d8d07a125adbf27a2006' ? 'Noticias' : (data.category === '6270d8e07a125adbf27a200e' ? 'Ofertas' : (data.category === '6270d8d87a125adbf27a200a' ? 'Comunicados' : 'Publicaciones'))} </div>} arrow>
                                        <Link to={data.category === '6270d8d07a125adbf27a2006' ? '/news' : (data.category === '6270d8e07a125adbf27a200e' ? '/offersAndPromotions' : (data.category === '6270d8d87a125adbf27a200a' ? '/comunicados' : '/publications'))}>
                                            <Typography variant="body1" id="breadcrumbTitle"><CategoryNameShort id={data.category}/></Typography>
                                        </Link>
                                    </MyTooltip>
                                </Box>
                                <NavigateNextRoundedIcon id="navigationArrow"/>
                                <Box>
                                    <Typography variant="body1" id="breadcrumbTitle">{data.title}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {permissions === "Admin"  &&
                            <Box style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row-reverse'}}>
                                {data && data.state === "publicado" &&
                                    <Box className={classes.GreenStateChip}>
                                        <ChipField source="state" style={{textTransform: 'capitalize'}}/>
                                    </Box>
                                }
                                {data && data.state === "borrador" &&
                                    <Box className={classes.YellowStateChip}>
                                        <ChipField source="state" style={{textTransform: 'capitalize'}}/>
                                    </Box>
                                }
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar {data.category === '6270d8d07a125adbf27a2006' ? 'Noticia' : (data.category === '6270d8e07a125adbf27a200e' ? 'Oferta' : (data.category === '6270d8d87a125adbf27a200a' ? 'Comunicado' : 'Publicacion'))}</div>} arrow>
                                    <DeleteButton label="" basePath={basePath} record={data} resource={resource} id="GoogleStyleDeleteButton"/>
                                </MyTooltip>           
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Editar {data.category === '6270d8d07a125adbf27a2006' ? 'Noticia' : (data.category === '6270d8e07a125adbf27a200e' ? 'Oferta' : (data.category === '6270d8d87a125adbf27a200a' ? 'Comunicado' : 'Publicacion'))}</div>} arrow>
                                    <EditButton label="" type="link" to={`/publications/${props.id}/edit`} id="GoogleStyleButton"/>
                                </MyTooltip>
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Clonar {data.category === '6270d8d07a125adbf27a2006' ? 'Noticia' : (data.category === '6270d8e07a125adbf27a200e' ? 'Oferta' : (data.category === '6270d8d87a125adbf27a200a' ? 'Comunicado' : 'Publicacion'))}</div>} arrow>
                                    <CloneButton label="" icon={<CloneIcon /> } basePath={basePath} resource={resource} id="GoogleStyleButton"
                                        record={{
                                            autor: data.autor, bodyCopy: data.body, category: data.category, subCategory: data.subCategory, shops: data.shops, 
                                            title: data.title, subTitle: data.subTitle, review: data.review, state: data.state, logo: data.logo
                                        }}
                                    />
                                </MyTooltip>
                                <Divider orientation="vertical" flexItem style={{marginLeft: '20px', marginRight: '10px', marginTop: '6px', marginBottom: '6px'}}/>
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Adjuntar Archivos para {data.category === '6270d8d07a125adbf27a2006' ? 'esta Noticia' : (data.category === '6270d8e07a125adbf27a200e' ? 'esta Oferta' : (data.category === '6270d8d87a125adbf27a200a' ? 'este Comunicado' : 'esta Publicacion'))}</div>} arrow>
                                    <CreateButton label="" icon={<AttachFileRoundedIcon />} id="GoogleStyleButton" 
                                        to={`/files/create?source={"category":"VtNc5D0KMKEtk4cX1LZq","categoryName":"Publicaciones","notice_id":"${data._id}"}`}
                                    />
                                </MyTooltip>
                                {data.state === 'borrador' &&
                                    <MyTooltip title={<div style={{fontSize: '12px'}}>Publicar {data.category === '6270d8d07a125adbf27a2006' ? 'Noticia' : (data.category === '6270d8e07a125adbf27a200e' ? 'Oferta' : (data.category === '6270d8d87a125adbf27a200a' ? 'Comunicado' : 'Publicacion'))}</div>} aria-label="GoBack" arrow>
                                        <Button id="GoogleStyleBackButton" 
                                            startIcon={<IosShareRoundedIcon/>} onClick={() => {PublicateSendEmail()}}>
                                        </Button>
                                    </MyTooltip>
                                }
                                {data.state === 'publicado' &&
                                    <MyTooltip title={<div style={{fontSize: '12px'}}>Convertir en Borrador</div>} aria-label="GoBack" arrow>
                                        <Button id="GoogleStyleBackButton" 
                                            startIcon={<DraftIcon/>} onClick={() => {SetDraftState()}}>
                                        </Button>
                                    </MyTooltip>
                                }
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Forzar Notificaciones</div>} aria-label="GoBack" arrow>
                                    <Button id="GoogleStyleBackButton" 
                                        startIcon={<EmailRoundedIcon/>} onClick={() => {ForceSendEmail()}}>
                                    </Button>
                                </MyTooltip>
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Notificar Cambios</div>} aria-label="GoBack" arrow>
                                    <Button id="GoogleStyleBackButton" 
                                        startIcon={<PublishedWithChangesRoundedIcon/>} onClick={() => {NotifyChangesEmail()}}>
                                    </Button>
                                </MyTooltip>
                                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} TransitionComponent={TransitionLeft}>
                                    <MyAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}  icon={<MarkEmailReadRoundedIcon fontSize="inherit" />}>
                                        Email enviado con éxito !
                                    </MyAlert>
                                </Snackbar>
                                <Snackbar open={open2} autoHideDuration={5000} onClose={handleClose2} TransitionComponent={TransitionLeft}>
                                    <MyAlert onClose={handleClose2} severity="success" sx={{ width: '100%' }}  icon={<AddTaskRoundedIcon fontSize="inherit" />}>
                                        {data.category === '6270d8d07a125adbf27a2006' ? 'Noticia publicada' : (data.category === '6270d8e07a125adbf27a200e' ? 'Oferta publicada' : (data.category === '6270d8d87a125adbf27a200a' ? 'Comunicado publicado' : 'Publicacion publicada'))} con éxito !
                                    </MyAlert>
                                </Snackbar>
                            </Box>
                        }
                    </Box>
                }
            </TopToolbar>
            )
        };

        return (
            <div>
                <Box>
                    {data &&
                        <>
                            <Show {...props} actions={<NewsViewActions />} aside={<RelatedNews NewsData={data} NewsProps={NoticeFilesProps}/>} 
                                className={classes.ShowStyles}>
                                <SimpleShowLayout >
                                    <Box className={classes.NewsCard}>
                                        <Box className={classes.ImageOverlay} 
                                            style={{backgroundImage: `linear-gradient(to top, rgba(0,0,0,.4) 0%, rgba(0,0,0,.2) 51%, rgba(0,0,0,0) 100%), url(${data.logo.image})`}}>
                                                <div id="textTitle">
                                                    <TextField source="title" multiline="true" fullWidth id="title"/>
                                                    <RichTextField source="subTitle" label="" id="subTitle"/>
                                                </div>
                                        </Box>
                                        <Box style={{display: 'flex', width: '100%', marginBottom: '20px', marginTop: '20px'}}>
                                            <Box className={classes.ShopsBar}>
                                                {data.shops && data.shops.map(shop => (
                                                    <Typography className="ShopBadge" id={shop === '618bd896efea2b2049ada487' ? 'FoM' : 
                                                        (shop === '618bd896efea2b2049ada486' ? 'Trendico' : 
                                                        (shop === '618bd896efea2b2049ada488' ? 'Atleet' : (shop === '618bd896efea2b2049ada489' ? 'Sötkatt' : 'TrendicoGroup')))}
                                                    >
                                                        <ShopName id={shop} />
                                                    </Typography>
                                                ))}
                                            </Box>
                                            {data.createdate &&
                                                <Typography id="Date">
                                                    <DaysPassed date={data.createdate}/>
                                                </Typography>
                                            }
                                        </Box>
                                        <Typography variant="button" style={{color: '#196cdb'}} id="Category">
                                            <SubCategoryName id={data.subCategory} />
                                        </Typography>
                                        <RichTextField source="review" label="" id="Review"/>
                                        <NewsBody body={data.body} index="Body"/>
                                        
                                        {NoticeFilesProps &&
                                            <Box className={classes.AttachedFilesBox}>
                                                <Box id="Blocktitle">
                                                    <Typography className={classes.PostTitle}>Archivos Adjuntos</Typography>
                                                    <Typography className={classes.PostSubTitle}>&nbsp;Archivos relacionados con esta Noticia</Typography>
                                                </Box>
                                                <Box>
                                                    <FilesGrid {...NoticeFilesProps} id="FilesGridBlock"/>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                    
                                </SimpleShowLayout>
                            </Show>
                        </>
                    }
                </Box>
            </div>
        );
    } else {return null}
}; export default NewsShow;