import React from "react";
import { Link } from 'react-router-dom';
import { 
  List,  useListContext, ImageField, FilterLiveSearch, TopToolbar,
  Pagination, usePermissions, useRedirect, useGetList
} from 'react-admin';
import { makeStyles, Grid, CardMedia, Typography, Box, Card, CardContent } from '@material-ui/core';
import { CategoryFilter, PriceFilter, TypeFilter } from './MaterialFilters';
import MyTooltip from "../components/MuiTooltip";
import Fab from '@mui/material/Fab';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
  root: lightTheme.MaterialGridroot,
  media: lightTheme.MaterialGridmedia,
  title: lightTheme.MaterialGridtitle,
  actionSpacer: lightTheme.MaterialGridactionSpacer,
  card: lightTheme.MaterialGridcard,
  InputBlackBorder: lightTheme.InputBlackBorder,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  EditToolBar: lightTheme.EditToolBar,
  Pagination: lightTheme.MaterialGridPaginationBox,
  MaterialStylePagination: lightTheme.MaterialGridMaterialStylePagination,
  modal: lightTheme.MaterialGridModal,
  paper: lightTheme.MaterialGridPaper,
  TramitarButton: lightTheme.MaterialGridTramitarButton,
  list: {width: 300},
  fullList: {width: 'auto'},
});

export function MaterialListActions() {
  const {permissions} = usePermissions();
  const redirect = useRedirect();
  
  return (
    <Box>
      <TopToolbar style={{paddingTop: '22px', paddingBottom: '10px'}}>
        {(permissions === "Admin" || permissions === "Transporte") &&
          <Box style={{position: 'fixed', bottom: '20px', right: '30px', zIndex: '999'}}>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Consumible</div>} arrow placement="left">
              <Fab color="primary" aria-label="add" onClick={() => { redirect(`/material/create`); }}>
                <AddRoundedIcon />
              </Fab>
            </MyTooltip>
          </Box>
        }
      </TopToolbar>
    </Box>
  );
}

const FilterSidebar = () => {
  const classes = useStyles();
  return (
    <Card id="FilterAside">
      <CardContent>
        <FilterLiveSearch source="reference" className={classes.BlackInput} variant="outlined"/>
        <PriceFilter />
        <CategoryFilter/>
        <TypeFilter />
      </CardContent>
    </Card>
  )
};

const VariantPrice = ({ id }) => {
  const { data, ids } = useGetList('variants', { page: 1, perPage: 999 }, { field: 'price', order: 'DESC' }, {material_id: id});
  let price = 0;
  if (data && ids.length > 0) {
    price = data[ids[0]].price ? data[ids[0]].price.toString().split('.') : "0".toString().split('.');
    return (
      <Box style={{width: '100%', display: 'flex'}}>{price[0]}
        {price[1] === undefined &&
          <Box style={{width: '100%', display: 'flex'}}>,<div style={{marginRight: '5px', fontSize: '0.9rem', marginTop: '3px'}}>{price[1]}00 €</div></Box>
        }
        {price[1] && price[1].length <= 1 &&
          <Box style={{width: '100%', display: 'flex'}}>,<div style={{marginRight: '5px', fontSize: '0.9rem', marginTop: '3px'}}>{price[1]}0 €</div></Box>
        }
        {price[1] && price[1].length > 1 &&
          <Box style={{width: '100%', display: 'flex'}}>,<div style={{marginRight: '5px', fontSize: '0.9rem', marginTop: '3px'}}>{price[1]} €</div></Box>
        }
      </Box>
    );
  } else {
    price = "0".toString().split('.');
    return (
      <Box style={{width: '100%', display: 'flex'}}>{price[0]}
        {price[1] === undefined &&
          <Box style={{width: '100%', display: 'flex'}}>,<div style={{marginRight: '5px', fontSize: '0.9rem', marginTop: '3px'}}>{price[1]}00 €</div></Box>
        }
        {price[1] && price[1].length <= 1 &&
          <Box style={{width: '100%', display: 'flex'}}>,<div style={{marginRight: '5px', fontSize: '0.9rem', marginTop: '3px'}}>{price[1]}0 €</div></Box>
        }
        {price[1] && price[1].length > 1 &&
          <Box style={{width: '100%', display: 'flex'}}>,<div style={{marginRight: '5px', fontSize: '0.9rem', marginTop: '3px'}}>{price[1]} €</div></Box>
        }
      </Box>
    )
  }
};


const MaterialGrid =  props  => {
  const classes = useStyles(props);
  const { data, ids } = useListContext();
  return ids ? (
    <Grid container spacing={1} className={classes.root}>
      {ids.map(id => (
        <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={3} item >
          <Card className={classes.card} style={{height: '100%'}}>
            {data && data[id] && 
              <Box>
                <Link to={`/material/${data[id].id}/show`}>
                  <Box style={{padding: '10px', paddingTop: '20px'}}>
                    { data[id].logo &&
                      <CardMedia className={classes.media} image={data[id].logo.image} style={{backgroundPosition: 'center', backgroundSize: 'contain'}}>
                        <ImageField source="image" title="imageName" />
                      </CardMedia>
                    }
                  </Box>
                </Link>
                <CardContent className={classes.title}>
                  <Link to={`/material/${data[id].id}/show`}>
                    <Typography variant="body1" align="center" style={{textTransform: 'uppercase', textDecoratiom: 'none', height: '30px', fontSize: '13px'}} id="title">
                      {data[id].title}
                    </Typography>
                  </Link>
                  <Box display="flex" style= {{width: '100%'}}>
                    <Box display="flex" style= {{width: '100%', marginBottom: '-10px', paddingTop: '20px'}} justifyContent="flex-start">
                      <Typography variant="body1" align="center" style={{textDecoratiom: 'none', width: '100%'}} id="price">
                        <VariantPrice id={data[id].id}/>
                      </Typography>
                      <Box display="flex" style= {{width: '65%'}} justifyContent="flex-end">
                        {data[id].stock > 0 &&
                          <Typography variant="body1" align="center" style={{textDecoratiom: 'none', color: '#007600'}} id="stock">
                            En Stock
                          </Typography>
                        }
                        {data[id].stock === 0 &&
                          <Typography variant="body1" align="center" style={{textDecoratiom: 'none', color: '#b12704'}} id="stock">
                            Sin Stock
                          </Typography>
                        }
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Box>
            }
          </Card>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

const MaterialPagination = (props) => {
  const classes = useStyles();
  return(
    <Pagination rowsPerPageOptions={[8, 12, 16, 20, 24]} {...props} className={classes.Pagination}/>
  )
};

const MaterialList = (props) => {  
  const classes = useStyles(props);
  return(
    <Box style={{marginTop: '5px'}}>
      <Box display="flex" style={{ width: '100%', position: 'relative', top: '5px'}} justifyContent="flex-start">
        <Typography className={classes.PostTitle}>Tienda de Consumibles</Typography>
        <Typography className={classes.PostSubTitle}>&nbsp;Todo el material Consumible a tu Disposición</Typography>
      </Box>
      <List {...props} perPage={12} sort={{ field: 'title', order: 'ASC' }} className={classes.MaterialStylePagination} component="div" 
        actions={<MaterialListActions/>} aside={<FilterSidebar />} pagination={<MaterialPagination/>} filter={{type: 'consumible'}}>
        <MaterialGrid />
      </List>
    </Box>
  )
}; export default MaterialList;