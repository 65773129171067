import ProviderIcon from '@material-ui/icons/DomainRounded';
import ProviderList from './ProviderList';
import ProviderEdit from './ProviderEdit';
import ProviderCreate from './ProviderCreate';
import ProviderView from './ProviderView';

const ProviderModule = {
    list: ProviderList,
    edit: ProviderEdit,
    icon: ProviderIcon,
    show: ProviderView,
    create: ProviderCreate,
}; export default ProviderModule;