import React from 'react';
import PropTypes from 'prop-types';
import glyphs from './glyphs';

const propTypes = {
  color: PropTypes.string,
  extension: PropTypes.string,
  fold: PropTypes.bool,
  foldColor: PropTypes.string,
  glyphColor: PropTypes.string,
  gradientColor: PropTypes.string,
  gradientOpacity: PropTypes.number,
  labelColor: PropTypes.string,
  labelTextColor: PropTypes.string,
  labelUppercase: PropTypes.bool,
  radius: PropTypes.number,
  type: PropTypes.oneOf([
    'acrobat',
    'document',
    'image',
    'presentation',
    'spreadsheet',
  ]),
};

export const FileIcon = ({ type }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: '25px', height:'25px'}} viewBox="0 0 25 25" >
      {type && (
        <g>{glyphs[type]}</g>
      )}
    </svg>
  );
};

FileIcon.propTypes = propTypes;
export default FileIcon;
