import React from 'react';
import { useGetList } from "react-admin";
import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';
import { FilterList, FilterListItem } from 'react-admin';

export const CategoryFilter = () => {
    const { data, ids } = useGetList('shops', { page: 1, perPage: 10 }, {field: 'name', order: 'ASC'})
    return(
        <FilterList label="resources.material.filters.ecommerce" icon={<LocalOfferRoundedIcon />}>
            {ids.map(id => (
                <FilterListItem key={id} label={data[id].name} value={{ shop: data[id].id }}/>
            ))}
        </FilterList>
    )
}; 

