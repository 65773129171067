
export const darkTheme = {
    palette: {
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
};

export const lightTheme = {

    // <===== CSS INTRANET TRENDICO - PALETA DE COLORES ======> // 

    palette: {
        primary: {
            main: '#3d3d3d', // TRENDICO GREY //
        },
        secondary: {
            main: '#196cdb', // GOOGLE BLUE //
        },
        green: {
            main: '#1b841b', // AMAZON STOCK GREEN //
        },
        red: {
            main: '#b63818', // AMAZON PRICE RED //
        },
        badge: {
            main: '#dc004e', // RED BADGE //
        }
    },


    // <===== CSS INTRANET TRENDICO - MUI OVERRIDES & STYLES ======> // 

    // >>-------------------------------<< //
    // >>===== CSS - COMMON STYLES =====<< //
    // >>-------------------------------<< //

    CommonStyles:{
        CreateAndEdit: {
            ToolBar: {
                '& #GoogleStyleSaveButton.MuiButtonBase-root': {
                    padding: '7px',
                    paddingRight: '17px !important',
                    paddingLeft: '17px !important',
                    fontSize: '14px',
                    backgroundColor: '#196cdb !important',
                    color: '#fff !important',
                    textTransform: 'none !important',
                    boxShadow: '0px 0px 0px transparent ! important',
                },
                '& #GoogleStyleSaveButton.Mui-disabled': {
                    padding: '7px',
                    paddingRight: '17px !important',
                    paddingLeft: '17px !important',
                    fontSize: '14px',
                    backgroundColor: '#a2a1a1 !important',
                    color: '#fff !important',
                    textTransform: 'none !important',
                    boxShadow: '0px 0px 0px transparent ! important',
                },
                '& button:hover':{
                    boxShadow: '0px 0px 0px transparent ! important',
                    opacity: '0.7',
                },
                '& .MuiCircularProgress-colorPrimary': {
                    color: '#fff !important',
                    width: '35px',
                    height: '35px',
                },
            },
            RichTextInputBlack: {
                width: '100%',
                '& .ra-rich-text-input':{
                    paddingLeft: '10px',
                    width: '98% !important',
                },
            },
            BlackInputAutocomplete: {
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
                '&.MuiTextField-root':{
                    width: '100%',
                },
                '& div[aria-haspopup="listbox"]':{
                    width: '100%',
                    '&.MuiTextField-root':{
                        width: '100%',
                    }
                }
            },
            BlackInputMargin: {
                width: '100%',
                marginLeft: '10px',
                marginRight: '10px',
                '& .MuiFormLabel-root':{
                    zIndex: '0 !important'
                },
                '& .MuiInputLabel-shrink':{
                    backgroundColor: '#fff !important',
                    paddingLeft: '5px !important',
                    paddingRight: '5px !important',
                },
                '& .Mui-focused':{
                    '& span':{
                        color: '#196cdb !important',
                    }
                },
                '& .MuiOutlinedInput-root': {
                    '& .MuiSelect-select:focus':{
                        backgroundColor: 'transparent !important',
                    },
                    '& fieldset': {
                        borderColor: '#939393',
                    },
                    '&:hover fieldset': {
                        borderColor: '#000',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#196cdb',
                    },
                },
            },
            BlackInputMarginRight: {
                width: '100%',
                marginRight: '10px',
                '& .MuiFormLabel-root':{
                    zIndex: '0 !important'
                },
                '& .MuiInputLabel-shrink':{
                    backgroundColor: '#fff !important',
                    paddingLeft: '5px !important',
                    paddingRight: '5px !important',
                },
                '& .Mui-focused':{
                    '& span':{
                        color: '#196cdb !important',
                    }
                },
                '& .MuiOutlinedInput-root': {
                    '& .MuiSelect-select:focus':{
                        backgroundColor: 'transparent !important',
                    },
                    '& fieldset': {
                        borderColor: '#939393',
                    },
                    '&:hover fieldset': {
                        borderColor: '#000',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#196cdb',
                    },
                },
            },
            BlackInput: {
                width: '100%',
                '& .Mui-focused':{
                    '& span':{
                        color: '#196cdb !important',
                    }
                },
                '& .MuiInputLabel-shrink':{
                    backgroundColor: '#fff !important',
                    paddingLeft: '5px !important',
                    paddingRight: '5px !important',
                },
                '& .MuiOutlinedInput-root': {
                    '& .MuiSelect-select:focus':{
                        backgroundColor: 'transparent !important',
                    },
                    '& fieldset': {
                        borderColor: '#939393',
                    },
                    '&:hover fieldset': {
                        borderColor: '#000',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#196cdb',
                    },
                },
            },
            FlexTitleBox:{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
                color: '#3d3d3d',
                FlexTitle:{
                    width: '100%',
                    '& .MuiTypography-root':{
                        marginBottom: '1.5vw', 
                        marginLeft: '-5px',
                    }
                },
                FlexCloseButtonBox:{
                    flexShrink: 0,
                    position: 'relative', 
                    top: '-15px',
                    '& .MuiButtonBase-root':{
                        paddingLeft: '17px',  
                        paddingRight: '5px'
                    }
                },
            },
            
        },
        ListItems:{
            TopTitleBox:{
                display: 'flex',
                width: '100%', 
                position: 'relative', 
                top: '5px',
                justifyContent: 'flex-start',
            },
            TopTitle: {
                color: '#3d3d3d',
                fontSize: '25px',
                marginTop: '10px',
                marginBottom: '10px'
            },
            TopSubTitle: {
                color: '#5e5e5e',
                padding: '8px',
                fontStyle: 'italic',
                marginTop: '10px',
                marginBottom: '10px'
            },
            FilterButtonGoogleStyle: {
                '& .MuiButtonBase-root':{
                    color: '#3d3d3d !important',
                    backgroundColor: 'transparent !important',
                    marginLeft: '10px !important',
                    minWidth: '40px',
                    minHeight: '40px',
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    transition: 'ease 0.6s',
                    marginTop: '-4px',
                },
                '& .MuiIconButton-root':{
                    marginLeft: '350px !important',
                    marginRight: '8px !important',
                    marginBottom: '6px !important',
                },
                '& button:hover':{
                    backgroundColor: 'hsla(0, 0%, 80%, 0.51) !important',
                },
                '& span':{
                    paddingLeft: '5px',
                },
                '& .hide-filter':{
                    paddingLeft: '5px',
                },
            },
            ToolbarBackButton: {
                paddingLeft: '17px', 
                paddingRight: '5px', 
                marginRight: '10px'
            },
            ToolbarDivider: {
                marginLeft: '20px', 
                marginRight: '10px', 
                marginTop: '6px', 
                marginBottom: '6px',
            },
            TooltipTitle: {
                fontSize: '12px',
            },
            W100:{
                width: '100%',
            },
            FlexStart:{
                display: 'flex',
                justifyContent: 'flex-start',
            },
            FlexCenter:{
                display: 'flex',
                justifyContent: 'center',
            },
            FlexEnd:{
                display: 'flex',
                justifyContent: 'flex-end',
            },
            Flex100:{
                display: 'flex',
                width: '100%',
            },
            FlexStart100:{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
            },
            FlexCenter100:{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
            },
            FlexEnd100:{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
            },
            FlexStart50:{
                display: 'flex',
                width: '50%',
                justifyContent: 'flex-start',
            },
            FlexCenter50:{
                display: 'flex',
                width: '50%',
                justifyContent: 'center',
            },
            FlexEnd50:{
                display: 'flex',
                width: '50%',
                justifyContent: 'flex-end',
            },
            FlexStart70:{
                display: 'flex',
                width: '70%',
                justifyContent: 'flex-start',
            },
            FlexCenter70:{
                display: 'flex',
                width: '70%',
                justifyContent: 'center',
            },
            FlexEnd70:{
                display: 'flex',
                width: '70%',
                justifyContent: 'flex-end',
            },
            FlexStart30:{
                display: 'flex',
                width: '30%',
                justifyContent: 'flex-start',
            },
            FlexCenter30:{
                display: 'flex',
                width: '30%',
                justifyContent: 'center',
            },
            FlexEnd30:{
                display: 'flex',
                width: '30%',
                justifyContent: 'flex-end',
            },
        },
        Modal:{
            MuiModal: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiBackdrop-root':{
                  backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
                },
                '& h2':{
                    fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
                    fontWeight: 'normal !important',
                    margin: '0px !important',
                },
            },
            MuiPaper: {
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow: '1px 1px 10px #757171 !important',
                padding: '30px',
                marginLeft: '20vh',
                '& .MuiCardContent-root':{
                  padding: '0px !important',
                },
                '& .MuiToolbar-root':{
                  backgroundColor: '#fff !important',
                  marginTop: '-5px',
                  padding: '0px !important',
                },
                '& .MuiCard-root':{
                    boxShadow: '0px 0px 0px #fff !important'
                },
                '& #TitleBox':{
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: 'flex-start',
                    '& #Title':{
                        width: '90%', 
                        display: 'flex', 
                        justifyContent: 'flex-start'
                    },
                    '& #CloseButton':{
                        width: '10%', 
                        display: 'flex', 
                        justifyContent: 'flex-end'
                    },
                }
            },
        }
    },

    // >>------------------------------<< //
    // >>===== CSS - POSTS STYLES =====<< //
    // >>------------------------------<< //

    PostsStyles: {
        CreateAndEdit: {
            PostAutor:{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%', 
                marginTop:'-15px', 
                marginBottom: '10px',
                '& p':{
                    marginBottom: '1.2vw',
                    '& #autor':{
                        marginLeft: '5px',
                        fontWeight: 'bold', 
                        fontStyle: 'normal'
                    },
                    '& span':{
                        marginLeft: '5px',
                    }
                },
            },
            PostEditCard:{
                paddingRight: '10px', 
                paddingLeft: '10px',
            },
        },
        PostView: {
            CommentsSectionTitle:{
                display: 'flex', 
                width: '100%', 
                position: 'relative', 
                top: '10px', 
                marginLeft: '5px', 
                marginBottom: '-60px',
                justifyContent: 'flex-start',
            },
            CustomTopToolbar: {
                paddingBottom: '2px', 
                marginTop: '18px',
                '& .MuiCardContent-root':{
                    padding: '0px !important',
                }
            },
            ToolbarForm: {
                width: '100%', 
                height: '20px'
            },
            PostTitle:{
                width: '100%',
                '& .MuiTypography-h6':{
                    marginBottom: '1.5vw', 
                    marginLeft: '-15px', 
                    marginTop: '-10px'
                },
                '& .MuiTypography-body2':{
                    fontSize: '1.4rem', 
                    textTransform: 'capitalize', 
                    fontWeight: 'bold'
                }
            },
            PostAutor:{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%', 
                marginTop:'-20px', 
                marginBottom: '15px',
                '& p':{
                    marginBottom: '1.2vw',
                    '& #autor':{
                        marginLeft: '5px',
                        fontWeight: 'bold', 
                        fontStyle: 'normal'
                    },
                    '& span':{
                        marginLeft: '5px',
                    }
                },
            },
            PostBody:{
                marginTop: '-5px', 
                marginLeft: '5px', 
                width: '100%',
            },
            PostViewDivider: {
                marginBottom: '25px',
                marginTop: '-10px', 
                width: 'auto', 
                backgroundColor: '#000',
            },
            PostShowPage: {
                width: '100%',
                '& .show-page':{
                    marginTop: '-15px',
                },
            },
        },
        PostList: {
            PostsListTable:{
                '& .column-user_id':{
                    textAlign: 'right !important',
                    color: 'rgb(130, 130, 130)',
                },
                '& .column-lastupdate':{
                    color: 'rgb(130, 130, 130)',
                },
            },
            InLineViewPost: {
                border: '1px solid #3d3d3d63', 
                borderRadius: '10px', 
                marginTop: '10px', 
                marginBottom: '10px',
                '& h6':{
                    fontStyle: 'italic',
                    '& div':{
                        paddingTop: '15px', 
                        paddingLeft: '15px', 
                        paddingBottom: '5px',
                    },
                },
                '& p':{
                    marginTop: '-5px', 
                    lineHeight: 'normal',
                    '& div':{
                        padding: '15px',
                    },
                },
            },
            TopToolbarCustom: {
                paddingTop: '22px', 
                paddingBottom: '10px'
            },
        },
    },

    // >>---------------------------------<< //
    // >>===== CSS - COMMENTS STYLES =====<< //
    // >>---------------------------------<< //

    CommentsStyles: {
        CreateAndEdit: {
            CommentsEditCard:{
                paddingRight: '10px', 
                paddingLeft: '10px',
            },
        },
        CommentsList: {
            CommentsTable: {
                '&.MuiTable-root': {
                    '& thead': {
                        display: 'none',
                    },
                    '& tbody': {
                        display: 'flex',
                        flexWrap: 'wrap',
                    },
                    '& .MuiTableRow-root': {
                        backgroundColor: '#edf3f0',
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        boxShadow: '1px 1px 5px #b5b5b5',
                        border: '1px solid #d7d7d7',
                        borderRadius: '10px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        display: 'table',
                        width: '100%',
                        position: 'relative',
                    },
                    '& .MuiTableRow-root:before':{
                        content: "",
                        width: '0px',
                        height: '0px',
                        position: 'absolute',
                        borderLeft: '24px solid #fff',
                        borderRight: '12px solid transparent',
                        borderTop: '12px solid #fff',
                        borderBottom: '20px solid transparent',
                        left: '32px',
                        bottom: '-24px',
                    },
                    '& tr:hover':{
                        backgroundColor: '#edf3f0!important',
                    },
                    '& td': {
                        borderBottom: 'none',
                        textAlign: 'center',
                        paddingLeft: '15px !important',
                        paddingRight: '0px !important',
                        width: '100% !important',
                        display: 'flex',
                        '& a':{
                            marginRight: '13px',
                            display: 'none'
                        },
                        '& button':{
                            marginRight: '13px',
                            display: 'none'
                        }
                    },
                    '& td:hover':{
                        '& a':{
                            display: 'inline-flex',
                        },
                        '& Button':{
                            display: 'inline-flex',
                        },
                    },
                },
                ' & .MuiCard-root': {
                    borderRadius: 'none !important',
                },
            },
            CommentAutorBox:{
                display: 'flex', 
                justifyContent: 'flex-start', 
                width: '100%', 
                borderBottom: '1px solid #3d3d3d', 
                marginRight: '40px', 
                paddingBottom: '15px', 
                height: '42px',
                '& p':{
                    marginTop: '10px',
                    '& #autor':{
                        fontWeight: 'bold',
                    },
                },
                '& .MuiTypography-body1':{
                    width: '100%', 
                    textAlign: 'left',
                },
            },
            ButtonBox:{
                display: 'flex', 
                width: '20%', 
                justifyContent:'flex-end', 
                paddingTop: '5px',
                alignItems: 'end',
                '& button':{
                    display: 'none',
                    '& svg':{
                        marginLeft: '13px'
                    }
                }
            },
            BubbleMessage:{
                '& #BubbleMessage': {
                    backgroundColor: '#edf3f0',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    boxShadow: '1px 1px 5px #b5b5b5',
                    border: '1px solid #d7d7d7',
                    borderRadius: '10px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'table',
                    width: '100%',
                    position: 'relative',
                },
                '& #BubbleMessage::before':{
                    content: "",
                    width: '0px',
                    height: '0px',
                    position: 'absolute',
                    borderLeft: '24px solid #fff',
                    borderRight: '12px solid transparent',
                    borderTop: '12px solid #fff',
                    borderBottom: '20px solid transparent',
                    left: '32px',
                    bottom: '-24px',
                },
            },
            BodyComment:{ 
                textAlign: 'left',
                marginBottom: '-15px',
                width:'80%'
            },
            CommentBodyBox:{
                width: '100%',
                '& .MuiFormControl-root':{
                    width: '100%',
                    marginLeft: '-25px', 
                    marginTop: '-10px',
                }
            },
            CommentsListBackground:{
                marginTop: '60px',
                '& .MuiPaper-rounded': {
                    backgroundColor: 'transparent !important',
                    boxShadow: '0px 0px 0px transparent !important',
                }, 
            },
            EmptyCommentsMediaCard: {
                backgroundPosition: 'center', 
                backgroundSize: 'contain', 
                marginTop: '15px', 
                backgroundImage: 'url(/static/images/CommentEmpty.svg)',
                height: 120,
            },
            EmptyCommentsBox: {
                display: 'flex',
                justifyContent: 'center', 
                marginTop: '70px',
                textAlign: 'center',
                '& .MuiTypography-h6':{
                    marginTop: '20px',
                },
            },
        },
    },

    // >>-----------------------------------<< //
    // >>===== CSS - NEWSLETTER STYLES =====<< //
    // >>-----------------------------------<< //
    NesletterStyles: {
        CreateAndEdit:{
            TransferList:{
                margin: "0px",
                height: "100%",
                '& .MuiGrid-item':{
                  '& .MuiPaper-root':{
                    boxShadow: "none !important",
                    border: "1px solid #cacaca",
                    '& #cardHeader':{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        '& .MuiCardHeader-root':{
                            width: "55%",
                            paddingRight: "0px",
                        },
                        '& #TextFieldBox':{
                            padding: '8px',
                            width: "45%",
                            '& .MuiTextField-root':{
                                width: "100%",
                                '& div':{
                                    '& input':{
                                        padding: '8px',
                                    }
                                }
                            }
                        }
                    },
                    '& .MuiListItem-root':{
                        '& .MuiBox-root':{
                            width: '100%',
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            '& p':{
                                marginTop: '5px',
                                marginBottom: '5px',
                                marginLeft: '0px',
                                marginRight: '0px',
                            },
                            '& #email-listitem':{
                                margin: "0px",
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                fontWeight: "400",
                                fontSize: "0.875rem",
                                lineHeight: "1.43",
                                color: "rgba(0, 0, 0, 0.6)",
                                display: "block",
                            }
                        }
                    },
                    '& #pagination':{
                      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                      display: 'flex',
                      width: '100%',
                      paddingTop: '15px',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '20px',
                      '& #page':{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      },
                      '& #info':{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px',
                        '& #demo-simple-select-autowidth':{
                          paddingTop: '5px',
                          paddingBottom: '5px',
                        }
                      }
                    }
                  }
                },
                '& .MuiGrid-item:nth-child(1)':{
                  width: '45%',
                  height: "calc(100vh - 200px)",
                  paddingTop: '0px',
                  '& .MuiPaper-root':{
                    height: "100%",
                    width: "100%",
                    '& .MuiList-root':{
                      height: "calc(100vh - 350px)",
                      width: "100%",
                    },
                  }
                },
                '& .MuiGrid-item:nth-child(2)':{
                  paddingTop: '0px',
                  width: '10%',
                  height: "calc(100vh - 200px)",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                '& .MuiGrid-item:nth-child(3)':{
                  paddingTop: '0px',
                  width: '45%',
                  height: "calc(100vh - 200px)",
                  '& .MuiPaper-root':{
                    height: "100%",
                    width: "100%",
                    '& .MuiList-root':{
                      height: "calc(100vh - 350px)",
                      width: "100%",
                    },
                  }
                },
            }
        }
    },

    // >>--------------------------------<< //
    // >>===== CSS - NEWS STYLES =====<< //
    // >>--------------------------------<< //

    NewsStyles: {
        CreateAndEdit: {
            ImageBoxStyle: {
                paddingLeft: '10px', 
                paddingRight: '10px',
                '& .previews':{
                    '& #ImageBox':{ 
                        border: '1px solid #939393', 
                        borderRadius: '10px',
                    },
                    '& img':{
                        borderRadius: '10px',
                    }
                }
            },
        },
        NewsList: {
            root: { flexGrow: 1 },
            headTable: { display: 'none' },
            dateCreate: { margin: '8px' },
            NewsListTable:{
                '& tr':{
                    '& .MuiTableCell-paddingCheckbox':{
                        verticalAlign: 'text-top',
                        paddingTop: '10px',
                    },
                },
            },
            NoticeTitle: {
                textTransform: 'uppercase', 
                marginLeft: '13px'
            },
            imageBoxButton: {
                width: 200,
                height: 200,
                margin: '8px',
                paddingTop: '10px',
            },
            image: {
                display: 'block',
                border: '0',
                height: '250px', 
                width: 'auto'
            },
            TopToolbarCustom: {
                paddingTop: '22px', 
                paddingBottom: '10px'
            },
            boxTitle: {
                display: 'flex',
                width: '100%',
                position: 'relative',
                top: '4px',
                justifyContent: 'flex-start'
            },
            btnNotify: {
                height: '30px',
                marginRight: '10px !important',
                paddingTop: '12px !important',
            },
            ListBackground:{
                '& .MuiPaper-root':{
                    backgroundColor: 'transparent !important',
                    boxShadow: 'none !important',
                }
            },
            ListBackground_RightSearchBar:{
                '& .MuiPaper-root':{
                    backgroundColor: 'transparent !important',
                    boxShadow: 'none !important',
                },
                '& .MuiToolbar-root': {
                    display: 'flex',
                    '& .MuiToolbar-gutters':{
                      paddingTop: '21px',
                      width: 'auto',
                      '& a':{
                        marginLeft: '0px !important',
                        '& span':{
                          width: '110px !important',
                        }
                      }
                    },
                    '& form': {
                      justifyContent: 'right',
                      marginBottom: '10px',
                      marginTop: '-10px'
                    },
                },
                '& .list-page':{
                    marginTop: '-45px'
                }
            },
            NewsCard:{
                position: 'relative',
                fontSize: '20px',
                lineHeight: '24px',
                background: '#fff',
                borderRadius: '10px',
                padding: '24px',
                textAlign: 'left',
                color: '#000',
                boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
                marginBottom: '25px',
                display: 'flex',
                minHeight: '45px',
                '& #Body':{
                    color: '#000',
                    height: '125px',
                    width: '100%',
                    textOverflow: 'ellipsis',
                    position: 'relative',
                    fontSize: '14px',
                    lineHeight: '1.4285714285714286em',
                    marginBottom: '20px',
                    marginTop: '-5px',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: '5',
                    WebkitBoxOrient: 'vertical',
                    textAlign: 'justify',
                    '& :hover':{
                        cursor: 'pointer',
                    },
                    '& #subTitle':{
                        color: '#3d3d3d'
                    },
                    '& #review':{
                        marginTop: '10px',
                        height: '60px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: '3',
                        display: '-webkit-box',
                        '& p':{
                            margin: '0px !important',
                            padding: '0px !important',
                            letterSpacing: '0px !important',
                        },
                    },
                    '& #seeMore':{
                        content: '"Leer más »"',
                        color: '#3d3d3d',
                        transition: 'all .2s',
                        paddingTop: '10px',
                        display: 'block',
                        width: 'fit-content'
                    },
                    '& #seeMore:hover':{
                        color: '#196cdb',
                        cursor: 'pointer',
                    },
                    '& div':{
                        '& p':{
                            marginTop: '10px',
                            marginBottom: '0px',
                        }
                    }
                },
                '& #Title':{
                    width: '90%',
                    color: '#000',
                    marginBottom: '10px',
                    marginTop: '20px',
                },
                '& #Title:hover':{
                    cursor: 'pointer',
                },
                '& #Date':{
                    marginBottom: '-5px',
                    color: '#70757a', 
                    fontSize: '13px', 
                    lineHeight: '1.3333em',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    '& #DraftState':{
                        color: '#dc004e !important',
                        fontSize: '15px !important'
                    }
                },
                '& #Autor':{
                    marginBottom: '5px',
                    color: '#70757a', 
                    fontSize: '15px', 
                    lineHeight: '1.3333em'
                },
                '& #LogoImg':{
                    width: '16px',
                    height: '16px',
                    marginBottom: '-3px',
                    marginRight: '9px',
                }
            },
            ShopsBar:{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                flexDirection: 'row',
                '& .ShopBadge':{
                    border: '1px solid',
                    borderRadius: '25px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingLeft: '4px',
                    paddingRight: '13px',
                    marginRight: '10px',
                    fontSize: '13px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    cursor: 'default',
                    '& div':{
                        display: 'flex',
                        height: '20px',
                        alignItems: 'center',
                    }
                },
                '& #Trendico':{
                    borderColor: '#3d3d3d',
                    color: '#3d3d3d',
                    background: 'linear-gradient(15deg, rgba(61,61,61,0.15) 0%, rgba(61,61,61,0.05) 100%)',
                    '& img':{
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        marginTop: '-2px'
                    }
                },
                '& #TrendicoGroup':{
                    borderColor: '#3d3d3d',
                    color: '#3d3d3d',
                    background: 'linear-gradient(15deg, rgba(61,61,61,0.15) 0%, rgba(61,61,61,0.05) 100%)',
                    '& img':{
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        marginTop: '-2px'
                    }
                },
                '& #FoM':{
                    borderColor: '#da291c',
                    color: '#da291c',
                    background: 'linear-gradient(15deg, rgba(218,41,28,0.15) 0%, rgba(218,41,28,0.05) 100%)',
                    '& img':{
                        backgroundColor: '#da291c',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        marginTop: '-2px'
                    }
                },
                '& #Atleet':{
                    borderColor: '#00bcb4',
                    color: '#00bcb4',
                    background: 'linear-gradient(15deg, rgba(0,188,180,0.15) 0%, rgba(0,188,180,0.05) 100%)',
                    '& img':{
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        marginTop: '-2px'
                    }
                },
                '& #Sötkatt':{
                    borderColor: '#181716',
                    color: '#181716',
                    background: 'linear-gradient(15deg, rgba(24,23,22,0.15) 0%, rgba(24,23,22,0) 100%)',
                    '& img':{
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                        marginTop: '-2px'
                    }
                }  
            },
            skeletonNewsList:{
                borderRadius: '10px',
                backgroundColor: '#fff',
                boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row',
                width: '100%',
                paddingTop: '20px',
                paddingBottom: '20px',
                marginBottom: '20px',
                '& #text':{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: '80%',
                    marginRight: '20px',
                    marginLeft: '20px',
                    '& .MuiSkeleton-root':{
                        transform: 'scale(1) !important',
                        marginBottom: '5px',
                    },
                    '& .MuiSkeleton-root:nth-child(2)':{
                        width: '600px',
                    },
                    '& .MuiSkeleton-root:nth-child(3)':{
                        width: '250px',
                        marginBottom: '10px',
                    },
                    '& .MuiSkeleton-root:last-child':{
                        transform: 'scale(1) !important',
                        marginTop: '10px',
                        width: '100px',
                        marginBottom: '5px',
                    },
                    '& #chips':{
                        '& .MuiSkeleton-root':{
                            display: 'flex !important',
                            fontSize: '13px !important',
                            alignItems: 'center !important',
                            paddingTop: '4px !important',
                            marginRight: '10px !important',
                            paddingLeft: '4px !important',
                            borderRadius: '25px !important',
                            paddingRight: '13px !important',
                            paddingBottom: '4px !important',
                            justifyContent: 'flex-start !important',
                        },
                        marginBottom: '10px',
                    }
                },
                '& #picture':{
                    width: '25%',
                    height: '250px',
                    marginRight: '20px',
                    '& .MuiSkeleton-root':{
                        height: '100%',
                    }
                },
                '& .MuiSkeleton-root':{
                    transform: 'scale(1) !important',
                }
            }
        },
        NewsShow: {
            BodyBox:{
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
            },
            NewsCard:{
                padding: '10px',
                '& #Body':{
                    width: '100%',
                    fontSize: '14px',
                    '& table':{
                        width: '100% !important',
                    },
                    '& .ql-align-center':{
                        textAlign: 'center',
                    },
                    '& p#imgParagraph.ql-align-center ':{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'center',
                        '& a':{
                            display: 'flex',
                            justifyContent: 'center',
                        }
                    },
                    '& p#imgParagraph.ql-align-right ':{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-end',
                        '& a':{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }
                    },
                    '& p#imgParagraph.ql-align-left ':{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-start',
                        '& a':{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }
                    },
                    '& p img':{
                        maxWidth: '100%',
                        maxHeight: 'auto',
                        borderRadius: '6px',
                    },
                    '& blockquote':{
                        marginLeft: '0px',
                        marginRight: '0px',
                        paddingLeft: '10px',
                        borderLeft: '3px solid #cacaca',
                    },
                    '& a':{
                        color: '#196cdb !important',
                        width: '100%',
                        marginRight: '10px'
                    },
                    '& a:last-child':{
                        color: '#196cdb !important',
                        width: '100%',
                        marginRight: '0px !important'
                    },
                    '& li':{
                        marginTop: '5px'
                    },
                    '& .ql-indent-1':{
                        marginLeft: '25px'
                    },
                    '& .ql-indent-1::marker':{
                        content: '"○ "',
                    }
                },
                '& #Review':{
                    width: '100%',
                    fontSize: '16px',
                    '& .ql-align-center':{
                        textAlign: 'center',
                    },
                    '& p#imgParagraph.ql-align-center ':{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'center',
                        '& a':{
                            display: 'flex',
                            justifyContent: 'center',
                        }
                    },
                    '& p#imgParagraph.ql-align-right ':{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-end',
                        '& a':{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }
                    },
                    '& p#imgParagraph.ql-align-left ':{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        justifyContent: 'flex-start',
                        '& a':{
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }
                    },
                    '& p img':{
                        maxWidth: '100%',
                        maxHeight: 'auto',
                        borderRadius: '6px',
                    },
                    '& blockquote':{
                        marginLeft: '0px',
                        marginRight: '0px',
                        paddingLeft: '10px',
                        borderLeft: '3px solid #cacaca',
                    },
                    '& a':{
                        color: '#196cdb !important',
                        width: '100%',
                        marginRight: '10px'
                    },
                    '& a:last-child':{
                        color: '#196cdb !important',
                        width: '100%',
                        marginRight: '0px !important'
                    },
                    '& li':{
                        marginTop: '5px'
                    },
                    '& .ql-indent-1':{
                        marginLeft: '25px'
                    },
                    '& .ql-indent-1::marker':{
                        content: '"○ "',
                    }
                },
                '& #Date':{
                    marginBottom: '-5px',
                    color: '#70757a', 
                    fontSize: '13px', 
                    lineHeight: '1.3333em',
                    display: 'flex',
                    width: '40%',
                    justifyContent: 'flex-end',
                },
                '& #Category':{
                    display: 'flex',
                    width: '40%',
                    justifyContent: 'flex-start',
                    marginBottom: '10px',
                },
                '& #Shop':{
                    marginBottom: '-5px',
                    color: '#70757a', 
                    fontSize: '13px', 
                    lineHeight: '1.3333em',
                    display: 'flex',
                    width: '40%',
                    justifyContent: 'flex-start',
                },
                '& #LogoImg':{
                    width: '16px',
                    height: '16px',
                    marginBottom: '-3px',
                    marginRight: '9px',
                }
            },
            ImageOverlay:{
                ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                    height: '500px',
                },
                ["@media (min-width:1600px)"]:{ // eslint-disable-line no-useless-computed-key
                    height: '650px',
                },
                backgroundSize: 'cover',
                color: 'white',
                padding: '20px',
                borderRadius: '10px',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                '& #textTitle':{
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    '& #title':{
                        fontWeight: '600',
                        textShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                        color: '#fff !important',
                        ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                            fontSize: '40px',
                            lineHeight: '40px',
                        },
                        ["@media (min-width:1600px)"]:{ // eslint-disable-line no-useless-computed-key
                            fontSize: '50px',
                            lineHeight: '50px',
                        }
                    },
                    '& #subTitle':{
                        color: '#fff !important',
                        fontWeight: '100',
                        textShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                        ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                            fontSize: '17px',
                        },
                        ["@media (min-width:1600px)"]:{ // eslint-disable-line no-useless-computed-key
                            fontSize: '22px',
                        }
                    }
                },
            },
            ShopsBar:{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                flexDirection: 'row',
                '& .ShopBadge':{
                    border: '1px solid',
                    borderRadius: '25px',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingLeft: '4px',
                    paddingRight: '13px',
                    marginRight: '10px',
                    fontSize: '13px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    cursor: 'default',
                    '& div':{
                        display: 'flex',
                        height: '20px',
                        alignItems: 'center',
                    },
                    '& img':{
                        ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                            marginTop: '-2px'
                        },
                        ["@media (min-width:1600px)"]:{ // eslint-disable-line no-useless-computed-key
                            marginTop: '-3px'
                        },
                    }
                },
                '& #Trendico':{
                    borderColor: '#3d3d3d',
                    color: '#3d3d3d',
                    background: 'linear-gradient(15deg, rgba(61,61,61,0.15) 0%, rgba(61,61,61,0.05) 100%)',
                    '& img':{
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                    }
                },
                '& #TrendicoGroup':{
                    borderColor: '#3d3d3d',
                    color: '#3d3d3d',
                    background: 'linear-gradient(15deg, rgba(61,61,61,0.15) 0%, rgba(61,61,61,0.05) 100%)',
                    '& img':{
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                    }
                },
                '& #FoM':{
                    borderColor: '#da291c',
                    color: '#da291c',
                    background: 'linear-gradient(15deg, rgba(218,41,28,0.15) 0%, rgba(218,41,28,0.05) 100%)',
                    '& img':{
                        backgroundColor: '#da291c',
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                    }
                },
                '& #Atleet':{
                    borderColor: '#00bcb4',
                    color: '#00bcb4',
                    background: 'linear-gradient(15deg, rgba(0,188,180,0.15) 0%, rgba(0,188,180,0.05) 100%)',
                    '& img':{
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                    }
                },
                '& #Sötkatt':{
                    borderColor: '#181716',
                    color: '#181716',
                    background: 'linear-gradient(15deg, rgba(24,23,22,0.15) 0%, rgba(24,23,22,0) 100%)',
                    '& img':{
                        borderRadius: '50%',
                        width: '20px',
                        height: '20px',
                    }
                }  
            },
            RelatedNews:{
                ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                    width: '350px',
                },
                ["@media (min-width:1600px)"]:{ // eslint-disable-line no-useless-computed-key
                    width: '700px',
                },
                '& #Title_Box': {
                    borderTop: '1px solid #dfdfdf',
                    marginLeft: '16px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingLeft: '0px',
                    paddingTop: '13px',
                    paddingRight: '0px',
                    padding: '5px',
                    '& p':{
                        fontSize: '20px !important'
                    }
                },
                '& #Title_Box2': {
                    marginLeft: '0px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    paddingLeft: '0px',
                    paddingTop: '20px',
                    paddingRight: '0px',
                    padding: '0px',
                    marginBottom: '-10px',
                    '& p':{
                        fontSize: '20px !important'
                    }
                },
                '& #Rnews_Box':{
                    marginLeft: '16px',
                    width: '100%',
                    '& .MuiBox-root':{
                        marginBottom: '10px',
                        marginTop: '10px',
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                            height: '90px',
                        },
                        ["@media (min-width:1600px)"]:{ // eslint-disable-line no-useless-computed-key
                            height: '130px',
                        },
                        '& #image_box':{
                            overflow: 'hidden',
                            width: '30%',
                            borderRadius: '6px',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                        },
                        '& #title':{
                            width: '65%',
                            marginLeft: '10px',
                            fontSize: '15px',
                            cursor: 'pointer',
                            height: '55px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                        }
                    },
                    '& #Rnew-Atleet':{
                        borderLeft: '3px solid #00bcb4'
                    },
                    '& #Rnew-FoM':{
                        borderLeft: '3px solid #da291c'
                    },
                    '& #Rnew-Trendico':{
                        borderLeft: '3px solid #3d3d3d'
                    },
                    '& #Rnew-TrendicoGroup':{
                        borderLeft: '3px solid #3d3d3d'
                    },
                    '& #Rnew-Sötkatt':{
                        borderLeft: '3px solid #000'
                    },
                    '& .MuiBox-root:hover':{
                        boxShadow: '1px 1px 5px rgba(66,66,66,.15) !important',
                        borderRadius: '6px',
                        cursor: 'pointer',
                        backgroundColor: '#fff',
                    }
                },
                '& #Skeleton_Box':{
                    marginLeft: '16px',
                    width: '100%',
                    '& #skeleton':{
                        width: '100%',
                        height: '95px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        '& #skeleton_picture':{
                            width: '30%',
                            height: '120px',
                            marginRight: '10px'
                        },
                        '& #skeleton_text':{
                            width: '70%',
                            height: '72px',
                            display: 'flex',
                            flexDirection: 'column',
                            '& .MuiSkeleton-root:last-child':{
                                width: '50%'

                            }
                        }
                    },
                },
                '& #filesColumn':{
                    marginLeft: '16px',
                    '& > div':{
                        width: '100%',
                        '& > div':{
                            width: '100%',
                            '&  .MuiGrid-item':{
                                width: '100%',
                                maxWidth: '100%',
                                flexBasis: '100%',
                                '& #ContentCard':{
                                    width: '100% !important',
                                    ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                                        height: '60px',
                                    },
                                    ["@media (min-width:1600px)"]:{ // eslint-disable-line no-useless-computed-key
                                        height: '80px',
                                    },
                                    '& .MuiCardContent-root':{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        '& #FolderContent':{
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                            width: '93%',
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            },
            ShowStyles: {
                '& .MuiPaper-root':{
                  '& .MuiCardContent-root':{
                    padding: '0px !important',
                  }
                }
            },
            NewsTopToolbar:{
                paddingBottom: '2px', 
                marginTop: '8px'
            },
            CategoryPlusInfo:{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                '& #Category':{
                    width: '50%'
                },
                '& #plusInfo':{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '50%',
                    '& #sendButton':{
                        color: '#196cdb',
                        textTransform: 'capitalize',
                    },
                    '& #sendButton:hover':{
                        backgroundColor: '#196cdb1c'
                    }
                }
            }
        }
    },

    // >>------------------------------<< //
    // >>===== CSS - SHOPS STYLES =====<< //
    // >>------------------------------<< //

    ShopsStyles: {
        ShopList: {
            GridRoot: {
                paddingRight: '33px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                  borderColor: '#939393',
                  },
                  '&:hover fieldset': {
                  borderColor: '#000',
                  },
                  '&.Mui-focused fieldset': {
                  borderColor: '#196cdb',
                  },
              },
            },
            GridMedia: {
                height: 150,
                width: '100%',
                transition: '0.5s ease-in-out',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                '&:hover': {
                  transform: 'scale(1.1, 1.1)',
                  transition: '0.5s ease-in-out',
                },
                '& img':{
                    width: '70%',
                }
            },
            GridMediaDeny: {
                height: 150,
                width: '100%',
                transition: '0.5s ease-in-out',
                backgroundPosition: 'center', 
                backgroundSize: 'contain',
                cursor: 'not-allowed',
                '&:hover': {
                  transform: 'scale(1.1, 1.1)',
                  transition: '0.5s ease-in-out',
                  filter: 'grayscale(100%)',
                },
            },
            GridTitle: {
                paddingBottom: '0.5em',
                '& .MuiTypography-root':{
                    textAlign: 'center',
                    textTransform: 'uppercase', 
                    textDecoratiom: 'none',
                },
            },
            GridActionSpacer: {
                display: 'flex',
                justifyContent: 'space-around',
            },
            GridCard: {
                marginRight: '-35px',
                minWidth: '150px !important',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
                '& .MuiDivider-root':{
                    marginLeft: '5px !important', 
                    marginRight: '5px !important',
                },
            },
        },
    },

    // >>---------------------------------<< //
    // >>===== CSS - CALENDAR STYLES =====<< //
    // >>---------------------------------<< //

    CalendarStyles:{
        Events: {
            fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
            '& #CalendarBox':{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                '& .calendar':{
                    width: '100%',
                }
            },
            '& .calendar':{
                border: '0px solid transparent !important'
            },
            '& .calendar-body':{
                border: '1px solid #d9d9d9 !important',
                backgroundColor: '#fff !important',
                borderRadius: '10px !important',
                borderBottom: 'none !important',
                boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
                height: '800px'
            },
            '& .day-name':{
                borderTop: '0px !important',
                fontSize: '13px !important',
                borderBottom: '0px !important',
                borderRight: '1px solid lightgray !important',
            },
            '& .day-name:nth-child(7)':{
                borderRight: '0px !important',
            },
            '& .css-9s9q7h-Calendar':{
                textAlign: 'center !important',
                fontSize: '14px !important',
                paddingRight: '10px',
                paddingLeft: '2px !important',
                borderRight: '1px solid lightgray !important',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'center',
                '& .css-csro95-Calendar':{
                    color: '#fff !important',
                    marginTop: '-8px !important',
                    paddingTop: '8px !important',
                    paddingLeft: '0px !important',
                    borderRadius: '50% !important',
                    marginBottom: '5px !important',
                    paddingRight: '0px !important',
                    paddingBottom: '0px !important',
                    backgroundColor: '#1a73e8 !important',
                    width: '32px !important',
                    height: '25px !important',
                    display: 'flex !important',
                    justifyContent: 'center !important',
                },
                '& .css-jc1ljl-MultiEvent':{
                    fontSize: '13px !important',
                }
            },
            '& .day':{
                textAlign: 'center !important',
                fontSize: '14px !important',
                paddingRight: '10px',
                paddingLeft: '2px !important',
                borderRight: '1px solid lightgray !important',
                '& .css-csro95-Calendar':{
                    marginBottom: '10px !important',
                    textAlign: 'center',
                    marginRight: '-10px',
                },
                '& .css-jc1ljl-MultiEvent':{
                    fontSize: '13px !important',
                },
                '& .css-sgs7ru':{
                    marginBottom: '10px !important',
                    textAlign: 'center',
                    marginRight: '-10px',
                },
                '& .css-f3vz0n':{
                    fontSize: '13px !important',
                }
            },
            '& .day:nth-child(7), .day:nth-child(14), .day:nth-child(21), .day:nth-child(28), .day:nth-child(35), .day:nth-child(42), .day:nth-child(49)':{
                borderRight: '0px !important',
            },
            '& .css-13wylk3':{
                textAlign: 'center !important',
                fontSize: '14px !important',
                paddingRight: '10px',
                paddingLeft: '2px !important',
                borderRight: '1px solid lightgray !important',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'center',
                '& .css-sgs7ru':{
                    color: '#fff !important',
                    marginTop: '-8px !important',
                    paddingTop: '8px !important',
                    paddingLeft: '0px !important',
                    borderRadius: '50% !important',
                    marginBottom: '5px !important',
                    paddingRight: '0px !important',
                    paddingBottom: '0px !important',
                    backgroundColor: '#1a73e8 !important',
                    width: '32px !important',
                    height: '25px !important',
                    display: 'flex !important',
                    justifyContent: 'center !important',
                },
                '& .css-f3vz0n':{
                    fontSize: '13px !important',
                }
            },
            '& .calendar-navigate':{
                color: '#000 !important',
                width: '40px !important',
                height: '25px !important',
                display: 'flex !important',
                paddingTop: '8px !important',
                paddingLeft: '0px !important',
                borderRadius: '50% !important',
                marginBottom: '5px !important',
                paddingRight: '3px !important',
                paddingBottom: '8px !important',
                justifyContent: 'center !important',
                backgroundColor: '#transparent !important',
                opacity: '1 !important',
                transition: 'all .3s',
                '& #rightIcon':{
                    marginLeft: '9px'
                },
                '& #leftIcon':{
                    marginLeft: '4px'
                }
            },
            '& #ButtonLeft':{
                marginTop: '3px',
                position: 'absolute',
                left: '7%',
                ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                    left: '7.5%',
                }
            },
            '& #ButtonRight':{
                marginTop: '3px',
                position: 'absolute',
                left: '10%',
                ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                    left: '11%',
                }
            },
            '& #title-box':{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
                marginLeft: '17%',
                '& #powered-by':{
                    display: 'flex',
                    width: '40%',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    '& span':{
                        marginRight: '10px',
                        fontSize: '16px',
                        ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                            fontSize: '14px',
                        },
                        '& svg':{
                            width: '18px',
                            ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                                width: '15px',
                            },
                        }
                    }
                }
            },
            '& .today-button':{
                border: '1px solid #cacaca',
                borderRadius: '4px',
                padding: '10px',
                paddingLeft: '15px',
                paddingRight: '15px',
                position: 'absolute',
                left: '0%',
                color: '#3c4043 !important',
                fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
                fontSize: '16px',
                transition: 'all .3s'
            },
            '& .today-button:hover':{
                backgroundColor: '#eeeeee',
                cursor: 'pointer',
            },
            '& .calendar-navigate:hover':{
                backgroundColor: '#eeeeee !important',
            },
            '& .calendar-title':{
                color: '#3c4043 !important',
                fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
                fontSize: '25px !important',
                fontWeight: '400 !important',
                letterSpacing: '0 !important',
                lineHeight: '28px !important',
                whiteSpace: 'nowrap !important',
                width: '35%',
            },
            '& .tooltip':{
                border: '1px solid #d9d9d9 !important',
                boxShadow: '1px 1px 10px #bbb6b6 !important',
                maxWidth: '350px !important',
                width: '350px !important',
            },
            '& .css-1micmwc-Tooltip':{
                color: '#3c4043 !important',
                fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
                fontSize: '25px !important',
                fontWeight: '400 !important',
                letterSpacing: '0 !important',
                lineHeight: '28px !important',
            },
            '& .css-xfysr9-Tooltip':{
                paddingLeft: '10px',
                paddingRight: '10px',
                paddingBottom: '10px',
            },
            '& .css-xaf3ee-Tooltip':{
                textDecoration: 'none !important',
                color: '#1a73e8 !important',
                width: '101%',
                display: 'block',
            },
            '& .css-xaf3ee-Tooltip:hover':{
                textDecoration: 'underline !important',
            },
        },
        CalendarFilterButton:{
            width: 'fit-content',
            display: 'flex',
            position: 'absolute',
            left: '94%',
            top: '7%',
            zIndex: '100',
            ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
                left: '92.5%',
                top: '7%',
            },
            '& #button':{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                color: '#3c4043 !important',
                fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
                fontSize: '16px',
                transition: 'all .3s',
                border: '1px solid #cacaca',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: 'transparent !important',
                margin: '0px !important',
                '& svg':{
                    fontSize: '1.2rem !important',
                    marginRight: '5px',
                }
            },
            '& #button:hover':{
                backgroundColor: '#eeeeee !important',
                cursor: 'pointer',
            }
        }
    },

    // >>----------------------------------<< //
    // >>===== CSS - DASHBOARD STYLES =====<< //
    // >>----------------------------------<< //

    DashboardStyles:{
        BoxTableRowOffers: {
            '& .MuiBox-root':{
                paddingBottom: '5px', 
                paddingLeft: '20px', 
                marginLeft: '-20px', 
                marginRight: '-20px', 
                display: 'flex',
                paddingTop: '5px',
                alignItems: 'center',
                height: '40px !important',
                '& a':{
                    display: 'none',
                }
            },
            '& .MuiBox-root:hover':{
                backgroundColor: '#f4f4f4',
                height: '40px !important',
                '& a':{
                    display: 'flex',
                }
            },
            '& #User_id':{
                display: 'flex',
                justifyContent: 'flex-start',
                '& span': {
                    width: '100px',
                    overflow: 'hidden',
                    fontSize: '14px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }
            },
            '& #Offers_Title':{
                cursor: 'pointer',
                display: 'flex', 
                width: '100%', 
                justifyContent: 'flex-start'
            },
            '& #Offers_SeeMore':{
                display: 'flex', 
                width: '100%', 
                justifyContent: 'flex-end', 
                marginRight: '15px'
            }
        },
        NewsBody: {
            color: '#3d3d3d',
            textOverflow: 'ellipsis', 
            textDecoration: 'none', 
            overflow: 'hidden', 
            height: '100px',
            backgroundImage: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 60%)',
            WebkitTextFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
            paddingRight: '20px',
            /*'& ul li::marker':{
                content: '"•"',
            },
            '& ol li::marker':{
                content: '"•"',
            },*/
            '& strong':{
                paddingLeft: '5px',
            },
            '& li':{
                paddingLeft: '5px',
            }
        },
        TitleIcon: {
            marginBottom: '-5px', 
            marginRight: '10px'
        },
        CardTitleBox: {
            borderBottom: '1px solid rgb(217, 217, 217)', 
            paddingBottom: '20px', 
            paddingLeft: '20px', 
            marginLeft: '-20px', 
            marginRight: '-20px', 
            display: 'flex',
            marginTop: '-21px',
            paddingTop: '21px',
            backgroundColor: '#ececec',
        },
        CardTitleBoxAdmin: {
            paddingBottom: '20px', 
            paddingLeft: '20px', 
            paddingRight: '20px', 
            marginLeft: '-20px', 
            marginRight: '-20px', 
            display: 'flex',
            marginTop: '-21px',
            paddingTop: '21px',
            height: '20%',
        },
        BoxTableRowNews: {
            '& #Hover_Row:hover':{
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '& .MuiBox-root':{
                paddingBottom: '5px', 
                paddingLeft: '20px', 
                marginLeft: '-20px', 
                marginRight: '-20px', 
                display: 'flex',
                paddingTop: '5px',
                '& #News_Title':{
                    display: 'flex', 
                    width: '100%', 
                    justifyContent: 'flex-start'
                },
                '& #News_Body':{
                    display: 'flex', 
                    width: '100%', 
                    justifyContent: 'flex-start',
                    marginTop: '-20px',
                    '& .MuiTypography-body2':{
                        marginTop: '20px',
                    }
                },
            }
        },
        BoxTableRowProvider: {
            '& .MuiBox-root':{
                paddingBottom: '10px', 
                paddingLeft: '20px', 
                marginLeft: '-20px', 
                marginRight: '-20px', 
                display: 'flex',
                paddingTop: '10px',
                cursor: 'pointer',
                '& a':{
                    display: 'none',
                },
            },
            '& .MuiBox-root:hover':{
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '& a':{
                    display: 'flex',
                }
            },
            '& #ImageBox':{
                marginLeft: '-10px', 
                marginRight: '20px',
                width: '70px',
                height: '70px',
                minWidth: '70px',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: '50px',
            },
            '& #TitleBox':{
                width: '100%',
            }
        },
        ProviderSeeMoreButton: {
            display: 'flex', 
            width: '35%', 
            justifyContent: 'flex-end', 
            marginRight: '15px', 
        },
        BoxQTY: {
            marginRight: '30px',
            display: 'flex',
            width: '80%',
            justifyContent: 'flex-end',
        },
        NumberQTY: {
            backgroundColor: '#C5DAF6',
            padding: '20px',
            marginTop: '-20px',
            marginBottom: '-20px',
            marginRight: '-30px',
            paddingLeft: '30px',
            borderBottomLeftRadius: '25px',
            borderTopLeftRadius: '50px',
        },
        FlexBox: {
            display: 'flex',
            width: '100%',
            marginTop: '15px',
            marginBottom: '15px'
        },
        AdminFlexBox: {
            display: 'flex',
            width: '100%',
            marginBottom: '30px'
        },
        AdminFlexBox1: {
            display: 'flex',
            width: '66.4%',
            marginBottom: '30px'
        },
        AdminUsersBox: {
            width: '100%', 
            height: 'auto',
            '& #SmallCard:hover':{
                boxShadow: '0px 1px 10px #848484 !important'
            }
        },
        AdminBox: {
            width: '100%', 
            height: 'auto',
            marginLeft: '15px',
            '& #SmallCard:hover':{
                boxShadow: '0px 1px 10px #848484 !important'
            }
        },
        AdminCard: {
            padding: '20px', 
            paddingBottom: '15px',
            height: '89%',
            '& #AdminCard':{
                height: '100%',
                '& #CardBody':{
                    height: '70%',
                    '& #Link':{
                        height: "60%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                    }
                }
            }
        },
        AdminSmallCard: {
            paddingRight: '20px',
            paddingLeft: '20px',
            paddingTop: '10px', 
            paddingBottom: '10px',
            height: '89%',
        },
        PromotionsBox: {
            width: '100%', 
            height: 'auto',
            marginBottom: '20px'
        },
        PromotionsCard: {
            padding: '20px', 
            paddingBottom: '0px !important',
            height: '100%',
        },
        OrdersChartBox: {
            width: '100%', 
            height: 'auto',
            marginBottom: '20px',
            marginRight: '18px',
        },
        OrdersChartCard: {
            padding: '20px', 
            height: '100%',
        },
        UsersChartBox: {
            width: '100%', 
            height: 'auto',
            marginBottom: '20px',
        },
        UsersChartCard: {
            padding: '20px', 
            height: '100%',
            marginBottom: '10px',
            paddingLeft: '0px !important'
        },
        OrdersBox: {
            width: '49%', 
            height: 'auto',
            marginBottom: '20px'
        },
        OrdersCard: {
            padding: '20px', 
            height: '100%',
        },
        NewsBox: {
            width: '120%', 
            height: 'auto'
        },
        NewsCard: {
            padding: '20px', 
            height: '90%',
        },
        ProvidersBox: {
            width: '80%', 
            height: 'auto', 
            marginLeft: '20px',
        },
        ProvidersCard: {
            padding: '20px', 
            height: '90%',
        },
        CalendarBox: {
            width: 'fit-content', 
            justifyContent: 'flex-end', 
            display: 'flex', 
            marginLeft: '20px',
            flexDirection: 'row',
            '& .react-calendar':{
                boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
            },
            '&  .react-calendar__month-view__days__day':{
                '& abbr':{
                    padding: '9px !important',
                    borderRadius: '50% !important',
                    height: '35px',
                    width: '35px',
                    display: 'block',
                }
            },
            '& .react-calendar__tile--active':{
                backgroundColor: '#fff !important',
                color: '#000 !important',
                '& abbr':{
                    backgroundColor: '#196cdb !important',
                    color: '#fff !important',
                    padding: '9px !important',
                    borderRadius: '50% !important',
                    height: '35px',
                    width: '35px',
                    display: 'block',
                }
            },
            '& .react-calendar__tile--active:enabled:hover':{
                backgroundColor: '#fff !important',
                '& abbr':{
                    backgroundColor: '#196cdbbd  !important',
                }
            },
            '& .react-calendar__tile--now':{
                backgroundColor: '#fff !important',
                color: '#000 !important',
                '& abbr':{
                    backgroundColor: '#ffff76 !important',
                    color: '#000 !important',
                    padding: '9px',
                    borderRadius: '50%',
                    height: '35px',
                    width: '35px',
                    display: 'block',
                }
            },
            '& .react-calendar__tile--now:enabled:hover':{
                backgroundColor: '#fff !important',
                '& abbr':{
                    backgroundColor: '#ffffa9  !important',
                }
            },
            '& .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus': {
                backgroundColor: '#fff',
                '& abbr':{
                    backgroundColor: '#e6e6e6',
                    padding: '9px',
                    borderRadius: '50%',
                    height: '35px',
                    width: '35px',
                    display: 'block',
                }
            },
            '& .react-calendar__year-view__months__month':{
                paddingTop: '5px !important',
                '& abbr':{
                    color: '#000 !important',
                    padding: '9px !important',
                    borderRadius: '5px !important',
                    height: '40px !important',
                    width: '100px !important',
                    display: 'block !important',
                }
            },
            '& .react-calendar__year-view__months__month:hover':{
                '& abbr':{
                    backgroundColor: '#e6e6e6',
                    color: '#000 !important',
                    padding: '9px !important',
                    borderRadius: '5px !important',
                    height: '40px !important',
                    width: '100px !important',
                    display: 'block !important',
                }
            },
            '& .react-calendar__tile--hasActive':{
                borderRadius: '5px !important',
                '& abbr':{
                    color: '#000 !important',
                    padding: '9px !important',
                    borderRadius: '5px !important',
                    height: '40px !important',
                    width: '100px !important',
                    display: 'block !important',
                }
            }
        },
        imageBoxButton: {
            width: '50px'
        },
        RowTitle: {
            width: '500px',
            paddingTop: '3px',
            paddingBottom: '10px',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        NewsCategory:{
            color: 'rgb(25, 108, 219)',
            marginTop: '-7px',
            textTransform: 'uppercase',
            fontSize: '0.75rem',
        },
        RowDate: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingTop: '10px',
            textTransform: 'uppercase',
            width: '100px',
            marginLeft: '148px',
            color: '#797979',
        },
        RowTitleOffer: {
            width: '75%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            justifyContent: 'flex-start',
            display: 'flex',
            fontSize: '14px',
        },
        RowDateOffer: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '11%',
            color: '#797979',
        },
        RowDateNews: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            paddingTop: '10px',
            width: '100px',
            marginLeft: '95px',
            color: '#797979',
            textAlign: 'right'
        },
        RowTitleProviderName: {
            textTransform: 'uppercase',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '300px',
        },
        RowTitleProviderShop: {
            paddingTop: '10px',
            textTransform: 'uppercase',
            color: '#196cdb',
        },
        SeeMoreButton:{
            marginTop: '15px', 
            marginLeft: '-5px',
            '& .MuiButtonBase-root':{
                border: '1px solid rgb(132, 132, 132)',
                textTransform: 'capitalize',
                paddingLeft: '10px',
                paddingRight: '10px'
            }
        },
    },

    // >>----------------------------------<< //
    // >>===== CSS - WHATS NEW STYLES =====<< //
    // >>----------------------------------<< //

    WhatsNewStyles:{
        WelcomeBox :{
            paddingTop: '1em',
            paddingBottom: '1em',
            marginBottom: '10px',
        },
        WelcomeBox2 :{
            paddingBottom: '1em',
        },
        AdminButtons:{
            '& .MuiButtonBase-root':{
                color: '#196cdb !important',
                textTransform: 'capitalize !important',
            },
            '& .MuiButtonBase-root:hover':{
                backgroundColor: '#196cdb2e !important',
            }
        },
        LinksTitles:{
            marginTop: '15px',
            marginLeft: '-5px',
            '& p':{
                color: '#196cdb',
                cursor: 'pointer',
                padding: '5px',
                borderRadius: '5px',
            },
            '& p:hover':{
                backgroundColor: '#196cdb2e !important',
            }
        },
        SmallAdminBox: {
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            '& #IconBox':{
                width: '10%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                marginRight: '20px'
            },
            '& #TextBox':{
                width: '90%',
                '& h6':{
                    fontSize: '1.1rem',
                },
                '& p':{
                    fontSize: '0.85rem',
                }
            },
        },
        StepperBox :{
            maxWidth: '100%',
            '& #TitleBox':{
                display: 'flex',
                alignItems: 'center',
            },
            '& #imageBox':{
                height: 255,
                display: 'block',
                maxWidth: 400,
                overflow: 'hidden',
                width: '100%',
            },
            '& #stepper':{
                position: 'static',
                display: 'flex',
                justifyContent: 'center',
                padding: '20px',
            },
            '& #Button':{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                paddingBottom: '10px',
            }
        },
        NewsCard:{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '10px 10px 0px 10px',
            '& #Title':{
                paddingBottom: '10px',
            },
            '& #Category':{
                paddingBottom: '8px',
            },
            '& #Body':{
                color: '#000',
                width: '90%',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                lineHeight: '1.4285714285714286em',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '3',
                WebkitBoxOrient: 'vertical',
                '& #review':{
                    '& p':{
                        margin: '0px !important',
                        marginBottom: '10px !important',

                    }
                },
                '& #subTitle':{
                    marginBottom: '10px',
                    color: '#3d3d3d',
                    '& p':{
                        margin: '0px !important',
                        marginBottom: '10px !important',
                        color: '#3d3d3d',
                    }
                },
            },
            '& #seeMore':{
                content: '"Leer más »"',
                color: '#3d3d3d',
                transition: 'all .2s',
                paddingTop: '15px',
                display: 'block',
                width: 'fit-content',
                fontSize: '14px',
            },
            '& #seeMore:hover':{
                color: '#196cdb',
                cursor: 'pointer',
            },
            '& #Image':{
                overflow: 'hidden',
                borderRadius: '8px', 
                backgroundColor: '#fff', 
                height: '200px', 
                width: '200px',
                paddingRight: '30px',
                '& g-img':{
                    display: 'flex', 
                    height: '100%', 
                    justifyContent: 'center', 
                    width: '100%',
                    paddingTop: '20px',
                }
            }
        },
        BannersBox:{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            '& #BlockBox':{
                height: '350px',
                width: '100%',
                overflow: 'hidden',
                position: 'relative',
                borderRadius: '10px',
                boxShadow: '0px 8px 10px #d9d2d2 !important',
                border: '1px solid #eeeeee',
            },
            '& #BlockBox:first-child':{
                marginRight: '10px'
            },
            '& #BlockBox:nth-child(2)':{
                marginRight: '10px'
            },
            '& #Card':{
                alignItems: 'center',
                backgroundColor: 'transparent !important',
                backgroundPosition: 'center',
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                transition: 'all .5s',
                height: '350px',
                display: 'flex',
                justifyContent: 'center',
                '& #text':{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    justifyContent: 'flex-start',
                    marginTop: '100px',
                    marginLeft: '50px',
                    alignItems: 'start',
                    height: '100%',
                    color: '#fff',
                    textShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                    '& #title':{
                        fontSize: '40px',
                    },
                    '& #subTitle':{
                        marginTop: '20px',
                        fontSize: '24px',
                    }
                },
                '& #poweredBy':{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    height: '100%',
                    color: '#fff',
                    textShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                    marginTop: '30px',
                    fontSize: '15px',
                    '& span':{
                        marginRight: '10px'
                    },
                    '& #icon':{
                        '& svg':{
                            width: '25px'
                        }
                    }
                }
            },
            '& #Card:hover':{
                backgroundSize: '110%',
                cursor: 'pointer',
            },
        },
    },

    // >>-------------------------------------<< //
    // >>===== CSS - USER PROFILE STYLES =====<< //
    // >>-------------------------------------<< //

    ProfileStyles:{
        MaterialUI_Tabs: {
            marginTop: '10px',
            '& .MuiAppBar-colorDefault':{
                backgroundColor: '#fbfbfb !important',
                boxShadow: '0px 0px 0px #fff !important',
                '& .MuiTabs-indicator': {
                    backgroundColor: '#196cdb !important',
                },
                '& .MuiTab-textColorPrimary.Mui-selected': {
                    color: '#196cdb !important',
                    textTransform: 'capitalize',
                },
                '& .MuiTab-textColorPrimary': {
                    textTransform: 'capitalize',
                },
            },
            '& .MuiTab-labelIcon:hover:active':{
                borderRadius: '5px !important',
                color: '#196cdb !important'
            }
        },
        Profile: {
            paddingTop: '10px', 
            paddingBottom: '20px',
            '& .MuiBox-root': {
                textAlign: 'center',
            }
        },
        Profile_ImageBlock:{
            '& img':{
                width: '250px',
                border: '5px solid #fff',
                borderRadius: '50%'
            }
        },
        Profile_InfoBlock: {
            paddingTop: '0px',
            paddingLeft: '20px',
            paddingRight: '0px',
            paddingBottom: '0px !important',
            width: '100%',
        },
        UserInfoCard:{
            justifyContent: 'flex-start',
            display: 'flex',
            marginBottom: '20px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            paddingTop: '30px',
            paddingBottom: '30px',
            MaterialUI_Icon:{
                paddingRight: '30px',
                paddingLeft: '50px',
                '& svg':{
                    color: '#424242 !important',
                    fontSize: '4.5rem !important',
                    marginTop: '5px',
                }
            },
            LastCard:{
                justifyContent: 'flex-start',
                display: 'flex',
                paddingTop: '30px',
                paddingBottom: '30px',
            },
            UserInfo_Name: {
                color: '#3d3d3d' ,
                fontSize: '30px', 
                padding: '5px',
                fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
                fontWeight: 'normal !important',
                cursor: 'default',
            },
            UserInfo_Email: {
                marginTop: '10px',
                color: '#3d3d3d',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                '& span':{
                    textDecoration: 'none', 
                    color: '#3d3d3d',
                    padding: '10px',
                    borderRadius: '5px',
                    fontFamily:'Montserrat,sans-serif',
                    transition: 'ease 0.6s',
                },
                '& span:hover':{
                    backgroundColor: '#f2f2f2',
                    cursor: 'default',
                },
                '& .divider':{
                    display: 'block',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                },
                '& .divider:last-child':{
                    display: 'none !important',
                }
            },
            UserInfo_Text: { 
                marginLeft: '30px',
                '& .MuiTypography-body1':{
                    color: '#000 !important',
                    fontSize: '1.1rem !important',
                },
            },
        },
        Divider_Text: {
            color: '#3d3d3d' ,
            fontSize: '25px', 
            padding: '5px',
            fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
            fontWeight: 'normal !important',
            cursor: 'default',
        },
        ShopGrid_Card:{
            paddingBottom: '30px',
            justifyContent: 'flex-start',
            display: 'flex',
            Shop_Image:{
                height: '60px',
                width: '60px',
                marginTop: '-20px',
                marginLeft: '20px',
                marginRight: '-25px',
                borderRadius: '50%',
            },
            LogoBox_FoM:{
                backgroundColor: '#ff1e0a91',
                borderRadius: '50%',
                width: '100px',
                height: '100px',
                marginLeft: '20px',
                marginTop: '23px',
                '& svg':{
                    fontSize: '3.0rem !important',
                },
                '& img':{
                    height: '60px',
                    width: '60px',
                    marginTop: '38px',
                },
            },
            LogoBox_Atleet:{
                backgroundColor: '#00bcb4c4',
                borderRadius: '50%',
                width: '100px',
                height: '100px',
                marginLeft: '20px',
                marginTop: '23px',
                '& svg':{
                    fontSize: '3.0rem !important',
                },
                '& img':{
                    height: '80px',
                    width: '80px',
                    marginLeft: '-12px',
                    marginTop: '25px',
                },
            },
            LogoBox_Sotkatt:{
                backgroundColor: '#b7b7b73d',
                borderRadius: '50%',
                width: '100px',
                height: '100px',
                marginLeft: '20px',
                marginTop: '23px',
                '& svg':{
                    fontSize: '3.0rem !important',
                },
                '& img':{
                    height: '80px',
                    width: '80px',
                    marginLeft: '-12px',
                    marginTop: '25px',
                },
            },
            LogoBox_Trendico:{
                backgroundColor: '#3d3d3d9c',
                borderRadius: '50%',
                width: '100px',
                height: '100px',
                marginLeft: '20px',
                marginTop: '23px',
                '& svg':{
                    fontSize: '3.0rem !important',
                },
                '& img':{
                    height: '80px',
                    width: '80px',
                    marginLeft: '-12px',
                    marginTop: '25px',
                }
            },
            Black_Text: {
                marginLeft: '30px',
                marginTop: '15px',
                marginBottom: '-5px',
                '& .MuiTypography-body1':{
                    color: '#000 !important',
                    fontSize: '1.2rem !important',
                    marginTop: '5px !important',
                    '& svg':{
                        marginBottom: '-5px',
                        fontSize: '1.6rem !important',
                    }
                },
                '& .MuiTypography-body2':{
                    lineHeight: '1.50 !important',
                    marginTop: '5px',
                    marginBottom: '-10px',
                    fontSize: '0.9rem !important',
                },
                '& #CompanyName':{
                    marginTop: '3px',
                    marginBottom: '3px',
                    fontSize: '1rem !important',
                    color: '#196cdb',
                }
            },
            LogoBox_OldStyle:{
                paddingTop: '50px',
                paddingRight: '30px',
                paddingLeft: '50px',
                marginBottom: '-30px',
                paddingBottom: '56px',
                backgroundColor: '#ff1e0a91',
                borderTopRightRadius: '50%',
                borderBottomRightRadius: '25%',
                '& svg':{
                    fontSize: '3.0rem !important',
                },
                '& img':{
                    height: '60px',
                    width: '60px',
                    marginLeft: '-40px',
                    marginTop: '15px',
                }
            },
        },
        UserCardBackground:{
            height: '180px',
            backgroundSize: 'cover',
            backgroundImage: 'url(/static/images/ProfileImageBackground.jpg)',
            backgroundPosition: 'center',
            marginTop: '-38px',
            paddingBottom: '13px',
            marginBottom: '-150px',
            marginLeft: '-21px',
            marginRight: '-20px',
            borderBottom: '5px solid #fff'
        },
        CodeBox: {
            position: 'relative',
            top: '30px',
            left: '30px',
            textAlign: 'left !important',
            '& p':{
                display: 'flex',
                flexDirection: 'row',
                margin: '0px !important',
            },
            '& span':{
                color: 'rgb(25, 108, 219) !important',
                width: '70px',
                border: '1px solid rgb(25, 108, 219)',
                display: 'flex',
                padding: '0px',
                borderRadius: '25px',
                textTransform: 'capitalize !important',
                justifyContent: 'center',
                backgroundColor: 'rgba(25, 108, 219, 0.25) !important',
                fontSize: '14px',
                marginLeft: '5px !important',
                alignItems: 'center',
                alignContent: 'center',
            }
        },
        UserCardImage: {
            paddingTop: '20px', 
            paddingBottom: '40px', 
            paddingLeft: '90px', 
            paddingRight: '90px', 
            marginBottom: '-20px',
        },
        InfoText: {
            marginLeft: '30px',
            marginTop: '15px',
            marginBottom: '-5px',
            '& .MuiTypography-body1':{
                color: '#000 !important',
                fontSize: '1.4rem !important',
                marginTop: '5px !important',
                '& svg':{
                    marginBottom: '-3px',
                    fontSize: '1.6rem !important',
                }
            },
            '& .MuiTypography-body2':{
                lineHeight: '1.50 !important',
                marginTop: '5px',
                marginBottom: '-10px',
                fontSize: '0.9rem !important',
            }
        },
    },

    // >>----------------------------<< //
    // >>===== CSS - FAQ STYLES =====<< //
    // >>----------------------------<< //

    FAQStyles: {
        CreateAndEdit: {
            FAQInputCard: {
                marginLeft: '10px',
                marginRight: '10px',
            }
        },
        FAQList:{

        },
    },

    // >>--------------------------------------<< //
    // >>===== CSS - POINT OF SALE STYLES =====<< //
    // >>--------------------------------------<< //

    PointOfSaleStyle:{
        CreateAndEdit:{
            ContactArray:{
                '& .MuiFormControl-root':{
                    '& li':{
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        marginRight: '25px',
                        '& p':{
                            display: 'none',
                        }
                    }
                }
            }
        },
        List:{
            RightSerchBar: {
                '& .MuiToolbar-root': {
                  display: 'flex',
                  '& .MuiToolbar-gutters':{
                    paddingTop: '21px',
                    width: 'auto',
                    '& a':{
                      marginLeft: '0px !important',
                      '& span':{
                        width: '110px !important',
                      }
                    }
                  },
                  '& form': {
                    width: '100%',
                    justifyContent: 'right',
                  },
                },
                '& .list-page':{
                  marginTop: '-45px'
                },
            },
            root: {
                marginRight: '-16px',
                marginLeft: '10px',
                zIndex: '0',
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: '#939393',
                    },
                    '&:hover fieldset': {
                    borderColor: '#000',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: '#196cdb',
                    },
                },
                '& .RaBulkActionsToolbar-toolbar': {
                    '& h6': {
                        color: '#3d3d3d',
                    },
                },
            },
        },
        View:{
            LogoBox: {
                borderRadius: '50%',
                width: '100px',
                height: '100px',
                marginLeft: '20px',
                marginTop: '23px',
                '& img':{
                    height: '80px',
                    width: '80px',
                },
            },
            InfoBox: {
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'flex-start', 
                marginTop: '25px',
                marginLeft: '30px',
                '& .MuiChip-outlined':{
                    marginLeft: '-5px',
                    backgroundColor: '#ccc',
                    color: '#000 !important',
                    border: 'none !important',
                },
                '& .MuiChip-outlined:hover':{
                    backgroundColor: '#196cdb3d',
                    color: '#196cdb !important',
                    border: 'none !important',
                },
            },
            ContactBox: {
                width: '100%',
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'flex-start', 
                marginTop: '40px',
                marginLeft: '20px', 
                '& a':{
                    color: '#196cdb',
                    textDecoration: 'none',
                }
            },
            Aside:{
                width: '300px',
                height: '100px',
                boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
                marginLeft: '20px',
                padding: '20px',
                borderRadius: '10px',
                marginBottom: '20px',
                '& a':{
                    color: '#196cdb',
                    textDecoration: 'none',
                }
            },
            TimeLine: {
                marginTop: '-15px',
                '& .MuiTimeline-root':{
                    alignItems: 'baseline !important',
                    '& li':{
                        '& ::before':{
                            content: 'none !important',
                        }
                    },
                    '& .MuiTimelineItem-root':{
                        width: '105%',
                    },
                    '& .MuiTimelineItem-root::before':{
                        content: 'none !important',
                    },
                },
            },
            DirectionCard:{
                width: '100%',
                borderRadius: '10px',
                padding: '20px',
                marginTop: '20px'
            },
            Contact: {
               marginBottom: '35px',
               '& svg#mail':{
                marginBottom: '-3px',
                fontSize: '1rem',
                color: '#196cdb',
               },
               '& svg#phone':{
                marginBottom: '-3px',
                marginRight: '7px',
                fontSize: '1rem',
                color: '#000',
               },
            },
            MapsButton:{
                '& .MuiButtonBase-root':{
                    color: '#196cdb !important',
                    textTransform: 'capitalize !important',
                },
                '& .MuiButtonBase-root:hover':{
                    backgroundColor: '#196cdb2e !important',
                }
            },
            AtleetChip:{
                '& .MuiChip-root':{
                    backgroundColor: '#ccc',
                    color: '#000 !important',
                    border: 'none !important',
                },
                '& .MuiChip-root:hover':{
                    backgroundColor: '#3bcbc5',
                    color: '#fff !important',
                    border: 'none !important',
                }, 
            },
            FoMChip:{
                '& .MuiChip-root':{
                    backgroundColor: '#ccc',
                    color: '#000 !important',
                    border: 'none !important',
                },
                '& .MuiChip-root:hover':{
                    backgroundColor: '#ff1e0a',
                    color: '#fff !important',
                    border: 'none !important',
                }, 
            },
            DarkChip:{
                '& .MuiChip-root':{
                    backgroundColor: '#ccc',
                    color: '#000 !important',
                    border: 'none !important',
                },
                '& .MuiChip-root:hover':{
                    backgroundColor: '#3d3d3d',
                    color: '#fff !important',
                    border: 'none !important',
                }, 
            },
            Info_Card:{
                paddingBottom: '30px',
                justifyContent: 'flex-start',
                display: 'flex',
                Shop_Image:{
                    height: '60px',
                    width: '60px',
                    marginTop: '-20px',
                    marginLeft: '20px',
                    marginRight: '-25px',
                    borderRadius: '50%',
                },
                LogoBox_FoM:{
                    backgroundColor: '#ff1e0a91',
                    borderRadius: '50%',
                    width: '130px',
                    height: '130px',
                    marginLeft: '20px',
                    marginTop: '25px',
                    '& svg':{
                        fontSize: '3.0rem !important',
                    },
                    '& img':{
                        height: '90px',
                        width: '90px',
                        marginTop: '38px',
                    },
                },
                LogoBox_Atleet:{
                    backgroundColor: '#00bcb4c4',
                    borderRadius: '50%',
                    width: '130px',
                    height: '130px',
                    marginLeft: '20px',
                    marginTop: '25px',
                    '& svg':{
                        fontSize: '3.0rem !important',
                    },
                    '& img':{
                        height: '110px',
                        width: '110px',
                        marginLeft: '-12px',
                        marginTop: '25px',
                    },
                },
                LogoBox_Sotkatt:{
                    backgroundColor: '#b7b7b73d',
                    borderRadius: '50%',
                    width: '130px',
                    height: '130px',
                    marginLeft: '20px',
                    marginTop: '25px',
                    '& svg':{
                        fontSize: '3.0rem !important',
                    },
                    '& img':{
                        height: '110px',
                        width: '110px',
                        marginLeft: '-12px',
                        marginTop: '25px',
                    },
                },
                LogoBox_Trendico:{
                    backgroundColor: '#3d3d3d9c',
                    borderRadius: '50%',
                    width: '130px',
                    height: '130px',
                    marginLeft: '20px',
                    marginTop: '25px',
                    '& svg':{
                        fontSize: '3.0rem !important',
                    },
                    '& img':{
                        height: '110px',
                        width: '110px',
                        marginLeft: '-12px',
                        marginTop: '25px',
                    }
                },
            },
        },
    },

    // >>-------------------------------------<< //
    // >>======== CSS - COMPANY STYLES =======<< //
    // >>-------------------------------------<< //

    CompanyStyle:{
        CreateAndEdit:{
            ContactArray:{
                '& .MuiFormControl-root':{
                    '& li':{
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        marginRight: '25px',
                        '& p':{
                            display: 'none',
                        }
                    }
                }
            }
        },
        List:{
            RightSerchBar: {
                '& .MuiToolbar-root': {
                  display: 'flex',
                  '& .MuiToolbar-gutters':{
                    paddingTop: '21px',
                    width: 'auto',
                    '& a':{
                      marginLeft: '0px !important',
                      '& span':{
                        width: '110px !important',
                      }
                    }
                  },
                  '& form': {
                    width: '100%',
                    justifyContent: 'right',
                  },
                },
                '& .list-page':{
                  marginTop: '-45px'
                },
            },
            root: {
                marginRight: '-16px',
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                    borderColor: '#939393',
                    },
                    '&:hover fieldset': {
                    borderColor: '#000',
                    },
                    '&.Mui-focused fieldset': {
                    borderColor: '#196cdb',
                    },
                },
                '& .RaBulkActionsToolbar-toolbar': {
                    '& h6': {
                        color: '#3d3d3d',
                    },
                },
            },
        },
        View:{
            LogoBox: {
                borderRadius: '50%',
                width: '100px',
                height: '100px',
                marginLeft: '20px',
                marginTop: '23px',
                '& img':{
                    height: '100px',
                    width: '100px',
                },
            },
            InfoBox: {
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'flex-start', 
                marginTop: '30px',
                marginLeft: '30px',
                '& .MuiChip-outlined':{
                    marginLeft: '-5px',
                    backgroundColor: '#ccc',
                    color: '#000 !important',
                    border: 'none !important',
                },
                '& .MuiChip-outlined:hover':{
                    backgroundColor: '#196cdb3d',
                    color: '#196cdb !important',
                    border: 'none !important',
                },
            },
            ContactBox: {
                width: '100%',
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'flex-start', 
                marginTop: '40px',
                marginLeft: '20px', 
                '& a':{
                    color: '#196cdb',
                    textDecoration: 'none',
                }
            },
            Aside:{
                width: '300px',
                height: '100px',
                boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
                marginLeft: '20px',
                padding: '20px',
                borderRadius: '10px',
                marginBottom: '20px',
                '& a':{
                    color: '#196cdb',
                    textDecoration: 'none',
                }
            },
            TimeLine: {
                marginTop: '-15px',
                '& .MuiTimeline-root':{
                    alignItems: 'baseline !important',
                    '& li':{
                        '& ::before':{
                            content: 'none !important',
                        }
                    },
                    '& .MuiTimelineItem-root':{
                        width: '105%',
                    },
                    '& .MuiTimelineItem-root::before':{
                        content: 'none !important',
                    },
                },
            },
            DirectionCard:{
                width: '100%',
                borderRadius: '10px',
                padding: '20px',
                marginTop: '20px'
            },
            Contact: {
               marginBottom: '35px',
               '& svg#mail':{
                marginBottom: '-3px',
                fontSize: '1rem',
                color: '#196cdb',
               },
               '& svg#phone':{
                marginBottom: '-3px',
                marginRight: '7px',
                fontSize: '1rem',
                color: '#000',
               },
            },
            MapsButton:{
                '& .MuiButtonBase-root':{
                    color: '#196cdb !important',
                    textTransform: 'capitalize !important',
                },
                '& .MuiButtonBase-root:hover':{
                    backgroundColor: '#196cdb2e !important',
                }
            },
        }
    },

    // >>--------------------------------------<< //
    // >>========= CSS - APP BAR STYLES =======<< //
    // >>--------------------------------------<< //

    AppBarStyles:{
        AboutModal:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiBackdrop-root':{
            backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
            },
            '& h2':{
                fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
                fontWeight: 'normal !important',
            },
        },
        AboutPaper:{
            backgroundColor: '#fff',
            borderRadius: '10px',
            boxShadow: '1px 1px 10px #757171 !important',
            padding: '20px',
            paddingTop: '5px',
            width: '450px',
            marginLeft: '20vh',
            userSelect: 'none',
            '& .ra-input':{
            marginLeft: '-23px',
            },
            '& .MuiToolbar-root':{
            backgroundColor: '#fff !important',
            marginLeft: '-20px !important',
            marginTop: '-5px',
            },
            '& .MuiCard-root':{
                boxShadow: '0px 0px 0px #fff !important'
            }
        },
        FlexCenter:{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        FlexCenterBox:{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
        },
        FlexStartTitle:{
            padding: '10px',
            paddingLeft: '5px !important',
        },
        FlexEndDescription:{
            color: '#196cdb',
            padding: '10px',
            paddingLeft: '0px !important',
            '& a':{
                color: '#196cdb', 
                textDecoration: 'none'
            }
        },
        FixedAppBar: {
            '& .MuiAppBar-root':{
                zIndex: '1000 !important',
                position: 'fixed !important',
                overflowY: 'auto !important',
                transition: 'all 0.5s ease !important',
                transform: 'none !important',
                visibility: 'visible !important',
                backgroundColor: "#196cdb",
                '& #Layer_1':{
                    marginLeft: '5px',
                },
            },
            '& .MuiToolbar-root':{
                zIndex: '1000 !important',
                position: 'fixed !important',
                overflowY: 'auto !important',
                transition: 'all 0.5s ease !important',
                transform: 'none !important',
                visibility: 'visible !important',
                backgroundColor: "#196cdb",
                width: '100%',
                marginLeft: '-15px',
                boxShadow: '0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12),0px 1px 8px 0px rgba(0,0,0,0.2) !important',
                '& button:first-child':{
                    marginLeft: '15px',
                },
                '& div:last-child':{
                    '& button':{
                        marginLeft: '0px'
                    }
                }
            },
        },
        title: {
            flex: 1,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        spacer: {
            flex: 1,
        },
        avatar: {
            height: 25,
            width: 25,
        },
        basketButton:{
            '& span':{
                margin: '0px',
            },
            '& .MuiBadge-badge':{
                backgroundColor: '#dc004e !important',
                marginTop: '9px',
                marginRight: '6px',
            }
        },
        list: {
            width: 330,
        },
        fullList: {
            width: 'auto',
        },
        TramitarButton: {
            display: 'flex', 
            justifyContent: 'center', 
            marginLeft: '10px', 
            marginRight: '10px', 
            marginTop: '20px', 
            marginBottom: '20px',
            '& div':{
                width: '100%',
                marginLeft: '10px',
                marginRight: '10px',
            },
            '& button':{
                backgroundColor: '#196cdb !important',
                color: '#fff !important',
                paddingRight: '10px !important',
                paddingLeft: '10px !important',
                textTransform: 'none !important',
                fontSize: '0.8125rem',
                padding: '4px 5px',
                width: '100%',
            '& svg':{
                marginLeft: '5px',
                marginRight: '5px',
            }
            },
            '& button:hover':{
                backgroundColor: '#196cdbc4 !important',
            },
        },
        MenuItem: {
            '& #MenuItem-About':{
                width: 'auto',
                overflow: 'hidden',
                fontSize: '1rem',
                boxSizing: 'border-box',
                minHeight: '40px',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontWeight: '400',
                lineHeight: '1.5',
                paddingTop: '6px',
                whiteSpace: 'nowrap',
                letterSpacing: '0.00938em',
                paddingBottom: '6px',
                paddingLeft: '16px',
                paddingRight: '16px',
                color: 'rgba(0, 0, 0, 0.54)',
                cursor: 'default',
                marginBottom: '5px',
                display: 'flex',
                alignItems: 'center',
            },
            '& #MenuItem-About:hover':{
                backgroundColor: 'rgba(229, 229, 229, 0.54) !important',
            },
            '& #MenuIcon':{
                display: 'inline-flex',
                minWidth: '40px',
                flexShrink: '0',
            }
        },
        ProfileMenuItem: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            '& img': {
                width: '80px',
                paddingTop: '10px',
                paddingBottom: '10px'
            },
            '& #infoBox':{
                background: '#e8f0fe',
                borderRadius: '4px',
                margin: '4px',
                padding: '4px 8px',
                textAlign: 'center',
                fontSize: '13px',
                marginTop: '-4px',
                '& spam':{
                    color: '#196cdb',
                },
                '& spam:hover':{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                }
            },
            '& #UserInfo':{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                '& #UserName':{
                    color: '#202124',
                    padding: '5px'
                },
                '& #UserEmail':{
                    color: '#5f6368',
                },
            },
            '& #ProfileButton':{
                border: '1px solid #dadce0',
                borderRadius: '50px',
                color: '#3c4043',
                display: 'inline-block',
                letterSpacing: '0.25px',
                margin: '16px 10px 10px',
                outline: '0',
                padding: '8px 25px',
                textAlign: 'center',
                textDecoration: 'none',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                fontSize: '14px',
            },
            '& #ProfileButton:hover':{
                borderColor: '#196cdb',
                color: '#196cdb',
                backgroundColor: '#196cdb1c !important',
            }
        },
    },

    // >>-----------------------------------------<< //
    // >>========= CSS - CONDITIONS STYLES =======<< //
    // >>-----------------------------------------<< //

    ConditionsStyles:{
        ConditionsTable:{
            '& tr':{
                '& .column-shop':{
                    '& a':{
                        '& span':{
                            color: '#196cdb !important'
                        },
                    },
                },
                '& .MuiTableCell-root':{
                    '& a':{
                        '& .MuiTypography-body2':{
                            color: '#196cdb !important'
                        },
                    },
                },
                '& .column-undefined':{
                    width: '50px !important',
                    height: '40px',
                    '& div':{
                        marginLeft: '-25px',
                        marginRight: '25px',
                        display: 'none',
                    },
                },
            },
            '& tr:hover':{
                '& .column-undefined':{
                    height: '40px',
                    '& div':{
                        display: 'inline-flex',
                    },
                },
            },
        },
        RightSerchBar: {
            '& .MuiToolbar-root': {
              display: 'flex',
              '& .MuiToolbar-gutters':{
                paddingTop: '21px',
                width: 'auto',
                '& a':{
                  marginLeft: '0px !important',
                  '& span':{
                    width: '110px !important',
                  }
                }
              },
              '& form': {
                width: '85%',
                justifyContent: 'right',
                marginBottom: "5px",
                marginTop: "10px",
                alignItems: 'center',
                minHeight: "65px !important",
              },
            },
            '& .list-page':{
              marginTop: '-45px'
            }
        },
        root: {
            marginRight: '-16px',
            marginLeft: '10px',
            zIndex: '0',
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: '#939393',
                },
                '&:hover fieldset': {
                borderColor: '#000',
                },
                '&.Mui-focused fieldset': {
                borderColor: '#196cdb',
                },
            },
            '& .RaBulkActionsToolbar-toolbar': {
                '& h6': {
                    color: '#3d3d3d',
                },
            },
        },
        ConditionsList:{
            FilterLabel: {
                display: 'flex', 
                marginTop: '-5px', 
                alignItems: 'center',
            },
            StateChipYellow:{
                color: '#eaa20f !important',
                backgroundColor: '#fff0bc !important',
                padding: '5px 8px 5px 8px',
                borderRadius: '25px',
                width: '90px',
                textTransform: 'capitalize !important',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '0px !important',
                border: '1px solid #eaa20f',
                '& #title':{
                    fontSize: '14px',
                },
            },
            StateChipGreen:{
                color: 'rgba(40, 121, 18, 0.87) !important',
                backgroundColor: 'rgba(63, 225, 17, 0.23) !important',
                padding: '5px 8px 5px 8px',
                borderRadius: '25px',
                width: '90px',
                textTransform: 'capitalize !important',
                display: 'flex',
                justifyContent: 'center',
                marginLeft: '0px !important',
                border: '1px solid rgba(40, 121, 18, 0.87)',
                '& #title':{
                    fontSize: '14px',
                },
            },
            ConditionsLoading:{
                '& #table':{
                    marginTop: '35px',
                    backgroundColor: '#fff',
                    boxShadow: '0 1px 2px 0 rgba(66,66,66,.3),0 2px 6px 2px rgba(66,66,66,.15) !important',
                    borderRadius: '10px',
                    padding: '10px',
                    '& .MuiDivider-root':{
                        marginTop: '5px',
                        marginBottom: '5px',
                    },
                    '& #header':{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        gap: '15px',
                        '& .MuiCheckbox-root':{
                            width: '3%',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '0px !important',
                        },
                        '& span':{
                            width: '33%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            fontSize: '0.875rem',
                            textAlign: 'left',
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: '500',
                            lineHeight: '1.5',
                            letterSpacing: '0.01071em',
                            height: '30px',
                        },
                    },
                }
            },
            tableRow:{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: '15px',
                '& .MuiCheckbox-root':{
                    width: '3%',
                    padding: '0px !important',
                },
                '& .MuiSkeleton-root':{
                    width: '33%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    height: '50px',
                },
            }
        },
        ConditionsViewStyles:{
            YellowStateChip:{
                width: '110px',
                cursor: 'default',
                display: 'flex',
                marginLeft: '10px',
                justifyContent: 'flex-end',
                height: '40px',
                backgroundColor: '#fff',
                borderRadius: '25px',
                '& .MuiChip-root':{
                    color: '#e1a900',
                    backgroundColor: '#ffc1071f',
                    border: '1px solid #e1a900',
                    width: '110px',
                    height: '40px',
                    borderRadius: '25px',
                    margin: '0px',
                    '& .MuiChip-label:before':{
                        width: '10px',
                        height: '10px',
                        content: '""',
                        borderRadius: '50%',
                        backgroundColor: '#e1a900',
                        display: 'inline-flex',
                        marginRight: '10px',
                    }
                },
            },
            GreenStateChip:{
                width: '110px',
                cursor: 'default',
                display: 'flex',
                justifyContent: 'flex-end',
                height: '40px',
                backgroundColor: '#fff',
                borderRadius: '25px',
                marginLeft: '10px',
                '& .MuiChip-root':{
                    color: '#5cb85c',
                    backgroundColor: '#d4edda',
                    border: '1px solid #5cb85c',
                    width: '110px',
                    height: '40px',
                    borderRadius: '25px',
                    margin: '0px',
                    '& .MuiChip-label:before':{
                        width: '10px',
                        height: '10px',
                        content: '""',
                        borderRadius: '50%',
                        backgroundColor: '#5cb85c',
                        display: 'inline-flex',
                        marginRight: '10px',
                    }
                }
            },
            HideFilesTitle:{
                '& .MuiBox-root':{
                  '& h6':{
                    display: 'none',
                  },
                  '& .MuiToolbar-root':{
                    display: 'none',
                  },
                }
            },
            ReferenceLinkText:{
                '& .MuiTypography-root':{
                  '& a':{
                    '& span':{
                      color: 'rgb(61, 61, 61)',
                      marginTop: '10px',
                      fontSize: '14px !important',
                      cursor: 'pointer',
                    }
                  }
                }
            },
            IconTitle:{
                fontSize: '1.2em', 
                marginBottom: '-5px',
                '& svg':{
                  marginBottom: '-4px',
                  fontSize: '1.2rem',
                },
                '& .MuiTextField-root':{
                  marginBottom: '-5px',
                },
            },
            ProviderLogo:{
                display: 'block',
                height: '50px',
                '& a':{
                    display: 'block',
                    height: '100%',
                    '& div':{
                        display: 'block',
                        height: '100%',
                        '& img':{
                            height: '160px',
                            width: '160px',
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            marginTop: '-55px',
                        }
                    }
                }
            },
            DatesBox:{
                boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
                borderRadius: '10px',
                backgroundColor: '#fff',
                padding: '30px',
                paddingRight: '20px',
                marginTop: '10px',
                '& svg':{
                    marginBottom: '-5px',
                },
                '& #DatesTitle':{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    '& #title-1':{
                        fontSize: '1.2em', 
                        marginRight: '30px', 
                        width: '70%'
                    },
                    '& #title-2':{
                        fontSize: '1.2em', 
                        marginRight: '30px', 
                        width: '35%'
                    },
                },
                '& #divider':{
                    marginBottom: '10px', 
                    marginTop: '11px', 
                    width: '100%',
                },
            },
            dateBox:{
                paddingBottom: '6px',
                paddingTop: '6px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                '& #icon':{
                  width: '35%',
                },
                '& #deadLine':{
                  width: '35%',
                },
                '& #serviceDate':{
                  width: '35%',
                },
                '& div':{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  '& p':{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingBottom: '5px'
                  },
                }
            },
            ConditionsTitle:{
                marginTop: '5px', 
                width: '80%', 
                display: 'flex', 
                justifyContent: 'flex-start', 
                cursor: 'default', 
                flexDirection: 'column',
                '& .MuiTypography-root':{
                  color: '#3d3d3d', 
                  marginTop: '5px', 
                  zIndex: '5', 
                  fontSize: '14px', 
                  cursor: 'pointer'
                },
                '& #categories':{
                  marginLeft: '-5px', 
                  marginTop: '20px',
                  display: 'flex', 
                  justifyContent: 'flex-start', 
                  cursor: 'default', 
                  flexDirection: 'row',
                  '& .MuiTypography-root':{
                    fontSize: '1.2em', 
                    marginRight: '15px', 
                    marginTop: '-3px', 
                    marginLeft: '5px'
                  },
                  '& .MuiChip-root':{
                    border: '1px solid #757575',
                  }
                }
              },
            ConditionsToolbar:{
                paddingBottom: '2px', 
                marginTop: '13px',
            },
            Breadcrumb:{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-start',
                '& #GoogleStyleBackButton':{
                  paddingLeft: '17px', 
                  paddingRight: '5px', 
                  marginRight: '10px'
                },
                '& #ActionsBar':{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-start',
                },
                '& #Main_breadcrumb':{
                  display: 'flex',
                  width: '100%',
                },
                '& #breadcrumbTitle':{
                  color: '#3d3d3d', 
                  marginTop: '10px', 
                  zIndex: '5', 
                  fontSize: '14px', 
                  cursor: 'pointer',
                  '& a':{
                    color: '#3d3d3d',
                    '& span':{
                        color: '#3d3d3d',
                    }
                  }
                },
                '& #navigationArrow':{
                  marginLeft: '10px', 
                  marginRight: '10px', 
                  marginTop: '8px', 
                  marginBottom: '6px',
                },
                '& #mailActions':{
                  width: '30%', 
                  display: 'flex', 
                  justifyContent: 'flex-end',
                  '& #GoogleStyleBackButton':{
                    paddingLeft: '17px', 
                    paddingRight: '5px', 
                    marginRight: '10px'
                  }
                },
                '& #PrintButton':{
                  '& .MuiBox-root':{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                  '& #GoogleStyleBackButton':{
                    paddingLeft: '17px', 
                    paddingRight: '5px', 
                    marginRight: '10px'
                  }
                },
                '& #AdminButtons':{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  '& #divider':{
                    marginLeft: '20px', 
                    marginRight: '10px', 
                    marginTop: '6px', 
                    marginBottom: '6px'
                  }
                }
            },
            ConditionsBoxShow:{
                marginTop: '-10px',
                '& #reference-to-print':{
                    width: '100%', 
                    paddingLeft: '15px', 
                    paddingRight: '15px', 
                    paddingTop: '5px', 
                    paddingBottom: '-5px',
                    marginBottom: '-15px'
                },
                '& #MainTitle':{
                    display: 'flex', 
                    width: '100%', 
                    justifyContent: 'flex-start', 
                    alignItems: 'center', 
                    height: '100px',
                    '& #ProviderLogoBox':{
                        width: '20%', 
                        marginRight: '20px', 
                        display: 'flex', 
                        justifyContent: 'flex-end',
                    }
                },
                '& #divider-1':{
                    marginBottom: '15px', 
                    marginTop: '17px', 
                    width: 'auto', 
                    marginRight: '20px'
                },
                '& #divider-2':{
                  marginBottom: '15px', 
                  marginTop: '10px', 
                  width: 'auto', 
                  marginRight: '20px'
                },
                '& #SectionTitle':{
                  width: '100%', 
                  fontSize: '1.2em',
                  marginBottom: '-5px',
                  '& .MuiTextField-root':{
                    marginBottom: '-5px'
                  }
                },
                '& #DetailBox':{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '98%',
                  '& #Box1':{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '35%',
                    '& .MuiBox-root':{
                      marginRight: '10px',
                      width: '100%'
                    }
                  },
                  '& #Box2':{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '35%',
                    '& .MuiBox-root':{
                      marginLeft: '10px', 
                      marginRight: '-10px',
                      width: '100%'
                    }
                  },
                  '& #Box3':{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '35%',
                    '& .MuiBox-root':{
                      marginLeft: '10px', 
                      marginRight: '-10px',
                      width: '100%'
                    }
                  },
                }
            },
            ConditionsAtachedFilesBox:{
                width: '100%',
                '& #Blocktitle':{
                  width: '100%', 
                  marginTop: '10px', 
                  marginBottom: '-60px', 
                  padding: 1, 
                  display: 'flex', 
                  justifyContent: 'flex-start'
                },
                '& #FilesGridBlock':{
                  marginTop: '45px'
                }
            }
        }
    },

    // >>---------------------------------------<< //
    // >>========= CSS - PROVIDER STYLES =======<< //
    // >>---------------------------------------<< //

    ProviderStyles:{
        Show:{
            ContactBox:{
                '& #contentBox':{
                    borderTop: '1px solid #cacaca',
                    paddingBottom: '0.5em',
                    paddingTop: '0.5em',
                },
                '& #contentBox:first-child':{
                    borderTop: '0px solid #cacaca !important',
                    paddingBottom: '0.5em',
                    paddingTop: '0.5em',
                },
                '& #Name':{
                  fontSize: '1.2rem', 
                  paddingTop: '5px', 
                  paddingBottom: '5px',
                  '& svg':{
                    height: '22px !important',
                    width: '22px !important',
                    marginBottom: '-4px',
                  }
                },
                '& #WorkPlace':{
                  fontSize: '0.8rem !important',
                  marginBottom: '7px',
                  '& svg':{
                    height: '15px !important',
                    width: '15px !important',
                    marginBottom: '-3px',
                  }
                },
                '& #Email':{
                  fontSize: '0.9rem !important',
                  marginTop: '-5px',
                  marginBottom: '7px',
                  '& a':{
                    textDecoration: 'none',
                    color: '#196cdb',
                  },
                  '& a:hover':{
                    textDecoration: 'underline',
                  },
                  '& svg':{
                    height: '15px !important',
                    width: '15px !important',
                    marginBottom: '-3px',
                  }
                },
                '& #Phone':{
                  fontSize: '0.9rem !important',
                  marginTop: '-5px',
                  marginBottom: '7px',
                  '& svg':{
                    height: '15px !important',
                    width: '15px !important',
                    marginBottom: '-3px',
                  }
                }
            },
            SeeMoreButton:{
            '& .MuiButtonBase-root':{
                color: '#196cdb !important',
                textTransform: 'capitalize !important',
            },
            '& .MuiButtonBase-root:hover':{
                backgroundColor: '#196cdb2e !important',
            }
            },
            Contact: {
            '& svg#mail':{
                marginBottom: '-4px',
                marginRight: '7px',
                fontSize: '1rem',
                color: '#196cdb',
            },
            '& svg#phone':{
                marginBottom: '-3px',
                marginRight: '7px',
                fontSize: '1rem',
                color: '#000',
            },
            '& svg#location':{
                marginTop: '3px',
                marginRight: '7px',
                fontSize: '1.1rem',
                color: '#000',
            },
            '& #web':{
                '& svg':{
                marginBottom: '-4px',
                marginRight: '7px',
                fontSize: '1rem',
                color: '#196cdb',
                },
                '& a':{
                fontSize: '0.9rem', 
                color: '#196cdb', 
                textDecoration: 'none',
                },
                '& a:hover':{
                textDecoration: 'underline',
                }
                },
            },
            TabbedForm:{
                padding: '10px',
                marginBottom: '50px',
                '& .MuiButtonBase-root:hover:active':{
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                    color: '#196cdb !important',
                },
                '& .MuiTabs-indicator':{
                    backgroundColor: '#196cdb !important',
                },
                '& .Mui-selected':{
                    '& span':{
                    color: '#196cdb !important',
                    }
                },
                '& .MuiDivider-root':{
                    marginLeft: '32px',
                    marginRight: '25px',
                },
                '& .MuiTabs-root':{
                    marginLeft: '32px !important'
                },
                '& #SeeMoreButton':{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '-50px',
                    paddingTop: '10px',
                    paddingBottom: '10px'
                }
            },
            LogoImg: {
            marginTop: '-35px',
            '& img':{
                paddingLeft: '35px',
                paddingRight: '35px',
                paddingTop: '10px',
                paddingBottom: '10px',
            }
            }
        }
    },

    // >>------------------------------------<< //
    // >>========= CSS - FILES STYLES =======<< //
    // >>------------------------------------<< //

    FilesStyles:{
        FilesTable:{
            marginTop: '10px',
            '& th':{
                backgroundColor: '#fbfbfb !important',
                '& span[data-field="name"]':{
                    marginLeft: '-72px'
                }
            },
            '& td':{
                '& .MuiCircularProgress-root':{
                    color: '#196cdb !important',
                    width: '20px !important',
                    height: '20px !important',
                    marginLeft: '15px',
                    marginTop: '5px',
                },
            },
            '& tr':{
                '& .column-name':{
                    height: '30px !important',
                    '& span':{
                        marginLeft: '-30px !important'
                    },
                },
                '& .column-user':{
                    height: '30px !important',
                    '& span':{
                        color: '#196cdb',
                    }
                },
                '& .column-undefined':{
                    width: '50px !important',
                    height: '30px !important',
                    '& div#FileLogo':{
                        backgroundRepeat: 'no-repeat',
                        height: '30px !important',
                        backgroundSize: '40px !important',
                    },
                    '& svg[data-testid="ErrorIcon"]':{
                        height: '35px !important',
                        color: '#dc004e !important',
                    },
                    '& a':{
                        display: 'none',
                    },
                    '& button':{
                        display: 'none',
                    },
                },
            },
            '& tr:hover':{
                '& .column-undefined':{
                    height: '30px',
                    '& div#FileLogo':{
                        backgroundRepeat: 'no-repeat !important',
                        backgroundPosition: 'center !important',
                        height: '30px !important',
                        backgroundSize: '40px !important',
                        backgroundColor: 'transparent !important',
                    },
                    '& a':{
                        display: 'inline-flex',
                    },
                    '& button':{
                        display: 'inline-flex',
                    },
                },
            },
        }
    },

    // >>---------------------------------------------<< //
    // >>========= CSS - COMERCIAL LIST STYLES =======<< //
    // >>---------------------------------------------<< //

    ComercialListStyles: {
        WelcomeBox :{
            paddingTop: '1em',
            paddingBottom: '1em',
            marginBottom: '10px',
        },
        WelcomeBox2 :{
            paddingBottom: '1em',
        },
        AdminButtons:{
            '& .MuiButtonBase-root':{
                color: '#196cdb !important',
                textTransform: 'capitalize !important',
            },
            '& .MuiButtonBase-root:hover':{
                backgroundColor: '#196cdb2e !important',
            }
        },
        LinksTitles:{
            marginTop: '15px',
            marginLeft: '-5px',
            '& p':{
                color: '#196cdb',
                cursor: 'pointer',
                padding: '5px',
                borderRadius: '5px',
            },
            '& p:hover':{
                backgroundColor: '#196cdb2e !important',
            }
        },
        AdminCard: {
            height: '100%',
        },
        OffersCard: {
            height: '100%',
            padding: '20px',
            paddingBottom: '0px !important',
        },
        AdminRow: {
            height: '100%',
        },
        AdminRowSecond: {
            height: '100%',
            marginTop: '10px' 
        },
        ColumnsBox:{
            width: '100%', 
            height: 'auto',
            marginLeft: '15px',
            display: 'flex',
            flexDirection: 'column',
            '& #SmallCard:hover':{
                boxShadow: '0px 1px 10px #848484 !important'
            }
        },
        ShopsStepperBox :{
            maxWidth: '100%',
            '& #TitleBox':{
                display: 'flex',
                alignItems: 'center',
            },
            '& #imageBox':{
                height: 255,
                display: 'block',
                maxWidth: 400,
                overflow: 'hidden',
                width: '100%',
            },
            '& #stepper':{
                position: 'static',
                '& #stepperButton':{
                    color: '#424242'
                },
                '& #stepperButton:hover':{
                    backgroundColor: '#196cdb3d',
                    color: '#196cdb'
                }
            },
            '& #Button':{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                paddingBottom: '10px',
            }
        },
        SmallAdminBox: {
            display: 'flex',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            cursor: 'pointer',
            '& #IconBox':{
                width: '20%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                '& svg':{
                    fontSize: '3rem !important',
                    width: '3rem !important',
                    height: '3rem !important',
                }
            },
            '& #TextBox':{
                width: '90%',
                '& h6':{
                    fontSize: '1.5rem',
                },
                '& p':{
                    fontSize: '0.95rem',
                }
            },
        },
        ShopsCard:{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            cursor: 'pointer',
            '& #Image':{
                overflow: 'hidden',
                borderRadius: '8px', 
                backgroundColor: '#fff', 
                width: '100%',
                display: 'flex', 
                justifyContent: 'center', 
                padding: '40px',
                '& img':{
                    width: '60%',
                }
            }
        },
        StepperBox :{
            maxWidth: '100%',
            '& #TitleBox':{
                display: 'flex',
                alignItems: 'center',
            },
            '& #imageBox':{
                height: 255,
                display: 'block',
                maxWidth: 400,
                overflow: 'hidden',
                width: '100%',
            },
            '& #stepper':{
                position: 'static',
                display: 'flex',
                justifyContent: 'center',
                padding: '20px',
            },
            '& #Button':{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                paddingBottom: '10px',
            }
        },
        NewsCard:{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& #Title':{
                paddingBottom: '10px',
            },
            '& #Body':{
                color: '#3d3d3d',
                width: '90%',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                lineHeight: '1.4285714285714286em',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '5',
                WebkitBoxOrient: 'vertical',
            },
            '& #Image':{
                overflow: 'hidden',
                borderRadius: '8px', 
                backgroundColor: '#fff', 
                width: '20%',
                paddingRight: '30px',
                '& img':{
                    width: '100%',
                }
            }
        },
    },

    // <===========================================================> //

    
    ConditionsListBackground:{
        marginTop: '40px',
        '& .MuiPaper-rounded': {
            backgroundColor: 'transparent !important',
            boxShadow: '0px 0px 0px transparent !important',
        }, 
    },

    imageFieldStyle:{
        image: {
            maxHeight: '3rem'
        },
    },
    editableField:{
        border: '0px',
        textAlign: 'center',
    },
    simpleFormStyles:{
        width: '100%',
        border: '2px',
        height: '100%',
    },
    fieldStyle:{
        width:'100%',
    },
    inputStyle:{
        width:'35%',
    },
    divStyles:{
        width:'45%',
        float:'left',
        paddingRight: '5%',
    },
    divStylesToolbar:{
        width:'100%',
        float: 'left',
        marginTop:'50px',
    },
    dateFrom:{
        width:"41%"
    },
    heightFormGroup: { 
        display: 'inline-block', 
        marginLeft: 32,
        width:"45%"
    },
    toolbar: {
        marginTop:'300px',
        display: 'flex',
    },
    deleteStyle: {
        marginLeft: '60%'
    },
    FormGroup: { 
        display: 'inline-block', 
        width: '33%', 
        float: 'right' 
    },
    Title: { 
        display: 'inline-block' 
    },
    link: {
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: '#64258426',
        borderRadius: 40,
        width: 250,
        border: '1px solid transparent',
        borderColor: '#642584',
    },
    icon: { 
        paddingRight: '0.5em' 
    },
    widthUsersList: { 
        width: 300 ,
        marginLeft: '10px',
        marginRight: '10px',
    },
    widthProviderName: { 
        width: 910,
        marginLeft: '10px',
        marginRight: '10px',
    },
    widthUsersEdit: { 
        width: 250 ,
        marginLeft: '10px',
        marginRight: '10px',
    },
    CardUsersEdit: { 
        width: 'auto',
        boxShadow: '0px 0px 0px #fff !important'
    },
    CardUsersCreate: { 
        width: 'auto',
        boxShadow: '0px 0px 0px #fff !important'
    },
    paddingUsers:{
        paddingLeft: '15px',
        width: 300 ,
        marginTop: '5px'
    },
    widthDateReport:{
        paddingLeft: '15px',
        width: 200 ,
    },
    imageFieldNewEdit:{
        height:'50px',
    },
    divStylesRight:{
        width:'45%',
        paddingRight: '5%',
        float:'right',
    },
    hidden:{
        display: 'none',
    },
    loginStyles:{
        main: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-start',
            background: 'white',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
        },
        card: {
            maxWidth: '420px',
            padding: '30px 38px 50px',
            height: '260px',
            border: '1px solid #ccc',
        },
        avatar: {
            display: 'flex',
            justifyContent: 'left',
            paddingBlockEnd: '30px',
        },
        title: {
            fontFamily: 'Platform-regular',
            fontSize: 19,
            float: 'left',
            padding: '0 0em 0em 0.5em',
            color: '#333',
        },
        hint: {
            marginTop: '0.5em',
            display: 'flex',
            justifyContent: 'center',
        },
        input: {
            marginTop: '1em',
            color: '#00b0ea',
        },
        textinput:{
            width: 300,
            background:'white',
        },
        actions: {
            marginLeft: '10px',
            borderTop: '1px solid #ccc',
            width: 350,
        },
        buttonPass:{
            '&:hover':{
                color: '#00b0ea',
                backgroundColor: 'rgba(0,0,0,0)',
                borderColor: '#0084B0'
            },
            color:'#00B7D7',
            background:'white',
            borderRadius: 40,
            width: 250,
            marginTop: 15,
            border: '1px solid transparent',
            borderColor: '#00b0ea',
            boxShadow: 'none',
        },
        buttonlogin:{
            '&:hover':{
                backgroundColor: '#0084B0', 
                borderLeft: '7px solid #0084B0',
                
            },
            borderRadius: 40,
            width: 100,
            marginTop: 15,
            color: '#FFF',
            backgroundColor: '#00b0ea'
        },
        h2:{
            fontWeight: 500,
            lineHeight: 1.1,
            color: '#333',
            fontSize: 28,
            margin: 40,
            fontFamily: 'Platform-light',
            textAlign: 'center',
        },
        info:{
            textAlign: 'right',
            fontSize: 13,
        },
        a: {
            color: '#00b0ea',
            textDecoration: 'none',
            marginRight: 45,
        },
        formGroup:{
            lineHeight: '2em',
            height: '30px',
            marginBottom: '15px',
        },
        label:{
            position: 'relative',
            minHeight: '1px',
            paddingLeft: '10px',
            paddingRight: '10px',
            float: 'left',
            marginTop:'15px',
            fontWeight: 'bold',
            fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
            fontSize: '13px',
            color: '#555',
    
        },
        list:{
            fontSize: 14,
        },
        containerUsuario:{
            width: '66.6666666667%',
            float: 'left',
            position: 'relative',
            minHeight: '1px',
            paddingLeft: '37px',
            paddingRight: '10px',
            fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
        },
        container:{
            width: '66.6666666667%',
            float: 'left',
            position: 'relative',
            minHeight: '1px',
            paddingLeft: '14px',
            paddingRight: '10px',
            paddingBlockEnd: '30px',
        }, 
        logo:{
            backgroundColor: '#f5f5f5',
            width: '100%',
            textAlign: 'center',
        },
    },
    acceptButton:{
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
        minWidth:'64px',
        padding: '7px 16px'
    },
    generateButton:{
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
        minWidth:'64px',
        padding: '7px 16px',
        marginTop: '12px'
    },
    titleReport:{
        color: '#642584'
    }, 
    volverButton:{
        background: '#642584',
        color: '#fff',
        padding: '6px 16px',
        borderRadius: '4px',
        fontSize: '0.875rem',
        marginLeft: '10px'
      },
      menu:{
        Active: {
            marginTop: '5px',
            marginBottom: '5px',
            marginRight: '5px',
            fontSize: '14px',
            borderTopRightRadius: '25px',
            borderBottomRightRadius: '25px',
            paddingTop: '8px',
            paddingBottom: '8px',
            color: '#424242',
            transition: 'width 195ms ease-in-out',
            '&:hover':{
                fontSize: '14px',
                backgroundColor: 'rgba(25, 108, 219, 0.14) !important',
                borderTopRightRadius: '25px',
                borderBottomRightRadius: '25px',
                transition: 'width 195ms ease-in-out',
                color: '#196cdb',
                '& svg':{
                    color: '#196cdb !important',
                },
            },        
        },
          popover: {
            pointerEvents: 'none',
            marginLeft:'30px'
          },
          paper: {
            background:'#6A6A6A',
            color:'#fff',
            padding:'3px 8px 3px',
            height:'7px',
            boxShadow:'none'
          },
          PopText:{
              fontSize:'10px'
          },
          Link:{
            display:'block',
            paddingTop: '6px',
            paddingBottom: '6px',
            paddingLeft:'16px',
            paddingRight:'16px',
            fontSize:'14px',
            color:'#6A6A6A',
            
            '&:hover':{
                backgroundColor: '#E6E6E6', 
                borderLeft: '7px solid #7d2c8b',
                transition: 'all .2s ease-in-out',
            },   
        },
        IconLink:{
            display:'block',
            float:'left',
            paddingTop: '0px',
            paddingBottom: '6px',
            paddingRight:'16px',
        },
        LinkText:{
            textAlign: 'center',
            fontSize:'14px',
            display:'inline-block',
            paddingTop:'4px',
            paddingBottom:'4px',
        },
        contentLink:{
            display:'block',
            width:'240px',
            height:'24px'
      }
    },
    editPostButton: {
        backgroundColor: 'transparent !important',
        color: '#3d3d3d !important',
        boxShadow: 'none !important',
        '&:hover': {
          backgroundColor: 'transparent !important',
          color: '#6f6f6f  !important',
          boxShadow: 'none !important',
        },
    },
    deletePostButton: {
        backgroundColor: 'transparent !important',
        color: '#f00 !important',
        boxShadow: 'none !important',
        '&:hover': {
          backgroundColor: 'transparent !important',
          color: '#ff0000a8 !important',
          boxShadow: 'none !important',
        },
    },
    CreatePostButton: {
        backgroundColor: '#3d3d3d !important',
        color: '#fff !important',
        boxShadow: 'none !important',
        '&:hover': {
          backgroundColor: '#6a6a6a  !important',
          color: '#fff  !important',
          boxShadow: '2px 2px 2px #5e5e5e !important',
        },
    },
    BulkDeletePostButton: {
        backgroundColor: '#F44336 !important',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#F44336A6 !important',
            color: '#fff !important',
        },
    },
    PaginationNumbers: {
        backgroundColor: '#fff !important;',
        color: '#000 !important',
        borderRadius: '3px !important',
        border: '1px solid #3d3d3d !important',
        marginRight: '15px !important',
        width: ' 35px !important',
        height: '30px !important',
        minWidth: '30px !important',
        fontSize: '11px',
        padding: '3px 4px !important',
        '&:hover': {
            backgroundColor: '#fff !important;',
            borderRadius: '3px !important',
            border: '1px solid #3d3d3d !important',
            boxShadow: '1px 2px 5px rgba(0,0,0,0.2) !important;'
        },
        '&:focus':{
            backgroundColor: '#3d3d3d !important',
            color: '#fff !important',
        },
        '&:active':{
            backgroundColor: '#3d3d3d !important',
            color: '#fff !important',
        },
    },
    PaginationNumbersActive: {
        backgroundColor: '#3d3d3d !important',
        color: '#fff !important',
        borderRadius: '3px !important',
        border: '1px solid #3d3d3d !important',
        marginRight: '15px !important',
        width: ' 35px !important',
        height: '30px !important',
        minWidth: '30px !important',
        fontSize: '11px',
        padding: '3px 4px !important',
    },
    PaginationArrow: {
        backgroundColor: '#fff !important;',
        color: '#000 !important',
        borderRadius: '3px !important',
        border: '1px solid #3d3d3d !important',
        marginLeft: '15px !important',
        fontSize: '11px',
        padding: '3px 4px !important',
        '&:hover': {
            backgroundColor: '#fff !important;',
            borderRadius: '3px !important',
            border: '1px solid #3d3d3d !important',
            boxShadow: '1px 2px 5px rgba(0,0,0,0.2) !important;'
        },
        '&:focus':{
            backgroundColor: '#3d3d3d !important',
            color: '#fff !important',
        },
        '&:active':{
            backgroundColor: '#3d3d3d !important',
            color: '#fff !important',
        },
    },
    PostViewCard: {
        backgroundColor: 'transparent', 
        boxShadow: '0px 0px 0px white !important',
        color: '#7c7c7c !important',
    },
    PostViewTypography: {
        color: '#7c7c7c !important',
    },
    AsideProviderAddress: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        marginLeft: '5px',
        boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
        color: '#000 !important',
        width: '330px',
    },
    OffersAndPromotions: {
        hight: { height: 100},
        inputBox: { width: 500 },
        title: { width: 500 },
        buttonDowload: { display: 'inline-block', margin: 10,},
        titleFile: { display: 'inline-block', fontSize: 16, margin: 12, width: 300}
    },
    InputBlackBorder: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px',
        '& .Mui-focused':{
            '& span':{
                color: '#196cdb !important',
            }
        },
        '& .MuiInputLabel-shrink':{
            backgroundColor: '#fff !important',
            paddingLeft: '5px !important',
            paddingRight: '5px !important',
        },
        '& .MuiOutlinedInput-root': {
            '& .MuiSelect-select:focus':{
                backgroundColor: 'transparent !important',
            },
            '& fieldset': {
                borderColor: '#939393',
            },
            '&:hover fieldset': {
                borderColor: '#000',

            },
            '&.Mui-focused fieldset': {
                borderColor: '#196cdb',
            },
        },
    },
    RichTextInputBlackBorder: {
        width: '100%',
        '& .ra-rich-text-input':{
            paddingLeft: '10px',
            width: '98% !important',
        },
    },
    PostInputBlackBorder: {
        '& .MuiInputLabel-shrink':{
            backgroundColor: '#fff !important',
            paddingLeft: '5px !important',
            paddingRight: '5px !important',
        },
        '& .Mui-focused':{
            '& span':{
                color: '#196cdb !important',
            }
        },
        '& .MuiOutlinedInput-root': {
            '& .MuiSelect-select:focus':{
                backgroundColor: 'transparent !important',
            },
            '& fieldset': {
                borderColor: '#939393',
            },
            '&:hover fieldset': {
                borderColor: '#000',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#196cdb',
            },
        },
    },
    TablaCondiciones: {
        '&.MuiTable-root': {
            backgroundColor: '#fff',
            '& .MuiTableHead-root':{
                display: 'none !important',
            },
            '& thead': {
                '& span': {
                    fontSize: '15px',
                },
                '& tr': {
                    boxShadow: '0px 0px 0px transparent !important',
                    display: 'contents',
                },
            },
            '& tbody': {
                display: 'flex',
                flexWrap: 'wrap',
                width: '108%',
            },
            '& tr': {
                width: '44%',
                paddingTop: '5px',
                paddingBottom: '5px',
                boxShadow: '1px 1px 5px #b5b5b5',
                border: '1px solid #d7d7d7',
                borderRadius: '5px',
                marginTop: '10px',
                marginBottom: '10px',
                marginLeft: '15px',
                display: 'table',
            },
            '& td': {
                borderBottom: 'none',
                textAlign: 'center',
                width: '100px',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                '& a':{
                    backgroundColor: 'transparent !important',
                    color: '#000 !important',
                    borderRadius: '50%',
                    height: '40px',
                    width: '40px !important',
                    minWidth: '40px !important',
                },
                '& a:hover':{
                    backgroundColor: '#eaeaea !important',
                    boxShadow: '0px 0px 0px transparent !important',
                },
            }
        },
        '& .column-undefined':{
            width: '40px !important',
        },
        ' & .MuiCard-root': {
            borderRadius: 'none !important',
        },
    },
    ProviderGridMedia: {
        height: 150,
        width: '100%',
        transition: '0.5s ease-in-out',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        '&:hover': {
            transform: 'scale(1.1, 1.1)',
            transition: '0.5s ease-in-out',
        }
    },
    ProviderGridMediaEmpty: {
        height: 120,
    },
    EmptyConditionsMediaCard: {
        height: 120,
        backgroundPosition: 'center', 
        backgroundSize: 'contain',
        backgroundImage: 'url(/static/images/CondicionesEmpty.svg)',
    },
    EmptyFilesMediaCard: {
        height: 120,
        backgroundPosition: 'center', 
        backgroundSize: 'contain',
         marginTop: '75px', 
         backgroundImage: 'url(/static/images/DescargasEmpty.svg)',
    },
    EmptyProvidersMediaCard: {
        height: 120,
        backgroundPosition: 'center', 
        backgroundSize: 'contain',
         marginTop: '75px', 
         backgroundImage: 'url(/static/images/ProvidersEmpty.svg)',
    },
    EmptyBasketMediaCard: {
        height: 120,
        backgroundPosition: 'center', 
        backgroundSize: 'contain',
         marginTop: '75px', 
         backgroundImage: 'url(/static/images/BasketEmpty.svg)',
    },
    EmptyInvoiceMediaCard: {
        height: 120,
        backgroundPosition: 'center', 
        backgroundSize: 'contain',
         marginTop: '75px', 
         backgroundImage: 'url(/static/images/InvoiceEmpty.svg)',
    },
    EmptyAddressMediaCard: {
        height: 120,
        backgroundPosition: 'center', 
        backgroundSize: 'contain',
         marginTop: '75px', 
         backgroundImage: 'url(/static/images/AddressEmpty.svg)',
    },
    ProviderGridMediaFilesExcel: {
        height: 100,
        paddingTop: '10px',
        textAlign: 'left',
        textTransform: 'uppercase',
        backgroundPosition: 'center', 
        backgroundSize: 'contain', 
        backgroundImage: 'url(/static/images/Excel.png)',
        '& p':{
            display: 'none',
            fontSize: '12px',
        },
        '& div':{
            display: 'none',
            marginTop: '10px',
        },
        '& a':{
            display: 'none',
            marginTop: '10px',
        },
        '&:hover': {
            backgroundColor: '#fff',
            backgroundSize: '50px',
            backgroundPosition: 'left !important',
            backgroundPositionY: '0px !important',
            '& div':{
                display: 'inline-flex',
                color: '#3d3d3d !important'
            },
            '& a':{
                display: 'inline-flex',
                color: '#3d3d3d !important'
            },
            '& button':{
                marginTop: '10px',
            },
            '& p':{
                display: 'block',
                color: '#3d3d3d !important',
                marginTop: '-3px',
            },
        },
    },
    ProviderGridMediaFilesPoint: {
        height: 100,
        paddingTop: '10px',
        textAlign: 'left',
        textTransform: 'uppercase',
        backgroundPosition: 'center', 
        backgroundSize: 'contain', 
        backgroundImage: 'url(/static/images/Point.png)',
        '& p':{
            display: 'none',
            fontSize: '12px',
        },
        '& div':{
            display: 'none',
            marginTop: '10px',
        },
        '& a':{
            display: 'none',
            marginTop: '10px',
        },
        '&:hover': {
            backgroundColor: '#fff',
            backgroundSize: '50px',
            backgroundPosition: 'left !important',
            backgroundPositionY: '0px !important',
            '& div':{
                display: 'inline-flex',
                color: '#3d3d3d !important'
            },
            '& a':{
                display: 'inline-flex',
                color: '#3d3d3d !important'
            },
            '& button':{
                marginTop: '10px',
            },
            '& p':{
                display: 'block',
                color: '#3d3d3d !important',
                marginTop: '-3px',
            },
        },
    },
    ProviderGridMediaFilesPDF: {
        height: 100,
        paddingTop: '10px',
        textAlign: 'left',
        textTransform: 'uppercase',
        backgroundPosition: 'center', 
        backgroundSize: 'contain', 
        backgroundImage: 'url(/static/images/PDF.png)',
        '& p':{
            display: 'none',
            fontSize: '12px',
        },
        '& div':{
            display: 'none',
            marginTop: '10px',
        },
        '& a':{
            display: 'none',
            marginTop: '10px',
        },
        '&:hover': {
            backgroundColor: '#fff',
            backgroundSize: '50px',
            backgroundPosition: 'left !important',
            backgroundPositionY: '0px !important',
            '& div':{
                display: 'inline-flex',
                color: '#3d3d3d !important'
            },
            '& a':{
                display: 'inline-flex',
                color: '#3d3d3d !important'
            },
            '& button':{
                marginTop: '10px',
            },
            '& p':{
                display: 'block',
                color: '#3d3d3d !important',
                marginTop: '-3px',
            },
        },
    },
    ProviderGridMediaFilesPNG: {
        height: 100,
        paddingTop: '10px',
        textAlign: 'left',
        textTransform: 'uppercase',
        backgroundPosition: 'center', 
        backgroundSize: 'contain', 
        backgroundImage: 'url(/static/images/PNG.png)',
        '& p':{
            display: 'none',
            fontSize: '12px',
        },
        '& div':{
            display: 'none',
            marginTop: '10px',
        },
        '& a':{
            display: 'none',
            marginTop: '10px',
        },
        '&:hover': {
            backgroundColor: '#fff',
            backgroundSize: '50px',
            backgroundPosition: 'left !important',
            backgroundPositionY: '0px !important',
            '& div':{
                display: 'inline-flex',
                color: '#3d3d3d !important'
            },
            '& a':{
                display: 'inline-flex',
                color: '#3d3d3d !important'
            },
            '& button':{
                marginTop: '10px',
            },
            '& p':{
                display: 'block',
                color: '#3d3d3d !important',
                marginTop: '-3px',
            },
        },
    },
    ProviderGridTitle: {
        paddingBottom: '0.5em',
    },
    ProviderGridTitleFilesExcel: {
        paddingBottom: '0.5em',
    },
    ProviderGridTitleFilesPoint: {
        paddingBottom: '0.5em',
    },
    ProviderGridTitleFilesPDF: {
        paddingBottom: '0.5em',
    },
    ProviderGridTitleFilesPNG: {
        paddingBottom: '0.5em',
    },
    ProviderGridActionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    ProviderGridCard: {
        height: '100%', 
        boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
        backgroundColor: '#fff !important',
        '& a': {
          textDecoration: 'none',
          color: 'inherit',
        }
    },
    InLineViewOffer: {
        border: '1px solid #3d3d3d63 !important', 
        borderRadius: '10px', 
        marginTop: '10px', 
        marginBottom: '10px',
        height: '310px',
        '& .MuiButtonBase-root': {
            color: '#fff !important',
            backgroundColor: '#3d3d3d',
            textTransform: 'none !important',
            width: '240px',
        },
        '& button:hover':{
            opacity: '0.7',
            boxShadow: '0px 0px 0px transparent !important',
        },
    },
    PostTitle: {
        color: '#3d3d3d !important',
        fontSize: '25px !important'
    },
    PostSubTitle: {
        color: '#5e5e5e !important',
        padding: '8px !important',
        fontStyle: 'italic !important'
    },
    EditFilesButton: {
        textDecoration: 'none !important',
        background: 'none !important',
        backgroundColor: '#3d3d3d !important',
        width: '100px !important',
        borderRadius: '3px !important',
        marginBottom: '5px !important',
        height: '30px !important',
        display: 'flex !important',
    },
    FileInputBox: {
        '& .previews': {
            '& a': {
                textDecoration: 'none',
                color: '#000',
                textTransform: 'capitalize',
            },
            '& a:hover': {
                textDecoration: 'underline',
                color: '#828282',
            },
        },
    },
    EmptyBubble: {
        marginTop: '40px', 
        backgroundColor: 'rgb(225, 224, 224) !important', 
        width: '400px', 
        padding: '30px', 
        borderRadius: '50%', 
        height: '400px',
    },
    EditToolBar: {
        '& #GoogleStyleSaveButton.MuiButtonBase-root': {
            padding: '7px',
            paddingRight: '17px !important',
            paddingLeft: '17px !important',
            fontSize: '14px',
            backgroundColor: '#196cdb !important',
            color: '#fff !important',
            textTransform: 'none !important',
            boxShadow: '0px 0px 0px transparent ! important',
        },
        '& #GoogleStyleSaveButton.Mui-disabled': {
            padding: '7px',
            paddingRight: '17px !important',
            paddingLeft: '17px !important',
            fontSize: '14px',
            backgroundColor: '#a2a1a1 !important',
            color: '#fff !important',
            textTransform: 'none !important',
            boxShadow: '0px 0px 0px transparent ! important',
        },
        '& button:hover':{
            boxShadow: '0px 0px 0px transparent ! important',
            opacity: '0.7',
        },
        '& .MuiCircularProgress-colorPrimary': {
            color: '#fff !important',
            width: '35px',
            height: '35px',
        },
    },
    BackButtonStyle: {
        '& .MuiButtonBase-root':{
            borderRadius: '50%', 
            height: '33px', 
            width: '33px', 
            minWidth: '25px', 
            minHeight: '25px', 
            margin: '0',
        },
        '& button:hover':{
            backgroundColor: '#dbd9d9 !important',
            color: '#3d3d3d !important',
        },
    },
    FilterButtonGoogleStyle: {
        '& .MuiButtonBase-root':{
            color: '#3d3d3d !important',
            backgroundColor: 'transparent !important',
            marginLeft: '10px !important',
            minWidth: '40px',
            minHeight: '40px',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            transition: 'ease 0.6s',
            marginTop: '-4px',
        },
        '& .MuiIconButton-root':{
            marginLeft: '350px !important',
            marginRight: '8px !important',
            marginBottom: '6px !important',
        },
        '& button:hover':{
            backgroundColor: 'hsla(0, 0%, 80%, 0.51) !important',
        },
        '& span':{
            paddingLeft: '5px',
        },
        '& .hide-filter':{
            paddingLeft: '5px',
        },
    },
    ProviderFilterButtonGoogleStyle: {
        '& .MuiButton-root':{
            marginTop: '-10px !important',
        },
        '& .add-filter':{
            color: '#3d3d3d !important',
            backgroundColor: 'transparent !important',
            marginLeft: '10px !important',
            minWidth: '40px',
            minHeight: '40px',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            transition: 'ease 0.6s',
            marginTop: '-4px',
            '& span':{
                paddingLeft: '6px',
                paddingTop: '1px',
            }
        },
        '& button:hover':{
            backgroundColor: 'hsla(0, 0%, 80%, 0.51) !important',
        },
        '& .hide-filter[data-key="category"]':{
            marginBottom: '5px',
            marginLeft: '-17px',
            marginRight: '-7px',
        },
        '& .hide-filter[data-key="jornadas_compras"]':{
            marginLeft: '-20px',
        },
    },
    ConditionsFilterButtonGoogleStyle: {
        width: '90% !important', 
        display: 'flex',
        justifyContent: 'flex-start !important',
        '& .MuiButton-root':{
            marginTop: '-0px !important',
        },
        '& .add-filter':{
            color: '#3d3d3d !important',
            backgroundColor: 'transparent !important',
            marginRight: '10px !important',
            transition: 'ease 0.6s',
            border: '1px solid #cacaca !important',
            padding: '8px !important',
            '& span':{
                '& span':{
                    fontSize: '0.95rem !important',
                }
            }
        },
        '& button:hover':{
            backgroundColor: 'hsla(0, 0%, 80%, 0.51) !important',
        },
        '& form':{
            justifyContent: 'flex-start !important',
            '& .filter-field':{
                alignItems: 'center',
            },
            '& .filter-field[data-source="provider"]':{
                zIndex: "9999",
                background: "#fbfbfb",
                width: "25%",
                display: "flex",
                alignItems: "center",
                height: "70%",
                marginLeft: "10px",
                gap: "5px",
            },
            '& .filter-field[data-source="campaign"], .filter-field[data-source="shop"], .filter-field[data-source="state"]':{
                zIndex: '150',
                backgroundColor: '#fbfbfb',
            },
            '& .filter-field[data-source="campaign"]':{
                paddingRight: '10px',
                width: "25%",
            }
        },
        '& .filter-toolbar-displayed':{
            zIndex: '100',
            backgroundColor: '#fbfbfb',
        }
    },
    FilesGridButtonsBoxExcel:{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#fff',
        zIndex: '5',
        position: 'relative',
        marginTop: '20px',
        paddingBottom: '20px',
    },
    FilesGridButtonsBoxPoint:{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#fff',
        zIndex: '5',
        position: 'relative',
        marginTop: '20px',
        paddingBottom: '20px',
    },
    FilesGridButtonsBoxPDF:{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#fff',
        zIndex: '5',
        position: 'relative',
        marginTop: '20px',
        paddingBottom: '20px',
    },
    FilesGridButtonsBoxPNG:{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#fff',
        zIndex: '5',
        position: 'relative',
        marginTop: '20px',
        paddingBottom: '20px',
    },
    FilesGridHoverTitle:{
        fontSize: '13px', 
        textTransform: 'uppercase',
        textDecoratiom: 'none', 
        width: '100%',
        marginTop: '5px',
        textAlign: 'left',
    },
    FilesGridHiddenTitle: {
        fontSize: '13px', 
        textTransform: 'uppercase', 
        textOverflow: 'ellipsis', 
        textDecoratiom: 'none', 
        overflow: 'hidden', 
        whiteSpace: 'nowrap',
        width: '210px',
        textAlign: 'left',
    },
    
    MaterialGridroot: {
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
          borderColor: '#3d3d3d',
          },
          '&:hover fieldset': {
          borderColor: '#3d3d3d',
          },
          '&.Mui-focused fieldset': {
          borderColor: '#3d3d3d',
          },
      },
    },
    MaterialGridmedia: {
        transition: '0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1, 1.1)',
          transition: '0.2s ease-in-out',
        },
        height: 150,
    },
    MaterialGridtitle: {
        paddingBottom: '0.5em',
        zIndex: 5,
        '& p':{
            fontSize: '12px',
            textAlign: 'left',
        },
        '& p#title:hover':{
            cursor: 'pointer',
            color: '#6c6c6c',
        },
        '& p#price':{
            fontSize: '1.3rem !important',
            textAlign: 'left',
        },
        '& p#price:hover':{
            cursor: 'default',
        },
        '& p#stock':{
            fontSize: '1rem !important',
            textAlign: 'left',
            paddingTop: '5px',
            paddingRight: '5px'
        },
        '& p#stock:hover':{
            cursor: 'default',
        },
    },
    MaterialGridactionSpacer: {
        display: 'flex',
    },
    MaterialGridcard: {
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& span':{
            margin: '0px !important'
        },
    },
    MaterialViewImage:{
        '& img':{
            height: '30rem !important',
            width: '30rem !important',
        },
        '& span#MaterialPrice':{
            fontSize: '1.5rem !important',
        }
    },
    MaterialViewProductDescription:{
        padding: '10px', 
        width: '50%', 
        marginTop: '20px',
        '& button':{
          textTransform: 'capitalize',
          backgroundColor: '#196cdb',
          color: '#fff',
          paddingLeft: '15px',
          paddingRight: '15px',
        },
        '& button:hover':{
          opacity: '0.7 !important',
          backgroundColor: '#196cdb !important'
        }
    },
    MaterialViewZoomImage:{
        padding: '10px', 
        ["@media (max-width:1550px)"]:{ // eslint-disable-line no-useless-computed-key
            width: '50%',
        },
        ["@media (min-width:1600px)"]:{ // eslint-disable-line no-useless-computed-key
            width: '40%',
        },
        '& div':{
          zIndex: '5',
          cursor: 'crosshair'
        },
        '& .js-image-zoom__zoomed-image':{
          border: '1px solid #d7d7d7',
          borderRadius: '10px',
          width: '500px !important',
          height: '500px !important',
        },
    },
    MaterialViewDisabledButton:{
        '& button':{
          color: '#fff !important', 
          opacity: '0.7', 
        },
        '& button:hover':{
          cursor: 'not-allowed',
        },
    },
    MaterialViewVariantGlobalBox:{
        marginTop: '25px', 
        marginLeft: '-4px', 
        width: '100%',
        display: 'flex',
    },
    MaterialViewVariantBox:{
        border: '1px solid rgb(25, 108, 219)', 
        borderRadius: '5px', 
        marginRight: '10px', 
        paddingBottom: '10px', 
        paddingTop: '5px', 
        paddingLeft: '10px', 
        paddingRight: '10px',
        height: '80px !important',
        '& div#EditButton':{
            height: '40px',
            '& button':{
                display: 'none !important'
            },
        },
        '& div#EditButton:hover':{
            '& button':{
                display: 'flex !important'
            },
        },
        '& p':{
          color: 'rgb(25, 108, 219)',
          textAlign: 'left',
        },
    },
    MaterialViewVariantBoxSoldHover:{
        marginTop: '25px', 
        marginLeft: '-4px', 
        width: '100%',
        display: 'flex',
    },
    MaterialViewVariantBoxSold:{
        border: '1px solid rgba(98, 96, 96, 0.87)', 
        borderRadius: '5px', 
        marginRight: '10px', 
        paddingBottom: '10px', 
        paddingTop: '5px', 
        paddingLeft: '10px', 
        paddingRight: '10px',
        height: '60px !important',
        opacity: '0.5',
        '& p':{
          color: 'rgba(98, 96, 96, 0.87)',
          textAlign: 'left',
        },
        '& h6':{
          color: 'rgba(98, 96, 96, 0.87)',
        },
        '& div#EditButton':{
            height: '40px',
            '& a':{
                display: 'none !important'
            },
        },
        '& div#EditButton:hover':{
            '& a':{
                display: 'flex !important'
            },
        },
    },
    MaterialViewModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiBackdrop-root':{
          backgroundColor: 'rgba(0, 0, 0, 0.5) !important',
        },
        '& h2':{
            fontFamily: '"Google Sans",Roboto,Arial,sans-serif !important',
            fontWeight: 'normal !important',
        },
    },
    MaterialViewPaper: {
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '1px 1px 10px #757171 !important',
        padding: '20px',
        width: '800px',
        marginLeft: '20vh',
        '& .ra-input':{
          marginLeft: '-23px',
        },
        '& .MuiToolbar-root':{
          backgroundColor: '#fff !important',
          marginLeft: '-20px !important',
          marginTop: '-5px',
        },
        '& .MuiCard-root':{
            boxShadow: '0px 0px 0px #fff !important'
        }
    },
    MaterialViewPriceTitle: {
        marginBottom: '1.5em',
        marginTop: '-10px',
        display: 'flex',
        width: '100%',
        '& p':{
            fontSize: '20px',
            textAlign: 'left',
        },
        '& p#title:hover':{
            cursor: 'pointer',
            color: '#6c6c6c',
        },
        '& p#price':{
            fontSize: '2rem !important',
            textAlign: 'left',
        },
        '& p#price:hover':{
            cursor: 'default',
        },
        '& p#stock':{
            fontSize: '1rem !important',
            textAlign: 'left',
            paddingTop: '5px',
            paddingRight: '5px'
        },
        '& p#stock:hover':{
            cursor: 'default',
        },
    },
    OffersAndPromotionsTitle: {
        textAlign: 'left',
        color: '#000'
    },
    OffersAndPromotionsBody: {
        color: '#3d3d3d',
        textOverflow: 'ellipsis', 
        textDecoration: 'none', 
        overflow: 'hidden', 
        height: '260px',
        backgroundImage: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 60%)',
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        '& li':{
            paddingLeft: '10px',
        },
        '& ul li::marker':{
            content: '"•"',
        },
        '& ol li::marker':{
            content: '"•"',
        },
        '& strong':{
            paddingLeft: '5px',
        },
        '& span':{
            paddingLeft: '5px',
        },
    },
    OffersAndPromotionsOfferImage: {
        width: '100%', 
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '20px',
        paddingBottom: '20px',
        '& img':{
            borderRadius: '10px',
            marginTop: '-16px',
        },
    },
    OffersAndPromotionsBtnContainer: {
        width: '100%',
        display: 'grid',
        alignContent: 'center',
        marginTop: '15px',
    },
    OffersAndPromotionsBtnArchivos: {
        marginTop: '15px',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    OffersAndPromotionsBtnSolicitar: {
        marginTop: '15px',
        marginRight: 'auto',
        marginLeft: 'auto',
        backgroundColor: '#196cdb !important',
    },
    OffersAndPromotionsOffersTable:{
        '& tr':{
            '& .column-undefined':{
                width: '50px !important',
                height: '40px',
                '& div':{
                    marginLeft: '-25px',
                    marginRight: '25px',
                    display: 'none',
                },
            },
        },
        '& tr:hover':{
            '& .column-undefined':{
                height: '40px',
                '& div':{
                    display: 'inline-flex',
                },
            },
        },
    },
    StateTable:{
        '& tr':{
            '& .column-name':{
                width: '20rem'
            },
            '& .column-country':{
                '& a':{
                    '& span':{
                        color: '#196cdb !important'
                    },
                },
            },
            '& .column-undefined':{
                width: '50px !important',
                height: '40px',
                '& div':{
                    marginLeft: '-25px',
                    marginRight: '25px',
                    display: 'none',
                },
            },
        },
        '& tr:hover':{
            '& .column-undefined':{
                height: '40px',
                '& div':{
                    display: 'inline-flex',
                },
            },
        },
    },
    UsersTable:{
        '& tr':{
            '& th:nth-child(2)':{
                display: 'none',
            },
            '& th:nth-child(3)':{
                paddingLeft: '32px !important',
            },
            '& .column-state':{
                '& a':{
                    '& span':{
                        color: '#196cdb !important'
                    },
                },
            },
            '& .column-id':{
                display: 'none',
            },
            '& .column-undefined':{
                height: '40px',
                paddingLeft: '0px !important',
                paddingRight: '0px !important',
                '& div':{
                    marginLeft: '-40px !important',
                    marginRight: '0px !important',
                    display: 'none',
                },
            },
            '& td':{
                '& [title="displayName"]':{
                    marginLeft: '15px',
                },
                '& [data-tooltip="false"]:before':{
                    left: '50px',
                    color: '#fff',
                    width: '8px',
                    border: '1px solid #3d8929',
                    content: '""',
                    display: 'flex',
                    position: 'absolute',
                    fontSize: '12px',
                    marginTop: '-15px',
                    marginLeft: '15px',
                    transition: 'all 0.5s',
                    alignItems: 'center',
                    borderRadius: '20px',
                    justifyContent: 'center',
                    backgroundColor: '#3d8929',
                    opacity: '1',
                    aspectRatio: '1 / 1',
                    padding: '0px !important',
                },
                '& [data-tooltip="true"]:before':{
                    left: '50px',
                    color: '#fff',
                    width: '8px',
                    border: '1px solid #eaa20f',
                    content: '""',
                    display: 'flex',
                    position: 'absolute',
                    fontSize: '12px',
                    marginTop: '-15px',
                    marginLeft: '15px',
                    transition: 'all 0.5s',
                    alignItems: 'center',
                    borderRadius: '20px',
                    justifyContent: 'center',
                    backgroundColor: '#eaa20f',
                    opacity: '1',
                    aspectRatio: '1 / 1',
                    padding: '0px !important',
                },
            }
        },
        '& tr:hover':{
            '& .column-undefined':{
                height: '40px',
                '& div':{
                    display: 'inline-flex',
                },
            },
        },
    },
    PointsTable:{
        '& tr':{
            '& .column-state':{
                '& a':{
                    '& span':{
                        color: '#196cdb !important'
                    },
                },
            },
            '& .column-undefined':{
                width: '50px !important',
                height: '40px',
                '& div':{
                    marginLeft: '-25px',
                    marginRight: '25px',
                    display: 'none',
                },
            },
        },
        '& tr:hover':{
            '& .column-undefined':{
                height: '40px',
                '& div':{
                    display: 'inline-flex',
                },
            },
        },
    },
    CompanyTable:{
        '& tr':{
            '& .column-state':{
                '& a':{
                    '& span':{
                        color: '#196cdb !important'
                    },
                },
            },
            '& .column-undefined':{
                width: '50px !important',
                height: '40px',
                '& div':{
                    marginLeft: '-25px',
                    marginRight: '25px',
                    display: 'none',
                },
            },
        },
        '& tr:hover':{
            '& .column-undefined':{
                height: '40px',
                '& div':{
                    display: 'inline-flex',
                },
            },
        },
    },
    NoticesTable:{
        '& tr':{
            '& .MuiTableCell-paddingCheckbox':{
                verticalAlign: 'text-top',
                paddingTop: '10px',
            },
        },
    },
    OrdersTable:{
        '& tr':{
            '& th:nth-child(2)':{
                display: 'none'
            },
            '& .column-id':{
                display: 'none'
            },
            '& .column-undefined':{
                width: '50px !important',
                height: '40px',
                '& div':{
                    marginLeft: '-25px',
                    marginRight: '25px',
                    '& a':{
                        display: 'none',
                    },
                    '& button':{
                        display: 'none',
                    },
                },
            },
            '& .column-state':{
                paddingLeft: '0px',
                paddingRight: '0px'
            }
        },
        '& tr:hover':{
            '& .column-undefined':{
                height: '40px',
                '& div':{
                    '& a':{
                        display: 'inline-flex',
                    },
                    '& button':{
                        display: 'inline-flex',
                    },
                },
            },
        },
        '& .column-total_price':{
            '& span':{
                color: '#b63615 !important',
                fontWeight: 'bold',
            }
        },
        '& .column-user_id':{
            '& span':{
                color: '#196cdb !important',
            }
        },
        '& .MuiTableCell-alignRight':{
            textAlign: 'left !important',
        }
    },
    OrdersMaterialTable_Mui:{
        '& .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner':{
            '& .MuiDataGrid-columnHeader':{
                '& .MuiDataGrid-columnSeparator':{
                    display: 'none',
                }
            },
            '& .MuiDataGrid-columnHeader:focus':{
                outline: 'solid #1976d2 0px !important',
            },
            '& [data-field="imagen"]':{
                width: '120px !important',
                maxWidth: '120px !important',
                minWidth: '90px !important',
                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                    width: '90px !important',
                },
            },
            '& [data-field="material_id"]':{
                width: '300px !important',
                maxWidth: '300px !important',
                minWidth: '200px !important',
                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                    width: '200px !important',
                },
            },
            '& [data-field="referencia"]':{
                width: '120px !important',
                maxWidth: '120px !important',
                minWidth: '110px !important',
                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                    width: '110px !important',
                },
            },
            '& [data-field="upc"]':{
                width: '150px !important',
                maxWidth: '150px !important',
                minWidth: '120px !important',
                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                    width: '120px !important',
                },
            },
            '& [data-field="variant_id"]':{
                width: '150px !important',
                maxWidth: '150px !important',
                minWidth: '120px !important',
                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                    width: '120px !important',
                },
            },
            '& [data-field="acciones"]':{
                display: 'none',
                width: '0px !important',
                maxWidth: '0px !important',
                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                    width: '0px !important',
                    maxWidth: '0px !important',
                },
            }
        },
        '& .MuiDataGrid-main':{
            overflow: 'unset !important',
            '& .MuiDataGrid-virtualScroller':{
                overflow: 'unset !important',
                '& .MuiDataGrid-virtualScrollerContent':{
                    '& .MuiDataGrid-virtualScrollerRenderZone':{
                        '& .MuiDataGrid-row':{
                            '& .MuiDataGrid-cell:last-child':{
                                display: 'none !important',
                            },
                            '& .MuiDataGrid-cell:focus':{
                                outline: 'solid #1976d2 0px !important',
                            },
                            '& [data-field="imagen"]':{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                width: '120px !important',
                                maxWidth: '120px !important',
                                minWidth: '90px !important',
                                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                                    width: '90px !important',
                                },
                                '& img':{
                                    width: '50px',
                                    height: '50px',
                                }
                            },
                            '& [data-field="total_variant_price"]':{
                                '& div':{
                                    color: '#bd4a2c !important'
                                }
                            },
                            '& [data-field="material_id"]':{
                                '& a':{
                                    color: '#196cdb !important'
                                },
                                width: '300px !important',
                                maxWidth: '300px !important',
                                minWidth: '200px !important',
                                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                                    width: '200px !important',
                                },
                            },
                            '& [data-field="referencia"]':{
                                width: '120px !important',
                                maxWidth: '120px !important',
                                minWidth: '110px !important',
                                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                                    width: '110px !important',
                                },
                            },
                            '& [data-field="upc"]':{
                                width: '150px !important',
                                maxWidth: '150px !important',
                                minWidth: '120px !important',
                                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                                    width: '120px !important',
                                },
                            },
                            '& [data-field="variant_id"]':{
                                width: '150px !important',
                                maxWidth: '150px !important',
                                minWidth: '120px !important',
                                ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                                    width: '120px !important',
                                },
                            },
                            '& [data-field="acciones"]':{
                                width: '0px !important',
                                maxWidth: '0px !important',
                                minWidth: '0px !important',
                                border: 'none !important',
                                '& div':{
                                    display: 'none'
                                }
                            }
                        },
                        '& .MuiDataGrid-row:hover':{
                            backgroundColor: 'transparent !important',
                        },
                        '& .MuiDataGrid-row:first-child':{
                            overflow: 'unset !important',
                            '& [data-field="acciones"]':{
                                display: 'block !important',
                                overflow: 'unset !important',
                                border: 'none !important',
                                width: '0px !important',
                                maxWidth: '0px !important',
                                minWidth: '0px !important',
                                '& div':{
                                    display: 'flex !important',
                                    position: 'absolute',
                                    right: '-15px',
                                    top: '-110px',
                                    justifyContent: 'end',
                                    ["@media (max-width:1500px)"]:{ // eslint-disable-line no-useless-computed-key
                                        right: '20px',
                                    },
                                }
                            },
                        }
                    }
                }
            }
        }
    },
    OrdersMaterialTable:{
        '& .column-total_price':{
            '& span':{
                color: '#b63615 !important',
                fontWeight: 'bold',
            }
        },
        '& .column-material_id':{
            '& a':{
                '& span':{
                    color: '#196cdb !important',
                    textTransform: 'uppercase',
                }
            }
        },
        '& .MuiTableCell-alignRight':{
            textAlign: 'left !important',
        },
        '& tbody tr':{
            '& td:nth-child(1)':{
                display: 'none'
            }
        },
        '& thead tr':{
            '& th:nth-child(1)':{
                display: 'none'
            }
        }
    },
    MaterialGridPaginationBox:{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        '& .MuiTablePagination-spacer':{
            display: 'none',
        },
    },
    MaterialGridMaterialStylePagination:{
        marginTop: '-55px',
        '& .MuiTablePagination-toolbar':{
            marginTop: '15px',
            borderTop: '1px solid #3d3d3d',
            borderBottom: '1px solid #3d3d3d',
          '& .page-number':{
                border: '1px solid #959595 !important',
          },
          '& .next-page':{
                border: '1px solid #959595 !important',
          },
          '& .previous-page':{
                border: '1px solid #959595 !important',
          },
        },
        '& .MuiCard-root#FilterAside':{
            order: -1,
            marginRight: '7px',
            width: '250px !important',
            display: 'inline-table',
            marginBottom: '68px',
            '& .MuiCardContent-root':{
                width: '250px !important',
            },
            '& .MuiListItem-root':{
                transition: 'all .5s',
                border: '1px solid transparent',
                borderRadius: '4px',
                '&:before':{
                    content: '""',
                    fontSize: '12px',
                    width: '8px',
                    height: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '20px',
                    backgroundColor: '#e8e8e8',
                    color: '#fff',
                    position: 'absolute',
                    top: '6px',
                    left: '6px',
                    transition: 'all 0.5s',
                    marginTop: '3px',
                    border: '1px solid transparent',
                },
                '&:hover':{
                    '&:before':{
                        border: '1px solid #196cdb',
                        backgroundColor: '#196cdb3d',
                    }
                }
            },
            '& .MuiListItem-root.Mui-selected':{
                backgroundColor: 'rgba(25, 108, 219, 0.2) !important',
                color: '#196cdb',
                border: '1px solid #196cdb',
                borderRadius: '4px',
                '&:before':{
                    border: '1px solid #196cdb',
                    backgroundColor: '#196cdb',
                },
                '&:hover':{
                    backgroundColor: 'rgba(25, 108, 219, 0.2) !important',
                    '&:before':{
                        border: '1px solid #196cdb',
                        backgroundColor: '#196cdb',
                    },
                }
            },
            '& .MuiListItemSecondaryAction-root':{
                marginRight: '-10px',
                '& button':{
                    '& span':{
                        '& .MuiSvgIcon-root':{
                            width: '0.8em !important',
                            height: '0.8em !important',
                            color: '#196cdb !important',
                        },
                    }
                }
            },
            '& .MuiButtonBase-root:hover:active::after':{
                borderRadius: '4px !important',
                backgroundColor: 'rgba(0,0,0,0) !important',
            },
            '& .MuiListItem-button:hover':{
                borderRadius: '4px !important',
                backgroundColor: 'rgba(0,0,0,0) !important',
            },
        },
    },
    MaterialGridModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiBackdrop-root':{
            backgroundColor: 'rgba(0, 0, 0, 0.06) !important',
        }
    },
    MaterialGridPaper: {
        backgroundColor: '#e5e5e5',
        borderRadius: '10px',
        boxShadow: '1px 1px 10px #757171 !important',
        padding: '20px',
        width: '800px',
        marginLeft: '20vh',
        '& .ra-input':{
            marginLeft: '-23px',
        },
        '& .MuiToolbar-root':{
            backgroundColor: '#e5e5e5 !important',
            marginLeft: '-20px !important',
            marginTop: '-5px',
        }
    },
    MaterialGridBasketButton: {
        '& button':{
            backgroundColor: '#3d3d3d !important',
            color: '#fff !important',
            marginLeft: '10px !important',
            paddingRight: '10px !important',
            paddingLeft: '10px !important',
            textTransform: 'none !important',
            fontSize: '0.8125rem',
            padding: '4px 5px',
            '& svg':{
                marginLeft: '5px',
                marginRight: '5px',
            } 
        },
        '& button:hover':{
            backgroundColor: '#5f5e5e !important',
        },
    },
    MaterialGridTramitarButton: {
        '& div':{
            width: '100%',
            marginLeft: '10px',
            marginRight: '10px',
        },
        '& button':{
            backgroundColor: '#196cdb !important',
            color: '#fff !important',
            paddingRight: '10px !important',
            paddingLeft: '10px !important',
            textTransform: 'none !important',
            fontSize: '0.8125rem',
            padding: '4px 5px',
            width: '100%',
            '& svg':{
                marginLeft: '5px',
                marginRight: '5px',
            }
        },
        '& button:hover':{
            backgroundColor: '#196cdbc4 !important',
        },
    },
    BasketListTable:{
        height: "100vh",
        maxHeight: "calc(100vh - 150px)",
        overflowY: "scroll",
        scrollbarColor: "#acacac #fbfbfb",
        scrollbarWidth: "thin",
        '& .MuiPaper-elevation1':{
            boxShadow: '0px 0px 0px #fff !important',
        },
        '& .MuiTableHead-root':{
            display: 'none !important',
        },
        '& .MuiTableRow-root:first-child':{
            marginTop: '0px !important'
        },
        '& .MuiTableRow-root':{
            marginTop: '45px'
        },
        '& .MuiTableRow-root.MuiTableRow-hover:hover':{
            backgroundColor: '#fff !important',
        },
        '& img':{
             width: '80px',
        },
        '& td':{
            display: 'flex',
            borderBottom: 'none',
            paddingBottom: '15px',
            justifyContent: 'flex-end',
            '& button':{
                position: 'absolute',
                right: '10px',
            },
            '& span':{
                textTransform: 'uppercase',
            }
        },
        '& tr':{
            height: '130px',
            display: 'inline-block',
            minHeight: '100px',
        },
        '& span':{
            color: '#3d3d3d',
        },
    },
    BasketListInfoTable:{
        '& .MuiPaper-elevation1':{
            boxShadow: '0px 0px 0px #fff !important',
        },
        '& .list-page':{
            height: "260px",
            overflowY: "scroll",
            scrollbarColor: "#acacac #fbfbfb",
            scrollbarWidth: "thin",
        },
        '& .MuiTableHead-root':{
            display: 'none !important',
        },
        '& .MuiTableRow-root.MuiTableRow-hover:hover':{
            backgroundColor: '#fff !important',
        },
        '& img':{
            width: '80px',
        },
        '& td':{
            display: 'flex',
            borderBottom: 'none',
            paddingBottom: '15px',
            '& span':{
                textTransform: 'uppercase',
            }
        },
        '& tr':{
            height: '130px',
            minHeight: '100px',
        },
        '& span':{
            color: '#3d3d3d !important',
        },
        '& span#ProductPrice':{
            color: 'rgb(182, 54, 21) !important',
        },
    },
    
    FilesSubTitle: {
        color: '#5e5e5e !important',
        paddingTop: '5px !important',
        paddingBottom: '8px !important',
        paddingLeft: '8px !important',
        paddingRight: '8px !important',
        fontStyle: 'italic !important'
    },
    FilesRightSerchBar: {
        '& .MuiToolbar-root': {
          display: 'flex',
          marginBottom: '5px',
          '& .MuiToolbar-gutters':{
            paddingTop: '21px',
            width: 'auto',
            '& a':{
              marginLeft: '0px !important',
              '& span':{
                width: '110px !important',
              }
            }
          },
          '& form': {
            width: '100%',
            justifyContent: 'right',
          },
        },
        '& .list-page':{
          marginTop: '-45px'
        },
        '& .MuiTablePagination-toolbar':{
            width: '40% !important',
            justifyContent: 'center',
        },
    },
    FilesrootAdmin: {
        marginRight: '-16px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: '#3d3d3d',
            },
            '&:hover fieldset': {
            borderColor: '#3d3d3d',
            },
            '&.Mui-focused fieldset': {
            borderColor: '#3d3d3d',
            },
        },
        '& .RaBulkActionsToolbar-toolbar': {
            '& h6': {
                color: '#3d3d3d',
            },
        },
    },
    FilesrootUser: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: '#939393',
            },
            '&:hover fieldset': {
            borderColor: '#000',
            },
            '&.Mui-focused fieldset': {
            borderColor: '#196cdb',
            },
        },
        '& .RaBulkActionsToolbar-toolbar': {
            '& h6': {
                color: '#196cdb',
            },
        },
    },
    FilesRightSerchMargin:{
        '& .MuiToolbar-root': {
          '& form':{
            '& .filter-field':{
              marginRight: '-15px',
            }
          },
        }
    },
    OrdersRightSerchMargin:{
        '& .MuiToolbar-root': {
          '& form':{
            '& .filter-field':{
              marginRight: '-15px',
            }
          },
        }
    },
    OrdersRightSerchBar: {
        '& .MuiToolbar-root': {
          display: 'flex',
          marginBottom: '10px',
          '& .MuiToolbar-gutters':{
            paddingTop: '21px',
            width: 'auto',
            position: 'absolute',
            left: '680px',
            '& a':{
              marginLeft: '0px !important',
              '& span':{
                width: '110px !important',
              }
            }
          },
          '& form': {
            width: '100%',
            justifyContent: 'right',
          },
        },
        '& .list-page':{
          marginTop: '-45px'
        },
        '& div[data-test="bulk-actions-toolbar"]':{
            '& .MuiToolbar-gutters':{
                paddingTop: '21px',
                width: 'auto',
                position: 'absolute',
                right: '20px !important',
            }
        }
    },
    OrderFilterButtonGoogleStyle: {
        '& div[data-source="tocken"]':{
            position: 'absolute',
            left: '450px',
        },
        '& .hide-filter':{
            marginBottom: '4px',
        },
        '& .add-filter':{
            color: '#3d3d3d !important',
            backgroundColor: 'transparent !important',
            marginLeft: '10px !important',
            minWidth: '40px',
            minHeight: '40px',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            transition: 'ease 0.6s',
            marginTop: '-4px',
            '& span':{
                paddingLeft: '6px',
                paddingTop: '1px',
            }
        },
        '& button:hover':{
            backgroundColor: 'hsla(0, 0%, 80%, 0.51) !important',
        },
        '& .hide-filter[data-key="category"]':{
            marginBottom: '5px',
            marginLeft: '-17px',
            marginRight: '-7px',
        },
        '& .hide-filter[data-key="jornadas_compras"]':{
            marginLeft: '-20px',
        },
    },
};

export default lightTheme;
