import React from "react";
import { Datagrid, List, TextField, ImageField, DeleteButton, ReferenceField, FunctionField, useRedirect, useNotify, useRefresh } from "react-admin";
import { makeStyles, Box, Typography, CardMedia } from '@material-ui/core';
import RemoveShoppingCartRoundedIcon from '@material-ui/icons/RemoveShoppingCartRounded';
import CloseIcon from '@material-ui/icons/Close';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  BulkDeletePostButton: lightTheme.BulkDeletePostButton,
  InputBlackBorder: lightTheme.InputBlackBorder,
  InLineViewPost: lightTheme.InLineViewPost,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  FilterButtonGoogleStyle: lightTheme.FilterButtonGoogleStyle,
  BasketListTable: lightTheme.BasketListTable,
  EmptyBasketMediaCard: lightTheme.EmptyBasketMediaCard,
  ImageHover:{
    '& div':{
      transition: '0.2s ease-in-out',
    },
    '& div:hover':{
      transform: 'scale(1.1, 1.1)',
      transition: '0.2s ease-in-out',
    }
  },
  VariantBadge:{
    fontSize: '0.80rem',
    '& span':{
      marginLeft: '-92px',
      position: 'absolute',
      zIndex: '50',
      marginTop: '-25px',
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '6px',
      paddingBottom: '4px',
      backgroundColor: '#bf1e2d',
      color: '#fff !important',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '12px'
    }
  },
  PriceColor:{
    '& p':{
      color: 'rgb(182, 54, 21) !important',
    }
  }
});

const EmptyBasket = () => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" style={{width: '100%', marginTop: '30px'}}>
      <Box textAlign="center" className={classes.EmptyBubble}>
        <CardMedia className={classes.EmptyBasketMediaCard}></CardMedia>
        <Typography variant="h6" style={{marginTop: '20px'}}>
          Tu Cesta está Vacía
        </Typography>
      </Box>
    </Box>
  );
};

const BasketList = ({record, ...props}) => {
  const classes = useStyles();

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const Message = () => {
    return(
      <Box>
        <Typography style={{color: '#fff', display: 'flex', width: '100%', fontSize: '14px'}}>
          <RemoveShoppingCartRoundedIcon/>
            <Box style={{marginLeft: '15px', paddingTop: '3px'}}><span>Eliminado de tu Cesta</span></Box>
          </Typography>
      </Box>
      
    )
  };

  const onSuccess = () => {
    notify(<Message/>, 'info', undefined, true);
    refresh();
    redirect('/material');
  };

  function containsAnyLetter(str) {
    return /[a-zA-Z]/.test(str);
  }

  return (
    <Box className={classes.BasketListTable}>
      <List {...props} sort={{ field: 'createdate', order: 'DESC'}} actions={false} pagination={false} bulkActionButtons={false} empty={<EmptyBasket/>}>
        <Datagrid>
          <MyTooltip title={<div style={{fontSize: '12px'}}>Retirar de la cesta</div>} placement="left" aria-label="edit" id="CartDeleteButton">
            <DeleteButton basePath="/basket" label="" record={props} id="GoogleStyleDownload" icon={<CloseIcon/>} redirect="/material" onSuccess={onSuccess}/>
          </MyTooltip>
          <Box style={{display: 'flex', width: '100%', borderTop: '1px solid #cacaca', marginRight: '20px', paddingTop: '5px'}}>
            <Box style={{margiLeft: '-15px', width: '50%'}} className={classes.ImageHover}>
              <ReferenceField label="" source="material_id" reference="material" link={false} style={{color: 'rgb(130, 130, 130)'}} id="ProductImage">
                <ImageField disabled label="" source="logo.image" variant="outlined"/>
              </ReferenceField>
            </Box>
            <Box style={{marginTop: '20px', marginRight: '-30px'}}>
              <Typography variant="body2" style={{width: '165px'}}>
                <ReferenceField label="" source="material_id" reference="material" link="show" style={{fontSize: '13px',}}>
                  <TextField disabled label="" source="title" variant="outlined"/>
                </ReferenceField>&nbsp;(
                <ReferenceField label="" source="variant_id" reference="variants" link={false} style={{fontSize: '13px',}}>
                  <TextField disabled label="" source="name" variant="outlined"/>
                </ReferenceField>)
              </Typography>
              <Box style={{width: '100%', display: 'flex'}}>
                <Box style={{width: '40%', display: 'flex', justifyContent: 'flex-start'}}>
                  <FunctionField 
                    render={ record => 
                      <Box>
                        <Box style={{display: 'flex', width: '250%', marginTop: '5px'}}>
                          {record.variant_price &&
                            <Box className={classes.PriceColor} style={{display: 'flex', width: '100%', justifyContent: 'flex-start'}}>
                              <Typography variant="body2" >
                                {(record.variant_price).toFixed(2).replace('.', ',')} €
                              </Typography>
                            </Box>
                          }
                          <Box style={{display: 'flex', width: '100%', justifyContent: 'flex-start', color: '#196cdb'}}>
                            <Typography variant="body2" style={{fontSize: '0.80rem'}}>x&nbsp;
                              <TextField disabled label="" source="cantidad" variant="outlined" style={{fontSize: '15px', color: '#196cdb'}}/>&nbsp; UD/S
                            </Typography>
                          </Box>
                        </Box>
                        {record.variant_id && containsAnyLetter(record.variant_name) === false &&
                          <Typography variant="body2" className={classes.VariantBadge}>
                            <ReferenceField label="" source="variant_id" reference="variants" link={false}>
                              <TextField disabled label="" source="name" variant="outlined"/>
                            </ReferenceField>
                          </Typography>
                        }
                      </Box>
                    } 
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Datagrid>
      </List>
    </Box>
  )
}; export default BasketList;