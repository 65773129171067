import React from "react";
import { 
  Create, SimpleForm, TextInput, ImageInput, ImageField, ReferenceInput, SelectInput, AutocompleteInput, FormDataConsumer,
  BooleanInput, Toolbar, SaveButton, required, SelectArrayInput , ReferenceArrayInput, useTranslate, SimpleFormIterator, ArrayInput
} from "react-admin";
import { makeStyles, Typography, Box, Divider, CircularProgress, Button } from '@material-ui/core';
import { mdiMapMarkerRadius, mdiAccountBox } from '@mdi/js';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import BackButton from '../components/BackButton';
import MyTooltip from "../components/MuiTooltip";
import lightTheme from '../layout/themes';
import Icon from '@mdi/react'
import '../index.css';

const LocationIcon = () => {  return( <Icon path={mdiMapMarkerRadius} size={'24px'} style={{marginBottom: '-4px'}}/> ) };
const ContactIcon = () => {  return( <Icon path={mdiAccountBox} size={'24px'} style={{marginBottom: '-4px'}}/> ) };

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  EditToolBar: lightTheme.EditToolBar,
  ContactArray: lightTheme.PointOfSaleStyle.CreateAndEdit.ContactArray,
  ModalidadBox:{
    display: 'flex',
    width: '100%',
    justifyContent:'flex-start',
    '& .MuiFormGroup-root':{
      width: '50%', 
      marginTop: '15px',
    },
    '& .MuiFormLabel-root':{
      backgroundColor: 'transparent',
      paddingLeft: '5px',
      paddingRight: '5px',
    }
  }
});

const ProviderCreateToolbar = ({loading, ...props}) => {
  const classes = useStyles();
  return (
    <Box>
      <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Proveedor</div>} arrow placement="right">
          <SaveButton disabled={props.pristine} id="GoogleStyleSaveButton" label="Crear" icon={<AddRoundedIcon/>}>
            {loading && (<CircularProgress/>)}
          </SaveButton>
        </MyTooltip>
      </Toolbar>
    </Box>
  )
};

const DefaultAddButton = props => {
  const classes = useStyles(props);
  return (
      <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Contacto</div>} aria-label="Delete" arrow placement="right">
          <Button size="small" {...props} id="GoogleStyleDownload">
              <AddRoundedIcon className={classes.leftIcon} />
          </Button>
      </MyTooltip>
  );
};

const DefaultRemoveButton = props => {
  const classes = useStyles(props);
  return (
      <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Contacto</div>} aria-label="Delete" arrow placement="bottom">
          <Button size="small" {...props} id="GoogleStyleMuiDeleteButton">
              <DeleteRoundedIcon className={classes.leftIcon} />
          </Button>
      </MyTooltip>
  );
};

const ProviderCreate = (props) => {
  const shop = (props.location.search);
  const classes = useStyles();
  const translate = useTranslate();
  const RoleChoices=[ 
    { id: 'socio', name: 'socio' }, 
    { id: 'central', name: 'central' }
  ] 

  return(
    <Create {...props}>
      <SimpleForm toolbar={<ProviderCreateToolbar/>} defaultValue={{ shop: shop }} redirect={`/shops`}>
        <Box style={{ width:'100%', color: '#3d3d3d' }}>
          <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
            <Box p={1} width="100%">
              <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Nuevo Proveedor</Typography>
            </Box>
            <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack">
                <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
              </MyTooltip>
            </Box>
          </Box>
          <Box>
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <InfoRoundedIcon style={{fontSize: '1.5rem'}}/>&nbsp;&nbsp;Información General
            </Typography>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Nombre" source="name" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()}/>
              <ReferenceInput label="Tienda" source="shop" reference="shops" variant="outlined" className={classes.InputBlackBorder} validate={required()}>
                <SelectInput source="name" style={{ width: '100%' }}/>
              </ReferenceInput>
            </Box>
            <Box className={classes.ModalidadBox}>
              <ReferenceArrayInput label="Modalidad" source="category" reference="categorys" variant="outlined" className={classes.InputBlackBorder} 
                perPage={100} sort={{ field: 'name', order: 'ASC' }}>
                <SelectArrayInput source="name" style={{ width: '50%'}}/>
              </ReferenceArrayInput>
              <BooleanInput label="Presente en Jornadas de Compras" source="jornadas_compras" className={classes.InputBlackBorder}/>
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <DomainRoundedIcon style={{fontSize: '1.5rem'}}/>&nbsp;&nbsp;Razón Social
            </Typography>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Nombre" source="company" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
              <TextInput label="CIF/NIF" source="nif" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder} style={{ width: '30%' }}/>
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Email" source="email" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
              <TextInput label="Teléfono" source="phone" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
            </Box>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
              <TextInput label="Dirección Web" defaultValue="https://" source="web" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px', marginTop: '2px'}} />
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <LocationIcon style={{fontSize: '1.5rem'}}/>&nbsp;&nbsp;Dirección
            </Typography>
            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                <TextInput label={translate(`resources.users.field.address`)} variant="outlined" source="address" 
                    multiline="true" fullWidth className={classes.InputBlackBorder} />
            </Box>
            <Box style={{ width: '100%',display: 'flex' }}>
              <ReferenceInput source="country" reference="country" filterToQuery={searchText => ({ name: searchText })} 
                  label={translate(`resources.users.field.country`)} className={classes.InputBlackBorder} variant="outlined" style={{ width: '30%' }}>
                  <AutocompleteInput optionText="name" />
              </ReferenceInput>
              <FormDataConsumer>
                  {({ formData, ...rest }) => {
                      return (
                          <Box style={{marginRight: '20px', width: '30%', marginLeft: '20px' }}>
                              <ReferenceInput source="state" reference="state" filterToQuery={searchText => ({ name: searchText })} perPage={55}
                                  label={translate(`resources.users.field.state`)} className={classes.InputBlackBorder} variant="outlined"
                                  filter={{country: formData.country}} {...rest}>
                                  <AutocompleteInput optionText="name"/>
                              </ReferenceInput>
                          </Box>
                      );
                  }}
              </FormDataConsumer>
              <TextInput label={translate(`resources.users.field.location`)} variant="outlined" source="location" 
                  multiline="true" fullWidth className={classes.InputBlackBorder} style={{ width: '30%' }}/>
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px', marginTop: '2px'}} />
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <ContactIcon style={{fontSize: '1.5rem'}}/>&nbsp;&nbsp;Contactos
            </Typography>
            <Box className={classes.ContactArray}>
              <ArrayInput source="contact" label="" style={{width: '100%', marginLeft: '10px', marginTop: '-10px'}}>
                <SimpleFormIterator addButton={<DefaultAddButton />} removeButton={<DefaultRemoveButton />}>
                    <FormDataConsumer>
                      {({ getSource, scopedFormData}) => {
                        return (
                          <Box>
                            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                              <TextInput label="Nombre del Contacto" source={getSource('name')} variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}
                                style={{width: '50%'}}/>
                              <TextInput label="Puesto de Trabajo" source={getSource('work')} variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}
                                style={{width: '50%'}}
                              />
                            </Box>
                            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                              <TextInput label="Email" source={getSource('email')} variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
                              <TextInput label="Teléfono" source={getSource('phone')} variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder}/>
                              <SelectInput required label="Rol" variant="outlined" source={getSource('role')}
                                multiline="true" fullWidth className={classes.InputBlackBorder} choices={RoleChoices} style={{textTransform: 'capitalize'}}/>
                            </Box>
                          </Box>
                        );
                      }}
                    </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px', marginTop: '2px'}} />
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
              <ImageOutlinedIcon style={{fontSize: '1.5rem'}}/>&nbsp;&nbsp;Logo del Proveedor
            </Typography>
            <Box style={{paddingLeft: '10px', paddingRight: '10px'}}>
              <ImageInput source="logo" label="" accept="image/*" validate={required()}>
                  <ImageField source="src" title="logo" style={{border: '1px solid rgb(208, 203, 203)', borderRadius: '10px'}}/>
              </ImageInput>
            </Box>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}; export default ProviderCreate;