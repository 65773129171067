import React from 'react';
import { TopToolbar, usePermissions, useGetOne, useRedirect } from 'react-admin';
import { Box, makeStyles } from '@material-ui/core';
import Fab from '@mui/material/Fab';
import lightTheme from '../layout/themes';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ProviderList from '../provider/ProviderList';
import MyTooltip from '../components/MuiTooltip';

const useStyles = makeStyles({
  MainProviderBox: lightTheme.ShopsStyles.ShopList.MainProviderBox,
  LogoBox: lightTheme.ShopsStyles.ShopList.LogoBox,
});


const ShowView = (props ) => {
    const ShopId = props.id;
    const classes = useStyles();
    const { permissions } = usePermissions();
    const redirect = useRedirect();
    const { data } = useGetOne('shops', ShopId);

    let fakeProps = {basePath: "/provider", resource: "provider", shop_id: ShopId }

    const ProviderListActions = ({ resource, filters, displayedFilters, filterValues, showFilter, ...fakeProps }) => {
      return (
        <Box style={{display: 'flex', width: '50%', justifyContent: 'flex-end'}}>
          <TopToolbar {...fakeProps}>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Filtro</div>} id="hideFilterTooltip" arrow>
              {filters && React.cloneElement(filters, { showFilter, displayedFilters, context: 'button', shop: ShopId}) }
            </MyTooltip>
          </TopToolbar>
          <Box style={{position: 'fixed', bottom: '10px', right: '5px', zIndex: '999'}}>
            {permissions === "Admin" &&
              <Box style={{position: 'fixed', bottom: '25px', right: '30px', zIndex: '999'}}>
                <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Proveedor</div>} arrow>
                  <Fab color="primary" aria-label="add" onClick={() => { redirect(`/provider/create?source={"shop":"${ShopId}"}`); }}>
                    <AddRoundedIcon />
                  </Fab>
                </MyTooltip>
              </Box>
            }
          </Box>
        </Box>
      )
    };

    return (
      <Box className={classes.MainProviderBox}>
        {data &&
          <Box>
            <ProviderList {...fakeProps} pagination={false} filter={{ shop: ShopId }} actions={<ProviderListActions />}/>
          </Box>
        }
      </Box>
    )
};

export default ShowView;