import React from 'react';
import { } from 'react-admin';
import { makeStyles, Box,Typography, Modal, Backdrop, Fade } from '@material-ui/core';
import AboutLogo from './AboutLogo';
import MyTooltip from "../components/MuiTooltip";
import CloseButton from '../components/CloseButton';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
    MuiModal: lightTheme.CommonStyles.Modal.MuiModal,
    MuiPaper: lightTheme.CommonStyles.Modal.MuiPaper,
    AboutModal: lightTheme.AppBarStyles.AboutModal,
    AboutPaper: lightTheme.AppBarStyles.AboutPaper,
    FlexCenter: lightTheme.AppBarStyles.FlexCenter,
    FlexCenterBox: lightTheme.AppBarStyles.FlexCenterBox,
    FlexStartTitle: lightTheme.AppBarStyles.FlexStartTitle,
    FlexEndDescription: lightTheme.AppBarStyles.FlexEndDescription,
    FixedAppBar: lightTheme.AppBarStyles.FixedAppBar,
    title: lightTheme.AppBarStyles.title,
    spacer: lightTheme.AppBarStyles.spacer,
    avatar: lightTheme.AppBarStyles.avatar,
    list: lightTheme.AppBarStyles.list,
    fullList: lightTheme.AppBarStyles.fullList,
    basketButton: lightTheme.AppBarStyles.basketButton,
    TramitarButton: lightTheme.AppBarStyles.TramitarButton,
    MenuItem: {
        width: '100%',
        padding: '8px',
        paddingTop: '2px',
        paddingBottom: '2px',
        display: 'flex',
        justifyContent: 'center',
        '& span':{
            paddingLeft: '8px',
            paddingRight: '8px',
            color: '#5f6368',
            fontSize: '13px',
            padding: '10px',
            borderRadius: '6px',
        },
        '& span:hover':{
            backgroundColor: '#3d3d3d1c',
            cursor: 'pointer',
            color: '#3d3d3d'
        },
        '& #divider:hover':{
            textDecoration: 'none',
            backgroundColor: '#fff',
            cursor: 'default',
        }
    },
    AboutLogo: {
        width: '100%',
        '& svg':{
            marginLeft: '-15px',
            width: '200px'
        }
    },
    CopyrightBox: {
        backgroundColor: '#eaeaea',
        borderRadius: '10px',
        padding: '15px',
        cursor: 'default',
        '& a':{
            color: '#196cdb',
            textDecoration: 'none',
        },
        '& #Brands':{
            fontSize: '13px',
            color: '#747474',
        },
    },
    LogosBox:{
        paddingTop: '10px',
        display: 'flex',
        width: '100%',
        '& .MuiBox-root':{
            display: 'flex',
            alignItems: 'center',
            '& img':{
                width : '60px',
                padding: '10px',
            },
            '& img#FoM':{
                width : '90px',
                paddingLeft: '0px',
            }
        }
    },
    VersionBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%'
    },
});


const About = () => {
    const classes = useStyles();
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => {setOpen2(true)};
    const handleClose2 = () => {setOpen2(false)};
    let date =  new Date().getFullYear();
    return (
        <Box>
            <Box className={classes.MenuItem}>
                <span onClick={handleOpen2}>Acerca de la Intranet</span><span id="divider">•</span><span>Política de Datos</span>
            </Box>

            <Modal className={classes.AboutModal} open={open2} onClose={handleClose2} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
                <Fade in={open2}>
                    <div className={classes.AboutPaper}>
                        <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                            <Box p={1} className={classes.AboutLogo}>
                                <AboutLogo/>
                            </Box>
                            <Box p={1} flexShrink={0} style={{alignItems: 'center', display: 'flex'}}>
                                <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                                    <CloseButton style={{paddingLeft: '17px',  paddingRight: '5px'}} onClick={handleClose2}></CloseButton>
                                </MyTooltip>
                            </Box>
                        </Box>
                        <Box className={classes.VersionBox}>
                            <span className={classes.FlexStartTitle}>Versión: </span><span className={classes.FlexEndDescription}>Beta 1.0.0 </span>
                        </Box>
                        <Box className={classes.CopyrightBox}>
                            <Typography>
                                Copyright © {date} Trendigo Group S.L.
                            </Typography>
                            <Typography>
                                Trendico.net es un dominio registrado de <a href="https://trendico.es/" target="_blank" rel="noreferrer">Tréndico Group</a>
                            </Typography><br/>
                            <Typography id="Brands">
                                Nuestras Marcas
                            </Typography>
                            <Box className={classes.LogosBox}>
                                <Box>
                                    <a href="https://footonmars.com/" target="_blank" rel="noreferrer">
                                        <img src="../../static/images/Shops/AboutFom.svg" alt="FoM Logo" id="FoM"/>
                                    </a>
                                </Box>
                                <Box>
                                    <a href="https://atleet.store/sotkatt" target="_blank" rel="noreferrer">
                                        <img src="../../static/images/Shops/AboutAtleet.png" alt="Atleet Logo" id="Atleet"/>
                                    </a>
                                </Box>
                                <Box>
                                    <a href="https://sotkatt.com/" target="_blank" rel="noreferrer">
                                        <img src="../../static/images/Shops/AboutSotkatt.png" alt="Sotkatt Logo" id="Sotkatt"/>
                                    </a>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </Fade>
            </Modal>
        </Box>
    );
}; export default About;
