import React from "react";
import { Link } from 'react-router-dom';
import { 
  Datagrid, List, TextField, EditButton, DeleteButton, ReferenceField, useGetOne,
  Filter, SearchInput, useListContext, usePermissions, DateField, ReferenceInput, AutocompleteInput, LinearProgress
} from "react-admin";
import { makeStyles, Box, Typography, ThemeProvider, TablePagination } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import * as locales from '@material-ui/core/locale';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import ToolbarBackButton from '../components/ToolbarBackButton';
import MyTooltip from '../components/MuiTooltip';
import { FileIcon, defaultStyles } from '../components/FileIcon';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  BulkDeletePostButton: lightTheme.BulkDeletePostButton,
  InputBlackBorder: lightTheme.InputBlackBorder,
  FilesTable: lightTheme.FilesStyles.FilesTable,
  PostViewCard: lightTheme.PostViewCard,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  RightSerchBar: lightTheme.CompanyStyle.List.RightSerchBar,
  root: lightTheme.CompanyStyle.List.root,
  mediaExcel: lightTheme.ProviderGridMediaFilesExcel,
  mediaPoint: lightTheme.ProviderGridMediaFilesPoint,
  mediaPDF: lightTheme.ProviderGridMediaFilesPDF,
  mediaPNG: lightTheme.ProviderGridMediaFilesPNG,
  ProviderFilterButtonGoogleStyle: lightTheme.ProviderFilterButtonGoogleStyle,
  ProviderFilter: {
    marginRight: '-16px',
    marginLeft: '15px',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
        borderColor: '#3d3d3d',
        },
        '&:hover fieldset': {
        borderColor: '#3d3d3d',
        },
        '&.Mui-focused fieldset': {
        borderColor: '#3d3d3d',
        },
    },
    '& .RaBulkActionsToolbar-toolbar': {
        '& h6': {
            color: '#3d3d3d',
        },
    },
  }
});

const CategoryName = ({ id }) => {
  const { data, loading, error } = useGetOne('filesCategories', id);
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data) { return (data.name) };
};

const CompanyFilter = ({ resource, filters, displayedFilters, filterValues, showFilter, shop, ...props }) => {
  const classes = useStyles();
  return (
    <Box style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
      { props.context !== 'button' &&
        <Box style={{ width: 'auto', display: 'flex', justifyContent: 'flex-start' }}>
          <Box>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Volver Atrás</div>} arrow>
              <ToolbarBackButton style={{paddingLeft: '16px', paddingRight: '5px', marginRight: '10px', marginTop: '15px'}} id="GoogleStyleBackButton"></ToolbarBackButton>
            </MyTooltip>
          </Box>
          <Box style={{ width: 'auto', display: 'flex', justifyContent: 'flex-start', marginTop: '15px' }}>
            <Box>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Carpetas de Archivos</div>} arrow>
                <Link to={`/filesCategories`} style={{textDecoration: 'none'}}>
                  <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '10px', zIndex: '5', fontSize: '14px', cursor: 'pointer'}}>Carpetas</Typography>
                </Link>
              </MyTooltip>
            </Box>
            <NavigateNextRoundedIcon style={{marginLeft: '10px', marginRight: '10px', marginTop: '8px', marginBottom: '6px'}}/>
              <Box>
                <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '10px', zIndex: '5', fontSize: '14px', cursor: 'pointer'}}>
                  <CategoryName id={props.category}/>
                </Typography>
              </Box>
          </Box>
        </Box>
      }
      <Box style={{marginLeft: '10px'}}>
        <Filter {...props}>
          <SearchInput source="name" alwaysOn variant="outlined" className={classes.InputBlackBorder}/>
          {props.category === 'eLRVRilYd5sdIR7w8Zl3' &&
            <ReferenceInput source="provider" label="Proveedor" reference="provider" filterToQuery={searchText => ({ name: searchText })}
              variant="outlined" className={classes.InputBlackBorder} perPage={500} alwaysOn>
              <AutocompleteInput optionText="name"/>
            </ReferenceInput>
          }
        </Filter>
      </Box>
    </Box>
  )
};


const CompanyPagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();

  const handleChangeRowsPerPage = (event) => { 
      setPerPage(parseInt(event.target.value, 10));
      setPage(1);
  };
  const handleChangePage = (event, newPage) => {
      setPage(newPage + 1);
   };
  
  return (
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
          <TablePagination
              component="div"
              rowsPerPageOptions={[8, 10, 12, 15, 20]}
              count={total || 0}
              page={page - 1}
              onChangePage ={handleChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </ThemeProvider>
  );
}

const FileTypeLogo = ({record}) => {
  return (
    <Box style={{display: 'flex', justifyContent: 'center', height: '40px', alignItems: 'center'}}>
      {record && record.type === 'PDF' &&
        <FileIcon extension="pdf" {...defaultStyles.pdf} />
      }
      {record && record.type === 'Excel' &&
        <FileIcon extension="xlsx" {...defaultStyles.xlsx} />
      }
      {record && record.type === 'PowerPoint' &&
        <FileIcon extension="pptx" {...defaultStyles.pptx} />
      }
      {record && record.type === 'Word' &&
        <FileIcon extension="docx" {...defaultStyles.docx} />
      }
      {record && record.type === 'Imagen' &&
        <FileIcon extension="png" {...defaultStyles.png} />
      }
    </Box>
  );
}


const CompanyList = ({...props }) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const FilesCategory = props.category;

  return (
    <Box className={classes.RightSerchBar}>
      <List {...props} actions={false} bulkActionButtons={false} filters={<CompanyFilter category={FilesCategory}/>} 
        pagination={<CompanyPagination/>} perPage={8} sort={{ field: 'lastupdate', order: 'DESC' }} component="div" style={{marginBottom: '4em'}}>
        <Datagrid rowClick="show" className={classes.FilesTable} >
          <FileTypeLogo/>
          <TextField label="Nombre" source="name" />
          <TextField label="Tipo" source="type" />
          <ReferenceField label="Propietario" source="user" reference="users" link={false}>
            <TextField source="displayName" />
          </ReferenceField>
          <DateField label="Última Modificación" source="lastupdate" locales="fr-FR"/>
          {permissions === 'Admin' &&
              <MyTooltip title={<div style={{fontSize: '12px'}}>Editar</div>} aria-label="edit" arrow placement="bottom">
                  <EditButton basePath="/company" label="" record={props} id="GoogleStyleButton"/>
              </MyTooltip>
          }
          {permissions === 'Admin' &&
              <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar</div>} aria-label="delete" arrow placement="bottom">
                  <DeleteButton basePath="/company" label="" record={props} id="GoogleStyleDeleteButton"/>
              </MyTooltip>
          }
        </Datagrid>
      </List>
    </Box>
  )
};

export default CompanyList;