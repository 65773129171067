import React from "react";
import { Redirect } from 'react-router';
import { 
  Edit, SimpleForm, TextInput, ImageInput, ImageField, ReferenceInput, SelectInput, Toolbar, ReferenceArrayInput, BooleanInput,
  SaveButton, required, NumberInput, useGetList, SelectArrayInput, DeleteButton, useGetOne, FormDataConsumer, useRedirect
} from "react-admin";
import { makeStyles, Typography, Box, Divider, CircularProgress, Modal, Backdrop, Fade } from '@material-ui/core';
import { mdiHanger } from '@mdi/js'; 
import Icon from '@mdi/react';
import TinyMCETextEditor from '../components/TextEditors/TinyMCETextEditor';
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import VariantsList from "../variants/VariantsList";
import VariantsCreate from "../variants/VariantsCreate";
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import lightTheme from '../layout/themes';
import Fab from '@mui/material/Fab';
import '../index.css';

const AddVariant = () => {  return( <Icon path={mdiHanger} size={0.9}/> ) };

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  ReactRichTextStyles: lightTheme.NewsStyles.CreateAndEdit.ReactRichTextStyles,
  EditToolBar: lightTheme.EditToolBar,
  modal: lightTheme.MaterialViewModal,
  paper: lightTheme.MaterialViewPaper,
  SelectInputs:{
    '& .MuiOutlinedInput-root':{
      height: '57px',
    },
  },
});

const ProviderCreateToolbar = ({loading, basePath, ...props}) => {
  const classes = useStyles();
  return (
    <Box>
      <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="Save" arrow placement="top">
          <SaveButton id="GoogleStyleSaveButton">
            {loading && (<CircularProgress/>)}
          </SaveButton>
        </MyTooltip>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Consumible</div>} aria-label="Delete" arrow placement="right">
          <DeleteButton basePath={basePath} label="" id="GoogleStyleDeleteButton"/>
        </MyTooltip>
      </Toolbar>
    </Box>
  )
};

const MaterialEdit = ({ permissions, ...props }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {setOpen2(false)};
  const {data} = useGetOne('material', props.id);
  const { ids } = useGetList('variants', { page: 1, perPage: 200 }, { field: 'ean', order: 'ASC'}, { material_id: props.id });
  let VariantsProps = {basePath: "/variants", resource: "variants", material_id: props.id}

  if (permissions === 'Admin' || permissions === "Transporte") {
    return(
      <Box>
        {data &&
          <Edit {...props} actions={false}>
            <SimpleForm toolbar={<ProviderCreateToolbar/>} redirect={data.type === 'consumibles' ? `/material` : `/informacion`}>
              <Box style={{ width:'100%', color: '#3d3d3d' }}>
                <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                  <Box p={1} width="100%">
                    <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Consumible</Typography>
                  </Box>
                  <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                      <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                    </MyTooltip>
                  </Box>
                </Box>
                {data &&
                  <Box>
                    <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                      <DescriptionRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>
                      Detalles del Consumibles
                    </Typography>
                    <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                      <TextInput label="Título" source="title" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()} style={{ width: '60%' }}/>
                      <TextInput label="Referencia" source="reference" variant="outlined" multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()} style={{ width: '40%' }}/>
                    </Box>
                    <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start" className={classes.SelectInputs}>
                      <ReferenceInput label="Categoría" source="shop" reference="shops" variant="outlined" className={classes.InputBlackBorder} validate={required()}>
                        <SelectInput source="name" style={{ width: '50%' }}/>
                      </ReferenceInput>
                      <ReferenceArrayInput label="División del Producto" source="division" reference="division" variant="outlined" className={classes.InputBlackBorder} validate={required()}>
                        <SelectArrayInput source="name" style={{ width: '50%'}}/>
                      </ReferenceArrayInput>
                    </Box>
                    <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
                    <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                      <SubjectRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>
                      Descripción
                    </Typography>
                    <Box style={{display:'none'}}>
                        <NumberInput step={0.1} label="Cantidad" source="stock" variant="outlined" fullWidth className={classes.InputBlackBorder} validate={required()} style={{ width: '48%' }} min={0}/>
                        <BooleanInput label="Disponible en central" source="stock_filter" className={classes.InputBlackBorder} defaultValue={false} style={{width: '30%', paddingTop: '8px'}}/>
                    </Box>
                  
                    <TinyMCETextEditor data={data.description} source="textValue"/>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                          formData.description = formData.textValue
                        }}
                    </FormDataConsumer>

                    <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, marginTop: 25, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                      <SubjectRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>
                      Información del Envio
                    </Typography>

                    <TinyMCETextEditor data={data.shipping} source="textValue2"/>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                          formData.shipping = formData.textValue2
                        }}
                    </FormDataConsumer>

                    <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
                    <Box style={{paddingLeft: '10px', paddingRight: '10px'}}>
                      <ImageInput source="logo" label="Imagen del Consumible" accept="image/*" >
                          <ImageField source="image" title="imageName" style={{border: '1px solid rgb(208, 203, 203)', borderRadius: '10px'}}/>
                      </ImageInput>
                    </Box>
                  </Box>
                }
              </Box>
            </SimpleForm>
          </Edit>
        }
        {ids.length > 0 && data.type === 'consumible' &&
          <Box style={{boxShadow: '1px 1px 10px #bbb6b6', backgroundColor: '#fff', borderRadius: '10px', padding: '20px', marginTop: '30px'}}>
            <Box p={1} style={{ width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
              <Box>
                <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Variantes del Producto</Typography>
              </Box>
              <Box style={{position: 'fixed', bottom: '20px', right: '30px', zIndex: '999'}}>
                <MyTooltip title={<div style={{fontSize: '12px'}}>Crear nueva Variante para este Producto</div>} arrow placement="left">
                  <Fab color="primary" aria-label="add" onClick={() => { redirect(`/variants/create`); }}>
                    <AddVariant />
                  </Fab>
                </MyTooltip>
              </Box>
            </Box>
            <Box p={1} style={{ marginTop: '-40px'}}>
              <VariantsList {...VariantsProps}/>
            </Box>
        </Box>
        }

        <Modal className={classes.modal} open={open2} onClose={handleClose2} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
          <Fade in={open2}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title-2">Crear Variante del Producto</h2>
              <Box style={{width: '100%'}}>
                <VariantsCreate {...VariantsProps} />
              </Box>
            </div>
          </Fade>
        </Modal>

      </Box>
    )
  } else { return (<Redirect to={`/material`} />); }
}; export default MaterialEdit;