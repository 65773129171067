import React from "react";
import { 
  Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, FileInput, FileField, 
  Toolbar, SaveButton, DeleteButton, useGetOne, FormDataConsumer, required
} from "react-admin";
import { makeStyles, Typography, Box, Divider, CircularProgress } from '@material-ui/core';
import TinyMCETextEditor from '../components/TextEditors/TinyMCETextEditor'
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import BackButton from '../components/BackButton';
import MyTooltip from "../components/MuiTooltip";
import lightTheme from '../layout/themes';
import '../index.css';

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  FileInputBox: lightTheme.FileInputBox,
  EditToolBar: lightTheme.EditToolBar,
});

const FilesEdit = ({permissions, ...props}) => {
  const classes = useStyles();
  const { data } = useGetOne('files', props.id);
  const ConditionRedirect = () => `/conditions/${data.condition_id}/show`
  const NoticeRedirect = () => `/publications/${data.notice_id}/show`
  const OfferRedirect = () => `/publications/${data.offer_id}/show`

  const FilesEditToolbar = ({loading, ...props}) => {
    const classes = useStyles();
    return (
      <Box>
        {data &&
          <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="Save" arrow>
              <SaveButton id="GoogleStyleSaveButton">
                {loading && ( <CircularProgress/> )}
              </SaveButton>
            </MyTooltip>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Archivo</div>} aria-label="Delete" arrow placement="right">
              <DeleteButton label="" id="GoogleStyleDeleteButton" redirect={
                data.category === 'eLRVRilYd5sdIR7w8Zl3' ? ConditionRedirect : 
                  (data.category === 'VtNc5D0KMKEtk4cX1LZq' ? NoticeRedirect : 
                    (data.category === 'JovTtJjyBDezCYAtXpMH' ? OfferRedirect: 
                      null
                    )
                  )
                }
              />
            </MyTooltip>
          </Toolbar>
        }
      </Box>
    )
  };

  return(
    <Edit {...props} actions={false}>
      {data &&
        <SimpleForm toolbar={<FilesEditToolbar/>} redirect={
          data.category === 'eLRVRilYd5sdIR7w8Zl3' ? ConditionRedirect : 
            (data.category === 'VtNc5D0KMKEtk4cX1LZq' ? NoticeRedirect : 
              (data.category === 'JovTtJjyBDezCYAtXpMH' ? OfferRedirect: 
                null
              )
            )
          }
        >
          <Box style={{ width:'100%' }}>
            <Box style={{ width:'100%', color: '#3d3d3d' }}>
              <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                <Box p={1} width="100%">
                  <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Adjuntar Archivo</Typography>
                </Box>
                <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                  <MyTooltip title={<div style={{fontSize: '13px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                    <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                  </MyTooltip>
                </Box>
              </Box>
            </Box>
            <Box >
              <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                  <InfoRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>Información General
              </Typography>
              <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                <TextInput label="Título" source="name" multiline="true" fullWidth variant="outlined" className={classes.InputBlackBorder} validate={required()}/> 
                <TextInput label="Categoría" source="category" variant="outlined" style={{ display: 'none' }} className={classes.InputBlackBorder} validate={required()}/>
              </Box>
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.category === 'eLRVRilYd5sdIR7w8Zl3' &&
                  <Box>
                    <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                      <ReferenceInput label="Proveedor" source="provider" reference="provider" className={classes.InputBlackBorder} allowEmpty
                      perPage={200} sort={{ field: 'name', order: 'ASC' }} filter={{shop: formData.shop}}>
                        <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }} {...rest}/>
                      </ReferenceInput>
                      <ReferenceInput label="Enseña" source="shop" reference="shops" className={classes.InputBlackBorder} allowEmpty>
                        <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }} {...rest}/>
                      </ReferenceInput>
                    </Box>
                    <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                      <ReferenceInput label="Campaña" source="campaign" reference="campaigns" className={classes.InputBlackBorder} allowEmpty>
                        <SelectInput optionText="name" variant="outlined" style={{ width: '100%' }} {...rest}/>
                      </ReferenceInput>
                    </Box>
                    <Box style={{display: 'none'}}>
                      <ReferenceInput source="condition_id" reference="conditions" allowEmpty>
                        <SelectInput optionText="id" {...rest}/>
                      </ReferenceInput>
                      <TextInput label="shopName" source="shopName" />
                      <TextInput label="providerName" source="providerName" />
                    </Box>
                  </Box>
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.category === 'VtNc5D0KMKEtk4cX1LZq' &&
                  <Box style={{display: 'none'}}>
                    <ReferenceInput source="notice_id" reference="news" allowEmpty>
                      <SelectInput optionText="id" {...rest}/>
                    </ReferenceInput>
                  </Box>
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.category === 'JovTtJjyBDezCYAtXpMH' &&
                  <Box style={{display: 'none'}}>
                    <ReferenceInput source="offer_id" reference="offersAndPromotions" allowEmpty>
                      <SelectInput optionText="id" {...rest}/>
                    </ReferenceInput>
                  </Box>
                }
              </FormDataConsumer>
              <Divider variant="middle" style={{marginLeft: '10px', marginRight: '15px', marginBottom: '15px'}} />
              <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                  <SubjectRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>
                  Descripción del Archivo
              </Typography>

              <TinyMCETextEditor data={data.description}/>
              <FormDataConsumer>
                  {({ formData, ...rest }) => {
                      formData.description = formData.textValue
                  }}
              </FormDataConsumer>

              <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                  <AttachFileRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>Archivo
              </Typography>
              <Box style={{paddingLeft: '10px', paddingRight: '10px', marginTop: '-15px'}} className={classes.FileInputBox} >
                <FileInput source="fileUrl" label="" validate={required()}>
                    <FileField source="src" title="title"/>
                </FileInput>
              </Box>
              <FormDataConsumer>
                {({ formData, ...rest }) => formData.fileUrl &&
                  <Box style={{display: 'none'}}>
                    {(formData.fileUrl.title.includes(".pptx") || formData.fileUrl.title.includes(".ppt")) &&
                      <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="PowerPoint" {...rest}/> 
                    }
                    {(formData.fileUrl.title.includes(".xlsx") || formData.fileUrl.title.includes(".xls")) &&
                      <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="Excel" {...rest}/> 
                    }
                    {formData.fileUrl.title.includes(".pdf") &&
                      <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="PDF" {...rest}/> 
                    }
                    {(formData.fileUrl.title.includes(".docx") || formData.fileUrl.title.includes(".doc") || formData.fileUrl.title.includes(".txt")) &&
                      <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="Word" {...rest}/> 
                    }
                    {(formData.fileUrl.title.includes(".svg") || formData.fileUrl.title.includes(".png") || 
                      formData.fileUrl.title.includes(".jpg") || formData.fileUrl.title.includes(".jpeg")) &&
                      <TextInput label="Tipo" source="type" variant="outlined" className={classes.InputBlackBorder} initialValue="Imagen" {...rest}/> 
                    }
                  </Box>
                }
              </FormDataConsumer>
            </Box>
          </Box>
        </SimpleForm>
      }
    </Edit>
  )
}; export default FilesEdit;