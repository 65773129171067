import CompanyIcon from '@material-ui/icons/PlaceRounded';
import CompanyList from './CompanyList';
import CompanyCreate from './CompanyCreate';
import CompanyEdit from './CompanyEdit';
import CompanyView from './CompanyView';

const CompanyModule = {
    list: CompanyList,
    edit: CompanyEdit,
    create: CompanyCreate,
    show: CompanyView,
    icon: CompanyIcon,
}

export default CompanyModule;