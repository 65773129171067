import jsPDF from 'jspdf'

export function generatePDF(){
  var OrderDate = document.getElementById('Fecha_Pedido').outerText;
  var OrderAlabran = document.getElementById('Albaran_Pedido').outerText
  var OrderTocken = document.getElementById('Pedido_Tocken').outerText
  var OrderDateYear = OrderDate.slice(-4)
  var AddressToName = document.getElementById('AddressToName').outerText
  var AddressToLastName = document.getElementById('AddressToLastName').outerText
  var Address = document.getElementById('Address').outerText
  var AddressState = document.getElementById('AddressState').outerText
  var AddressLocation = document.getElementById('AddressLocation').outerText
  var AddressCP = document.getElementById('AddressCP').outerText
  var AddressCountry = document.getElementById('AddressCountry').outerText
  var OrdersTabl = document.getElementsByClassName('MuiDataGrid-virtualScrollerRenderZone')[0];
  var OrdersTablRows = OrdersTabl.children;
  var UserCode = document.getElementById('UserCode').outerText;

  var doc = new jsPDF('p', 'pt');
  var textHeight = 20;
  var img = new Image();
  img.src = 'static/images/Dashboard/Trendico Group.png';

  doc.addFont('helvetica', 'normal');
  doc.addImage(img, "PNG", 20, textHeight, 200, 40);
  doc.setFontSize(12);
  textHeight = textHeight + 70
  doc.text(20, textHeight, `Paseo del Muro, Nº 60`);
  textHeight = textHeight + 20
  doc.text(20, textHeight, `Ejea de los Caballeros 50600`);
  textHeight = textHeight + 20
  doc.text(20, textHeight, `Tel.: 976 660 356`);
  doc.text(`${OrderDate}`, ( doc.internal.pageSize.getWidth() - 20 ) , textHeight, "right");
  textHeight = textHeight + 20
  doc.line(20, textHeight, ( doc.internal.pageSize.getWidth() - 20 ), textHeight);
  textHeight = textHeight + 20
  doc.rect(( doc.internal.pageSize.getWidth() - 210 ), textHeight, 190, 60,);
  doc.rect(20, textHeight, 70, 30,);
  textHeight = textHeight + 25
  doc.text(`Enviar a:`, 30, ( textHeight - 5));
  doc.text(`Nº Albarán: CT/${OrderDateYear}/${OrderAlabran}`, ( doc.internal.pageSize.getWidth() - 200 ), textHeight, "left");
  textHeight = textHeight + 20
  doc.text(`Nº Pedido: ${OrderTocken}`, ( doc.internal.pageSize.getWidth() - 200 ), textHeight, "left");
  textHeight = textHeight + 10
  doc.text(20, textHeight, `${AddressToName} ${AddressToLastName}`)
  textHeight = textHeight + 20
  doc.text(20, textHeight, `${Address}`)
  doc.text(( doc.internal.pageSize.getWidth() - 210 ), textHeight, `Cod Socio: ${UserCode}`)
  textHeight = textHeight + 20
  var splitTextAddress = doc.splitTextToSize(`${AddressState}, ${AddressLocation} ${AddressCP}`, ( doc.internal.pageSize.getWidth() - 220 ));
  doc.text(20, textHeight, splitTextAddress)
  textHeight = textHeight + 20
  doc.text(20, textHeight, `${AddressCountry}`)
  textHeight = textHeight + 25
  doc.rect(20, textHeight, ( doc.internal.pageSize.getWidth() - 40 ), 30,);
  textHeight = textHeight + 25
  doc.text(`Referencia`, 30, ( textHeight - 5));
  doc.text(`UPC`, 120, ( textHeight - 5));
  doc.text(`Talla`, 240, ( textHeight - 5));
  doc.text(`Qty`, 340, ( textHeight - 5));
  doc.text(`Precio/Ud`, 400, ( textHeight - 5));
  doc.text(`Total`, ( doc.internal.pageSize.getWidth() - 80 ), ( textHeight - 5));
  textHeight = textHeight + 25

  var QtyTotal = 0;
  var UnitPriceTotal = 0;
  var TotalPriceTotal = 0;
  var QtySum = 0;
  var UnitPriceSum = 0;
  var TotalPriceSum = 0;
  var QtyNums = [];
  var UnitPriceNums = [];
  var TotalPriceNums = [];

  for (var i = 0; i < OrdersTablRows.length; i++){
    doc.text(`${OrdersTablRows[i].children[2].textContent}`, 30, textHeight);
    doc.text(`${OrdersTablRows[i].children[3].textContent}`, 120, textHeight);
    doc.text(`${OrdersTablRows[i].children[4].textContent}`, 240, textHeight);

    doc.text(`${OrdersTablRows[i].children[6].textContent}`, 340, textHeight);
    QtyTotal = parseInt(OrdersTablRows[i].children[6].textContent);
    QtyNums.push(QtyTotal)

    doc.text(`${OrdersTablRows[i].children[5].textContent}`, 400, textHeight);
    UnitPriceTotal = OrdersTablRows[i].children[5].textContent.replaceAll(' €', '');
    UnitPriceNums.push(parseFloat(UnitPriceTotal.replaceAll(',','.')))

    doc.text(`${OrdersTablRows[i].children[7].textContent}`, ( doc.internal.pageSize.getWidth() - 80 ), textHeight);
    TotalPriceTotal = OrdersTablRows[i].children[7].textContent.trim().replaceAll('€', '');
    TotalPriceTotal = TotalPriceTotal.trim();
    TotalPriceNums.push(parseFloat(TotalPriceTotal))
    textHeight = textHeight + 20
    var pageHeight = doc.internal.pageSize.height;
    if (textHeight >= pageHeight)
    {
      doc.addPage();
      textHeight = 20 
    }
  }

  for(var j=0; j < UnitPriceNums.length; j++){
    UnitPriceSum += parseFloat(UnitPriceNums[j]);
  }
  for(var x=0; x < TotalPriceNums.length; x++){
    TotalPriceSum += parseFloat(TotalPriceNums[x]);
  }
  for(var y=0; y < QtyNums.length; y++){
    QtySum += parseInt(QtyNums[y]);
  }

  doc.line(20, textHeight, ( doc.internal.pageSize.getWidth() - 20 ), textHeight)
  textHeight = textHeight + 30
  doc.text(`TOTAL`, 30, textHeight);
  doc.text(`${QtySum}`, 340, textHeight);
  doc.text(`${UnitPriceSum.toFixed(2)} €`, 400, textHeight);
  doc.text(`${TotalPriceSum.toFixed(2)} €`, ( doc.internal.pageSize.getWidth() - 80 ), textHeight);

  doc.setProperties({ title: `Albarán CT/${OrderDateYear}/${OrderAlabran} - Pedido Nº ${OrderTocken}`});
  //window.open(doc.output('bloburl'))
  doc.save(`Albarán CT/${OrderDateYear}/${OrderAlabran} - Pedido Nº ${OrderTocken}.pdf`);
}