import React from 'react';
import { useGetList, useRedirect, useGetOne, LinearProgress } from 'react-admin';
import { Box, Card, Typography, makeStyles, IconButton, Button } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import lightTheme from '../layout/themes';
import sanitizeHtml from 'sanitize-html';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import { mdiFileDocumentOutline } from '@mdi/js';
import Icon from '@mdi/react'

const ConditionsIcon = () => {return(<Icon path={mdiFileDocumentOutline} size={1} />)};
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles({
    AdminUsersBox: lightTheme.DashboardStyles.AdminUsersBox,
    AdminFlexBox: lightTheme.DashboardStyles.AdminFlexBox,
    WelcomeBox : lightTheme.ComercialListStyles.WelcomeBox,
    WelcomeBox2 : lightTheme.ComercialListStyles.WelcomeBox2,
    AdminButtons : lightTheme.ComercialListStyles.AdminButtons,
    LinksTitles : lightTheme.ComercialListStyles.LinksTitles,
    AdminCard : lightTheme.ComercialListStyles.AdminCard,
    OffersCard : lightTheme.ComercialListStyles.OffersCard,
    AdminRow : lightTheme.ComercialListStyles.AdminRow,
    AdminRowSecond : lightTheme.ComercialListStyles.AdminRowSecond,
    ColumnsBox : lightTheme.ComercialListStyles.ColumnsBox,
    ShopsStepperBox : lightTheme.ComercialListStyles.ShopsStepperBox,
    SmallAdminBox : lightTheme.ComercialListStyles.SmallAdminBox,
    ShopsCard : lightTheme.ComercialListStyles.ShopsCard,
    StepperBox : lightTheme.WhatsNewStyles.StepperBox,
    NewsCard: lightTheme.WhatsNewStyles.NewsCard,
});

const CategotyName = ({ id }) => {
    const { data, loading, error } = useGetOne('newsSubCategories', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return (data.name); }
};

const NewsStepper = () => {
    const theme = useTheme();
    const redirect = useRedirect();
    const classes = useStyles();
    const { data, ids } = useGetList('publications', { page: 1, perPage: 3 }, { field: 'createdate', order: 'DESC'}, {category: '6270d8e07a125adbf27a200e'});
    
    const offers = [];
    for(var i = 0; i < ids.length; i++) {
        offers.push({
            id: data[ids[i]].id,
            label: data[ids[i]].title, 
            imgPath: data[ids[i]].logo.image,
            category: data[ids[i]].category,
            subCategory: data[ids[i]].subCategory,
            body: data[ids[i]].body, 
            subTitle: data[ids[i]].subTitle, 
            review: data[ids[i]].review
        })
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = offers.length;

    const handleStepChange = (id) => {
        setActiveStep(id);
    };

    const NewsBody = ({ body , index}) => {
        if (body && index) { 
            const CleanHTML = sanitizeHtml(body, {
                disallowedTags: [ 'img', 'table' ],
                transformTags: {
                    'h1': 'p',
                    'h2': 'p',
                    'h3': 'p',
                    'h4': 'p',
                    'h5': 'p',
                    'strong': 'span',
                    'table': 'p'
                },
            })
            return (<div dangerouslySetInnerHTML={{ __html: CleanHTML }} id={index}/>); 
        }
    };

    if (data[ids[0]] !== undefined) {
        return(
            <Box className={classes.StepperBox}>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    interval='8000'
                >
                    {offers.map((step, index) => (
                        <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <div className={classes.NewsCard}>
                                    <Box style={{display: 'flex', justifyContent: 'flex-start', width: '100%', flexDirection: 'column'}}>
                                        <Typography variant="h5" id="Title">{step.label}</Typography>
                                        <Typography variant="button" style={{color: '#196cdb', marginBottom: '5px'}} id="Category">
                                            <CategotyName id={step.subCategory} />
                                        </Typography>
                                        <Typography variant="body2" id="Body">
                                            {step.subTitle &&
                                                <NewsBody body={step.subTitle} index="subTitle"/>
                                            }
                                            <NewsBody body={step.review ? step.review: step.body} index="review"/>
                                        </Typography>
                                        <span id="seeMore" onClick={() => { redirect(`/publications/${step.id}/show`); }}>Leer más »</span>
                                    </Box>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <div style={{overflow: 'hidden', position: 'relative', borderRadius: '8px', backgroundColor: '#fff', height: '235px', width: '235px'}}>
                                            <g-img style={{display: 'flex', height: '100%', justifyContent: 'center'}}>
                                                <img className={classes.image} alt="complex" src={step.imgPath}/>
                                            </g-img>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper steps={maxSteps} activeStep={activeStep} id="stepper"/>
                <Box id="Button">
                    <div className={classes.AdminButtons}>
                        <Button onClick={() => { redirect('/news'); }}>Ver todas las noticias</Button>
                    </div>
                </Box>
            </Box>
        )
    } else {return (<div>Error</div>)}
};


const ShopsStepper = () => {
    const theme = useTheme();
    const redirect = useRedirect();
    const classes = useStyles();
    const { data, ids } = useGetList('shops', { page: 1, perPage: 4 }, { field: 'createdate', order: 'DESC'});
    
    const news = [];
    for(var i = 0; i < ids.length; i++) {
        news.push({imgPath: data[ids[i]].logo.src})
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = news.length;

    const handleStepChange = (id) => {
        setActiveStep(id);
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    if (data[ids[0]] !== undefined) {
        return(
            <Box className={classes.ShopsStepperBox}>
                <AutoPlaySwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                    interval='4000'
                >
                    {news.map((step, index) => (
                        <div key={step.label}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <div className={classes.ShopsCard} onClick={() => redirect('/shops')}>
                                    <div id="Image">
                                        <img alt={step.label} src={step.imgPath}/>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <MobileStepper steps={maxSteps} activeStep={activeStep} id="stepper"
                    nextButton={
                        <IconButton  onClick={handleNext} disabled={activeStep === maxSteps - 1} id="stepperButton">
                          {theme.direction === 'rtl' ? (<NavigateBeforeRoundedIcon />):(<NavigateNextRoundedIcon />)}
                        </IconButton>
                    }
                    backButton={
                        <IconButton  onClick={handleBack} disabled={activeStep === 0} id="stepperButton">
                          {theme.direction === 'rtl' ? (<NavigateNextRoundedIcon />):(<NavigateBeforeRoundedIcon />)}
                        </IconButton>
                    }
                />
                <Box id="Button">
                    <div className={classes.AdminButtons}>
                        <Button onClick={()=> {}}>Más Información</Button>
                    </div>
                </Box>
            </Box>
        )
    } else {return (<div>Error</div>)}
};

const ComercialList = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box>
            <Box className={classes.WelcomeBox}>
                <Typography variant="h5">Descubre nuestras enseñas y accede a proveedores exclusivos</Typography>
            </Box>
            <Box className={classes.AdminFlexBox}>
                <Box className={classes.AdminUsersBox}>
                    <Card className={classes.AdminCard}>
                        <ShopsStepper />
                    </Card>
                </Box>
                <Box className={classes.ColumnsBox}>
                    <Card className={classes.AdminRow} id="SmallCard">
                        <Box className={classes.SmallAdminBox} onClick={() => redirect('/shops')}>
                            <Box id="IconBox">
                                <DomainRoundedIcon/>
                            </Box>
                            <Box id="TextBox">
                                <Typography variant="h6">Nuestros Proveedores</Typography>
                                <Typography variant="body2">Accede a la nuestra sección de proveedres y decubre proveedores exclusivos de nuestras enseñas</Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Card className={classes.AdminRowSecond} id="SmallCard">
                        <Box className={classes.SmallAdminBox} onClick={() => redirect('/conditions')}>
                            <Box id="IconBox">
                                <ConditionsIcon />
                            </Box>
                            <Box id="TextBox">
                                <Typography variant="h6">Condiciones Comerciales</Typography>
                                <Typography variant="body2">Descubre todos los descuentos y las condiciones de nuestros proveedores</Typography>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Box>
            <Box className={classes.WelcomeBox2}>
                <Typography variant="h5">No te pierdas ninguna oferta de nuestros Proveedores</Typography>
            </Box>
            <Box className={classes.AdminFlexBox}>
                <Box className={classes.AdminUsersBox}>
                    <Card className={classes.OffersCard}>
                        <NewsStepper />
                    </Card>
                </Box>
            </Box>
        </Box>
    )
};

export default ComercialList;
