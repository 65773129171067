import React from "react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Show,SimpleShowLayout, TextField, RichTextField, usePermissions, useGetOne, ChipField, ReferenceField, Link,
  TopToolbar, DeleteButton, EditButton, CloneButton, useRedirect, LinearProgress, useGetMany, useGetList } from "react-admin";
import { Box, Button, makeStyles, Divider, Typography, Modal, Backdrop, Fade } from '@material-ui/core';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Chip from '@mui/material/Chip';
import { mdiHanger } from '@mdi/js'; 
import Icon from '@mdi/react'
import ToolbarBackButton from '../components/ToolbarBackButton';
import CloseButton from '../components/CloseButton';
import lightTheme from "../layout/themes";
import BasketCreate from '../basket/BasketCreate';
import MyTooltip from '../components/MuiTooltip';
import VariantsCreate from "../variants/VariantsCreate";
import VariantsList from "../variants/VariantsList";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import FilterNoneRoundedIcon from '@material-ui/icons/FilterNoneRounded';

const AddVariant = () => {  return( <Icon path={mdiHanger} size={0.9}/> ) };

const useStyles = makeStyles({
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  InputBlackBorder: lightTheme.InputBlackBorder,
  EditToolBar: lightTheme.EditToolBar,
  MaterialImage: lightTheme.MaterialViewImage,
  ProductDescription: lightTheme.MaterialViewProductDescription,
  ZoomImage: lightTheme.MaterialViewZoomImage,
  DisabledButton: lightTheme.MaterialViewDisabledButton,
  MuiModal: lightTheme.CommonStyles.Modal.MuiModal,
  MuiPaper: lightTheme.CommonStyles.Modal.MuiPaper,
  Breadcrumb: lightTheme.ConditionsStyles.ConditionsViewStyles.Breadcrumb,
  paperVariant: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '1px 1px 10px #757171 !important',
    padding: '20px',
    paddingRight: '0px !important',
    width: '400px',
    marginLeft: '20vh',
    '& .ra-input':{
      marginLeft: '-23px',
    },
    '& .MuiToolbar-root':{
      backgroundColor: '#fff !important',
      marginLeft: '-20px !important',
      marginTop: '-5px',
    },
    '& .MuiCard-root':{
        boxShadow: '0px 0px 0px #fff !important'
    }
  },
  MaterialViewtitle: lightTheme.MaterialViewPriceTitle,
  BasketCreatePage:{
    display: 'flex',
    '& .create-page':{
      marginTop: '-35px',
      '& .MuiPaper-elevation1':{
        backgroundColor: 'transparent !important',
        boxShadow: '0px 0px 0px #000 !important',
      },
      '&  .MuiToolbar-regular':{
        backgroundColor: 'transparent !important',
        marginTop: '-5px',
        padding: '0px !important',
      }
    }
  },
  BasketCreateBox:{
    display: 'flex',
    width: '100%',
    '& .create-page':{
      marginTop: '-35px',
      '& .MuiPaper-elevation1':{
        backgroundColor: 'transparent !important',
        boxShadow: '0px 0px 0px #000 !important',
      },
      '&  .MuiToolbar-regular':{
        backgroundColor: 'transparent !important',
        marginTop: '-5px',
        padding: '0px !important',
      }
    }
  },
  CloseModalButton:{
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginLeft: '-25px',
    marginTop: '-50px',
    '& .MuiButtonBase-root':{
      border: '1px solid #3d3d3d',
      borderRadius: '4px',
      textTransform: 'capitalize !important',
      padding: '5px',
      paddingLeft: '15px',
      paddingRight: '15px',
    }
  },
  ChipsBox:{
    marginLeft: '-4px', 
    width: '100%',
    display: 'flex', 
    '& .MuiChip-root':{
      border: '1px solid transparent',
    },
    '& .MuiChip-root:hover':{
      cursor: 'pointer',
      backgroundColor: '#196cdb4d',
      color: '#196cdb',
      border: '1px solid #196cdb',
    }
  },
  MaterialUI_Chip:{
    '& .MuiChip-filled':{
      backgroundColor: 'rgb(224, 224, 224)', 
      marginTop: '3px', 
      marginRight: '3px',
      border: '1px solid transparent',
    },
    '& .MuiChip-filled:hover':{
      cursor: 'pointer',
      backgroundColor: '#196cdb4d',
      color: '#196cdb',
      border: '1px solid #196cdb',
    },
  },
  RelatedProductsBox:{
    marginBottom: '50px',
    '& #ProductsBox':{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      marginTop: '10px',
      marginBottom: '15px',
      '& .MuiBox-root':{
        border: '1px solid #cacaca',
        borderRadius: '6px',
        width: '90px !important',
        height: '90px !important',
        '& img':{
          width: '80px !important',
          height: '80px !important',
          display: 'block',
          borderRadius: '6px',
          padding: '5px'
        }
      },
      '& .MuiBox-root:hover':{
        borderColor: '#196cdb',
        '& > #NameBox':{
          display: 'flex !important',
        }
      },
      '& .MuiBox-root:hover + #NameBox':{
        display: 'flex !important',
      },
      '& #NameBox':{
        position: 'absolute',
        display: 'none',
        border: 'none !important',
        textTransform: 'capitalize',
        backgroundColor: '#fff',
        zIndex: '999',
        width: '35% !important',
        height: 'fit-content !important',
        marginTop: '105px',
        alignItems: 'end',
      },
      '& #Default_NameBox':{
        position: 'absolute',
        display: 'flex',
        border: 'none !important',
        textTransform: 'capitalize',
        width: '35% !important',
        height: 'fit-content !important',
        marginTop: '105px',
        alignItems: 'end',
      }
    }
  },
  VariantsList:{
    padding: '25px',
    marginTop: '15px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important',
    '& .list-page div div':{
      '& .MuiBox-root':{
        marginTop: '0px !important'
      },
      '& #EditButton':{
        marginTop: '-80px !important'
      }
    }
  },
  DescriptionTabs:{
    padding: '10px',
    marginTop: '-55px',
    '& > .MuiBox-root':{
      borderBottom: '1px solid #cacaca'
    }
  },
  InfoBox:{
    paddingTop: '10px',
  }
});

const DivisionChips = ({ ids }) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const { data, loading, error } = useGetMany('division', ids);
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data) return (
    <Box className={classes.MaterialUI_Chip}>
      {data.map(division => (
        <Chip label={division.name}
          onClick={() => { redirect(`/material?filter={"division"%3A"${division.id}"}`); }}
        />
      ))}
    </Box>
  )
};

const RelatedProducts = ({m_division, m_id, m_title, m_type}) => {
  const classes = useStyles();
  var filteredDivisions = m_division.filter(n => n !== "618986dbf4cacf2b465474c0")
  const { data, ids, loading, error } = useGetList('material', 
    { page: 1, perPage: 5 }, 
    { field: 'name', order: 'ASC' },
    {type : m_type},
    {division: filteredDivisions[0] ? filteredDivisions[0] : ""},
    {division: filteredDivisions[1] ? filteredDivisions[1] : ""},
    {division: filteredDivisions[2] ? filteredDivisions[2] : ""},
    {division: filteredDivisions[3] ? filteredDivisions[3] : ""}
  );
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data && ids) {
    var filteredIds = ids.filter(n => n !== m_id)
    return (
      <Box className={classes.RelatedProductsBox}>
        {filteredIds.length > 0 &&
          <>
            <Typography variant="body1">
              {m_type === 'consumible' ? `Productos Relacionados:` : `Más Productos de Puntos de Venta:`}
            </Typography>
            <Box id="ProductsBox">
              {filteredIds.map(id => (
                <>
                  <Box> 
                    <Link to={`/material/${data[id].id}/show`}>
                      <img alt={data[id].title} src={data[id].logo.image}/>
                    </Link>
                  </Box>
                  <Box id="NameBox">
                    {data[id].title.toLowerCase()}
                  </Box>
                </>
              ))}
              <Box id="Default_NameBox">
                {m_title.toLowerCase()}
              </Box>
            </Box>
          </>
        }
      </Box>
    )
  }
}


const MaterialShow = ({...props}) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const { permissions } = usePermissions();

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => {setOpen2(true); console.log(open2)};
  const handleClose2 = () => {setOpen2(false)};

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data } = useGetOne('material', props.id);
  const VariantData = useGetList('variants', { page: 1, perPage: 999 }, { field: 'price', order: 'DESC' }, {material_id: props.id});

  let BasketProps = {
    basePath: "/basket", 
    resource: "basket",
    redirect: "/material",
    material_id: props.id,
  }
  let VariantsProps = {basePath: "/variants", resource: "variants", material_id: props.id}
  let price = 0;
  let VariantPrice = 0;
  
  const OffersViewActions = ({ basePath, resource }) => {
    return(
      <TopToolbar style={{paddingBottom: '2px', marginTop: '8px'}} id="GoogleStyleToolBar">
        <Box className={classes.Breadcrumb}>
          <MyTooltip title={<div style={{fontSize: '12px'}}>Volver Atrás</div>} aria-label="GoBack" arrow>
            <ToolbarBackButton style={{paddingLeft: '17px', paddingRight: '5px', marginRight: '10px'}} id="GoogleStyleBackButton"></ToolbarBackButton>
          </MyTooltip>
          <Box id="ActionsBar">
              <Box id="Main_breadcrumb">
                  <Box>
                      <MyTooltip title={<div style={{fontSize: '12px'}}>Volver a la lista de Consumibles</div>} arrow>
                          <Link to={data.type === 'consumible' ? `/material` : `/informacion`}>
                              <Typography variant="body1" id="breadcrumbTitle">{data.type === 'consumible' ? `Consumibles` : `Punto de Venta`}</Typography>
                          </Link>
                      </MyTooltip>
                  </Box>
                  <NavigateNextRoundedIcon id="navigationArrow"/>
                  <Box>
                      <Typography variant="body1" id="breadcrumbTitle" style={{textTransform: 'capitalize'}}>
                        {data.title.toLowerCase()}
                      </Typography>
                  </Box>
              </Box>
          </Box>
          {(permissions === "Admin" || permissions === "Transporte") &&
            <Box style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'row-reverse'}}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Producto</div>} aria-label="Delete" arrow>
                <DeleteButton label="" basePath={basePath} record={data} resource={resource} id="GoogleStyleDeleteButton"/>
              </MyTooltip>           
              <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Producto</div>} aria-label="Editar" arrow>
                <EditButton label="" type="link" to={`/material/${props.id}/edit`} id="GoogleStyleButton"/>
              </MyTooltip>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Duplicar Producto</div>} aria-label="Duplicar" arrow>
                <CloneButton label="" icon={<FilterNoneRoundedIcon /> } basePath={basePath} resource={resource} id="GoogleStyleButton"
                  record={{
                    division: data.division, price: data.price, shop: data.shop, stock: data.stock, reference: data.reference,
                    descriptionCopy: data.description, stock_filter: data.stock_filter, title: data.title, logo: data.logo, shippingCopy: data.shipping
                  }}
                />
              </MyTooltip>
              {data.type === 'consumible' &&
                <>
                  <Divider orientation="vertical" flexItem style={{marginLeft: '20px', marginRight: '10px', marginTop: '6px', marginBottom: '6px'}}/>
                  <MyTooltip title={<div style={{fontSize: '12px'}}>Crear variante para este producto</div>} placement="right" arrow>
                    <Button label="" startIcon={<AddVariant />} id="GoogleStyleBackButton" onClick={handleOpen2}></Button>
                  </MyTooltip>
                </>
              }
            </Box>
          }
        </Box>
      </TopToolbar>
    )
  };
  
  if(VariantData.data && VariantData.ids.length > 0 && BasketCreate && data && data.type === "consumible"){
    const v_data = VariantData.data;
    const v_ids = VariantData.ids;
    VariantPrice = v_data[v_ids[0]].price;
    let variantID = document.getElementById('v_price');
    if(variantID && variantID.textContent !== "empty"){
      VariantPrice = v_data[variantID.textContent].price
    }

    return (
      <Box>
        {data && data.logo.image &&
          <div>
            <Box>
              <Show {...props} actions={<OffersViewActions />}>
                <SimpleShowLayout className={classes.MaterialImage}>
                  <Box display="flex" justifyContent="center" style={{width: '100%', paddingBottom: '15px'}}>
                    <Box className={classes.ZoomImage} display="flex" justifyContent="flex-start">
                      <Zoom>
                        <img
                          alt={data.logo.imageName}
                          src={data.logo.image}
                          width="500"
                        />
                      </Zoom>
                    </Box>
                    <Box className={classes.ProductDescription}>
                      <Typography variant="h6" style={{marginBottom: '1.5vw', marginTop: '-10px'}}>
                        <TextField style={{fontSize: '1.6rem', textTransform: 'uppercase'}} source="title" multiline="true" fullWidth />
                      </Typography>
                      <Box className={classes.MaterialViewtitle}>
                        {VariantPrice && data.type === 'consumible' &&
                          <Typography variant="body1" align="center" style={{textDecoratiom: 'none', width: '50%', display: 'flex'}} id="price">
                            <p style={{display: 'none'}}>{price = VariantPrice ? VariantPrice.toString().split('.') : "0"}</p>
                            <Typography style={{marginTop: '8px'}}>Precio:&nbsp;&nbsp;&nbsp;</Typography>
                              <Box style={{width: '100%', display: 'flex', color: '#b53413'}}>{price[0]}
                                {price[1] === undefined &&
                                  <Box style={{width: '100%', display: 'flex'}}>,<div style={{marginRight: '5px', fontSize: '1.3rem', marginTop: '5px'}}>{price[1]}00 €</div></Box>
                                }
                                {price[1] && price[1].length <= 1 &&
                                  <Box style={{width: '100%', display: 'flex'}}>,<div style={{marginRight: '5px', fontSize: '1.3rem', marginTop: '5px'}}>{price[1]}0 €</div></Box>
                                }
                                {price[1] && price[1].length > 1 &&
                                  <Box style={{width: '100%', display: 'flex'}}>,<div style={{marginRight: '5px', fontSize: '1.3rem', marginTop: '5px'}}>{price[1]} €</div></Box>
                                }
                              </Box>
                          </Typography>
                        }
                        {data.type !== 'consumible' &&
                          <Typography variant="body1" align="center" style={{textDecoratiom: 'none', width: '50%', display: 'flex'}} id="price">
                            <Typography style={{marginTop: '8px'}}>Precio Asignado por el Proveedor (Ver Catálogo)</Typography>
                          </Typography>
                        }
                      </Box>
                      {data.stock > 0 && 
                        <Box>
                          <Box className={classes.BasketCreateBox}>
                            <BasketCreate {...BasketProps} />
                          </Box>
                          {data.stock > 11 &&
                            <Box>
                              <Typography variant="h6" style={{marginBottom: '10px', color: '#007600'}}>
                                En Stock, hasta acabar existencias
                              </Typography>
                            </Box>
                          }
                          {data.stock > 0 && data.stock < 10 &&
                            <Box>
                              <Typography variant="h6" style={{marginBottom: '10px', color: '#007600'}}>
                                Solo quedan {data.stock} en Stock
                              </Typography>
                            </Box>
                          }
                        </Box>
                      }
                      {data.stock === 0 &&
                        <Box className={classes.DisabledButton} display="flex">
                          <Typography variant="h6" style={{marginTop: '-10px', color: '#b12704', marginBottom: '10px'}}>
                            Actualmente no hay stock para este Producto
                          </Typography>
                        </Box>
                      }
                      <Box>
                        <RelatedProducts m_division={data.division} m_id={data.id} m_title={data.title}/>
                      </Box>
                      <Box className={classes.ChipsBox}>
                        <ReferenceField source="shop" reference="shops" link={false}>
                          <ChipField source="name" 
                            onClick={() => { redirect(`/material?filter={"shop"%3A"${data.shop}"}`); }}
                          />
                        </ReferenceField>
                        <DivisionChips ids={data.division}/>
                      </Box>
                    </Box>
                  </Box>
                  <TabContext value={value} className={classes.DescriptionTabs}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Descripción" value="1" />
                        <Tab label="Información del Envio" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" style={{padding: '15px'}}>
                      <RichTextField source="description" label=""/>
                    </TabPanel>
                    <TabPanel value="2" style={{padding: '15px'}}>
                      <RichTextField source="shipping" label=""/>
                    </TabPanel>
                  </TabContext>
                </SimpleShowLayout>
              </Show>
            </Box>
            {(permissions === "Admin" || permissions === "Transporte") &&
              <Box>
                <Box>
                  <Typography variant="h5" style={{marginBottom: '1vw', marginTop: '20px'}}>Variantes del Producto</Typography>
                </Box>
                <Box className={classes.VariantsList}>
                  <VariantsList {...VariantsProps}/>
                </Box>
              </Box>
            }

            <div>
              <Modal className={classes.MuiModal} open={open2} onClose={handleClose2} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
                <Fade in={open2}>
                  <div className={classes.MuiPaper} style={{width: '400px'}}>
                    <Box id="TitleBox">
                        <Box id="Title">
                          <h2>Crear variante del producto</h2>
                        </Box>
                        <Box id="CloseButton">
                          <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                            <CloseButton style={{paddingLeft: '17px',  paddingRight: '5px'}} onClick={handleClose2}></CloseButton>
                          </MyTooltip>
                        </Box>
                    </Box>
                    <VariantsCreate {...VariantsProps} />
                  </div>
                </Fade>
              </Modal>
            </div>

          </div>
        }
      </Box>
    );
  } else {
    return (
      <Box>
        {data && data.logo.image &&
          <>
            <div>
              <Box>
                <Show {...props} actions={<OffersViewActions />}>
                  <SimpleShowLayout className={classes.MaterialImage}>
                    <Box display="flex" justifyContent="center" style={{width: '100%', paddingBottom: '15px'}}>
                      <Box className={classes.ZoomImage} display="flex" justifyContent="flex-start">
                        <Zoom>
                          <img
                            alt={data.logo.imageName}
                            src={data.logo.image}
                            width="500"
                          />
                        </Zoom>
                      </Box>
                      <Box className={classes.ProductDescription}>
                        <Typography variant="h6" style={{marginBottom: '1.5vw', marginTop: '-10px'}}>
                          <TextField style={{fontSize: '1.6rem', textTransform: 'uppercase'}} source="title" multiline="true" fullWidth />
                        </Typography>
                        <Box>
                          <RelatedProducts m_division={data.division} m_id={data.id} m_title={data.title} m_type={data.type}/>
                        </Box>
                        <Box className={classes.ChipsBox}>
                          <ReferenceField source="shop" reference="shops" link={false}>
                            <ChipField source="name" 
                              onClick={() => { redirect(`/material?filter={"shop"%3A"${data.shop}"}`); }}
                            />
                          </ReferenceField>
                          <DivisionChips ids={data.division}/>
                        </Box>
                        <Box className={classes.InfoBox}>
                          <Typography variant="h6">Información</Typography>
                          <RichTextField source="description" label=""/>
                        </Box>
                      </Box>
                    </Box>
                  </SimpleShowLayout>
                </Show>
              </Box>
            </div>
            <div>
              <Modal className={classes.MuiModal} open={open2} onClose={handleClose2} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
                <Fade in={open2}>
                  <div className={classes.MuiPaper} style={{width: '400px'}}>
                    <Box id="TitleBox">
                        <Box id="Title">
                          <h2>Crear variante del producto</h2>
                        </Box>
                        <Box id="CloseButton">
                          <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                            <CloseButton style={{paddingLeft: '17px',  paddingRight: '5px'}} onClick={handleClose2}></CloseButton>
                          </MyTooltip>
                        </Box>
                    </Box>
                    <VariantsCreate {...VariantsProps} />
                  </div>
                </Fade>
              </Modal>
            </div>
          </>
        }
      </Box>
    )
  }
}; export default MaterialShow;