import React, { Fragment } from "react";
import { Datagrid, List, TextField, useTranslate, TopToolbar, useRedirect, BulkDeleteButton, EditButton, DeleteButton, ReferenceField,
  Filter, SearchInput, useListContext, usePermissions, AutocompleteInput, ReferenceInput} from "react-admin";
import { makeStyles, Box, Typography, ThemeProvider, TablePagination} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import * as locales from '@material-ui/core/locale';
import Fab from '@mui/material/Fab';
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  BulkDeletePostButton: lightTheme.BulkDeletePostButton,
  PointsTable: lightTheme.PointsTable,
  PostViewCard: lightTheme.PostViewCard,
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  RightSerchBar: lightTheme.PointOfSaleStyle.List.RightSerchBar,
  root: lightTheme.PointOfSaleStyle.List.root,
});

const BulkDeletePointsButton = ({ basePath }) => {
  return(
    <Fragment>
      <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Seleccionados</div>} aria-label="Delete" arrow placement="left">
        <BulkDeleteButton label="" basePath={basePath} id="GoogleStyleBulkButton"/>
      </MyTooltip>
    </Fragment>
  )
};

const PointsFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SearchInput source="name" alwaysOn variant="outlined" className={classes.root}/>
      <ReferenceInput source="company_id" label="Sociedad" reference="company" filterToQuery={searchText => ({ name: searchText })}
        variant="outlined" className={classes.root} perPage={500} alwaysOn>
        <AutocompleteInput optionText="name" resettable/>
      </ReferenceInput>
    </Filter>
  )
};

const PointsEditActions = () => {
  const redirect = useRedirect();
  return(
      <TopToolbar>
          <Box style={{position: 'fixed', bottom: '25px', right: '30px', zIndex: '999'}}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Punto de Venta</div>} arrow placement="left">
                  <Fab color="primary" aria-label="add" onClick={() => { redirect(`/pointOfSale/create`); }}>
                      <AddLocationAltRoundedIcon />
                  </Fab>
              </MyTooltip>
          </Box>
      </TopToolbar>
  )
};

const PointsPagination = () => {
  const { page, perPage, total, setPage, setPerPage } = useListContext();

  const handleChangeRowsPerPage = (event) => { 
      setPerPage(parseInt(event.target.value, 10));
      setPage(1);
  };
  const handleChangePage = (event, newPage) => {
      setPage(newPage + 1);
   };
  
  return (
      <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
          <TablePagination
              component="div"
              rowsPerPageOptions={[8, 10, 12, 15, 20]}
              count={total || 0}
              page={page - 1}
              onChangePage ={handleChangePage}
              rowsPerPage={perPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
      </ThemeProvider>
  );
}


const PointOfSaleList = props => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const classes = useStyles();

  return (
    <Box className={classes.RightSerchBar}>
      <Box display="flex" style={{ width: '100%', position: 'relative', top: '8px'}} justifyContent="flex-start">
        <Typography className={classes.PostTitle}>Puntos de Venta</Typography>
        <Typography className={classes.PostSubTitle}>&nbsp;Gestiona, Edita y Consulta</Typography>
      </Box>
      <List {...props} actions={<PointsEditActions />} bulkActionButtons={<BulkDeletePointsButton {...props} />} filters={<PointsFilter/>} 
        pagination={<PointsPagination/>} perPage={8} style={{marginBottom: '5em'}}>
        <Datagrid rowClick="show" className={classes.PointsTable}>
          <ReferenceField label={translate('resources.pointOfSale.field.company')} source="company_id" reference="company" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField label={translate('resources.pointOfSale.field.name')} source="name" />
          <ReferenceField label="Provincia" source="state" reference="state">
            <TextField source="name" />
          </ReferenceField>
          <TextField label={translate('resources.pointOfSale.field.gln')} source="gln" />
          {permissions === 'Admin' &&
              <MyTooltip title={<div style={{fontSize: '12px'}}>Editar</div>} aria-label="edit" arrow placement="bottom">
                  <EditButton basePath="/pointOfSale" label="" record={props} id="GoogleStyleButton"/>
              </MyTooltip>
          }
          {permissions === 'Admin' &&
              <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar</div>} aria-label="delete" arrow placement="bottom">
                  <DeleteButton basePath="/pointOfSale" label="" record={props} id="GoogleStyleDeleteButton"/>
              </MyTooltip>
          }
        </Datagrid>
      </List>
    </Box>
  )
};

export default PointOfSaleList;