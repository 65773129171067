import React from "react";
import { 
  Create, SimpleForm, TextInput, Toolbar, SaveButton, required, ReferenceInput,
  AutocompleteInput, FormDataConsumer, SelectInput
} from "react-admin";
import { Box, makeStyles, CircularProgress, Divider } from '@material-ui/core';
import lightTheme from '../layout/themes';
import firebase from 'firebase';
import MyTooltip from '../components/MuiTooltip';
import '../index.css';

const useStyles = makeStyles({
    BlackInputMarginRight: lightTheme.CommonStyles.CreateAndEdit.BlackInputMarginRight,
    CardUsersEdit: lightTheme.CardUsersEdit,
    EditToolBar: lightTheme.EditToolBar,
});

const AddressCreate = props => {
    const classes = useStyles();
    const user = firebase.auth().currentUser;
    const redirect = () => `/profile?tabInd=2`;

    const AddressEditToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        return (
          <Box>
            <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
              <MyTooltip title={<div style={{fontSize: '12px'}}>Agregar Dirección</div>} aria-label="Save" arrow placement="right">
                <SaveButton disabled={props.pristine} redirect={`/profile?tabInd=2`} id="GoogleStyleSaveButton" label="Crear">
                  {loading && (<CircularProgress/>)}
                </SaveButton>
              </MyTooltip>
            </Toolbar>
          </Box>
        )
    };

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect} toolbar={<AddressEditToolbar/>}>
                <Box style={{ width:'100%', color: '#3d3d3d' }}>
                    <Box>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label="Nombre de la Dirección" source="name" variant="outlined" 
                              multiline="true" fullWidth className={classes.BlackInputMarginRight} validate={required()}/>
                        </Box>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label="Nombre del Titular" source="user_name" variant="outlined" 
                              multiline="true" fullWidth className={classes.BlackInputMarginRight} validate={required()}/>
                            <TextInput label="Apellido" source="user_lastname" variant="outlined" 
                              multiline="true" fullWidth className={classes.BlackInputMarginRight} validate={required()}/>
                        </Box>
                        <Divider style={{marginTop: '10px', marginBottom: '30px', marginRight: '10px', marginLeft: '10px'}}/>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label="Dirección" variant="outlined" source="user_address" style={{ width: '80%' }}
                              multiline="true" fullWidth className={classes.BlackInputMarginRight} validate={required()}/>
                            <TextInput label="Código Postal" variant="outlined" source="CP" style={{ width: '20%' }}
                              multiline="true" fullWidth className={classes.BlackInputMarginRight} />
                        </Box>
                        <Box>
                          <Box style={{ width: '100%',display: 'flex' }}>
                              <ReferenceInput source="country" reference="country" filterToQuery={searchText => ({ name: searchText })} 
                                  label="País" className={classes.BlackInputMarginRight} variant="outlined" style={{ width: '30%' }} validate={required()}>
                                  <AutocompleteInput optionText="name" />
                              </ReferenceInput>
                              <FormDataConsumer>
                                  {({ formData, ...rest }) => {
                                      return (
                                          <Box style={{marginRight: '20px', width: '30%', marginLeft: '20px' }}>
                                              <ReferenceInput source="state" reference="state" filterToQuery={searchText => ({ name: searchText })} perPage={55}
                                                  label="Provincia" className={classes.BlackInputMarginRight} variant="outlined"
                                                  filter={{country: formData.country}} validate={required()}>
                                                  <AutocompleteInput optionText="name"/>
                                              </ReferenceInput>
                                          </Box>
                                      );
                                  }}
                              </FormDataConsumer>
                              <TextInput label="Ciudad" variant="outlined" source="location" 
                                  multiline="true" fullWidth className={classes.BlackInputMarginRight} style={{ width: '30%' }} validate={required()}/>
                          </Box>
                        </Box>
                        <Box style={{display: 'none'}}>
                          <ReferenceInput source="user" reference="users" defaultValue={user.uid}>
                            <SelectInput optionText="id" />
                          </ReferenceInput>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Create>
    )
};

export default AddressCreate;