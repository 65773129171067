import UserIcon from '@material-ui/icons/GroupRounded';

import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';

const UserModule = {
    list: UserList,
    edit: UserEdit,
    create: UserCreate,
    icon: UserIcon,
}

export default UserModule;