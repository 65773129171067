import React from 'react';
import { useGetList, useRedirect } from 'react-admin';
import { Box, Card, Typography, makeStyles, Button } from '@material-ui/core';
import lightTheme from '../layout/themes';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import CountryIcon from '@material-ui/icons/PublicRounded';
import HomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';

import { 
    mdiAccountBoxMultiple, mdiMapMarkerMultiple, mdiFileDocumentMultipleOutline,
    mdiAlarmMultiple, mdiClipboardMultipleOutline, mdiLabelMultipleOutline, mdiNewspaperVariantMultiple 
} from '@mdi/js';
import Icon from '@mdi/react'

const CompanyIcon = () => {return(<Icon path={mdiAccountBoxMultiple} size={1} style={{marginBottom: '-5px', marginRight: '10px',}}/>)};
const NewsCategoryIcon = () => {return(<Icon path={mdiNewspaperVariantMultiple} size={1}/>)};
const PointsIcon = () => {return(<Icon path={mdiMapMarkerMultiple} size={1} style={{marginBottom: '-5px', marginRight: '10px',}}/>)};
const CampaignsIcon = () => {return(<Icon path={mdiAlarmMultiple} size={1} />)};
const OrdersIcon = () => {return(<Icon path={mdiClipboardMultipleOutline} size={1} />)};
const CategorysIcon = () => {return(<Icon path={mdiLabelMultipleOutline} size={1} />)};
const MediaIcon = () => {return(<Icon path={mdiFileDocumentMultipleOutline} size={1} />)};


const useStyles = makeStyles({
    FlexStart100: lightTheme.CommonStyles.ListItems.FlexStart100,
    FlexStart70: lightTheme.CommonStyles.ListItems.FlexStart70,
    FlexEnd30: lightTheme.CommonStyles.ListItems.FlexEnd30,
    TitleIcon: lightTheme.DashboardStyles.TitleIcon,
    CardTitleBox: lightTheme.DashboardStyles.CardTitleBox,
    CardTitleBoxAdmin: lightTheme.DashboardStyles.CardTitleBoxAdmin,
    AdminCard: lightTheme.DashboardStyles.AdminCard,
    AdminSmallCard: lightTheme.DashboardStyles.AdminSmallCard,
    AdminBox: lightTheme.DashboardStyles.AdminBox,
    AdminUsersBox: lightTheme.DashboardStyles.AdminUsersBox,
    FlexBox: lightTheme.DashboardStyles.FlexBox,
    AdminFlexBox: lightTheme.DashboardStyles.AdminFlexBox,
    AdminFlexBox1: lightTheme.DashboardStyles.AdminFlexBox1,
    RowTitle: lightTheme.DashboardStyles.RowTitle,
    WelcomeBox :{
        paddingTop: '1em',
        paddingBottom: '1em',
        marginBottom: '10px',
    },
    AdminButtons:{
        '& .MuiButtonBase-root':{
            color: '#196cdb !important',
            textTransform: 'capitalize !important',
        },
        '& .MuiButtonBase-root:hover':{
            backgroundColor: '#196cdb2e !important',
        }
    },
    LinksTitles:{
        marginTop: '15px',
        marginLeft: '-5px',
        '& p':{
            color: '#196cdb',
            cursor: 'pointer',
            padding: '5px',
            borderRadius: '5px',
        },
        '& p:hover':{
            backgroundColor: '#196cdb2e !important',
        }
    },
    SmallAdminBox: {
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        cursor: 'pointer',
        '& #IconBox':{
            width: '10%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginRight: '20px'
        },
        '& #TextBox':{
            width: '90%',
            '& h6':{
                fontSize: '1.1rem',
            },
            '& p':{
                fontSize: '0.85rem',
            }
        },
    },
    shopTypes: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        paddingTop: '20px',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        ' & div':{
            '& .MuiTypography-body2':{
                fontSize: '0.8rem',
            },
            '& .MuiTypography-h5':{
                fontSize: '1rem',
            }
        }
    }
});

const UsersBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    const { ids } = useGetList('users', { page: 1, perPage: 999 }, { field: 'createdate', order: 'DESC'})

    return(
        <Box id="AdminCard">
            <Box className={classes.CardTitleBoxAdmin}>
                <Box className={classes.FlexStart100}>
                    <Box className={classes.FlexStart70}>
                        <Typography variant="h6"><GroupRoundedIcon className={classes.TitleIcon}/>Usuarios</Typography>
                    </Box>
                    <Box className={classes.FlexEnd30}>
                        <div className={classes.AdminButtons}>
                            <Button onClick={() => { redirect('/users'); }}>Gestionar</Button>
                        </div>
                    </Box>
                </Box>
            </Box>

            <Box id="CardBody">
                <Box className={classes.FlexStart70}>
                    <Typography variant="body2">Usuarios Totales</Typography>
                </Box>
                <Box className={classes.FlexStart70}>
                    <Typography variant="h5">{ids.length}</Typography>
                </Box>
                <Box className={classes.LinksTitles} id="Link">
                    <Typography variant="body2" onClick={() => { redirect('/users/create'); }}>Añadir un Usuario Nuevo</Typography>
                </Box>
            </Box>
        </Box>
    )
};

const SocietiesBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    const { ids } = useGetList('company', { page: 1, perPage: 999 }, { field: 'createdate', order: 'DESC'})
    return(
        <Box id="AdminCard">
            <Box className={classes.CardTitleBoxAdmin}>
                <Box className={classes.FlexStart100}>
                    <Box className={classes.FlexStart70}>
                        <Typography variant="h6"><CompanyIcon/>Sociedades</Typography>
                    </Box>
                    <Box className={classes.FlexEnd30}>
                        <div className={classes.AdminButtons}>
                            <Button onClick={() => { redirect('/company'); }}>Gestionar</Button>
                        </div>
                    </Box>
                </Box>
            </Box>

            <Box id="CardBody">
                <Box className={classes.FlexStart70}>
                    <Typography variant="body2">Sociedades Registradas</Typography>
                </Box>
                <Box className={classes.FlexStart70}>
                    <Typography variant="h5">{ids.length}</Typography>
                </Box>
                <Box className={classes.LinksTitles} id="Link">
                    <Typography variant="body2" onClick={() => { redirect('/company/create'); }}>Registrar una Nueva Sociedad</Typography>
                </Box>
            </Box>
        </Box>
    )
};

const PointsBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();

    const { data, ids } = useGetList('pointOfSale', { page: 1, perPage: 999 }, { field: 'createdate', order: 'DESC'})
    const FomShops = [];
    const SotkattShops = [];
    const AtleetShops = [];
    const TrendicoShops = [];
    for (let i = 0; i < ids.length; i++) {
        if (data[ids[i]].shop === '618bd896efea2b2049ada487') {
            FomShops.push(data[ids[i]]);
        }
        if (data[ids[i]].shop === '618bd896efea2b2049ada488') {
            AtleetShops.push(data[ids[i]]);
        }
        if (data[ids[i]].shop === '618bd896efea2b2049ada489') {
            SotkattShops.push(data[ids[i]]);
        }
        if (data[ids[i]].shop === '618bd896efea2b2049ada486') {
            TrendicoShops.push(data[ids[i]]);
        }
    }
    return(
        <Box id="AdminCard">
            <Box className={classes.CardTitleBoxAdmin}>
                <Box className={classes.FlexStart100}>
                    <Box className={classes.FlexStart70}>
                        <Typography variant="h6"><PointsIcon/>Puntos de Venta</Typography>
                    </Box>
                    <Box className={classes.FlexEnd30}>
                        <div className={classes.AdminButtons}>
                            <Button onClick={() => { redirect('/pointOfSale'); }}>Gestionar</Button>
                        </div>
                    </Box>
                </Box>
            </Box>

            <Box id="CardBody">
                <Box className={classes.FlexStart70}>
                    <Typography variant="body2">Puntos de Venta Activos</Typography>
                </Box>
                <Box className={classes.FlexStart70}>
                    <Typography variant="h5">{ids.length}</Typography>
                </Box>
                <Box className={classes.shopTypes}>
                    <div id="Trendico">
                        <Typography variant="body2">Trendico</Typography>
                        <Typography variant="h5">{TrendicoShops.length}</Typography>
                    </div>
                    <div id="FoM">
                        <Typography variant="body2">Foot on Mars</Typography>
                        <Typography variant="h5">{FomShops.length}</Typography>
                    </div>
                    <div id="Atleet">
                        <Typography variant="body2">Atleet</Typography>
                        <Typography variant="h5">{AtleetShops.length}</Typography>
                    </div>
                    <div id="Sötkatt">
                        <Typography variant="body2">Sötkatt</Typography>
                        <Typography variant="h5">{SotkattShops.length}</Typography>
                    </div>
                </Box>
                <Box className={classes.LinksTitles}>
                    <Typography variant="body2" onClick={() => { redirect('/pointOfSale/create'); }}>Añadir una Nueva Tienda</Typography>
                </Box>
            </Box>
        </Box>
    )
};

const OrdersBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box className={classes.SmallAdminBox} onClick={() => redirect('/orders')}>
            <Box id="IconBox">
                <OrdersIcon/>
            </Box>
            <Box id="TextBox">
                <Typography variant="h6">Pedidos de Consumibles</Typography>
                <Typography variant="body2">Gestiona y visualiza todos los pedidos de consumibles</Typography>
            </Box>
        </Box>
    )
};

const MediaBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box className={classes.SmallAdminBox} onClick={() => redirect('/filesCategories')}>
            <Box id="IconBox">
                <MediaIcon/>
            </Box>
            <Box id="TextBox">
                <Typography variant="h6">Biblioteca de Archivos</Typography>
                <Typography variant="body2">Gestiona y visualiza todos los archivos adjuntados en las Publicaciones y Condiciones Comerciales</Typography>
            </Box>
        </Box>
    )
};

const CampaignsBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box className={classes.SmallAdminBox} onClick={() => redirect('/campaigns')}>
            <Box id="IconBox">
                <CampaignsIcon/>
            </Box>
            <Box id="TextBox">
                <Typography variant="h6">Campañas Comerciales</Typography>
                <Typography variant="body2">Gestiona y crea nuevas campañas comerciales para proveedores</Typography>
            </Box>
        </Box>
    )
};

const CategoriesBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box className={classes.SmallAdminBox} onClick={() => redirect('/categorys')}>
            <Box id="IconBox">
                <CategorysIcon/>
            </Box>
            <Box id="TextBox">
                <Typography variant="h6">Categorías de Proveedor</Typography>
                <Typography variant="body2">Gestiona y crea nuevas categorías para clasificar a nuestros proveedores</Typography>
            </Box>
        </Box>
    )
};

const NewsCategoriesBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box className={classes.SmallAdminBox} onClick={() => redirect('/NewsSubCategories')}>
            <Box id="IconBox">
                <NewsCategoryIcon/>
            </Box>
            <Box id="TextBox">
                <Typography variant="h6">Categorías de las Publicaciones</Typography>
                <Typography variant="body2">Gestiona y crea nuevas categorías para las noticias</Typography>
            </Box>
        </Box>
    )
};

const NewsletterEmailsBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box className={classes.SmallAdminBox} onClick={() => redirect('/Newsletter')}>
            <Box id="IconBox">
                <MailOutlineRoundedIcon/>
            </Box>
            <Box id="TextBox">
                <Typography variant="h6">Emails de las Newsletter</Typography>
                <Typography variant="body2">Gestiona, añade y elimina emails en todas las Newsletters</Typography>
            </Box>
        </Box>
    )
};

const CountriesBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box className={classes.SmallAdminBox} onClick={() => redirect('/country')}>
            <Box id="IconBox">
                <CountryIcon/>
            </Box>
            <Box id="TextBox">
                <Typography variant="h6">Configuración de Paises</Typography>
                <Typography variant="body2">Gestiona y crea nuevos paises para cualquier ambito</Typography>
            </Box>
        </Box>
    )
};

const StateBox = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    return(
        <Box className={classes.SmallAdminBox} onClick={() => redirect('/state')}>
            <Box id="IconBox">
                <HomeWorkRoundedIcon/>
            </Box>
            <Box id="TextBox">
                <Typography variant="h6">Configuración de Provincias</Typography>
                <Typography variant="body2">Gestiona y crea nuevas provincias para cualquier ambito</Typography>
            </Box>
        </Box>
    )
};

const Admin = () => {
    const classes = useStyles();
    return(
        <Box>
            <Box className={classes.WelcomeBox}>
                <Typography variant="h5">Gestiona y administra los datos y ajustes de la Intranet</Typography>
            </Box>
            <Box className={classes.AdminFlexBox}>
                <Box className={classes.AdminUsersBox}>
                    <Card className={classes.AdminCard}>
                        <UsersBox/>
                    </Card>
                </Box>
                <Box className={classes.AdminBox}>
                    <Card className={classes.AdminCard}>
                        <SocietiesBox/>
                    </Card>
                </Box>
                <Box className={classes.AdminBox}>
                    <Card className={classes.AdminCard}>
                        <PointsBox/>
                    </Card>
                </Box>
            </Box>
            <Box className={classes.AdminFlexBox}>
                <Box className={classes.AdminUsersBox}>
                    <Card className={classes.AdminSmallCard} id="SmallCard">
                        <OrdersBox/>
                    </Card>
                </Box>
                <Box className={classes.AdminBox}>
                    <Card className={classes.AdminSmallCard} id="SmallCard">
                        <CategoriesBox/>
                    </Card>
                </Box>
                <Box className={classes.AdminBox}>
                    <Card className={classes.AdminSmallCard} id="SmallCard">
                        <StateBox/>
                    </Card>
                </Box>
            </Box>
            <Box className={classes.AdminFlexBox}>
                <Box className={classes.AdminUsersBox}>
                    <Card className={classes.AdminSmallCard} id="SmallCard">
                        <CampaignsBox/>
                    </Card>
                </Box>
                <Box className={classes.AdminBox}>
                    <Card className={classes.AdminSmallCard} id="SmallCard">
                        <NewsCategoriesBox/>
                    </Card>
                </Box>
                <Box className={classes.AdminBox}>
                    <Card className={classes.AdminSmallCard} id="SmallCard">
                        <CountriesBox/>
                    </Card>
                </Box>
            </Box>
            <Box className={classes.AdminFlexBox1}>
                <Box className={classes.AdminUsersBox}>
                    <Card className={classes.AdminSmallCard} id="SmallCard">
                        <MediaBox/>
                    </Card>
                </Box>
                <Box className={classes.AdminBox}>
                    <Card className={classes.AdminSmallCard} id="SmallCard">
                        <NewsletterEmailsBox/>
                    </Card>
                </Box>
            </Box>
        </Box>
    )
};

export default Admin;
