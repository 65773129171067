import React, {useState} from "react";
import { 
  Edit, SimpleForm, SelectInput, usePermissions, useRefresh, Link,
  ReferenceInput, Toolbar, SaveButton, NumberInput, useGetOne, useUpdate, LinearProgress
} from "react-admin";
import { makeStyles, Box, CircularProgress, Typography, Divider } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import MyTooltip from '../components/MuiTooltip';
import BackButton from '../components/BackButton';
import lightTheme from '../layout/themes';
import '../index.css';

const useStyles = makeStyles({
  InputBlackBorder: lightTheme.InputBlackBorder,
  EditToolBar: lightTheme.EditToolBar,
  OrdersMaterialTable: lightTheme.OrdersMaterialTable_Mui,
  ProductsTableTitle:{
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ProductLink:{
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "inline-block",
      textOverflow: "ellipsis",
  }
});

const OrdersCreateToolbar = ({loading, ...props}) => {
  const classes = useStyles();
  return (
    <Box>
      <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="Save" placement="right" arrow>
          <SaveButton id="GoogleStyleSaveButton" label="Guardar">
            {loading && ( <CircularProgress/> )}
          </SaveButton>
        </MyTooltip>
      </Toolbar>
    </Box>
  )
};

const MaterialImg = ({ id }) => {
  const { data, loading, error } = useGetOne('material', id);
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data) return (<img alt={data.title} src={data.logo.image}/>);
};

const MaterialName = ({ id }) => {
  const classes = useStyles();
  const { data, loading, error } = useGetOne('material', id);
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data) return (<Link to={`/material/${id}/show`} className={classes.ProductLink}>{data.title}</Link>);
};

const MaterialReference = ({ id }) => {
  const { data, loading, error } = useGetOne('material', id);
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data) return (data.reference);
};

const VariantUPC = ({ id }) => {
  const { data, loading, error } = useGetOne('variants', id);
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data) { return (data.ean)};
};

const VariantName = ({ id }) => {
  const { data, loading, error } = useGetOne('variants', id);
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data) return (data.name);
};

const OrdersEdit = ({record, ...props }) => {
  const classes = useStyles();
  const [hasEdit, setHasEdit] = useState(false);
  const { data } = useGetOne('orders', props.id);
  const [update] = useUpdate();
  const refresh = useRefresh();
  const { permissions } = usePermissions();
  const choices = [
    { id: '1', name: 'Sin Confirmar' },
    { id: '2', name: 'Aceptado' },
    { id: '3', name: 'Enviado' },
    { id: '4', name: 'Entregado' },
    { id: '5', name: 'Cancelado' },
    { id: '6', name: 'Devuelto'},
  ];

  if (data) {

    var objIndex = 0;
    var NewTotal = 0;
    const filteredArr = [];

    const columns = [
      { field: 'imagen', headerName: 'Imagen', editable: false, sortable: false,
        renderCell: (params) => {
          return (<MaterialImg id={params.row.material_id}/>);
        }
      },
      { field: 'material_id', headerName: 'Nombre', editable: false, sortable: false,
        renderCell: (params) => {
          return (<MaterialName id={params.value}/>);
        }
      },
      { field: 'referencia', headerName: 'Referencia', editable: false, sortable: false,
        renderCell: (params) => {
          return (<MaterialReference id={params.row.material_id}/>);
        }
      },
      { field: 'upc', headerName: 'UPC', editable: false, sortable: false,
        renderCell: (params) => {
          return (<VariantUPC id={params.row.variant_id}/>);
        }
      },
      { field: 'variant_id', headerName: 'Variante', editable: false, sortable: false,
        renderCell: (params) => {
          return (<VariantName id={params.value}/>);
        }
      },
      { field: 'variant_price', headerName: 'Precio/Ud', editable: false, sortable: false,
        renderCell: (params) => {
          return (<div>{params.value.toFixed(2)}&nbsp;€</div>);
        }
      },
      { field: 'cantidad', headerName: 'Cantidad', editable: (permissions === "Admin" || permissions === "Transporte") ? true : false, sortable: false
      },
      { field: 'total_variant_price', headerName: 'Precio Total', editable: false, sortable: false,
        renderCell: (params) => {
          return (<div>{params.value.toFixed(2)}&nbsp;€</div>);
        }
      },
        { field: 'acciones', headerName: 'Acciones', editable: false, sortable: false,
            renderCell: (params) => {
                params.row.total_variant_price = params.row.variant_price * params.row.cantidad
                if(filteredArr.findIndex((item) => item.variant_id === params.row.variant_id) === -1){
                    filteredArr.push(params.row);
                } 

                objIndex = filteredArr.findIndex((obj => obj.variant_id === params.row.variant_id));
                filteredArr[objIndex].cantidad = params.row.cantidad;
                filteredArr[objIndex].total_variant_price = params.row.total_variant_price;
                function UpdateProducts(){
                    for(var i = 0; i < filteredArr.length; i++){
                        NewTotal += filteredArr[i].total_variant_price;
                    }
                    update('orders', data.id, {_id: data.id, products: filteredArr, total_price: NewTotal});
                    setHasEdit(false);
                    refresh();
                }

                return(
                    <>
                        {hasEdit === true &&
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="save" arrow>
                                <IconButton aria-label="save-changes" component="span" onClick={() => UpdateProducts()}>
                                    <SaveRoundedIcon />
                                </IconButton>
                            </MyTooltip>
                        }
                    </>
                )
            }
        },
    ];

    function handleCellEdit(){
      setHasEdit(true)
    }

    return(
      <Edit {...props} actions={false}>
        <SimpleForm toolbar={<OrdersCreateToolbar/>}>
          <Box style={{width: '100%'}}>
            <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
              <Box p={1} width="100%">
                  <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Pedido</Typography>
              </Box>
              <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                  <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                      <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                  </MyTooltip>
              </Box>
            </Box>
            <Box>
              <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                <ReferenceInput label="Usuario" source="user_id" reference="users" multiline="true" fullWidth className={classes.InputBlackBorder} variant="outlined">
                    <SelectInput optionText="displayName" />
                </ReferenceInput>
                <SelectInput source="state" choices={choices} optionText="name" optionValue="name" label="Estado"
                  multiline="true" fullWidth className={classes.InputBlackBorder} variant="outlined"/>
              </Box>
              <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                <NumberInput source="total_price" fullWidth className={classes.InputBlackBorder} variant="outlined"/>
              </Box>
            </Box>
            <Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px', marginTop: '25px'}} />
            <Box className={classes.ProductsTableTitle}>
                <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, display: 'flex'}}>
                    <ShoppingCartRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Productos
                </Typography>
            </Box>
            <div style={{width: 'auto', paddingLeft: '10px', paddingRight: '10px'}}>
              <DataGrid
                  className={classes.OrdersMaterialTable} 
                  id="Products_Table"
                  columns={columns}
                  rows={data.products}
                  getRowId={(row) => row.variant_id}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableColumnMenu
                  autoHeight
                  hideFooter={true}
                  hideFooterPagination={true}
                  disableSelectionOnClick
                  disableColumnSelector
                  onCellEditCommit={() => handleCellEdit()}
              />
            </div>
          </Box>
        </SimpleForm>
      </Edit>
    )
  } else {
    return null;
  }
}; export default OrdersEdit;