import OrdersIcon from '@material-ui/icons/LocalOffer';
import OrdersList from './OrdersList';
import OrdersEdit from './OrdersEdit';
import OrdersCreate from './OrdersCreate';
import OrdersShow from './OrdersShow';

const OrdersModule = {
    list: OrdersList,
    edit: OrdersEdit,
    icon: OrdersIcon,
    create: OrdersCreate,
    show: OrdersShow
}; export default OrdersModule;