const defaultStyles = {
  pdf: {
    type: 'acrobat',
  },
  ppt: {
    type: 'presentation',
  },
  pptx: {
    type: 'presentation',
  },
  svg: {
    type: 'image',
  },
  png: {
    type: 'image',
  },
  jpeg: {
    type: 'image',
  },
  jpg: {
    type: 'image',
  },
  txt: {
    type: 'document',
  },
  doc: {
    type: 'document',
  },
  docx: {
    type: 'document',
  },
  csv: {
    type: 'spreadsheet',
  },
  xlr: {
    type: 'spreadsheet',
  },
  xls: {
    type: 'spreadsheet',
  },
  xlsx: {
    type: 'spreadsheet',
  },
};

export default defaultStyles;
