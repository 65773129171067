import React from "react";
import { List, useGetList, usePermissions, useDelete, useRefresh, useRedirect  } from 'react-admin';
import { makeStyles, Typography, Box, Grid } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
  VariantGlobalBox: lightTheme.MaterialViewVariantGlobalBox,
  VariantBox: lightTheme.MaterialViewVariantBox,
  VariantBoxSoldHover: lightTheme.MaterialViewVariantBoxSoldHover,
  VariantBoxSold: lightTheme.MaterialViewVariantBoxSold,
});


const VariantsList = (props) => {  
  const {permissions} = usePermissions();
  const classes = useStyles(props);
  const { data, ids } = useGetList('variants', { page: 1, perPage: 200 }, { field: 'price', order: 'DESC'}, { material_id: props.material_id });
  const [deleteOne] = useDelete();
  const refresh = useRefresh();
  const redirect = useRedirect();

  function EditVariant(v_id){
    redirect(`/variants/${v_id}/edit`);
  }

  function DeleteVariant(v_id){
    deleteOne('variants', v_id);
    refresh();

  }

  let price = 0;

  return(
    <List {...props} className={classes.MaterialPagination} component="div" actions={false} pagination={false}>
      {data && ids &&
        <Box className={classes.VariantGlobalBox}>
          <Grid container spacing={2} className={classes.root}>
            {ids.map(id => (
              <Grid key={id} xs={3} sm={3} md={3} lg={3} xl={3} item >
                {data[id] && 
                  <Box className={classes.VariantBox} style={{displey: 'flex'}}>
                    <div id="VariantInfo" style={{marginRight: '40px'}}>
                      <Typography variant="h6" style={{fontSize: '0.9rem', paddingTop: '2px'}}>
                        {data[id].name}&nbsp;<span style={{color: 'rgb(142, 142, 142)', fontSize: '0.8rem'}}>({data[id].stock})</span>
                      </Typography>
                      <Typography variant="h6" style={{textTransform: 'uppercase', fontSize: '0.9rem', paddingTop: '6px', color: '#b22c09'}}>
                        <p style={{display: 'none'}}>{price = data[id].price ? data[id].price.toString().split('.') : "0"}</p>
                        <Box style={{width: '100%', display: 'flex', color: '#b53413'}}>{price[0]}
                          {price[1] === undefined &&
                            <Box style={{width: '100%', display: 'flex'}}>,{price[1]}00 €</Box>
                          }
                          {price[1] && price[1].length <= 1 &&
                            <Box style={{width: '100%', display: 'flex'}}>,{price[1]}0 €</Box>
                          }
                          {price[1] && price[1].length > 1 &&
                            <Box style={{width: '100%', display: 'flex'}}>,{price[1]} €</Box>
                          }
                        </Box>
                      </Typography>
                      <Typography variant="h6" style={{textTransform: 'uppercase', fontSize: '0.9rem', paddingTop: '6px', color: 'rgb(142, 142, 142)'}}>
                        {data[id].ean}
                      </Typography>
                    </div>
                    {(permissions === "Admin" || permissions === "Transporte") && 
                      <Box style={{display:'flex', justifyContent: 'flex-end', width: '100%', marginTop: '-80px', height: '100%', alignItems: 'end'}} id="EditButton">
                        <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Variante</div>} aria-label="Editar" arrow>
                          <IconButton aria-label="edit" onClick={() => EditVariant(data[id].id)}>
                            <EditRoundedIcon />
                          </IconButton>
                        </MyTooltip>
                        <MyTooltip title={<div style={{fontSize: '12px'}}>Borrar Variante</div>} aria-label="Delete" arrow>
                          <IconButton aria-label="delete"  onClick={() => DeleteVariant(data[id].id)}>
                            <DeleteIcon />
                          </IconButton>
                        </MyTooltip>
                      </Box>
                    }
                  </Box>
                }
              </Grid>
            ))}
          </Grid>
        </Box>
      }
    </List>
  )
};
export default VariantsList;
