import React from 'react';
import { Link, useRedirect } from 'react-admin';
import { Card, Divider, Box, Typography, makeStyles, Button } from '@material-ui/core';
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';

const useStyles = makeStyles({

    DepartmentCard :{
        padding: '20px',
        marginLeft: '20px',
        paddingBottom: '0px',
        height: '100%',
        '& #Title': {
            cursor: 'pointer'
        },
        '& #Subtitle': {
            fontSize: '0.775rem'
        },
        '& img':{
            width: '65%',
            margin: '0 auto',
            display: 'block',
            cursor: 'pointer',
            transition: 'all .5s',
            '&:hover':{
                transform: 'scale(1.2)',
            }
        },
    },
    CardDivider :{
        marginTop: '10px',
        marginBottom: '10px',
        height: '1px !important',
        marginLeft: '-20px',
        marginRight: '-20px',
    },
    IconCard :{
        display: 'flex',
        justifyContent: 'center',
    },
    ActionBox :{
        display: 'flex',
        padding: '0px',
        alignItems: 'flex-start',
        flexDirection: 'column',
        overflow: 'auto',
        '& a':{
            width: '100%'
        },
        '& #Button':{
            color: '#196cdb',
            textTransform: 'capitalize',
            marginBottom: '5px',
            width: '100%',
            '& span':{
                justifyContent: 'left'
            }
        },
        '& #Button:hover':{
            backgroundColor: '#196cdb26',
        },
    }

});

export class Cards extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expand: false
        };
    }

    handleExpandOpen = () => {
        this.setState({ expand: !this.state.expand });
    };
    handleExpandClose = () => {
        this.setState({ expand: !this.state.expand });
    };
  
    render() {
        const classes = this.props.classes;
        const redirect = this.props.redirect
        return(
            <Card className={classes.DepartmentCard} id="Card">
                <Typography variant="h6" id="Title" onClick={()=> redirect(this.props.access)}>{this.props.title}</Typography>
                <Typography variant="body2" id="Subtitle">{this.props.SubTitle}</Typography>
                <Divider className={classes.CardDivider}/>
                <img src={this.props.img} alt={this.props.imgAlt} onClick={()=> redirect(this.props.access)}></img>
                <Divider className={classes.CardDivider}/>
                <Box className={classes.IconCard}>
                    <CardActions disableSpacing>
                        {this.state.expand === false &&
                            <IconButton onClick={this.handleExpandOpen}>
                                <ExpandMoreRoundedIcon />
                            </IconButton>
                        }
                        {this.state.expand === true &&
                            <Box>
                                <IconButton onClick={this.handleExpandClose}>
                                    <ExpandLessRounded />
                                </IconButton>
                            </Box>
                        }
                    </CardActions>
                </Box>
                <Collapse in={this.state.expand} timeout="auto" unmountOnExit mountOnEnter>
                    <CardContent className={classes.ActionBox}>
                        {this.props.link1 &&
                            <Link to={this.props.link1}>
                                <Button id="Button">{this.props.Button1}</Button>
                            </Link>
                        }
                        {this.props.link2 &&
                            <Link to={this.props.link2}>
                                <Button id="Button">{this.props.Button2}</Button>
                            </Link>
                        }
                        {this.props.link3 &&
                            <Link to={this.props.link3}>
                                <Button id="Button">{this.props.Button3}</Button>
                            </Link>
                        }
                        {this.props.link4 &&
                            <Link to={this.props.link4}>
                                <Button id="Button">{this.props.Button4}</Button>
                            </Link>
                        }
                    </CardContent>
                </Collapse>
            </Card>
        )
    }
};

export const UserDashboardCards = (props) => {
    const classes = useStyles();
    const redirect = useRedirect();
    return (
        <Cards
            redirect={redirect}
            classes={classes}
            access={props.access}
            title={props.title}
            SubTitle={props.SubTitle}
            img={props.img}
            imgAlt={props.imgAlt}
            link1={props.link1}
            Button1={props.Button1}
            link2={props.link2}
            Button2={props.Button2}
            link3={props.link3}
            Button3={props.Button3}
        />
    )
}
