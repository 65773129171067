import React, {Component, useRef} from "react";
import { TextInput } from "react-admin";
import { Box } from "@material-ui/core";
import { Editor } from '@tinymce/tinymce-react';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;

const TinyMCETextEditor = ({data, source}) => {
    const editorRef = useRef(null);
    class EditorConvertToHTML extends Component {

        constructor(props) {
            super(props);
            this.state = {
                textValue: data ? data : '<p>Empieza a escribir aquí...<p>'
            };
        }
        
        onChange = (content) => {
            this.setState(() => {
                return {textValue: content};
            })
        }

        render() {
            return (
                <div>
                    <Editor
                        onInit={(evt, editor) => { 
                            editorRef.current = editor;
                        }}
                        onEditorChange={this.onChange}
                        apiKey={TINY_API_KEY}
                        initialValue={data ? data : '<p>Empieza a escribir aquí...<p>'}
                        init={{
                            height: 500,
                            language: 'es',
                            editimage_cors_hosts: ['picsum.photos'],
                            image_advtab: true,
                            plugins: 'preview importcss searchreplace autolink autosave save code fullscreen image link media table insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                            menubar: 'file edit view insert format tools table help',
                            toolbar: 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | emoticons | fullscreen  preview | insertfile image media link',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:1rem }'
                        }}
                    />
                    <TextInput
                        source={source ? source : "textValue"}
                        multiline="true"
                        style={{width: '100%', display: 'none'}}
                        initialValue={this.state.textValue}
                    />
        
                </div>
            );
        }
    }

    return (
        <Box>
            <EditorConvertToHTML />
        </Box>
    );
}; export default TinyMCETextEditor;



// >>---------------------------------------------------<< //
// >>================ EJEMPLO DE USO ===================<< //
// >>---------------------------------------------------<< //

    /*{data &&
        <TinyMCETextEditor data={data.body} source="body"/>
    }*/

// >>---------------------------------------------------<< //