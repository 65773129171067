import React from 'react';
import { 
  ImageField, Show, SimpleForm, TextField, EmailField, TopToolbar, EditButton, CreateButton, Link, useGetOne, CloneButton,
  ReferenceField, DeleteButton, FormTab, TabbedForm, SingleFieldList, ReferenceArrayField , ChipField, LinearProgress, usePermissions
} from 'react-admin';
import { makeStyles, Divider, Typography, Box, /*CardMedia*/ } from '@material-ui/core';
import ToolbarBackButton from '../components/ToolbarBackButton';
import MyTooltip from "../components/MuiTooltip";
import ConditionsGrid from '../conditions/ConditionsGrid';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FilterNoneRoundedIcon from '@material-ui/icons/FilterNoneRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import lightTheme from '../layout/themes';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mdiFileMultipleOutline } from '@mdi/js';
import Icon from '@mdi/react'

const CreateConditionsIcon = () => {  return( <Icon path={mdiFileMultipleOutline} size={'24px'}/> ) };

const useStyles = makeStyles({
  AsideProviderAddress: lightTheme.AsideProviderAddress,
  PostViewTypography: lightTheme.PostViewTypography,
  EmptyBubbleConditions: lightTheme.EmptyBubble,
  EmptyConditionsMediaCard: lightTheme.EmptyConditionsMediaCard,
  ContactBox: lightTheme.ProviderStyles.Show.ContactBox,
  SeeMoreButton: lightTheme.ProviderStyles.Show.SeeMoreButton,
  Contact: lightTheme.ProviderStyles.Show.Contact,
  TabbedForm: lightTheme.ProviderStyles.Show.TabbedForm,
  LogoImg: lightTheme.ProviderStyles.Show.LogoImg,
  TabsBox: {
    marginTop: '10px',
    '& .MuiTabs-root':{
      borderBottom: '1px solid #cacaca',
    }
  },
  TabsPanel:{
    padding: '10px !important',
  },
  ShowStyles: {
    marginTop: '-20px',
    '& .MuiPaper-root':{
      width: '80%',
    }
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.TabsPanel}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ProviderAddressAside = ({ data, record, ...props }) => {
  const classes = useStyles();
  const permissions = usePermissions().permissions;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <SimpleForm toolbar={null} className={classes.AsideProviderAddress} {...props} >
      <Box style={{marginTop: '10px', width: '100%'}}>
        {data && data.jornadas_compras === true &&
          <Typography style={{fontSize: '0.9rem', textAlign: 'center', marginBottom: '5px', color: '#1b841b'}}>
            <CheckRoundedIcon style={{fontSize: '1.0rem', marginBottom: '-3px', paddingRight: '5px'}}/>Presente en Jornadas de Compras
          </Typography>
        }
        {data && data.jornadas_compras !== true &&
          <Typography style={{fontSize: '0.9rem', textAlign: 'center', marginBottom: '5px', color: '#b63818'}}>
            <CloseRoundedIcon style={{fontSize: '1.0rem', marginBottom: '-3px', paddingRight: '5px'}}/>No Presente en Jornadas de Compras
          </Typography>
        }
        <Divider style={{marginBottom: '10px', marginTop: '18px', width: 'auto'}} />
        <Box style={{marginLeft: '5px'}}>
          <Box>
            <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, marginLeft: '0px'}}>
              <DomainRoundedIcon style={{fontSize: '1.5rem', marginBottom: '-4px', paddingRight: '5px'}}/>&nbsp;{data.company}
            </Typography>
            <Box className={classes.Contact}>
              <Typography><span style={{color: '#757575', fontSize: '14px'}}>NIF/CIF:&nbsp;&nbsp;</span><TextField disabled label="" source="nif"/></Typography>
              <Typography>
                <LocalPhoneRoundedIcon id="phone"/><TextField disabled label="" source="phone"/>
              </Typography>
              <Typography>
                <MailOutlineRoundedIcon id="mail"/><EmailField style={{fontSize: '0.9rem', color: '#196cdb'}} disabled label="" source="email"/>
              </Typography>
              {data && data.web && data.web !== "https://" &&
                <Typography id="web">
                  <LanguageRoundedIcon id="web"/><a href={data.web} target="_blank" rel="noreferrer">{data.web.replace('https://','')}</a>
                </Typography>
              }
              <Typography style={{marginTop: '10px', display: 'flex'}}>
                <RoomRoundedIcon id="location"/>
                <span>
                  <TextField disabled label="" source="address"/>,&nbsp;
                  <TextField disabled label="" source="location"/>,&nbsp;
                  <ReferenceField label="" source="state" reference="state" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                </span>
              </Typography>
            </Box>
          </Box>
          {data.contact.length > 0 &&
            <Box>
              {permissions === 'Admin' &&
                <Box className={classes.TabsBox}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Central" {...a11yProps(0)} />
                    <Tab label="Socios" {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <Box className={classes.ContactBox}>
                      {data.contact && data.contact.map(Contact => (
                        <div>
                          {Contact.role === 'central' &&
                            <Box id="contentBox">
                              <Box>
                                <Typography id="Name">
                                  <PermContactCalendarOutlinedIcon/>&nbsp;{Contact.name}
                                </Typography>
                                {Contact.work &&
                                  <Typography id="WorkPlace">
                                    <ArrowRightRoundedIcon />&nbsp;{Contact.work}
                                  </Typography>
                                }
                                {Contact.email &&
                                  <Typography id="Email">
                                    <ArrowRightRoundedIcon />&nbsp;<a href={`mailto:${Contact.email}`} target="_blank" rel="noreferrer">{Contact.email}</a>
                                  </Typography>
                                }
                                {Contact.phone &&
                                  <Typography id="Phone">
                                    <ArrowRightRoundedIcon />&nbsp;{Contact.phone}
                                  </Typography>
                                }
                              </Box>
                            </Box>
                          }
                        </div>
                      ))}
                    </Box>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Box className={classes.ContactBox}>
                      {data.contact && data.contact.map(Contact => (
                        <div>
                          {Contact.role === 'socio' &&
                            <Box id="contentBox">
                              <Box>
                                <Typography id="Name">
                                  <PermContactCalendarOutlinedIcon/>&nbsp;{Contact.name}
                                </Typography>
                                {Contact.work &&
                                  <Typography id="WorkPlace">
                                    <ArrowRightRoundedIcon />&nbsp;{Contact.work}
                                  </Typography>
                                }
                                {Contact.email &&
                                  <Typography id="Email">
                                    <ArrowRightRoundedIcon />&nbsp;<a href={`mailto:${Contact.email}`} target="_blank" rel="noreferrer">{Contact.email}</a>
                                  </Typography>
                                }
                                {Contact.phone &&
                                  <Typography id="Phone">
                                    <ArrowRightRoundedIcon />&nbsp;{Contact.phone}
                                  </Typography>
                                }
                              </Box>
                            </Box>
                          }
                        </div>
                      ))}
                    </Box>
                  </TabPanel>
                </Box>
              }
              {permissions === 'User' && data.contact &&
                <Box>
                  <Typography style={{fontSize: '1.1rem', marginBottom: 15, marginTop: 15}}>
                    Agenda de contactos
                  </Typography>
                  {data.contact && data.contact.map(Contact => (
                    <div>
                      {Contact.role === 'socio' &&
                        <Box className={classes.ContactBox}>
                          <Divider style={{marginBottom: '10px', marginTop: '11px', width: '100%'}} />
                          <Box>
                            <Typography id="Name">
                              <PermContactCalendarOutlinedIcon/>&nbsp;{Contact.name}
                            </Typography>
                            {Contact.work &&
                              <Typography id="WorkPlace">
                                <ArrowRightRoundedIcon />&nbsp;{Contact.work}
                              </Typography>
                            }
                            {Contact.email &&
                              <Typography id="Email">
                                <ArrowRightRoundedIcon />&nbsp;<a href={`mailto:${Contact.email}`} target="_blank" rel="noreferrer">{Contact.email}</a>
                              </Typography>
                            }
                            {Contact.phone &&
                              <Typography id="Phone">
                                <ArrowRightRoundedIcon />&nbsp;{Contact.phone}
                              </Typography>
                            }
                          </Box>
                        </Box>
                      }
                    </div>
                  ))}
                </Box>
              }
            </Box>
          }
        </Box>
      </Box>
    </SimpleForm>
  )
};

const ProviderShopName = ({ id }) => {
  const { data, loading, error } = useGetOne('shops', id);
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data) return (
      <span>{data.name}</span>
  );
};

const ProviderView = ({ permissions, ...props }) => {
  const { data } = useGetOne('provider', props.id);
  const classes = useStyles();

  if (data) {
    const CloneData = {"name": data.name, "address": data.address, "location": data.location, "country": data.country, "state": data.state, 
    "jornadas_compras": data.jornadas_compras, "category": data.category, "logo": data.logo, "contact": data.contact, "web": data.web,
    "company": data.company, "shop": data.shop, "phone": data.phone, "email": data.email, "nif": data.nif};

    const ProviderViewActions = ({ basePath, resource }) => {
      return(
        <TopToolbar style={{paddingBottom: '2px', marginTop: '22px'}} id="GoogleStyleToolBar">
          <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
            <MyTooltip title={<div style={{fontSize: '12px'}}>Volver Atrás</div>} arrow>
              <ToolbarBackButton style={{paddingLeft: '17px', paddingRight: '5px', marginRight: '10px'}} id="GoogleStyleBackButton"></ToolbarBackButton>
            </MyTooltip>
            {data && CloneData &&
              <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                <Box display="flex" style={{ width: '100%' }}>
                  <Box>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Lista de Tiendas</div>} arrow>
                      <Link to={`/shops`}>
                        <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '10px', zIndex: '5', fontSize: '14px', cursor: 'pointer'}}>Proveedores</Typography>
                      </Link>
                    </MyTooltip>
                  </Box>
                  <NavigateNextRoundedIcon style={{marginLeft: '10px', marginRight: '10px', marginTop: '8px', marginBottom: '6px'}}/>
                  <Box>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Lista de Proveedores</div>} arrow>
                      <Link to={`/shops/${data.shop}/show`}>
                        <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '10px', zIndex: '5', fontSize: '14px'}}><ProviderShopName id={data.shop}/></Typography>
                      </Link>
                    </MyTooltip>
                  </Box>
                  <NavigateNextRoundedIcon style={{marginLeft: '10px', marginRight: '10px', marginTop: '8px', marginBottom: '6px'}}/>
                  <Box>
                    <Typography variant="body1" style={{color: '#3d3d3d', marginTop: '10px', zIndex: '5', fontSize: '14px'}}>{data.name}</Typography>
                  </Box>
                </Box>
                {permissions === "Admin" &&
                  <Box display="flex" style={{ width: '100%', marginRight: '20px' }} justifyContent="flex-end">
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Agregar Condición para este Proveedor</div>} arrow placement="left">
                      <CreateButton label="" icon={<CreateConditionsIcon />} id="GoogleStyleButton" 
                        to={`/conditions/create?source={"provider": {"id": "${props.id}", "name": "${data.name}"}, "shop":"${data.shop}", "shops":"${data.shop}"}`}/>
                    </MyTooltip>
                    <Divider orientation="vertical" flexItem style={{marginLeft: '20px', marginRight: '10px', marginTop: '6px', marginBottom: '6px'}}/>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Duplicar Proveedor</div>} arrow>
                      <CloneButton label="" icon={<FilterNoneRoundedIcon /> } basePath={basePath} record={CloneData} resource={resource} id="GoogleStyleButton"/>
                    </MyTooltip>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Proveedor</div>} arrow>
                      <EditButton label="" type="link" to={`/provider/${props.id}/edit`} id="GoogleStyleButton"/>
                    </MyTooltip>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Proveedor</div>} arrow>
                      <DeleteButton label="" redirect={`/shops/${data.shop}/show`} record={data} resource={resource} id="GoogleStyleDeleteButton"/>
                    </MyTooltip>
                  </Box>
                }
              </Box>
            }
          </Box>
        </TopToolbar>
      )
    };

    let fakeProps = {basePath: "/conditions", resource: "conditions", shop: data.shop, provider: props.id}
    return (
      <Box>
        <Show {...props} actions={<ProviderViewActions />} aside={<ProviderAddressAside data={data}/>} className={classes.ShowStyles}>
          <Box>
            <Box style={{width: '100%', display: 'flex', justifyContent: 'flex-start', paddingTop: '40px'}}>
              <ImageField source="logo.src" label="" className={classes.LogoImg}/>
              <Box style={{width: '90%'}}>
                <TextField source="name"/>
                <Box style={{marginTop: '25px', marginLeft: '-5px', width: '85%'}}>
                  <ReferenceArrayField reference="categorys" source="category">
                    <SingleFieldList linkType={false}>
                      <ChipField source="name" style={{border: '1px solid #757575'}}/>
                    </SingleFieldList>
                  </ReferenceArrayField>
                </Box>
              </Box>
            </Box>
            <TabbedForm toolbar={false} className={classes.TabbedForm}>
              <FormTab label="Condiciones">
                <ConditionsGrid {...fakeProps} style={{width: '100%'}}/>
              </FormTab>
              {/*<FormTab label="Pricats">
                <Box display="flex" justifyContent="center" style={{width: '100%', paddingTop: '60px'}}>
                  <Box textAlign="center" className={classes.EmptyBubble}>
                    <CardMedia className={classes.EmptyConditionsMediaCard}></CardMedia>
                    <Typography variant="h6" style={{marginTop: '20px'}}>
                      Esta Función aún no está Disponible
                    </Typography>
                  </Box>
                </Box>
              </FormTab>*/}
            </TabbedForm>
          </Box>
        </Show>
      </Box>
    )
  } else {
    return (null)
  }
}; export default ProviderView;