import React from "react";
import { useLocation } from 'react-router-dom';
import { Layout, useTranslate } from 'react-admin';
import MyAppBar from './AppBar';
import MyMenu from './Menu';
import MyNotification from './Notifications';
import ErrorPage from './Error/ErrorPage';
import config from '../config/config'
import PageTitle from '../components/PageTitle';

const MyLayout = ({history, ...props }) => {

    const location = useLocation();
    const translate = useTranslate()
    var resource = location.pathname.split('/')[1];

    return(
        <>
            <PageTitle title={translate(`resources.${resource}.TitleName`) + config.TITLE_NAME}/>
            <Layout
                {...props}
                appBar={MyAppBar}
                menu={MyMenu}
                notification={MyNotification}
                error={ErrorPage}
            />
        </>
    )
}
export default MyLayout;