import React, { useState } from 'react';
import { useGetList, LinearProgress, useGetOne, useRedirect } from "react-admin";
import { makeStyles, Typography, Card, Grid, Box, Divider, CardContent, CardMedia, Modal, Fade, Backdrop } from '@material-ui/core';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';
import firebase from 'firebase';
import AddressCreate from './AddressCreate';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseButton from '../components/CloseButton';
import EditLocationAltRoundedIcon from '@mui/icons-material/EditLocationAltRounded';
import Fab from '@mui/material/Fab';

const useStyles = makeStyles({
  EmptyAddressMediaCard: lightTheme.EmptyAddressMediaCard,
  EmptyBubble: lightTheme.EmptyBubble,
  MuiModal: lightTheme.CommonStyles.Modal.MuiModal,
  MuiPaper: lightTheme.CommonStyles.Modal.MuiPaper,
  CardHeader: {
    padding: '10px',
    paddingLeft: '15px',
    paddingTop: '20px',
    paddingBottom: '19px',
    marginLeft: '-18px',
    marginRight: '-18px',
    marginTop: '-18px',
    marginBottom: '24px',
  },
  ButtonBox:{
    display: 'flex', 
    justifyContent: 'flex-end', 
    marginBottom: '-7px', 
    marginTop: '-38px',
    '& .MuiFab-root':{
      boxShadow: '0px 0px 0px #fff !important'
    }
  }
});


const AddressList = () => {
  const user = firebase.auth().currentUser;

  const [open, setOpen] = useState(false);
  const handleOpen = () => {setOpen(true)};
  const handleClose = () => {setOpen(false)};

  let AddressProps = {basePath: "/address", resource: "address"}

  const StateName = ({ id }) => {
    const result = id
    const { data, loading, error } = useGetOne('state', result);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (data.name);
  };

  const CountryName = ({ id }) => {
      const result = id
      const { data, loading, error } = useGetOne('country', result);
      if (loading) { return <LinearProgress /> }
      if (error) { return <p>ERROR</p>; }
      if (data) return (data.name);
  };

  const AddressGrid = ()  => {
    const classes = useStyles();
    const redirect = useRedirect();
    const { data, ids } = useGetList('address', { page: 1, perPage: 50 }, { field: 'createdate', order: 'DESC' }, {user: user.uid});
    return ids.length > 0 ? (
      <Box>
        <Box>
          <Grid container spacing={5}>
            {ids.map(id => (
              <Grid key={id} xs={12} sm={12} md={12} lg={4} xl={4} item >
                <Card className={classes.card} style={{height: '100%'}}>
                    <CardContent style={{backgroundColor: '#fff'}}>
                      <Box className={classes.CardHeader}>
                        <Typography variant="h6" align="left" style={{fontSize: '18px'}}>
                          <RoomRoundedIcon style={{marginBottom: '-5px'}}/>&nbsp;{data[id].name}
                        </Typography>
                        <Box className={classes.ButtonBox}>
                          <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Dirección</div>} arrow placement="left">
                            <Fab size="small" color="primary" onClick={() => { redirect(`/address/${data[id].id}/edit`); }}>
                              <EditLocationAltRoundedIcon />
                            </Fab>
                          </MyTooltip>
                        </Box>
                      </Box>
                      <Box>
                        <Typography variant="body1" align="left" style={{ fontSize: '15px', lineHeight: '1.8'}}>
                          {data[id].user_address}<br/>
                          {data[id].location} {data[id].CP}<br/>
                          <StateName id={data[id].state}/>, <CountryName id={data[id].country}/>
                        </Typography>
                      </Box>
                      <Divider style={{marginTop: '20px', marginBottom: '20px'}}/>
                      <Box>
                        <Typography variant="body1" align="left" style={{ fontSize: '17px', lineHeight: '1.8', marginBottom: '5px', color: '#196cdb'}}>
                          Titular: &nbsp;
                          <span style={{color: '#3d3d3d', fontSize: '16px'}}>{data[id].user_name}&nbsp;{data[id].user_lastname}</span>
                        </Typography>
                      </Box>
                    </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          
          <Box style={{position: 'fixed', bottom: '40px', right: '30px'}}>
            <MyTooltip title={<div style={{fontSize: '12px'}}>Crear nueva Dirección</div>} arrow placement="left">
              <Fab color="primary" aria-label="add" onClick={handleOpen}>
                <AddRoundedIcon />
              </Fab>
            </MyTooltip>
          </Box>

        </Box>

        <div id="CountryCreateSection">
          <Modal className={classes.MuiModal} open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
            <Fade in={open}>
              <div className={classes.MuiPaper}>
                <Box id="TitleBox">
                    <Box id="Title">
                      <h2>Crear una nueva dirección</h2>
                    </Box>
                    <Box id="CloseButton">
                      <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                        <CloseButton style={{paddingLeft: '17px',  paddingRight: '5px'}} onClick={handleClose}></CloseButton>
                      </MyTooltip>
                    </Box>
                </Box>
                <AddressCreate {...AddressProps} />
              </div>
            </Fade>
          </Modal>
        </div>

      </Box>
    ) : 
    <Box>
      <Box display="flex" justifyContent="center" style={{width: '100%', marginTop: '30px'}}>
        <Box textAlign="center" className={classes.EmptyBubble}>
          <CardMedia className={classes.EmptyAddressMediaCard}></CardMedia>
          <Typography variant="h6" style={{marginTop: '20px', marginBottom: '30px'}}>
            No has creado Direcciones aún
          </Typography>
        </Box>
      </Box>
      <Box style={{position: 'fixed', bottom: '40px', right: '30px'}}>
        <MyTooltip title={<div style={{fontSize: '12px'}}>Crear nueva Dirección</div>} arrow placement="left">
          <Fab color="primary" aria-label="add" onClick={handleOpen}>
            <AddRoundedIcon />
          </Fab>
        </MyTooltip>
      </Box>

      <div id="CountryCreateSection">
        <Modal className={classes.MuiModal} open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}}>
          <Fade in={open}>
            <div className={classes.MuiPaper}>
              <h2>Crear Una Nueva Dirección</h2>
              <Box>
                <AddressCreate {...AddressProps} />
              </Box>
            </div>
          </Fade>
        </Modal>
      </div>

    </Box>
  };

  return (
    <AddressGrid/>
  )
}; export default AddressList;