import React from "react";
import { 
    Edit, SimpleForm, TextInput, Toolbar, SaveButton, required, DeleteButton,
    FormDataConsumer, AutocompleteInput, SelectInput, ReferenceInput
} from "react-admin";
import { Typography, Box, makeStyles, CircularProgress, Divider } from '@material-ui/core';
import lightTheme from '../layout/themes';
import BackButton from "../components/BackButton";
import firebase from 'firebase';
import MyTooltip from '../components/MuiTooltip';
import '../index.css';

const useStyles = makeStyles({
    InputBlackBorder: lightTheme.InputBlackBorder,
    CardUsersEdit: lightTheme.CardUsersEdit,
    EditToolBar: lightTheme.EditToolBar,
});

const AddressEdit = props => {
    const classes = useStyles();
    const user = firebase.auth().currentUser;
    const redirect = () => `/profile?tabInd=2`;

    const AddressEditToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        const basePath = `/address`;
        return (
            <Box>
                <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="Save" arrow>
                        <SaveButton disabled={props.pristine} redirect={`/profile?tabInd=2`} id="GoogleStyleSaveButton">
                        {loading && (<CircularProgress/>)}
                        </SaveButton>
                    </MyTooltip>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Dirección</div>} aria-label="Delete" arrow placement="right">
                        <DeleteButton basePath={basePath} label="" id="GoogleStyleDeleteButton" redirect={`/profile?tabInd=2`}/>
                    </MyTooltip>
                </Toolbar>
            </Box>
        )
    };

    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect} toolbar={<AddressEditToolbar/>}>
                <Box style={{ width:'100%', color: '#3d3d3d' }}>
                    <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                        <Box p={1} width="100%">
                            <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Dirección</Typography>
                        </Box>
                        <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                                <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                            </MyTooltip>
                        </Box>
                    </Box>
                    <Box>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label="Nombre de la Dirección" source="name" variant="outlined" 
                              multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()}/>
                        </Box>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label="Nombre del Titular" source="user_name" variant="outlined" 
                              multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()}/>
                            <TextInput label="Apellido" source="user_lastname" variant="outlined" 
                              multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()}/>
                        </Box>
                        <Divider style={{marginTop: '10px', marginBottom: '30px', marginRight: '10px', marginLeft: '10px'}}/>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label="Dirección" variant="outlined" source="user_address" style={{ width: '80%' }}
                              multiline="true" fullWidth className={classes.InputBlackBorder} validate={required()}/>
                            <TextInput label="Código Postal" variant="outlined" source="CP" style={{ width: '20%' }}
                              multiline="true" fullWidth className={classes.InputBlackBorder} />
                        </Box>
                        <Box>
                          <Box style={{ width: '100%',display: 'flex' }}>
                              <ReferenceInput source="country" reference="country" filterToQuery={searchText => ({ name: searchText })} 
                                  label="País" className={classes.InputBlackBorder} variant="outlined" style={{ width: '30%' }} validate={required()}>
                                  <AutocompleteInput optionText="name" />
                              </ReferenceInput>
                              <FormDataConsumer>
                                  {({ formData, ...rest }) => {
                                      return (
                                          <Box style={{marginRight: '20px', width: '30%', marginLeft: '20px' }}>
                                              <ReferenceInput source="state" reference="state" filterToQuery={searchText => ({ name: searchText })} perPage={55}
                                                  label="Provincia" className={classes.InputBlackBorder} variant="outlined"
                                                  filter={{country: formData.country}} validate={required()}>
                                                  <AutocompleteInput optionText="name"/>
                                              </ReferenceInput>
                                          </Box>
                                      );
                                  }}
                              </FormDataConsumer>
                              <TextInput label="Ciudad" variant="outlined" source="location" 
                                  multiline="true" fullWidth className={classes.InputBlackBorder} style={{ width: '30%' }} validate={required()}/>
                          </Box>
                        </Box>
                        <Box style={{display: 'none'}}>
                          <ReferenceInput source="user" reference="users" defaultValue={user.uid}>
                            <SelectInput optionText="id" />
                          </ReferenceInput>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    )
}; export default AddressEdit;