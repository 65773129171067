import * as React from 'react';
import { 
    Edit, useGetList, useGetOne, SimpleForm, Toolbar, SaveButton, FormDataConsumer
} from "react-admin";
import { Box, makeStyles, Typography, CircularProgress } from '@material-ui/core';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';
import IconButton from '@mui/material/IconButton';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

const useStyles = makeStyles({
  EditToolBar: lightTheme.EditToolBar,
  TransferList: lightTheme.NesletterStyles.CreateAndEdit.TransferList,
});

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function chunkArray(myArray, chunk_size){
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];
  var myChunk = [];
  for (index = 0; index < arrayLength; index += chunk_size) {
      myChunk = myArray.slice(index, index+chunk_size);
      tempArray.push(myChunk);
  }
  return tempArray;
}

const NewsletterEdit = (props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);

  const [left, setLeft] = React.useState([]);
  const [totalLeft, setTotalLeft] = React.useState([]);
  const [leftPages, setLeftPages] = React.useState(0);
  const [leftTotalItems, setLeftTotalItems] = React.useState(0);
  const [leftPerPage, setLeftPerPage] = React.useState(30);
  const [currentPageLeft, setCurrentPageLeft] = React.useState(0);

  const [right, setRight] = React.useState([]);
  const [totalRight, setTotalRight] = React.useState([]);
  const [rightPages, setRightPages] = React.useState(0);
  const [rightTotalItems, setRightTotalItems] = React.useState(0);
  const [rightPerPage, setRightPerPage] = React.useState(30);
  const [currentPageRight, setCurrentPageRight] = React.useState(0);

  const NewsletterData = useGetOne('newsletter', props.id);
  const UsersData = useGetList('users', { page: 1, perPage: 999 });

  React.useEffect(() => {
    const GetAllUsers = () => {
      if(UsersData.data && UsersData.ids && UsersData.loaded === true && NewsletterData.data && NewsletterData.loading === false){
        const usersAvailable = UsersData.ids.map(function (id) {
          return {'email': UsersData.data[id].email, 'name': UsersData.data[id].displayName, 'user_id': UsersData.data[id].id};
        });
        const usersNewsletterToFilter = NewsletterData.data.emails;
        const myArrayFiltered = usersAvailable.filter(o=> !usersNewsletterToFilter.some(i=> i.email === o.email));
        const SortedArray = myArrayFiltered.sort((a, b) => a.email.localeCompare(b.email));
        const chunkUsersAvailable = chunkArray(SortedArray, rightPerPage);
        setTotalRight(usersAvailable);
        setRight(chunkUsersAvailable[currentPageRight]);
        setRightTotalItems(SortedArray.length);
        setRightPages(chunkUsersAvailable.length);
      }else{
        const loadingArray = [{'email': 'Espere por favor...', 'name': 'Cargando Usuarios...', 'user_id': 'loading-users'}];
        setRight(loadingArray)
      }
    };
    const GetNewsletterEmails = () => {
      if(NewsletterData.data && NewsletterData.loading === false){
        const usersNewsletter = NewsletterData.data.emails;
        console.log(usersNewsletter)
        const newsletterSortedArray = usersNewsletter
        const chunckNewsletter = chunkArray(newsletterSortedArray, leftPerPage);
        setTotalLeft(newsletterSortedArray);
        setLeft(chunckNewsletter[currentPageLeft]);
        setLeftTotalItems(newsletterSortedArray.length);
        setLeftPages(chunckNewsletter.length);
      } else {
        const loadingArray = [{'email': 'Espere por favor...', 'name': 'Cargando Usuarios...', 'user_id': 'loading-newsletter'}]
        setLeft(loadingArray)
      }
    };

    GetAllUsers()
    GetNewsletterEmails()
  },[NewsletterData.data, NewsletterData.loading, UsersData.data, UsersData.ids, UsersData.loaded, currentPageLeft, currentPageRight, rightPerPage, leftPerPage]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setTotalRight(totalRight.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setTotalLeft(not(totalLeft, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setTotalLeft(totalLeft.concat(rightChecked));
    setRight(not(right, rightChecked));
    setTotalRight(not(totalRight, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const NewsletterToolbar = ({loading, ...props}) => {
    const classes = useStyles();
    return (
      <Box>
        <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
          <MyTooltip title={<div style={{fontSize: '12px'}}>Guardar Cambios</div>} aria-label="Save" arrow>
            <SaveButton redirect={`/Newsletter`} id="GoogleStyleSaveButton">
              {loading && (<CircularProgress/>)}
            </SaveButton>
          </MyTooltip>
        </Toolbar>
      </Box>
    )
  };

  const handleNextPage = (page, type) => {
    if(type === 'left'){
      setCurrentPageLeft(page + 1);
    } else {
      setCurrentPageRight(page + 1);
    }
  }

  const handlePrevPage = (page, type) => {
    if(type === 'left'){
      setCurrentPageLeft(page - 1);
    } else {
      setCurrentPageRight(page - 1);
    }
  }

  const handlePerPageChange = (event, type) => {
    if(type === 'left'){
      setLeftPerPage(event.target.value);
    } else {
      setRightPerPage(event.target.value);
    }
  };

  function searchFunction() {
    var inputLeft, inputRight, filterLeft, filterRight, liLeft, liRight, aLeft, aRight, txtValueLeft, txtValueRight;
    inputLeft = document.getElementById("searchEmail-left");
    filterLeft = inputLeft.value.toUpperCase();
    liLeft = document.querySelectorAll("#listItem-left");
    for (var i = 0; i < liLeft.length; i++) {
        aLeft = liLeft[i].children[1].children[1];
        txtValueLeft = aLeft.textContent || aLeft.innerText;
        if (txtValueLeft.toUpperCase().indexOf(filterLeft) > -1) {
          liLeft[i].style.display = "";
        } else {
          liLeft[i].style.display = "none";
        }
    }
    inputRight = document.getElementById("searchEmail-right");
    filterRight = inputRight.value.toUpperCase();
    liRight = document.querySelectorAll("#listItem-right");
    for (var j = 0; j < liRight.length; j++) {
        aRight = liRight[j].children[1].children[1];
        txtValueRight = aRight.textContent || aRight.innerText;
        if (txtValueRight.toUpperCase().indexOf(filterRight) > -1) {
          liRight[j].style.display = "";
        } else {
          liRight[j].style.display = "none";
        }
    }
  }

  const customList = (title, items, page, type, totalPages, totalItems, perPage) => {
    return (
      <Card>
        <div id="cardHeader">
          <CardHeader
            sx={{ px: 2, py: 1 }}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={numberOfChecked(items) === items.length && items.length !== 0}
                indeterminate={
                  numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{
                  'aria-label': 'Todos seleccionados',
                }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} seleccionados de ${totalItems}`}
          />
          <div id="TextFieldBox">
            <TextField
              label="Buscar"
              id={`searchEmail-${type}`}
              size="small"
              variant='outlined'
              onKeyUp={searchFunction}
            />
          </div>
        </div>
        <Divider />
        <List sx={{width: 200, height: 230, bgcolor: 'background.paper', overflow: 'auto'}}
          dense component="div" role="list" id={`EmailsList-${type}`}
        >
          {items.map((item) => {
            const labelId = `transfer-list-${item.name}-label`;
            return (
              <ListItem
                id={`listItem-${type}`}
                key={items.indexOf(item)}
                role="listitem"
                button
                onClick={handleToggle(item)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <Box id={labelId}>
                    <p>{item.name}</p>
                    <p id={`email-listitem-${type}`}>{item.email}</p>
                </Box>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
        <Box id="pagination">
          <Box id="info">
            <Typography variant="body2">Mostrando </Typography>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={perPage}
              onChange={(e) => handlePerPageChange(e, type)}
              autoWidth
              label=""
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={80}>80</MenuItem>
              <MenuItem value={120}>120</MenuItem>
              <MenuItem value={totalItems}>Todos</MenuItem>
            </Select>
            <Typography variant="body2"> por página</Typography>
          </Box>
          <Box id="prevBlock">
            <IconButton aria-label="previous" size="medium" onClick={() => handlePrevPage(page, type)} disabled={page === 0 ? true : false}>
              <NavigateBeforeRoundedIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Box id="page">
            {page + 1}
          </Box>
          <Box id="nextBlock">
            <IconButton aria-label="next" size="medium" onClick={() => handleNextPage(page, type)} disabled={page === totalPages - 1 ? true : false}>
              <NavigateNextRoundedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </Card>
    )
  };
  if(NewsletterData.data){
    return (
      <Edit {...props} actions={false}>
        <SimpleForm toolbar={<NewsletterToolbar />}>
          <Box style={{ width:'100%', color: '#3d3d3d' }}>
            <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                {NewsletterData.data && NewsletterData.data.title &&
                  <Box p={1} width="100%">
                      <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Editar Correos de la {NewsletterData.data.title}</Typography>
                  </Box>
                }
                <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                        <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                    </MyTooltip>
                </Box>
            </Box>
            <Box>
              <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.TransferList}>
                <Grid item>{customList('Usuarios en Newsletter', left, currentPageLeft, 'left', leftPages, leftTotalItems, leftPerPage)}</Grid>
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      <ArrowForwardRoundedIcon />
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      <ArrowBackRoundedIcon />
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>{customList('Usuarios Disponibles', right, currentPageRight, 'right', rightPages, rightTotalItems, rightPerPage)}</Grid>
              </Grid>
            </Box>
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    formData.emails = totalLeft;
                }}
            </FormDataConsumer>
          </Box>
        </SimpleForm>
      </Edit>
    );
  } else {
    return null
  }
}; export default NewsletterEdit;