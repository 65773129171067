import React from "react";
import { Edit, SimpleForm, TextInput, required, SaveButton, Toolbar, DeleteButton, FormDataConsumer, useGetOne } from "react-admin";
import { makeStyles, Box, Typography, CircularProgress } from "@material-ui/core";
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from "../layout/themes";
import clsx from 'clsx';
import TinyMCETextEditor from '../components/TextEditors/TinyMCETextEditor'

const useStyles = makeStyles({
    RichTextInputBlack: lightTheme.CommonStyles.CreateAndEdit.RichTextInputBlack,
    BlackInput: lightTheme.CommonStyles.CreateAndEdit.BlackInput,
    ToolBar: lightTheme.CommonStyles.CreateAndEdit.ToolBar,
    TooltipTitle: lightTheme.CommonStyles.ListItems.TooltipTitle,
    W100: lightTheme.CommonStyles.ListItems.W100,
    FlexTitleBox: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox,
    FlexTitle: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox.FlexTitle,
    FlexCloseButtonBox: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox.FlexCloseButtonBox,
    FAQInputCard: lightTheme.FAQStyles.CreateAndEdit.FAQInputCard,
});

const FAQEdit = props => {
    const classes = useStyles();
    const {data} = useGetOne('faq', props.id);
    const redirect = () => `/faq`;

    let today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;

    const FAQCreateToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        return (
            <Box>
                <Toolbar {...props} className={clsx(classes.ToolBar, classes.FlexStart100)}>
                    <MyTooltip title={<div className={classes.TooltipTitle}>Guardar Cambios</div>} arrow placement="top">
                        <SaveButton disabled={props.pristine} id="GoogleStyleSaveButton" label="Guardar">
                            {loading && (<CircularProgress/>)}
                        </SaveButton>
                    </MyTooltip>
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Pregunta Frecuente</div>}  arrow placement="right">
                        <DeleteButton redirect={`/faq`} label="" id="GoogleStyleDeleteButton"/>
                    </MyTooltip>
                </Toolbar>
            </Box>
        )
    };

    return (
        <Edit {...props} actions={false}>
            {data &&
                <SimpleForm redirect={redirect} toolbar={<FAQCreateToolbar/>}>
                    <Box className={classes.W100}>
                        <Box className={classes.FlexTitleBox} p={1}>
                            <Box className={classes.FlexTitle} p={1} >
                                <Typography variant="h5">Crear Pregunta Frecuente</Typography>
                            </Box>
                            <Box className={classes.FlexCloseButtonBox} p={1} >
                                <MyTooltip title={<div className={classes.TooltipTitle}>Cerrar</div>} arrow>
                                    <BackButton/>
                                </MyTooltip>
                            </Box>
                        </Box>
                        <Box className={classes.FAQInputCard}>
                            <TextInput label="Título" source="title" variant="outlined" multiline="true" fullWidth className={classes.BlackInput} validate={required()}/>
                        </Box>
                        <Box className={classes.ReactRichTextStyles}>

                            <TinyMCETextEditor data={data.body}/>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => {
                                    formData.body = formData.textValue
                                }}
                            </FormDataConsumer>

                        </Box>
                    </Box>
                </SimpleForm>
            }
        </Edit>
    )
}; export default FAQEdit;