import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import lightTheme from '../layout/themes';
import UserDashboard from './UserDashboard';
import firebase from 'firebase';


const useStyles = makeStyles({
    W100: lightTheme.CommonStyles.ListItems.W100,
    ExpandAdminBox: {
        '& #Header':{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            color: '#196cdb',
            borderRadius: '8px',
            padding: '8px',
            marginBottom: '8px'
        },
        '& #Header:hover':{
            backgroundColor: '#196cdb24',
        },
        '& #Text':{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'default',
            marginRight: '10px',
        },
        '& #Button':{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& button':{
                margin: '0',
                '& svg':{
                    color: '#196cdb',
                }
            }
        }
    },
    WelcomeBox :{
        paddingTop: '1em',
        paddingBottom: '1em',
    },
    WebsTitleBox :{
        paddingTop: '1.5em',
        paddingBottom: '1em',
    },
    BannersBox:{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        '& #BlockBox':{
            height: '280px',
            width: '50%',
            overflow: 'hidden',
            position: 'relative',
            borderRadius: '10px',
            boxShadow: '0px 8px 10px #d9d2d2 !important',
        },
        '& #BlockBox:first-child':{
            marginRight: '10px'
        },
        '& #BlockBox:nth-child(2)':{
            marginRight: '10px'
        },
        '& #Card':{
            alignItems: 'center',
            backgroundColor: 'transparent !important',
            backgroundPosition: 'center',
            backgroundSize: '150%',
            backgroundRepeat: 'no-repeat',
            transition: 'all .5s',
            height: '280px',
            display: 'flex',
            justifyContent: 'center',
            '& a':{
                width: '100%',
                height: '100%',
                position: 'absolute',
                '& span':{
                    display: 'none'
                }
            },
            '& img': {
                paddingBottom: '20px',
                width: '270px',
            }
        },
        '& #Card:hover':{
            backgroundSize: '170%',
        },
    },
    TrendicoBox :{
        padding: '40px',
        paddingTop: '15px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'transparent !important',
        backgroundImage: 'url(/static/images/Dashboard/Dasboard_Banner.webp)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        boxShadow: '0px 8px 10px #d9d2d2 !important',
        borderTopLeftRadius: '0px !important',
        borderTopRightRadius: '0px !important',
        height: '150px',
        '& img': {
            paddingBottom: '20px',
            width: '500px',
        }
    },
    DepartemntsBox :{
        paddingTop: '20px',
        display: 'flex',
        height: '100%',
        marginLeft: '-20px',
    },
});

const Dashboard = () => {
    const classes = useStyles();
    const user = firebase.auth().currentUser;

    return(
        <Box className={classes.W100}>
            <Box className={classes.WelcomeBox}>
                <Typography variant="h5">Bienvenid@ de nuevo {user.displayName}</Typography>
            </Box>
            <UserDashboard classes={classes}/>
        </Box> 
    )
}; export default Dashboard