import {
    Create, SimpleForm, TextInput, SelectInput, ArrayInput, SimpleFormIterator, useTranslate, ReferenceInput, 
    SaveButton, AutocompleteInput, Toolbar, FormDataConsumer, PasswordInput, Notification,
} from "react-admin";
import { makeStyles, Typography, Box, CircularProgress, Divider, Button} from '@material-ui/core/';
import lightTheme from '../layout/themes';
import BackButton from '../components/BackButton';
import MyTooltip from '../components/MuiTooltip';
import PlaceRoundedIcon from '@material-ui/icons/PlaceRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import DomainAddRoundedIcon from '@mui/icons-material/DomainAddRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';

const useStyles = makeStyles({
    InputBlackBorder: lightTheme.InputBlackBorder,
    CardUsersEdit: lightTheme.CardUsersEdit,
    EditToolBar: lightTheme.EditToolBar,
    ShopsList:{
        '& .MuiFormControl-root':{
            '& li':{
                paddingTop: '15px',
                paddingBottom: '15px',
                marginRight: '25px',
                '& p':{
                    display: 'none',
                }
            }
        }
    }
});

const UsersEditToolbar = ({loading, ...props}) => {
    const classes = useStyles();
    return (
      <Box>
        <Toolbar {...props} style={{display: 'flex', justifyContent: 'flex-start'}} className={classes.EditToolBar}>
          <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Usuario</div>} aria-label="Save" arrow>
            <SaveButton disabled={props.pristine} redirect={`/users`} id="GoogleStyleSaveButton">
              {loading && (<CircularProgress/>)}
            </SaveButton>
          </MyTooltip>
        </Toolbar>
      </Box>
    )
};

const DefaultAddButtonSociety = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Tienda</div>} aria-label="Delete" arrow placement="right">
            <Button size="small" {...props} id="GoogleStyleDownload">
                <DomainAddRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
};

const DefaultAddButton = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Tienda</div>} aria-label="Delete" arrow placement="right">
            <Button size="small" {...props} id="GoogleStyleDownload">
                <AddBusinessRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
};

const DefaultRemoveButton = props => {
    const classes = useStyles(props);
    return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Tienda</div>} aria-label="Delete" arrow placement="bottom">
            <Button size="small" {...props} id="GoogleStyleMuiDeleteButton">
                <DeleteRoundedIcon className={classes.leftIcon} />
            </Button>
        </MyTooltip>
    );
};

export const UserCreate = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const redirect = () => `/users`
    const RoleChoices=[ 
        { id: 'Admin', name: 'Admin' }, 
        { id: 'User', name: 'User' }, 
        { id: 'Central', name: 'Central' },
        { id: 'Publicador', name: 'Publicador' }, 
        { id: 'Transporte', name: 'Transporte' }, 
    ] 

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect} toolbar={<UsersEditToolbar/>}>
                <Box style={{ width:'100%', color: '#3d3d3d' }}>
                    <Box display="flex" p={1} style={{ width: '100%' }} justifyContent="flex-start">
                        <Box p={1} width="100%">
                            <Typography variant="h5" style={{marginBottom: '1.5vw', marginLeft: '-5px'}}>Crear Usuario</Typography>
                        </Box>
                        <Box p={1} flexShrink={0} style={{position: 'relative', top: '-15px'}}>
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Cerrar</div>} aria-label="GoBack" arrow>
                                <BackButton style={{paddingLeft: '17px',  paddingRight: '5px'}}></BackButton>
                            </MyTooltip>
                        </Box>
                    </Box>
                    <Box className={classes.ShopsList}>
                        <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, display: 'flex'}}>
                            <InfoRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Información General
                        </Typography>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label={translate(`resources.users.field.displayName`)} variant="outlined" source="displayName" 
                                multiline="true" fullWidth className={classes.InputBlackBorder} />
                            <TextInput label="Código" variant="outlined" source="code" type="text"
                                multiline="true" fullWidth className={classes.InputBlackBorder} />
                        </Box>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput required label={translate(`resources.users.field.email`)} variant="outlined" source="email" type="email"
                                multiline="true" fullWidth className={classes.InputBlackBorder} />
                            <PasswordInput required  label={translate(`resources.users.field.password`)} variant="outlined" source="password" fullWidth className={classes.InputBlackBorder} />
                        </Box>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <SelectInput required label={translate(`resources.users.field.role`)} variant="outlined" source="role" 
                                multiline="true" fullWidth className={classes.InputBlackBorder} choices={RoleChoices}/>
                            <TextInput label={translate(`resources.users.field.phone`)} variant="outlined" source="phone" 
                                multiline="true" fullWidth className={classes.InputBlackBorder} />
                        </Box>
                        <Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px'}} />
                        <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, display: 'flex'}}>
                            <PlaceRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Dirección de Envio
                        </Typography>
                        <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                            <TextInput label={translate(`resources.users.field.address`)} variant="outlined" source="address" 
                                multiline="true" fullWidth className={classes.InputBlackBorder} />
                        </Box>
                        <Box>
                            <Box style={{ width: '100%',display: 'flex' }}>
                                <ReferenceInput source="country" reference="country" filterToQuery={searchText => ({ name: searchText })} 
                                    label={translate(`resources.users.field.country`)} className={classes.InputBlackBorder} variant="outlined" style={{ width: '30%' }}>
                                    <AutocompleteInput optionText="name" />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        return (
                                            <Box style={{marginRight: '20px', width: '30%', marginLeft: '20px' }}>
                                                <ReferenceInput source="state" reference="state" filterToQuery={searchText => ({ name: searchText })} perPage={55}
                                                    label={translate(`resources.users.field.state`)} className={classes.InputBlackBorder} variant="outlined"
                                                    filter={{country: formData.country}}>
                                                    <AutocompleteInput optionText="name"/>
                                                </ReferenceInput>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                                <TextInput label={translate(`resources.users.field.location`)} variant="outlined" source="location" 
                                    multiline="true" fullWidth className={classes.InputBlackBorder} style={{ width: '30%' }}/>
                            </Box>
                        </Box>
                        <Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px'}} />
                        <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, display: 'flex'}}>
                            <DomainRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Detalles de la Sociedad
                        </Typography>
                        <Box style={{marginRight: '20px'}}>
                            <ReferenceInput source="company" reference="company" filterToQuery={searchText => ({ name: searchText })} label="Sociedad Principal"
                                className={classes.InputBlackBorder} variant="outlined">
                                <AutocompleteInput optionText="name" />
                            </ReferenceInput>
                        </Box>
                        <ArrayInput source="companies" label="Sociedades Asociadas" style={{width: '100%', marginLeft: '10px', marginTop: '-10px'}}>
                            <SimpleFormIterator addButton={<DefaultAddButtonSociety />} removeButton={<DefaultRemoveButton />}>
                                <FormDataConsumer>
                                    {({ getSource, scopedFormData, formData, ...rest }) => {
                                        return (
                                            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                                <Box display="flex" style={{ width: '98%' }} justifyContent="flex-start">
                                                    <ReferenceInput label="Sociedades Asociadas" variant="outlined" source={getSource('id')} 
                                                        reference="company" record={scopedFormData} className={classes.InputBlackBorder} filterToQuery={searchText => ({ name: searchText })}>
                                                        <AutocompleteInput optionText="name"/>
                                                    </ReferenceInput>
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <Typography style={{fontSize: '1.0rem', fontWeight: 'bold', margin: 10, marginBottom: 15, marginLeft: '10px', display: 'flex'}}>
                            <StoreRoundedIcon style={{fontSize: '1.5rem', marginRight: '5px'}}/>Tiendas en Propiedad
                        </Typography>
                        <ArrayInput source="shop" label="" style={{width: '100%', marginLeft: '10px', marginTop: '-10px'}}>
                            <SimpleFormIterator addButton={<DefaultAddButton />} removeButton={<DefaultRemoveButton />}>
                                <FormDataConsumer>
                                    {({ getSource, scopedFormData, formData, ...rest }) => {
                                        return (
                                            <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                                <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                                                    <ReferenceInput label={translate(`resources.users.field.shop`)} variant="outlined" source={getSource('id')} 
                                                        reference="shops" record={scopedFormData} className={classes.InputBlackBorder}>
                                                        <SelectInput optionText="name" multiline="true" fullWidth style={{ width: '30%' }}/>
                                                    </ReferenceInput>
                                                    {(formData.company && (formData.companies === undefined)) &&
                                                        <ReferenceInput label={translate(`resources.users.field.shopName`)} variant="outlined" source={getSource('shopName')} 
                                                            reference="pointOfSale" record={scopedFormData} className={classes.InputBlackBorder} 
                                                            filter={{company_id: formData.company}}
                                                            sort={{field: 'name', order: 'ASC'}}
                                                            perPage={500}
                                                            filterToQuery={searchText => ({ name: searchText })}>
                                                            <AutocompleteInput optionText="name" multiline="true" fullWidth style={{ width: '70%' }}/>
                                                        </ReferenceInput>
                                                    }
                                                    {(formData.company && formData.companies && formData.companies[0]) &&
                                                        <ReferenceInput label={translate(`resources.users.field.shopName`)} variant="outlined" source={getSource('shopName')} 
                                                            reference="pointOfSale" record={scopedFormData} className={classes.InputBlackBorder} 
                                                            filter={{company_id_gte: formData.company, company_id_lte: formData.companies[0].id}}
                                                            sort={{field: 'name', order: 'ASC'}}
                                                            perPage={500}
                                                            filterToQuery={searchText => ({ name: searchText })}>
                                                            <AutocompleteInput optionText="name" multiline="true" fullWidth style={{ width: '70%' }}/>
                                                        </ReferenceInput>
                                                    }
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>

                        {/*<Divider variant="middle" style={{marginLeft: '10px', marginBottom: '15px'}} /> */}

                        {/* <ImageInput source="avatar" label="Imagen de Perfil" accept="image/*" mulitple={false}>
                                <ImageField source="src" title="title" />
                        </ImageInput> */}
                        
                        {/* <CardActions>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    startIcon={<PersonAddRoundedIcon />}
                                    className={classes.generateButton}
                                >
                                    CREAR USUARIO
                                </Button>
                        </CardActions> */}
                    </Box>
                </Box>
                <Notification />
            </SimpleForm>        
        </Create>
    );


    // return (
    //     <Create {...props}>
    //         <SimpleForm>
    //             <TextInput source="name" />
    //             <TextInput source="rol" />
    //             <ImageInput source="avatar" label="Imagen de Perfil" accept="image/*" mulitple={false}>
    //                 <ImageField source="src" title="title" />
    //             </ImageInput>
    //         </SimpleForm>
    //     </Create>

    // );
};

export default UserCreate;