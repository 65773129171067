import React from 'react';
import { 
    Show, TextField , TopToolbar, EditButton, useGetOne, CloneButton,
    ReferenceField, DeleteButton, ChipField, LinearProgress 
} from 'react-admin';
import { makeStyles, Typography, Box } from '@material-ui/core';
import ToolbarBackButton from '../components/ToolbarBackButton';
import MyTooltip from "../components/MuiTooltip";
import FilterNoneRoundedIcon from '@material-ui/icons/FilterNoneRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import lightTheme from '../layout/themes';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';

const useStyles = makeStyles({
    AsideProviderAddress: lightTheme.AsideProviderAddress,
    PostViewTypography: lightTheme.PostViewTypography,
    Shop_Image: lightTheme.PointOfSaleStyle.View.Info_Card.Shop_Image,
    LogoBox_FoM: lightTheme.PointOfSaleStyle.View.Info_Card.LogoBox_FoM,
    LogoBox_Atleet: lightTheme.PointOfSaleStyle.View.Info_Card.LogoBox_Atleet,
    LogoBox_Sotkatt: lightTheme.PointOfSaleStyle.View.Info_Card.LogoBox_Sotkatt,
    LogoBox_Trendico: lightTheme.PointOfSaleStyle.View.Info_Card.LogoBox_Trendico,
    LogoBox: lightTheme.PointOfSaleStyle.View.LogoBox,
    InfoBox: lightTheme.PointOfSaleStyle.View.InfoBox,
    ContactBox: lightTheme.PointOfSaleStyle.View.ContactBox,
    Aside: lightTheme.PointOfSaleStyle.View.Aside,
    TimeLine: lightTheme.PointOfSaleStyle.View.TimeLine,
    DirectionCard: lightTheme.PointOfSaleStyle.View.DirectionCard,
    Contact: lightTheme.PointOfSaleStyle.View.Contact,
    MapsButton: lightTheme.PointOfSaleStyle.View.MapsButton,
    AtleetChip: lightTheme.PointOfSaleStyle.View.AtleetChip,
    FoMChip: lightTheme.PointOfSaleStyle.View.FoMChip,
    DarkChip: lightTheme.PointOfSaleStyle.View.DarkChip,
});

const StateName = ({ id }) => {
    const { data, loading, error } = useGetOne('state', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (data.name);
};

const CountryName = ({ id }) => {
    const { data, loading, error } = useGetOne('country', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (data.name);
};

const PointsView = ({ permissions, ...props }) => {
    const { data } = useGetOne('pointOfSale', props.id);
    const DateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const classes = useStyles();

    const Aside = () => {
        const classes = useStyles();
        return(
            <div>
                {data &&
                    <div>
                        <div className={classes.TimeLine}>
                            <Timeline>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <AccessTimeRoundedIcon style={{color: '#cacaca', padding: '10px'}}/>
                                        <TimelineConnector style={{width: '1px'}}/>
                                    </TimelineSeparator>
                                    <TimelineContent style={{marginBottom: '20px'}}>
                                        <span style={{fontSize: '1.1rem', color: '#000'}}>{new Date(data.createdate).toLocaleDateString("es-ES", DateOptions)}</span><br/>
                                        <span style={{color: '#196cdb'}}>{data.createdby}</span><br/>
                                        <span style={{color: 'rgb(102, 102, 102)'}}>Tienda creada</span>
                                    </TimelineContent>
                                </TimelineItem>
                                {data.lastupdate &&
                                    <TimelineItem>
                                        <TimelineSeparator>
                                            <AccessTimeRoundedIcon style={{color: '#cacaca', padding: '10px'}}/>
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <span style={{fontSize: '1.1rem', color: '#000'}}>{new Date(data.lastupdate).toLocaleDateString("es-ES", DateOptions)}</span><br/>
                                            <span style={{color: '#196cdb'}}>{data.updatedby}</span><br/>
                                            <span style={{color: 'rgb(102, 102, 102)'}}>Tienda actualizada</span>
                                        </TimelineContent>
                                    </TimelineItem>
                                }
                            </Timeline>
                        </div>
                    </div>
                }
            </div>
        )
    };

    const PointsViewActions = ({ basePath, resource }) => {
        return(
            <TopToolbar style={{paddingBottom: '2px', marginTop: '22px'}} id="GoogleStyleToolBar">
                <Box display="flex" style={{ width: '100%' }} justifyContent="flex-start">
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Volver Atrás</div>} arrow>
                        <ToolbarBackButton style={{paddingLeft: '17px', paddingRight: '5px', marginRight: '10px'}} id="GoogleStyleBackButton"></ToolbarBackButton>
                    </MyTooltip>
                    {permissions === "Admin" &&
                        <Box display="flex" style={{ width: '100%', marginRight: '20px' }} justifyContent="flex-start">
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Duplicar Tienda</div>} arrow>
                                <CloneButton label="" icon={<FilterNoneRoundedIcon /> } basePath={basePath} record={data} resource={resource} id="GoogleStyleButton"/>
                            </MyTooltip>
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Tienda</div>} arrow>
                                <EditButton label="" type="link" to={`/pointOfSale/${props.id}/edit`} id="GoogleStyleButton"/>
                            </MyTooltip>
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Tienda</div>} arrow>
                                <DeleteButton label="" redirect={`/pointOfSale`} record={data} resource={resource} id="GoogleStyleDeleteButton"/>
                            </MyTooltip>
                        </Box>
                    }
                </Box>
            </TopToolbar>
        )
    };

    return (
        <Box>
            <Show {...props} actions={<PointsViewActions />} aside={<Aside />} style={{marginTop: '-20px'}} >
                <Box>
                    {data &&
                        <Box>
                            <Box style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                                {data.shop === '618bd896efea2b2049ada487' && 
                                    <div className={classes.LogoBox_FoM} id="ShopLogo">
                                        <Box className={classes.Shop_Image}>
                                            <img src="../../static/images/Shops/FoMFavicon.png" alt="FoM" id="FoM"></img>
                                        </Box>
                                    </div>
                                }
                                {data.shop === '618bd896efea2b2049ada488' && 
                                    <div className={classes.LogoBox_Atleet} id="ShopLogo">
                                        <Box className={classes.Shop_Image}>
                                            <img src="../../static/images/Shops/AtleetFavicon.png" alt="Atleet" id="Atleet"></img>
                                        </Box>
                                    </div>
                                }
                                {data.shop === '618bd896efea2b2049ada489' && 
                                    <div className={classes.LogoBox_Sotkatt} id="ShopLogo">
                                        <Box className={classes.Shop_Image}>
                                            <img src="../../static/images/Shops/SotkattFavicon.png" alt="Sotkatt" id="Sotkatt"></img>
                                        </Box>
                                    </div>
                                }
                                {data.shop === '618bd896efea2b2049ada486' && 
                                    <div className={classes.LogoBox_Trendico} id="ShopLogo">
                                        <Box className={classes.Shop_Image}>
                                            <img src="../../static/images/Shops/TrendicoFavicon.png" alt="Trendico" id="Trendico"></img>
                                        </Box>
                                    </div>
                                }
                                <Box className={classes.InfoBox}>
                                    <TextField source="name" style={{fontSize: '1.5rem'}}/>
                                    <div><span style={{fontSize: '0.8rem', color: 'rgb(117, 117, 117)'}}>IVA: </span><TextField source="iva" style={{fontSize: '1.0rem'}}/></div>
                                    <div><span style={{fontSize: '0.8rem', color: 'rgb(117, 117, 117)'}}>Nº GLN: </span><TextField source="gln" style={{fontSize: '1.0rem'}}/></div>
                                    <Box style={{display: 'flex', width: '100%', marginTop: '5px'}}>
                                        <ReferenceField label="" source="company_id" reference="company" link="show">
                                            <ChipField label="" source="name" variant="outlined"/>
                                        </ReferenceField>
                                        {data.shop === '618bd896efea2b2049ada488' && 
                                            <Box className={classes.AtleetChip}>
                                                <ReferenceField label="" source="shop" reference="shops" link="show">
                                                    <ChipField label="" source="name"/>
                                                </ReferenceField>
                                            </Box>
                                        }
                                        {data.shop === '618bd896efea2b2049ada487' && 
                                            <Box className={classes.FoMChip}>
                                                <ReferenceField label="" source="shop" reference="shops" link="show">
                                                    <ChipField label="" source="name"/>
                                                </ReferenceField>
                                            </Box>
                                        }
                                        {data.shop === '618bd896efea2b2049ada489' && 
                                            <Box className={classes.DarkChip}>
                                                <ReferenceField label="" source="shop" reference="shops" link="show">
                                                    <ChipField label="" source="name"/>
                                                </ReferenceField>
                                            </Box>
                                        }
                                        {data.shop === '618bd896efea2b2049ada486' && 
                                            <Box className={classes.DarkChip}>
                                                <ReferenceField label="" source="shop" reference="shops" link="show">
                                                    <ChipField label="" source="name"/>
                                                </ReferenceField>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                                <Box className={classes.DirectionCard}>
                                    <Typography style={{fontSize: '1.5rem', marginBottom: '10px'}}>
                                        <RoomOutlinedIcon style={{marginBottom: '-3px'}}/>&nbsp;Dirección de la Tienda
                                    </Typography>
                                    <Box style={{marginLeft: '5px', width: '95%'}}>
                                        <Typography style={{fontSize: '1.1rem'}}>
                                            {data.address}
                                        </Typography>
                                        <Typography style={{fontSize: '1.1rem'}}>
                                            {data.location},&nbsp;
                                            <StateName id={data.state} style={{fontSize: '1.0rem'}}/>,&nbsp;
                                            <CountryName id={data.country} style={{fontSize: '1.0rem'}}/>
                                        </Typography>
                                    </Box>
                                    <Box style={{marginTop: '60px'}}>
                                        <Typography style={{color: '#196cdb'}}>Código de la Tienda:&nbsp;<span style={{color: '#3d3d3d'}}>{data.code}</span></Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.ContactBox}>
                                    {data.contact && data.contact.map(Contact => (
                                        <div className={classes.Contact}>
                                            <Box>
                                                <Typography style={{fontSize: '1.5rem'}}>
                                                    <PermContactCalendarOutlinedIcon style={{marginBottom: '-3px'}}/>&nbsp;{Contact.contactName}
                                                </Typography>
                                                <Typography style={{fontSize: '1.1rem', display: 'flex', width: '100%'}}>
                                                    <Box><a href={`mailto:${Contact.email}`}><MailOutlineRoundedIcon id="mail"/>&nbsp;{Contact.email}</a></Box>
                                                </Typography>
                                                <Typography style={{fontSize: '1.0rem'}}>
                                                    <LocalPhoneRoundedIcon id="phone"/>{Contact.phone}
                                                </Typography>
                                            </Box>
                                        </div>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    }
                </Box>
            </Show>
        </Box>
    )
}; export default PointsView;