import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useRedirect, useGetList, useAuthProvider, useGetOne } from "react-admin";
import { makeStyles, Typography, Card, Grid, Box, CardContent, Button, LinearProgress, CardMedia } from '@material-ui/core';
import MyTooltip from '../components/MuiTooltip';
import inflection from 'inflection';
import lightTheme from '../layout/themes';
import firebase from 'firebase';
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';


const useStyles = makeStyles({
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  InLineViewOffer: lightTheme.InLineViewOffer,
  title: lightTheme.OffersAndPromotionsTitle,
  body: lightTheme.OffersAndPromotionsBody,
  offerImage: lightTheme.OffersAndPromotionsOfferImage,
  btnContainer: lightTheme.OffersAndPromotionsBtnContainer,
  btnArchivos: lightTheme.OffersAndPromotionsBtnArchivos,
  btnSolicitar: lightTheme.OffersAndPromotionsBtnSolicitar,
  OrdersTable: lightTheme.OrdersTable,
  root: {flexGrow: 1},
  RightSerchBar: lightTheme.FilesRightSerchBar,
  Searchroot: lightTheme.Filesroot,
  RightSerchMargin: lightTheme.FilesRightSerchMargin,
  card: lightTheme.ProviderGridCard,
  EmptyBasketMediaCard: lightTheme.EmptyBasketMediaCard,
  EmptyBubble: lightTheme.EmptyBubble,
  EstateChipBlue:{
    color: '#196cdb !important',
    backgroundColor: '#196cdb24 !important',
    border: '1px solid #196cdb',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '15px',
    paddingLeft: '15px',
    borderRadius: '25px',
    marginTop: '10px',
    width: '60%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
  },
  EstateChipGreen:{
    color: 'rgba(40, 121, 18, 0.87) !important',
    backgroundColor: 'rgba(63, 225, 17, 0.23) !important',
    border: '1px solid rgba(40, 121, 18, 0.87)',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '15px',
    paddingLeft: '15px',
    borderRadius: '25px',
    marginTop: '10px',
    width: '60%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
  },
  EstateChipRed:{
    color: 'rgba(121, 18, 18, 0.87) !important',
    backgroundColor: 'rgba(225, 17, 17, 0.23) !important',
    border: '1px solid rgba(121, 18, 18, 0.87)',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '15px',
    paddingLeft: '15px',
    borderRadius: '25px',
    marginTop: '10px',
    width: '60%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
  },
  EstateChipYellow:{
    color: 'rgba(138, 142, 17, 0.87) !important',
    backgroundColor: 'rgba(248, 255, 0, 0.3) !important',
    border: '1px solid rgba(138, 142, 17, 0.87)',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '15px',
    paddingLeft: '15px',
    borderRadius: '25px',
    marginTop: '10px',
    width: '60%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
  },
  EstateChip:{
    color: '#757575 !important',
    backgroundColor: '#7575751c !important',
    border: '1px solid #757575',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '15px',
    paddingLeft: '15px',
    borderRadius: '25px',
    marginTop: '10px',
    width: '85%',
    textTransform: 'capitalize !important',
    display: 'flex',
    justifyContent: 'center',
  },
  HeaderText:{
    fontSize: '15px !important',
    textTransform: 'uppercase !important',
    color: '#5e5e5e',
    fontWeight: 'bold',
  },
  HeaderItems:{
    fontSize: '15px !important',
    textTransform: 'uppercase !important',
    color: '#fff',
    backgroundColor: '#dc004e',
    borderRadius: '50%',
    width: '22.5px',
    display: 'block',
  },
  InfoText:{
    fontSize: '15px !important',
    textTransform: 'uppercase !important',
    color: '#5e5e5e',
    marginLeft: '15px'
  },
  InvoiceButton:{
    '& .MuiButtonBase-root':{
      border: '1px solid #196cdb',
      color: '#196cdb',
      borderRadius: '4px !important',
      textTransform: 'capitalize',
      padding: '4px 8px !important',
    },
    '& .MuiButtonBase-root:hover':{
      backgroundColor: '#196cdb21',
      boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30) ,0px 2px 6px 2px rgba(66,66,66,.15) !important'
    },
  },
  SeeAllLink:{
    borderTop: '1px solid rgba(176, 176, 176, 0.47)',
    paddingBottom: '16px !important',
    '& .MuiBox-root': {
      marginLeft: '10px', 
    },
    '& a':{
      color: '#196cdb !important',
    },
    '& a:hover':{
      textDecoration: 'underline !important'
    }
  }
});

const OrderAddressName = ({ id }) => {
  const { data, loading } = useGetOne('address', id);
  if (loading) { return <LinearProgress /> }
  if (data) { return (
    <span>{data.name}</span>
  )};
};

const StateName = ({ id }) => {
  const result = id
  const { data } = useGetOne('state', result);
  if (data) { return (data.name)};
};

const CountryName = ({ id }) => {
  const result = id
  const { data } = useGetOne('country', result);
  if (data) { return (data.name)};
};

const OrderAddress = ({ id }) => {
  const result = id
  const { data } = useGetOne('address', result);
  if (data) { return (
    <h3><h3>{data.user_address}</h3>
    <h3>{data.location} {data.CP}</h3>
    <h3><StateName id={data.state}/>, <CountryName id={data.country}/></h3></h3>
    )
  };
};


const OrdersList = props => {
  const redirect = useRedirect();
  const user = firebase.auth().currentUser;
  const auth = useAuthProvider();
  const [userClaims, setUserClaims] = useState();
  auth.getUserData().then(userData => {
    if (!userClaims) {
      setUserClaims(userData);
    }
  });

  const ProductsList = ({ claims }) => {
    const result = claims.products.map(id => (id));
    const { data, loading, error } = useGetOne('material', result[0].material_id);
    const VariantData = useGetOne('variants', result[0].variant_id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data && VariantData) {
      let v_data = VariantData.data
      return(
        <Box style={{display: 'flex', width: '100%'}}>
          <CardMedia image={data.logo.image} style={{backgroundPosition: 'center', backgroundSize: 'contain', width: '120px', height: '120px'}} />
          <Box style={{marginTop: '30px', marginLeft: '10px'}}>
            <span style={{textTransform: 'uppercase'}}>{data.title}&nbsp;({v_data.name})</span><br/>
            <span style={{color: '#b63615'}}>{parseInt(v_data.price).toFixed(2).replace('.', ',')} €</span>&nbsp;x&nbsp;{result[0].cantidad}
          </Box>
        </Box>
      )
    }
  }

  const OrdersGrid = ()  => {
    const classes = useStyles();
    const { data, ids } = useGetList('orders', { page: 1, perPage: 50 }, { field: 'createdate', order: 'DESC' }, {user_id: user.uid});
    return ids.length > 0 ? (
      <Grid container spacing={5}>
        {ids.map(id => (
          <Grid key={id} xs={12} sm={12} md={12} lg={12} xl={12} item >
            <Card className={classes.card} style={{height: '100%'}}>
                <CardContent style={{borderBottom: '1px solid rgba(176, 176, 176, 0.47)'}} >
                  <Box style={{display: 'flex', width: '100%', marginBottom: '15px'}}>
                    <Box style={{display: 'flex', width: '100%', justifyContent: 'flex-start'}}>
                      <Typography variant="body1" align="left" style={{ fontSize: '13px', textTransform: 'uppercase', textDecoratiom: 'none', marginRight: '10vh'}}>
                        PEDIDO REALIZADO<br/>
                        <span className={classes.HeaderText}>{data[id].date}</span>
                      </Typography>
                      <Typography variant="body1" align="left" style={{ fontSize: '13px', textTransform: 'uppercase', textDecoratiom: 'none', marginRight: '10vh'}}>
                        TOTAL<br/>
                        <span className={classes.HeaderText}>{data[id].total_price.toFixed(2).replace('.', ',')} €</span>
                      </Typography>
                      <Typography variant="body1" align="left" style={{ fontSize: '13px', textTransform: 'uppercase', textDecoratiom: 'none', marginRight: '10vh'}}>
                        ENVIAR A<br/>
                        <MyTooltip title={
                            <React.Fragment>
                              <OrderAddress id={data[id].user_address}/>
                            </React.Fragment>
                          } aria-label="OrderUserInfo" arrow>
                          <span className={classes.HeaderUser}><OrderAddressName id={data[id].user_address}/></span>
                        </MyTooltip>
                      </Typography>
                      <Typography variant="body1" align="center" style={{ fontSize: '13px', textTransform: 'uppercase', textDecoratiom: 'none', marginRight: '10vh'}}>
                        Nº Productos<br/>
                        <Box style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                          <span className={classes.HeaderItems}>{data[id].total_items}</span>
                        </Box>
                      </Typography>
                    </Box>
                    <Box style={{display: 'flex', width: '50%', justifyContent: 'flex-end'}}>
                      <Typography variant="body1" align="right" style={{ fontSize: '13px', textTransform: 'uppercase', textDecoratiom: 'none', marginLeft: '10vh'}}>
                        Pedido<br/>
                        <span className={classes.HeaderText}>{data[id].tocken}</span>
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
                <CardContent>
                  <Box style={{display: 'flex', width: '100%', marginBottom: '15px'}}>
                    <Box style={{marginLeft: '10px'}}>
                      <Typography variant="body1" align="left" style={{ fontSize: '13px', textTransform: 'uppercase', textDecoratiom: 'none', textAlign: 'center'}}>
                        {!data[id].state.includes('Sin Confirmar') &&
                          <Box  className={data[id].state.includes('Aceptado') ? classes.EstateChipBlue : 
                              (data[id].state.includes('Entregado') ? classes.EstateChipGreen : 
                              (data[id].state.includes('Enviado') ? classes.EstateChipGreen: 
                              (data[id].state.includes('Cancelado') ? classes.EstateChipRed: classes.EstateChip )))}>
                            {inflection.humanize(data[id].state)}
                          </Box>
                        } {data[id].state.includes('Sin Confirmar') &&
                          <Box className={classes.EstateChip}>
                            Pendiente
                          </Box>
                        }
                      </Typography>
                    </Box>
                    <Box style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                      <div className={classes.InvoiceButton}>
                        <MyTooltip title={<div style={{fontSize: '12px'}}>Ver Pedido</div>} aria-label="Invoice" arrow>
                          <Button onClick={() => { redirect(`/orders/${data[id].id}/show`); }} startIcon={<DescriptionRoundedIcon/>}>
                              Ver Pedido
                          </Button>
                        </MyTooltip>
                      </div>
                    </Box>
                  </Box>
                  <div>{data[id] && <ProductsList claims={data[id]}/>}</div>
                </CardContent>
                <CardContent className={classes.SeeAllLink}>
                  <Box>
                    <span style={{fontSize: '14px'}}>Mostrando 1 de {data[id].total_items} Productos.
                      {data[id].total_items > 1 &&
                        <Link to={`/orders/${data[id].id}/show`}>Ver Todo</Link>
                      }
                    </span>
                  </Box>
                </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    ) : (
      <Box display="flex" justifyContent="center" style={{width: '100%', marginTop: '30px'}}>
        <Box textAlign="center" className={classes.EmptyBubble}>
          <CardMedia className={classes.EmptyBasketMediaCard}></CardMedia>
          <Typography variant="h6" style={{marginTop: '20px'}}>
            Sin Pedidos Todavía
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <OrdersGrid/>
  )
};

export default OrdersList;