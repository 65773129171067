import { 
    Datagrid, List, TextField, useTranslate, useDeleteMany, useRefresh, useNotify, TopToolbar,
    useRedirect, useUnselectAll, BulkDeleteButton, useListContext, DeleteButton, EditButton, ReferenceField, usePermissions, useGetList } 
from "react-admin";
import { Box, TablePagination, Typography, makeStyles, ThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
import MyTooltip from '../components/MuiTooltip';
import Fab from '@mui/material/Fab';
import lightTheme from '../layout/themes';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import * as locales from '@material-ui/core/locale';


const useStyles = makeStyles((theme) => ({
    PostTitle: lightTheme.PostTitle,
    PostSubTitle: lightTheme.PostSubTitle,
    UsersTable: lightTheme.UsersTable,
    RightSerchBar: {
        '& .MuiToolbar-root': {
        '& .MuiToolbar-gutters':{
            paddingTop: '21px',
        },
        '& form': {
            width: '100% !important',
            justifyContent: 'right !important',
            position: 'absolute',
            right: '130px',
        },
        },
        '& .list-page':{
            marginTop: '-45px',
            marginBottom: '5em'
        },
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#F44336',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#F44336A6 !important',
            color: '#fff !important',
        },
        marginBottom: '20px',
    },
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: '#3d3d3d',
            },
            '&:hover fieldset': {
            borderColor: '#3d3d3d',
            },
            '&.Mui-focused fieldset': {
            borderColor: '#3d3d3d',
            },
        },
    },
}));

const BulkDeleteUsersButton = ({ selectedIds }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const [deleteMany, { loading, error }]= useDeleteMany(
        'users',
        selectedIds,
        {
            onSuccess: () => {
                refresh();
                notify('Usuarios eliminados');
                unselectAll('users');
            },
            onFailure: error => notify('Error: Usuarios no eliminados', 'warning'),
        }
    );

    if (error) { return <p>ERROR</p>; }
    else { return (
        <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Seleccionados</div>} aria-label="Delete" arrow placement="left">
            <BulkDeleteButton disabled={loading} onClick={deleteMany} id="GoogleStyleBulkButton" label=""/>
        </MyTooltip>
    )}

};

/*const UserFilter = props => {
    const classes = useStyles();
    return (
        <Filter {...props}>
            <SearchInput source="displayName" alwaysOn variant="outlined" className={classes.root}/>
        </Filter>
    )
};*/

const PostEditActions = () => {
    const redirect = useRedirect();
    const { permissions } = usePermissions();
    return(
        <TopToolbar>
            <Box style={{position: 'fixed', bottom: '30px', right: '30px', zIndex: '999'}}>
                {permissions === 'Admin' && 
                    <MyTooltip title={<div style={{fontSize: '12px'}}>Crear Usuario</div>} arrow placement="left">
                        <Fab color="primary" aria-label="add" onClick={() => { redirect(`/users/create`); }}>
                            <PersonAddRoundedIcon />
                        </Fab>
                    </MyTooltip>
                }
            </Box>
        </TopToolbar>
    )
};

const UsersPagination = () => {
    const { page, perPage, total, setPage, setPerPage } = useListContext();
    const handleChangeRowsPerPage = (event) => { 
        setPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
    };
    
    return (
        <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
            {total &&
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[8, 10, 15, 20, 50, total]}
                    count={total || 0}
                    page={page - 1}
                    onChangePage ={handleChangePage}
                    rowsPerPage={perPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            }
        </ThemeProvider> 
    );
}

export const UserList = (({ ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const { permissions } = usePermissions();
    const { data } = useGetList('users', { page: 1, perPage: 999 }, { field: 'displayName', order: 'DESC' });
    
    if(data){
        var displayNameColumn = document.querySelectorAll('[title="displayName"]');
        var idColumn = document.querySelectorAll('[title="id"]');

        let displayNameColumnID;
        for (var i = 0; i < displayNameColumn.length; i++){
            displayNameColumnID = idColumn[i].textContent;
            displayNameColumn[i].id = displayNameColumnID;
            if(data[displayNameColumnID]){
                displayNameColumn[i].setAttribute('data-tooltip', `${data[displayNameColumnID].blocked}`)
            }
        }

        return (
            <Box className={classes.RightSerchBar}>
                <Box display="flex" style={{ width: '100%', position: 'relative', top: '8px'}} justifyContent="flex-start">
                    <Typography className={classes.PostTitle}>Usuarios Tréndico</Typography>
                    <Typography className={classes.PostSubTitle}>&nbsp;Gestiona, Edita y Consulta</Typography>
                </Box>
                <List {...props} actions={<PostEditActions />} bulkActionButtons={<BulkDeleteUsersButton />} /*filters={<UserFilter />} */
                    pagination={<UsersPagination />} perPage={8} sort={{ field: 'code', order: 'ASC' }}>
                    <Datagrid className={classes.UsersTable}>
                        <TextField label="id" source="id" title="id"/>
                        <TextField label={translate(`resources.users.field.displayName`)} source="displayName" id="DisplayName" title="displayName"/>
                        <TextField label={translate(`resources.users.field.email`)} source="email" />
                        <TextField label={translate(`resources.users.field.phone`)} source="phone" />
                        <ReferenceField label="Provincia" source="state" reference="state">
                            <TextField source="name" />
                        </ReferenceField>
                        <TextField label={translate(`resources.users.field.role`)} source="role" />
                        {/* <ImageField source="avatar.src" title="title" /> */}
                        {permissions === 'Admin' &&
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Editar</div>} aria-label="edit" arrow placement="bottom">
                                <EditButton basePath="/users" label="" record={props} id="GoogleStyleButton"/>
                            </MyTooltip>
                        }
                        {permissions === 'Admin' &&
                            <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar</div>} aria-label="delete" arrow placement="bottom">
                                <DeleteButton basePath="/users" label="" record={props} id="GoogleStyleDeleteButton"/>
                            </MyTooltip>
                        }
                    </Datagrid>
                </List>
            </Box>
        );
    }
});

export default UserList;