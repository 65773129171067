import CampaignsIcon from '@material-ui/icons/NotificationsRounded';
import CampaignsList from './CampaignsList';
import CampaignsCreate from './CampaignsCreate';
import CampaignsEdit from './CampaignsEdit';

const CampaignsModule = {
    list: CampaignsList,
    edit: CampaignsEdit,
    create: CampaignsCreate,
    icon: CampaignsIcon,
}; export default CampaignsModule;