import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import moment from 'moment';
import { useLogout } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
    alertWarning: {
        position: 'absolute',
        top: '60px',
        zIndex: '100',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'end',
        right: '30px',
        width: '100%',
        '& .MuiAlert-root':{
            border: '1px solid #ffbd5f',
        }
    }
});
  
const SessionTimeout = (authentified) => {
    const [events] = useState(['click', 'load', 'scroll']);
    const [second, setSecond] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const classes = useStyles();
    const logout = useLogout();

    let warningInactiveInterval = useRef();
    let startTimerInterval = useRef();

    // warning timer
    const warningInactive = useCallback((timeString) => {
        clearTimeout(startTimerInterval.current);
        warningInactiveInterval.current = setInterval(() => {
        const maxTime = 2;
        const popTime = 1;

        const diff = moment.duration(moment().diff(moment(timeString)));
        const minPast = diff.minutes();
        const leftSecond = 60 - diff.seconds();

        if (minPast === popTime) {
            setSecond(leftSecond);
            setOpen(true);
        }

        if (minPast === maxTime) {
            clearInterval(warningInactiveInterval.current);
            setOpen(false);
            sessionStorage.removeItem('lastTimeStamp');
            logout();
        }
        }, 1000);
    }, [logout]);

    // start inactive check
    const timeChecker = useCallback(() => {
        startTimerInterval.current = setTimeout(() => {
            let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
            warningInactive(storedTimeStamp);
        }, 3600000);
    }, [warningInactive])

    // reset interval timer
    let resetTimer = useCallback(() => {
        clearTimeout(startTimerInterval.current);
        clearInterval(warningInactiveInterval.current);
        let timeStamp;
        if (authentified) {
        timeStamp = moment();
        sessionStorage.setItem('lastTimeStamp', timeStamp);
        } else {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem('lastTimeStamp');
        }
        timeChecker();
        setOpen(false);
    }, [authentified, timeChecker]);

    // handle close popup
    const handleClose = () => {
        setOpen(false);
        resetTimer();
    };

    useEffect(() => {
        events.forEach((event) => {
        window.addEventListener(event, resetTimer);
        });

        timeChecker();

        return () => {
        clearTimeout(startTimerInterval.current);
        //resetTimer();
        };
    }, [resetTimer, events, timeChecker]);
    
    if (!isOpen) {
        return null;
    }

    // change fragment to modal and handleclose func to close
    return (
        <Box className={classes.alertWarning}>
            <Collapse in={isOpen}>
                <Alert
                severity="warning"
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        handleClose();
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2 }}
                >
                ¡ Tu sesión está a punto de caducar ! La sesión se cerrá en {second} segundos
                </Alert>
            </Collapse>
        </Box>
    );
};

export default SessionTimeout;