import * as React from 'react';
import { usePermissions, useGetList, useRefresh, useDelete, List, useRedirect, TopToolbar } from "react-admin";
import { makeStyles, Typography, Button, Box } from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Fab from '@mui/material/Fab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from '../layout/themes';

const useStyles = makeStyles({
    TopTitleBox: lightTheme.CommonStyles.ListItems.TopTitleBox,
    TopTitle: lightTheme.CommonStyles.ListItems.TopTitle,
    TopSubTitle: lightTheme.CommonStyles.ListItems.TopSubTitle,
    title: lightTheme.OffersAndPromotionsTitle,
    body: lightTheme.OffersAndPromotionsBody,
    AcordionBox: {
        borderRadius: '10px',
        '& .MuiAccordion-rounded':{
            borderRadius: '0px !important'
        },
        '& .css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type':{
            borderTopLeftRadius: '10px !important',
            borderTopRightRadius: '10px !important',
        },
        '& .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type':{
            borderBottomLeftRadius: '10px !important',
            borderBottomRightRadius: '10px !important',
        },
        '& .css-1elwnq4-MuiPaper-root-MuiAccordion-root':{
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important',
        },
    },
    AcordionText:{
        '& #ButtonBox':{
            '& a':{
                paddingLeft: '21px !important',
                marginLeft: '20px !important',
            },
            '& button':{
                paddingLeft: '21px !important',
                marginLeft: '20px !important',
            }
        }
    },
    RightSerchBar: {
        '& .MuiToolbar-root': {
          display: 'flex',
          width: '100%',
          '& .MuiToolbar-gutters':{
            paddingTop: '21px',
            width: 'auto',
            '& a':{
              marginLeft: '0px !important',
              '& span':{
                width: '110px !important',
              }
            }
          },
          '& form': {
            width: '100%',
            justifyContent: 'right',
          },
        },
        '& .list-page':{
          marginTop: '-45px'
        },
        '& .MuiCard-root':{
            backgroundColor: 'transparent !important',
            boxShadow: '0px 0px 0px transparent !important',
        }
    },
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            borderColor: '#3d3d3d',
            },
            '&:hover fieldset': {
            borderColor: '#3d3d3d',
            },
            '&.Mui-focused fieldset': {
            borderColor: '#3d3d3d',
            },
        },
        '& .RaBulkActionsToolbar-toolbar': {
            '& h6': {
                color: '#3d3d3d',
            },
        },
    },
});

/*const FAQFilter = props => {
    const classes = useStyles();
    return (
      <Filter {...props}>
        <SearchInput source="title" alwaysOn variant="outlined" className={classes.root}/>
      </Filter>
    )
};*/
  
const FAQEditActions = () => {
    const {permissions} = usePermissions();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        {permissions === "Admin" &&
            <Box style={{position: 'fixed', bottom: '40px', right: '30px', zIndex: '999'}}>
                <MyTooltip title={<div style={{fontSize: '12px'}}>Añadir Pregunta Frecuente</div>} arrow placement="left">
                    <Fab color="primary" aria-label="add" onClick={() => { redirect(`/faq/create`); }}>
                        <AddRoundedIcon />
                    </Fab>
                </MyTooltip>
            </Box>
        }
      </TopToolbar>
    )
};

const FAQList = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();

    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const { data, ids } = useGetList('faq');
    const [deleteOne] = useDelete();
    const refresh = useRefresh();
    const handleClick = (id) => {
        deleteOne('faq', id);
        refresh();
    }
    const redirect = useRedirect();

    return (
        <Box className={classes.RightSerchBar}>
            <Box className={classes.TopTitleBox}>
                <Typography className={classes.TopTitle}>Preguntas Frecuentes</Typography>
                <Typography className={classes.TopSubTitle}>&nbsp;Enterate de todo lo que necesitas</Typography>
            </Box>
            <List {...props} actions={<FAQEditActions />} bulkActionButtons={false} filters={false} pagination={false} style={{marginTop: '-65px'}}>
                <Box className={classes.AcordionBox}>
                    {ids.map(id => (
                        <Accordion expanded={expanded === data[id].id} onChange={handleChange(data[id].id)} TransitionProps={{ unmountOnExit: true }} >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" >
                                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                    {data[id].title}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.AcordionText}>
                                <Typography>
                                    <div dangerouslySetInnerHTML={{ __html: data[id].body }}/>
                                </Typography>
                                {permissions === 'Admin' &&
                                    <Box style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}} id="ButtonBox">
                                        <MyTooltip title={<div style={{fontSize: '12px'}}>Editar Pregunta</div>} arrow>
                                            <Button startIcon={<EditRoundedIcon/>} id="GoogleStyle-MuiButton" onClick={() => { redirect(`/faq/${data[id].id}/edit`)}}></Button>
                                        </MyTooltip>
                                        <MyTooltip title={<div style={{fontSize: '12px'}}>Eliminar Pregunta</div>} arrow>
                                            <Button startIcon={<DeleteRoundedIcon/>} id="GoogleStyleMuiDeleteButton" onClick={() => handleClick(data[id].id)}></Button>
                                        </MyTooltip>
                                    </Box>
                                }
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </List>
        </Box>
    )
}; export default FAQList;