import React from "react";
import { Tooltip, Zoom } from '@material-ui/core';

const TooltipWrapper = React.forwardRef(({children, ...props}, ref) => (
    <div ref={ref}>{React.cloneElement(children, props)}</div>
));

const MyTooltip = ({children, ...props}) => {
    return(
      <Tooltip {...props} TransitionComponent={Zoom} >
          <TooltipWrapper>{children}</TooltipWrapper>
      </Tooltip>
    )
}; export default MyTooltip;