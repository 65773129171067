import React, { useState, useEffect } from "react";
import { List, useGetOne, LinearProgress, TopToolbar, useRedirect, ReferenceInput, AutocompleteInput,
    useTranslate, useDataProvider, useNotify, useListContext, Filter, usePermissions
} from "react-admin"
import { Box, Typography, makeStyles, Button, ThemeProvider, TablePagination } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import * as locales from '@material-ui/core/locale';
import Skeleton from '@mui/material/Skeleton';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffRoundedIcon from '@mui/icons-material/NotificationsOffRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import lightTheme from '../layout/themes';
import MyTooltip from '../components/MuiTooltip';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import 'moment/locale/es';

import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const useStyles = makeStyles({
    image: lightTheme.NewsStyles.NewsList.image,
    TopToolbarCustom: lightTheme.NewsStyles.NewsList.TopToolbarCustom,
    boxTitle: lightTheme.NewsStyles.NewsList.boxTitle,
    TopTitle: lightTheme.CommonStyles.ListItems.TopTitle,
    TopSubTitle: lightTheme.CommonStyles.ListItems.TopSubTitle,
    TooltipTitle: lightTheme.CommonStyles.ListItems.TooltipTitle,
    btnNotify: lightTheme.NewsStyles.NewsList.btnNotify,
    ListBackground: lightTheme.NewsStyles.NewsList.ListBackground_RightSearchBar,
    NewsCard: lightTheme.NewsStyles.NewsList.NewsCard,
    ShopsBar: lightTheme.NewsStyles.NewsList.ShopsBar,
    skeletonNewsList:lightTheme.NewsStyles.NewsList.skeletonNewsList,
    BlackInputMargin: lightTheme.CommonStyles.CreateAndEdit.BlackInputMargin,
    NewsActionBar:{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    }
});

const AddSubscribe = () => {
    return(
      <Box>
        <Typography style={{color: '#fff', display: 'flex', width: '100%', fontSize: '14px'}}>
            <NotificationAddIcon/>
            <Box style={{marginLeft: '15px', paddingTop: '3px'}}><span>Te suscribiste al Newsletter de Noticias!</span></Box>
        </Typography>
      </Box>
    )
}

const RemoveSubscribe = () => {
    return(
      <Box>
        <Typography style={{color: '#fff', display: 'flex', width: '100%', fontSize: '14px'}}>
            <NotInterestedIcon/>
            <Box style={{marginLeft: '15px', paddingTop: '3px'}}><span>Ya no recibiras más emails de Noticias.</span></Box>
        </Typography>
      </Box>
    )
}

const SubCategotyName = ({ id }) => {
    const { data, loading, error } = useGetOne('newsSubCategories', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) { return (data.name); }
};

const NewsBody = ({ body , index}) => {
    if (body && index) { 
        const CleanHTML = sanitizeHtml(body, {
            disallowedTags: [ 'img' ]
        })
        return (<div dangerouslySetInnerHTML={{ __html: CleanHTML }} id={index}/>); 
    }
};

const ShopName = ({ id }) => {
    const { data, loading } = useGetOne('shops', id);
    if (loading) { return <LinearProgress /> }
    if (data) { 
        return (
            <div>
                {id === '618bd896efea2b2049ada486' &&
                    <img alt="logo" src="/static/images/Shops/TrendicoFaviconRounded.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada489' &&
                    <img alt="logo" src="/static/images/Shops/SotkattLogo.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada488' &&
                    <img alt="logo" src="/static/images/Shops/AtleetLogo.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada487' &&
                    <img alt="logo" src="/static/images/Shops/FoMLogo.png" id="LogoImg"/>
                }
                {id === '618bd896efea2b2049ada490' &&
                    <img alt="logo" src="/static/images/Shops/TrendicoFaviconRounded.png" id="LogoImg"/>
                }
                <span>{data.name}</span>
            </div>
        );
    }
};

const DaysPassed = ({ date }) => {
    const FormatDate = moment(date).format();
    const LongDate = moment(date).format('DD MMM YYYY');
    const ShortDate = moment(`${FormatDate}`).locale('es').fromNow();
    if (moment().subtract(60, 'days').valueOf() < moment(date).valueOf()) {
        return ( 
            <span style={{textTransform: 'capitalize'}}>{ShortDate}</span>
        )
    };
    if (moment().subtract(60, 'days').valueOf() > moment(date).valueOf()) {
        return ( 
            <span style={{textTransform: 'capitalize'}}>{LongDate}</span>
        )
    };
};

const UserName = ({ id }) => {
    const { data, loading, error } = useGetOne('users', id);
    if (loading) { return <LinearProgress /> }
    if (error) { return <p>ERROR</p>; }
    if (data) return (
        <div>
            <AccountCircleRoundedIcon id="LogoImg"/>
            <span>{data.displayName}</span>
        </div>
    );
};

const NewsList = (props) => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    const translate = useTranslate();

    const NewsGrid = () => { 
        const { data, ids, loading } = useListContext();
        const classes = useStyles();
        const redirect = useRedirect();

        const SkeletonData = {1: {key: 1, value: 1},2: {key: 2, value: 2},3: {key: 3, value: 3},4: {key: 4, value: 4},5: {key: 5, value: 5}};
        const SkeletonIds = [1,2,3,4,5];

        return (
            <div>
                {loading === false ?
                    <Box>
                        {ids.map(id => (
                            <Box key={id}>
                                {data[id] &&
                                    <div className={classes.NewsCard} onClick={() => redirect(`/publications/${data[id].id}/show`)}>
                                        <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
                                            <Box style={{display: 'flex', justifyContent: 'flex-start', width: '100%', flexDirection: 'column'}}>
                                                <Box className={classes.ShopsBar}>
                                                    <>
                                                        {data[id].shops && data[id].shops.map(shop => (
                                                            <Typography key={shop} component={'div'} className="ShopBadge" id={shop === '618bd896efea2b2049ada487' ? 'FoM' : 
                                                                (shop === '618bd896efea2b2049ada486' ? 'Trendico' : 
                                                                (shop === '618bd896efea2b2049ada488' ? 'Atleet' : 
                                                                (shop === '618bd896efea2b2049ada489' ? 'Sötkatt' : 
                                                                (shop === '618bd896efea2b2049ada490' ? 'TrendicoGroup' : 'ShopBadge'))))}
                                                            >
                                                                <ShopName id={shop} />
                                                            </Typography>
                                                        ))}
                                                    </>
                                                </Box>
                                                <Typography variant="h6" id="Title" onClick={() => redirect(`/publications/${data[id].id}/show`)}>
                                                    {data[id].title}
                                                </Typography>
                                                {data[id].category &&
                                                    <Typography component={'div'} variant="button" style={{color: '#196cdb', marginBottom: '15px'}}>
                                                        <SubCategotyName id={data[id].subCategory} />
                                                    </Typography>
                                                }
                                                <Typography component={'div'} variant="body2" id="Body">
                                                    {data[id].subTitle &&
                                                        <NewsBody body={data[id].subTitle} index="subTitle"/>
                                                    }
                                                    <NewsBody body={data[id].review ? data[id].review: data[id].body} index="review"/>
                                                    <span id="seeMore">Leer más »</span>
                                                </Typography>
                                                {data[id].autor &&
                                                    <Typography id="Date" component={'div'}>
                                                        <UserName id={data[id].autor} />&nbsp;-&nbsp;
                                                        <DaysPassed date={data[id].createdate}/>
                                                        {data[id].state === 'borrador' &&
                                                           <span>&nbsp;-&nbsp;<span id="DraftState">BORRADOR</span></span>
                                                        }
                                                    </Typography>
                                                }
                                            </Box>
                                            <div style={{display: 'flex', justifyContent: 'center', margin: '16px 0 16px 50px'}}>
                                                <div style={{overflow: 'hidden', position: 'relative', borderRadius: '8px', backgroundColor: '#fff', height: '250px', width: '250px'}}>
                                                    <g-img style={{display: 'flex', height: '100%', justifyContent: 'center'}}>
                                                        <img className={classes.image} alt="complex" src={data[id].logo.image}/>
                                                    </g-img>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Box>
                        ))}
                    </Box>
                :   
                    <div>
                       {SkeletonIds.map(id => (
                            <Box className={classes.skeletonNewsList} id={SkeletonData[id].key} key={id}>
                                <Box id="text">
                                    <div id="chips">
                                        <Skeleton variant="circular" width={110} height={20}/>
                                    </div>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Box>
                                <Box id="picture">
                                    <Skeleton />
                                </Box>
                            </Box>
                        ))}
                    </div>
                }
            </div>
        )
    }

    const NewsFilter = ({...props }) => {
        const classes = useStyles();
        const dataProvider = useDataProvider();
        const [isSuscribed, setIsSuscribed] = useState();
        const notify = useNotify();

        const addNewsletter = async () => {
            await dataProvider.addNewsletterSubscription('subscribe', { moduleId: 'news' })
            .then(({ data }) => {
                if (data.subscribed) {
                    notify(<AddSubscribe/>,'success');
                } else if (!data.subscribed) {
                    notify(<RemoveSubscribe/>,'error');
                }
                isSuscribedToNewsletter();
            })
            .catch(error => {
                console.log(error);
            })
        };

        const isSuscribedToNewsletter = () => {
            dataProvider.isSuscribedToNewsletter('isSubscribed', { moduleId: 'news' })
            .then(({ data }) => {
                setIsSuscribed(data.subscribed);
            })
            .catch(error => {
                console.log(error);
            })
        }

        useEffect(() => {
            isSuscribedToNewsletter();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
        return (
            <Box className={classes.NewsActionBar}>
                <TopToolbar className={classes.TopToolbarCustom}>
                    <Button color="primary" aria-label="add an alarm" onClick={() => addNewsletter()} className={classes.btnNotify} id="GoogleStyleOffer">
                        {isSuscribed === true &&
                        <MyTooltip title={<div className={classes.TooltipTitle}>Cancelar suscripción a la Newsletter de Noticias</div>} arrow placement="left">
                            <NotificationsOffRoundedIcon />
                        </MyTooltip>
                        }
                        {isSuscribed === false &&
                        <MyTooltip title={<div className={classes.TooltipTitle}>Suscribirse a la Newsletter de Noticias</div>} arrow placement="left">
                            <NotificationsActiveIcon />
                        </MyTooltip>
                        }
                    </Button >
                </TopToolbar>
                <Filter {...props}>
                    <ReferenceInput source="shops" label="Enseña" reference="shops" filterToQuery={searchText => ({ name: searchText })}
                        variant="outlined" className={classes.BlackInputMargin} perPage={500} alwaysOn>
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                </Filter>
            </Box>
        )
    };

    const NewsPagination = () => {
        const { page, perPage, total, setPage, setPerPage } = useListContext();
      
        const handleChangeRowsPerPage = (event) => { 
            setPerPage(parseInt(event.target.value, 10));
            setPage(1);
        };
        const handleChangePage = (event, newPage) => {
            setPage(newPage + 1);
         };
        
        return (
            <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales['esES'])}>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 12, 15, 20]}
                    count={total || 0}
                    page={page - 1}
                    onPageChange ={handleChangePage}
                    rowsPerPage={perPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </ThemeProvider>
        );
    }
    const NewsProps = {
        basePath: "/publications",
        resource: "publications",
    }
    return (
        <Box className={classes.ListBackground}>
            <Box className={classes.boxTitle}>
                <Typography component={'span'} className={classes.TopTitle}>{translate(`resources.news.name`, { smart_count: 2, })}</Typography>
                <Typography component={'span'} className={classes.TopSubTitle}>&nbsp;{translate(`resources.news.subTitle`)}</Typography>
            </Box>
            {permissions === 'Admin' &&
                <List {...NewsProps} actions={false} style={{marginTop: '-65px', marginBottom: '5em'}} perPage={10} pagination={<NewsPagination/>}
                    sort={{ field: 'createdate', order: 'DESC' }} filter={{ category: '6270d8d07a125adbf27a2006'}} filters={<NewsFilter/>}
                >
                    <NewsGrid record={props} style={{marginTop: '40px'}}/>
                </List>
            }
            {permissions !== 'Admin' &&
                <List {...NewsProps} actions={false} style={{marginTop: '-65px', marginBottom: '5em'}} perPage={10} pagination={<NewsPagination/>}
                    sort={{ field: 'createdate', order: 'DESC' }} filter={{ category: '6270d8d07a125adbf27a2006', state: 'publicado'}} filters={<NewsFilter/>}
                >
                    <NewsGrid record={props} style={{marginTop: '40px'}}/>
                </List>
            }
        </Box>
    )
}; export default NewsList;