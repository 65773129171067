import React from "react";
import { 
    Edit, SimpleForm, TextInput, useTranslate, required, ImageInput, ReferenceInput, DeleteButton, useGetOne,
    ImageField, SaveButton, Toolbar, SelectInput, SelectArrayInput, FormDataConsumer
} from "react-admin";
import { makeStyles, Box, Typography, CircularProgress } from "@material-ui/core";
import BackButton from '../components/BackButton';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import SubjectRoundedIcon from '@mui/icons-material/SubjectRounded';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import MyTooltip from '../components/MuiTooltip';
import lightTheme from "../layout/themes";
import clsx from 'clsx';
import TinyMCETextEditor from '../components/TextEditors/TinyMCETextEditor'


const useStyles = makeStyles({
    ImageBoxStyle: lightTheme.NewsStyles.CreateAndEdit.ImageBoxStyle,
    BoxBorder: lightTheme.NewsStyles.CreateAndEdit.ImageBoxStyle.BoxBorder,
    InputBlackBorder: lightTheme.InputBlackBorder,
    BlackInputMargin: lightTheme.CommonStyles.CreateAndEdit.BlackInputMargin,
    ToolBar: lightTheme.CommonStyles.CreateAndEdit.ToolBar,
    TooltipTitle: lightTheme.CommonStyles.ListItems.TooltipTitle,
    Flex100: lightTheme.CommonStyles.ListItems.Flex100,
    FlexStart100: lightTheme.CommonStyles.ListItems.FlexStart100,
    FlexTitleBox: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox,
    FlexTitle: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox.FlexTitle,
    FlexCloseButtonBox: lightTheme.CommonStyles.CreateAndEdit.FlexTitleBox.FlexCloseButtonBox,
    ModalidadBox:{
        display: 'flex',
        width: '100%',
        justifyContent:'flex-start',
        '& .MuiOutlinedInput-root':{
            height: '57px',
        },
        '& .MuiFormGroup-root':{
          width: '50%', 
          marginTop: '15px',
        },
        '& .MuiFormLabel-root':{
          backgroundColor: 'transparent',
          paddingLeft: '5px',
          paddingRight: '5px',
        }
    },
});

const NewsEdit = props => {
    const translate = useTranslate();
    const classes = useStyles();
    const {data} = useGetOne('publications', props.id);
    const redirect = () => `/publications/${props.id}/show`;

    const ShopChoices=[ 
        { _id: '618bd896efea2b2049ada486', name: 'Trendico' }, 
        { _id: '618bd896efea2b2049ada487', name: 'Foot on Mars' },
        { _id: '618bd896efea2b2049ada488', name: 'Atleet' },
        { _id: '618bd896efea2b2049ada489', name: 'Sötkatt' },
        { _id: '618bd896efea2b2049ada490', name: 'Trendico Group' }
    ] 

    const NewsEditToolbar = ({loading, ...props}) => {
        const classes = useStyles();
        return (
          <Box>
            <Toolbar {...props} className={ clsx(classes.ToolBar, classes.FlexStart100)}>
              <MyTooltip title={<div className={classes.TooltipTitle}>Guardar Cambios</div>} arrow>
                <SaveButton id="GoogleStyleSaveButton">
                  {loading && (<CircularProgress/>)}
                </SaveButton>
              </MyTooltip>
              <MyTooltip title={<div className={classes.TooltipTitle}>Eliminar Noticia</div>} arrow placement="right">
                <DeleteButton redirect={`/publications`} label="" id="GoogleStyleDeleteButton"/>
              </MyTooltip>
            </Toolbar>
          </Box>
        )
    };

    const StateChoices=[ 
        { id: 'publicado', name: 'Publicado' }, 
        { id: 'borrador', name: 'Borrador' },
    ] 

    return (
        <Edit {...props} actions={false}>
            <SimpleForm redirect={redirect} toolbar={<NewsEditToolbar/>}>
                {data &&
                    <Box style={{ width:'100%' }}>
                        <Box className={classes.FlexTitleBox} p={1}>
                            <Box className={classes.FlexTitle} p={1} >
                                <Typography variant="h5">Editar Publicación</Typography>
                            </Box>
                            <Box className={classes.FlexCloseButtonBox} p={1} >
                                <MyTooltip title={<div className={classes.TooltipTitle}>Cerrar</div>} arrow>
                                    <BackButton/>
                                </MyTooltip>
                            </Box>
                        </Box>
                        <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                            <InfoRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>Información General
                        </Typography>
                        <Box className={classes.ModalidadBox}>
                            <SelectArrayInput label="Comunicado para" variant="outlined" source="shops" style={{ width: '70%'}} 
                                className={classes.InputBlackBorder} choices={ShopChoices} optionValue="_id" optionText="name"/>
                            <ReferenceInput label="Categoría" source="category" reference="newsCategories" variant="outlined" perPage={999}
                                className={classes.BlackInputMargin} validate={required()} sort={{ field: 'name', order: 'ASC' }}>
                                <SelectInput source="name" style={{ width: '30%' }}/>
                            </ReferenceInput>
                            <ReferenceInput label="Subcategoría" source="subCategory" reference="newsSubCategories" variant="outlined" perPage={999}
                                className={classes.BlackInputMargin} validate={required()} sort={{ field: 'name', order: 'ASC' }}>
                                <SelectInput source="name" style={{ width: '30%' }}/>
                            </ReferenceInput>
                        </Box>
                        <Box className={classes.FlexStart100}>
                            <TextInput 
                                className={classes.BlackInputMargin} 
                                source="title" 
                                label={translate('resources.news.title')} 
                                validate={required()} 
                                variant="outlined"
                            />
                            <SelectInput required label="Estado" variant="outlined" source="state" choices={StateChoices}
                             multiline="true" fullWidth className={classes.BlackInputMargin} style={{textTransform: 'capitalize', width: '30%'}}/>
                        </Box>
                        <Box className={classes.FlexStart100}>
                            <TextInput 
                                className={classes.BlackInputMargin} 
                                source="subTitle" 
                                label="Subtítulo"
                                validate={required()} 
                                variant="outlined"
                                multiline="true"
                            />
                        </Box>
                        <Box className={classes.FlexStart100}>
                            <TextInput 
                                className={classes.BlackInputMargin} 
                                source="review" 
                                label="Entradita"
                                variant="outlined"
                                multiline="true"
                            />
                        </Box>
                        <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                            <SubjectRoundedIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>
                            Contenido de la Noticia <span style={{fontSize: '0.8rem', marginLeft: '5px'}}>(Tamaño Máximo de Fotos: 1 MB)</span>
                        </Typography>

                        <TinyMCETextEditor data={data.body}/>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => {
                                formData.body = formData.textValue
                            }}
                        </FormDataConsumer>

                        <Typography style={{fontSize: '1.1rem', margin: 10, marginBottom: 15, display: 'flex', alignItems: 'center', color: '#3d3d3d'}}>
                            <CropOriginalIcon style={{fontSize: '1.5rem', marginRight: '10px'}}/>Foto de Portada
                        </Typography>
                        <Box className={classes.ImageBoxStyle}>
                            <ImageInput source="logo" label="" accept="image/*" 
                                placeholder={<p>{translate('resources.news.imgPlaceHolder')}</p>} validate={required()}>
                                <ImageField source="image" title="imageName" id="ImageBox" style={{marginBottom: '15px'}}/>
                            </ImageInput>
                        </Box>

                    </Box>
                }
            </SimpleForm>
        </Edit>
    );
}; export default NewsEdit;