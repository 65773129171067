import React from "react";
import { useGetList, useRedirect, useGetOne, LinearProgress, usePermissions } from "react-admin";
import { makeStyles, Grid, Card, CardContent, Typography, Box, Button, CardMedia } from '@material-ui/core';
import { mdiFileDocumentOutline } from '@mdi/js';
import Icon from '@mdi/react'
import lightTheme from '../layout/themes';

const ConditionsIcon = () => {return(<Icon path={mdiFileDocumentOutline} size={1} />)};

const useStyles = makeStyles({
  PostTitle: lightTheme.PostTitle,
  PostSubTitle: lightTheme.PostSubTitle,
  EmptyBubbleConditions: lightTheme.EmptyBubble,
  EmptyConditionsMediaCard: lightTheme.EmptyConditionsMediaCard,
  SeeMoreButton:{
    '& .MuiButtonBase-root':{
        color: '#196cdb !important',
        textTransform: 'capitalize !important',
    },
    '& .MuiButtonBase-root:hover':{
        backgroundColor: '#196cdb2e !important',
    }
  },
  FolderGrid:{
    marginTop: '20px !important',
    width: '100% !important',
    margin: '0px !important',
    '& #ContentCard':{
      width: '100% !important',
    },
    '& #ContentCard:hover':{
      borderColor: 'transparent',
      boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.3) ,0px 2px 6px 5px rgba(26, 26, 26, 0.15) !important',
    }
  },
  Folder:{
    '& .MuiCardContent-root':{
      padding: '15px !important'
    },
    '& #FolderContent':{
      display: 'flex',
      width: '100% !important',
      alignItems: 'center',
      cursor: 'pointer',
      height: '40px',
      '& #GoogleStyleOffer':{
        display: 'none',
        '& svg':{
          paddingLeft: '13px'
        }
      }
    },
    '& #FolderContent:hover':{
      '& #GoogleStyleOffer':{
        display: 'flex',
      }
    },
    '& #FolderTitle':{
      color: '#424242',
      fontSize: '15px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '310px',
      '& svg':{
        marginBottom: '-5px',
        marginRight: '15px',
      }
    }
  }
});

const CampaignName = ({ id }) => {
  const { data, loading, error } = useGetOne('campaigns', id);
  if (loading) { return <LinearProgress /> }
  if (error) { return <p>ERROR</p>; }
  if (data) return (data.name);
};

const ConditionBox = (props) => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  const redirect = useRedirect();

  let filtersAdmin = {
    shop: props.shop,
  };

  let filtersUser = {
    shop: props.shop, 
    state: 'publicado'
  }


  const { data, ids } = useGetList('conditions', 
    { page: 1, perPage: 500 }, 
    { field: 'campaign_startDate', order: 'DESC'}, permissions === 'Admin' ? filtersAdmin : filtersUser
  )

  if (ids && ids.length > 0) {
    const FilteredData = ids.map(function (id) {
      return {'id': data[id]._id, 'shop': data[id].shop, 'provider': data[id].provider, 'campaign': data[id].campaign};
    });
    const ProviderFilter = FilteredData.filter(n => n.provider['id'] === props.provider);
    const ConditionsList = ProviderFilter.slice(0, 4);
    return(
      <Box>
        <Grid container spacing={4} className={classes.FolderGrid}>
          {ConditionsList.map(id => (
            <Grid key={id} xs={3} sm={3} md={6} lg={6} xl={6} item >
              <Card className={classes.Folder} id="ContentCard">
                <CardContent>
                  <Box id="FolderContent">
                    <Typography id="FolderTitle" onClick={() => redirect(`/conditions/${id.id}/show`)}>
                      <ConditionsIcon />
                      <CampaignName id={id.campaign}/>
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {ProviderFilter.length > 4 &&
        <Box id="SeeMoreButton">
          <div className={classes.SeeMoreButton}>
            <Button onClick={() => { redirect(`/conditions?displayedFilters={}&filter={"shop"%3A"${props.shop}"%2C"provider"%3A"${props.provider}"}`);}}>
              Ver todas las Campañas
            </Button>
          </div>
        </Box>
        }
      </Box>
    )
  } else {
    return (
      <Box display="flex" justifyContent="center" style={{width: '100%', paddingTop: '60px'}}>
        <Box textAlign="center" className={classes.EmptyBubble}>
          <CardMedia className={classes.EmptyConditionsMediaCard}></CardMedia>
          <Typography variant="h6" style={{marginTop: '20px'}}>
            No Hay Condiciones para este Proveedor
          </Typography>
        </Box>
      </Box>
    )
  }
}


const ConditionsGrid = props => {
  return (
    <ConditionBox {...props}/>
  )
}; export default ConditionsGrid;