import NoticeCreate from './PublicationsCreate';
import PublicationsEdit from './PublicationsEdit';
import PublicationsList from './PublicationsList';
import PublicationsShow from './PublicationsShow';
import PublicationsIcon from '@mui/icons-material/ForumRounded';

const NoticeModule = {
    list: PublicationsList,
    create: NoticeCreate,
    edit: PublicationsEdit,
    show: PublicationsShow,
    icon: PublicationsIcon
}

export default NoticeModule;